import { getMbuInstance } from "../entry"

export class BaseAPI {
  constructor() {
    const instance = getMbuInstance()
    this.configuration = instance.getAppSettings()
    this.auth = instance.getAuthClient()
  }

  get store() {
    return getMbuInstance().getStore()
  }

  get appId() {
    return this.configuration?.appId
  }

  get basePath() {
    return this.configuration.basePath
  }

  checkAdministrator(callback) {
    if (this.store.getters["mbuser/isAccountAdmin"]) {
      return callback()
    }
  }

  checkAuthorized(callback) {
    if (this.store.getters["mbuser/isAuthorized"]) {
      return callback()
    }
  }

  async accessToken() {
    const accessToken = await this.auth.getTokenSilently()
    return accessToken
  }

  async headers() {
    const accessToken = await this.accessToken()
    return {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    }
  }

  /**
   *
   * @param {string} path
   * @param {Object} [params] - optional array of query string parameters
   * @returns Promise<Response>
   */
  async get(path, params = {}) {
    var url = new URL(this.basePath + path)

    if (params) {
      // remove any params that are null, otherwise they come out in the url as '?key=null'
      const filtered = Object.fromEntries(
        Object.entries(params).filter(
          // eslint-disable-next-line no-unused-vars
          ([key, val]) => val != null
        )
      )

      url.search = new URLSearchParams(filtered).toString()
    }

    return fetch(url, {
      headers: await this.headers()
    })
  }

  async post(path, data) {
    return fetch(this.basePath + path, {
      method: "POST",
      headers: await this.headers(),
      body: JSON.stringify(data)
    })
  }

  async put(path, data) {
    return fetch(this.basePath + path, {
      method: "PUT",
      headers: await this.headers(),
      body: JSON.stringify(data)
    })
  }

  async delete(path) {
    return fetch(this.basePath + path, {
      method: "DELETE",
      headers: await this.headers()
    })
  }
}
