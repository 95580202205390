import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"

export const getters = {
  validation: (state, rootState, getters, rootGetters) => {
    return {
      vulnerableCustomer: {
        ...mapRulesForVulnerableCustomer(rootGetters)
      }
    }
  }
}

function mapRulesForVulnerableCustomer(rootGetters) {
  const kycFields = ["is_considered_vulnerable"]

  const factFind = rootGetters["caseSectionValidation/factfind"]

  const kycRules = createValidationRules(
    (field) =>
      factFind.required(
        "vulnerable_customer",
        "kyc_vulnerable_customer",
        field
      ),
    kycFields
  )

  const vulnerabilityFields = [
    "vulnerability_category_key",
    "related_vulnerability_health_key",
    "related_vulnerability_life_events_key",
    "related_vulnerability_resilience_key",
    "related_vulnerability_capability_key",
    "vulnerability_detail",
    "vulnerability_action_taken"
  ]

  const vunrabilityRules = createValidationRules(
    (field) => factFind.required("vulnerable_customer", "vulnerability", field),
    vulnerabilityFields
  )

  return {
    ...kycRules,
    ...vunrabilityRules
  }
}
