import {
  chainGuards,
  storeGuards
} from "@/global/modules/middleware/checkStore"
import { mortgageRequirementRoutes } from "./mortgage"
import { protectionRequirementRoutes } from "./protection"
import { giRequirementRoutes } from "./gi"

export const requirementRoutes = [
  {
    path: "requirements",
    beforeEnter: chainGuards([
      storeGuards.loadRequirementsGuard,
      storeGuards.loadCaseDocumentsGuard
    ]),
    component: () =>
      import(
        /* webpackChunkName: "client-case-requirements" */ "@/components/ux/clients/cases/requirements/CaseRequirements.vue"
      ),
    children: [
      {
        path: "",
        name: "client-case-requirements-list",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirements-list" */ "@/components/ux/clients/cases/requirements/CaseRequirementsList.vue"
          ),
        meta: {
          //requiresAuthCase: true,
          caseNavActive: true,
          title: "Requirements"
        }
      },
      {
        path: "add",
        name: "client-case-requirements-add",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirements-add" */ "@/components/ux/clients/cases/requirements/CaseRequirementsAdd.vue"
          ),
        meta: {
          //requiresAuthCase: true,
          caseNavActive: true,
          title: "Requirements / Add",
          excludeFromSnapshot: true
        }
      },
      ...mortgageRequirementRoutes,
      ...protectionRequirementRoutes,
      ...giRequirementRoutes
    ]
  }
]
