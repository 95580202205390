import store from "@/store"
import axios from "axios"

export const state = {
  clientPortalUsers: []
}

export const mutations = {
  setClientPortalUsers(state, users) {
    state.clientPortalUsers = users
  }
}

export const getters = {
  getClientPortalUsers: (state) => state.clientPortalUsers
}

export const actions = {
  async loadUsers({ dispatch }) {
    var selectedClients = store.getters["clientFile/selectedClients"]
    var clientsWithEmail = selectedClients
      .map((client) => ({
        full_name: client.fullname,
        email: client.contact_details?.emails?.find(
          (e) => e.is_contact_default === true
        )?.email_address,
        client_id: client.id
      }))
      .filter((c) => {
        return c.email !== null && c.email !== undefined
      })
    await dispatch("getInvitationDetails", clientsWithEmail)
  },

  async sendInvite({ dispatch }, id) {
    var selectedClients = store.getters["clientFile/selectedClients"]
    let thisClient = selectedClients.find((c) => c.id === id)
    let invitedUser = {
      full_name: thisClient.fullname,
      email: thisClient.contact_details?.emails?.find(
        (e) => e.is_contact_default === true
      )?.email_address,
      client_id: thisClient.id
    }
    await axios.post(
      `${process.env.VUE_APP_CDS_URL}/clientportaladvisor/invite`,
      invitedUser
    )
    await dispatch("loadUsers")
  },

  async getInvitationDetails({ commit }, clientList) {
    await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientportaladvisor/statuscheck`,
        clientList
      )
      .then((response) => {
        var portalClients = response.data
        commit("setClientPortalUsers", portalClients)
        Promise.resolve(true)
      })
      .catch(async (error) => {
        this.clientPortalDetails = []
        console.error(error)
        await window.alertAsync("connection error: " + error)
      })
  },

  async enableOrDisableFirm({ dispatch }, clientObj) {
    await axios.post(
      `${process.env.VUE_APP_CDS_URL}/clientportaladvisor/revoke?clientId=${clientObj.clientId}&accountId=${clientObj.accountId}&isFirmEnabled=${clientObj.isFirmEnabled}`
    )
    await dispatch("loadUsers")
  }
}
