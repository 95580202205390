import axios from "axios"

export const state = {
  products: []
}

export const getters = {
  products: (state) => state.products
}

export const mutations = {
  setProducts(state, data) {
    state.products = data
  },
  clearProducts(state) {
    state.products = []
  }
}

export const actions = {
  async loadGIProducts(context) {
    const caseId = context.rootGetters["cases/currentCaseId"]
    const requirementId =
      context.rootGetters["requirements/selectedRequirement"].id
    await axios
      .get(
        `${process.env.VUE_APP_CDS_URL}/cases/${caseId}/requirements/${requirementId}/general_insurance/products`
      )
      .then((res) => {
        context.commit("setProducts", res.data)
      })
      .catch(() => context.commit("clearProducts"))
  },
  async addUpdateGIProduct({ dispatch, rootGetters }, payload) {
    const caseId = rootGetters["cases/currentCaseId"]
    const requirementId = rootGetters["requirements/selectedRequirement"].id
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/cases/${caseId}/requirements/${requirementId}/general_insurance/products`,
        [payload]
      )
      if (response.status === 200) {
        await dispatch("loadGIProducts")
        return true
      }
    } catch (err) {
      console.log(err)
    }
    return false
  },
  async fetchGIProductForApplication(context) {
    const application = context.rootGetters["applications/selectedApplication"]
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/cases/${application.case_application.case_id}/requirements/${application.case_application.requirement_id}/general_insurance/products`
      )
      if (response.status === 200) {
        context.commit("setProducts", response.data)

        const product =
          application.case_application.general_insurance_details.product
        context.commit("requirements/setSelectedProduct", product, {
          root: true
        })
        context.commit("requirements/setGiProductsEditMode", true, {
          root: true
        })
        return product
      }
    } catch (err) {
      console.log(err)
    }
  }
}
