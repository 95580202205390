export function parseLinkString(
  linkText,
  linkStartCharacter = "[",
  linkEndCharacter = "]"
) {
  let splitInput = linkText.split(linkStartCharacter).filter((s) => s)
  let parsedText = []
  let linkIndex = 0
  splitInput.forEach((element) => {
    if (!element.includes(linkEndCharacter)) {
      parsedText.push({ text: element, link: false })
    } else {
      let splitOnEndCharacter = element.split(linkEndCharacter).filter((s) => s)
      parsedText.push({
        text: splitOnEndCharacter[0],
        link: true,
        linkIndex: linkIndex
      })
      linkIndex++
      if (splitOnEndCharacter.length > 1)
        parsedText.push({ text: splitOnEndCharacter[1], link: false })
    }
  })
  return parsedText
}
