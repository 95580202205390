import axios from "axios"
import { MbAddressMixin } from "@/plugins/components-plugin/entry.js"

export const state = {
  companies: [],
  individuals: [],
  branches: [],
  branchOptions: [],
  branchesForCompany: [],
  individualsForCompany: [],
  individualsForBranch: [],
  isNew: true,
  selectedCompany: {},
  selectedBranch: {},
  selectedIndividual: {},
  addressBookFilter: "btnCompanyAddressBook",
  roleListGuid: "",
  companyCount: 0
}

export const mutations = {
  clearCompanies(state) {
    state.companies = []
  },
  clearIndividuals(state) {
    state.individuals = []
  },
  clearBranches(state) {
    state.branches = []
  },
  clearBranchOptions(state) {
    state.branchOptions = []
  },
  addCompanies(state, data) {
    state.companies.push(data)
  },
  addIndividuals(state, data) {
    state.individuals.push(data)
  },
  addBranches(state, data) {
    state.branches.push(data)
  },
  setSelectedCompanyForView(state, data) {
    state.selectedCompany = data
  },
  setSelectedBranchForView(state, data) {
    state.selectedBranch = data
  },
  setCompanyCount(state, value) {
    state.companyCount = value
  },
  setAddressBookFilter(state, data) {
    state.addressBookFilter = data
  },
  setSelectedCompany(state, data) {
    state.isNew = false
    state.selectedCompany = data
  },
  setSelectedBranchForEdit(state, data) {
    state.isNew = false
    state.selectedBranch = data
  },
  setAddModeCompany(state) {
    state.isNew = true
    state.selectedCompany = null
  },
  setAddModeBranch(state) {
    state.isNew = true
    state.selectedBranch = null
  },
  setAddModeIndividual(state) {
    state.isNew = true
    state.selectedIndividual = null
  },
  addBranchForSelect(state, data) {
    state.branchOptions.push(data)
  },
  setRoleSelectGuid(state, data) {
    let guid = roleSelectGuid(data)
    state.roleListGuid = guid
  },
  setSelectedIndividualForEdit(state, data) {
    state.isNew = false
    state.selectedIndividual = data
  },
  setBranchesForCompany(state, data) {
    state.branchesForCompany = state.branches.filter((obj) => {
      return obj.business_id === data
    })
  },
  setIndividualsForCompany(state, data) {
    state.individualsForCompany = state.individuals.filter((obj) => {
      return obj.business_id === data
    })
  },
  setIndividualsForBranch(state, data) {
    state.individualsForBranch = state.individuals.filter((obj) => {
      return obj.branch_id === data
    })
  }
}

export const getters = {
  addressBookBranches: (state) => state.data,
  companies: (state) => state.companies,
  companyCount: (state) => state.companyCount,
  individuals: (state) => state.individuals,
  branches: (state) => state.branches,
  isAddMode: (state) => state.isNew,
  selectedCompany: (state) => state.selectedCompany,
  selectedBranch: (state) => state.selectedBranch,
  selectedIndividual: (state) => state.selectedIndividual,
  getAddressBookFilter: (state) => state.addressBookFilter,
  branchOptions: (state) => state.branchOptions,
  roleListGuid: (state) => state.roleListGuid,
  branchesForCompany: (state) => state.branchesForCompany,
  individualsForCompany: (state) => state.individualsForCompany,
  individualsForBranch: (state) => state.individualsForBranch
}

export const actions = {
  async loadAddressBook(context) {
    context.commit("clearCompanies")
    context.commit("clearIndividuals")
    context.commit("clearBranches")

    const getCompanies = axios.get(
      `${process.env.VUE_APP_CONTACTS_URL}/v1/businesses`
    )

    const getIndividuals = axios.get(
      `${process.env.VUE_APP_CONTACTS_URL}/v1/individuals`
    )
    const getBranches = axios.get(
      `${process.env.VUE_APP_CONTACTS_URL}/v1/branches`
    )

    return Promise.all([getCompanies, getIndividuals, getBranches])
      .then((responses) => {
        const [companiesResponse, individualResponse, branchResponse] =
          responses

        mapAddressBookForList(
          companiesResponse.data,
          individualResponse.data,
          branchResponse.data,
          context
        )

        context.commit("setCompanyCount", companiesResponse.data.total_count)
      })
      .catch((err) => console.log(err))
  },

  async addUpdateCompany({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CONTACTS_URL}/v1/businesses`,
        [payload]
      )
      if (response.status === 200) {
        await dispatch("loadAddressBook")
        let ids = response.data.map((e) => e.id)
        return ids
      }
    } catch (err) {
      console.log(err)
    }
    return null
  },
  async addUpdateBranch({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CONTACTS_URL}/v1/branches`,
        [payload]
      )
      if (response.status === 200) {
        await dispatch("loadAddressBook")
        let ids = response.data.map((e) => e.id)
        return ids
      }
    } catch (err) {
      console.log(err)
    }
    return null
  },
  async addUpdateIndividual({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CONTACTS_URL}/v1/individuals`,
        [payload]
      )
      if (response.status === 200) {
        await dispatch("loadAddressBook")
        let ids = response.data.map((e) => e.id)
        return ids
      }
    } catch (err) {
      console.log(err)
    }
    return null
  },
  getBranchOptions(context, companyId) {
    context.commit("clearBranchOptions")
    const filteredBranches = state.branches.filter((obj) => {
      return obj.business_id === companyId
    })
    filteredBranches.forEach((branchData) => {
      mapBranchesForSelect(branchData, context)
    })
  }
}

function mapAddressBookForList(
  companiesData,
  individualsData,
  branchesData,
  context
) {
  const companiesWithBranches = []

  companiesData.forEach((company) => {
    const companyBranches = []
    branchesData.forEach((branch) => {
      if (branch.business_id === company.id) {
        mapBranchesForList(branch, company, context)
        companyBranches.push(branch)
      }
    })

    mapCompaniesForList(company, companyBranches, context)
    companiesWithBranches.push({ company: company, branches: companyBranches })
  })

  const companyIDs = companiesWithBranches.map((company) => company.company.id)

  const pagedIndividualData = individualsData.filter((ind) =>
    companyIDs.includes(ind.business_id)
  )

  if (pagedIndividualData !== undefined) {
    pagedIndividualData.forEach((individual) => {
      const { company, branches } = companiesWithBranches?.find(
        (companyWithBranches) =>
          companyWithBranches?.company.id === individual.business_id
      )
      if (company) {
        mapIndividualsForList(individual, company, branches, context)
      }
    })
  }
}

function mapCompaniesForList(companyData, branchesData, context) {
  let addressStr = companyData.address
    ? MbAddressMixin.methods.formatAddress(
        { propertyAddress: companyData.address },
        context.rootGetters
      )
    : ""

  let defaultPhone = ""
  if (companyData.phones.length) {
    const phone = companyData.phones.find((p) => p.is_contact_default === true)
    if (phone) {
      defaultPhone = phone.phone_number
    }
  }

  const companyIndividualsCount = context.state.individuals.filter(
    (c) => c.business_id === companyData.id
  ).length

  let company = { ...companyData }
  company.individuals_count = companyIndividualsCount
  company.addressStr = addressStr
  company.defaultPhone = defaultPhone
  company.branch_count = branchesData.length
  company.introducer_link = company.introducer ? "Yes" : "No (Set Up)"
  company.type_text = context.rootGetters[
    "datadictionary/displayTextByFormIdAndValue"
  ]("q-e8fc7b8a-5d84-4c79-a123-96e88ef79c22", company.business_service_type_key)
  company.phones.forEach((phone) => {
    phone.department_text = context.rootGetters[
      "datadictionary/displayTextByFormIdAndValue"
    ]("q-7c91ddab-f258-40bc-9f1d-c0f3cb6f578f", phone.business_department)
  })
  company.emails.forEach((email) => {
    email.department_text = context.rootGetters[
      "datadictionary/displayTextByFormIdAndValue"
    ]("q-7c91ddab-f258-40bc-9f1d-c0f3cb6f578f", email.business_department)
  })

  context.commit("addCompanies", company)
}

function mapIndividualsForList(
  individualData,
  companyData,
  branchesData,
  context
) {
  const defaultPhone = individualData.phones?.length
    ? individualData.phones.find((p) => p.is_contact_default).phone_number
    : ""

  const defaultEmail = individualData.emails?.length
    ? individualData.emails[0].email_address
    : ""

  let individual = { ...individualData }

  individual.role_text = GetIndividualRole(individualData, companyData, context)
  individual.main_contact_number = defaultPhone
  individual.email_address = defaultEmail
  individual.fullname = `${individual.first_name} ${individual.last_name}`
  individual.business_name = companyData.business_name
  individual.company_type = companyData.business_service_type_key
  individual.branch =
    branchesData.find((e) => e.id === individualData.branch_id)
      ?.business_branch_name ?? ""

  context.commit("addIndividuals", individual)
}

function GetIndividualRole(individualData, companyData, context) {
  if (individualData.business_other_role)
    return individualData.business_other_role

  let roleGuid = roleSelectGuid(companyData.business_service_type_key)

  switch (companyData.business_service_type_key) {
    case "accountant":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_accountancy_role_key
      )
    case "estate_agent":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_estate_agency_role_key
      )
    case "insurance_provider":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_insurance_provider_role_key
      )
    case "mortgage_lender":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_mortgage_lender_role_key
      )
    case "removal_company":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_removal_company_role_key
      )
    case "solicitor":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_conveyancing_firm_role_key
      )
    case "surveyor":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_surveyor_firm_role_key
      )
    case "new_build_developer":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_new_build_developer_role_key
      )
    case "packager":
      return context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
        roleGuid,
        individualData.business_packager_firm_role_key
      )
  }
}

function mapBranchesForList(branchData, companyData, context) {
  let addressStr = branchData.address
    ? MbAddressMixin.methods.formatAddress(
        {
          propertyAddress: branchData.address
        },
        context.rootGetters
      )
    : ""

  let defaultPhone = branchData.phones?.length
    ? branchData.phones.find((p) => p.is_contact_default).phone_number
    : ""
  const branchIndividualsCount = context.state.individuals.filter(
    (c) => c.branch_id === branchData.id
  ).length

  let branch = { ...branchData }
  branch.individuals_count = branchIndividualsCount
  branch.addressStr = addressStr
  branch.default_phone = defaultPhone
  branch.company_type = companyData.business_service_type_key
  branch.company_type_text = context.rootGetters[
    "datadictionary/displayTextByFormIdAndValue"
  ](
    "q-e8fc7b8a-5d84-4c79-a123-96e88ef79c22",
    companyData.business_service_type_key
  )
  branch.individuals = companyData.individuals
  branch.introducer_link = companyData.introducer ? "Yes" : "No (Set Up)"
  branch.phones.forEach((phone) => {
    phone.department_text = context.rootGetters[
      "datadictionary/displayTextByFormIdAndValue"
    ]("q-7c91ddab-f258-40bc-9f1d-c0f3cb6f578f", phone.business_department)
  })
  branch.emails.forEach((email) => {
    email.department_text = context.rootGetters[
      "datadictionary/displayTextByFormIdAndValue"
    ]("q-7c91ddab-f258-40bc-9f1d-c0f3cb6f578f", email.business_department)
  })

  context.commit("addBranches", branch)
}

function mapBranchesForSelect(branchData, context) {
  let branchOption = {
    text: branchData.business_branch_name,
    value: branchData.id
  }

  context.commit("addBranchForSelect", branchOption)
}

function roleSelectGuid(type) {
  switch (type) {
    case "accountant":
      return "q-a3c0205f-4ebe-4243-93fd-62e230b92399"
    case "estate_agent":
      return "q-c6c9f9aa-49b9-46db-90d3-05b01f008132"
    case "insurance_provider":
      return "q-a88353fe-e902-464e-8e92-fc2769a70f73"
    case "mortgage_lender":
      return "q-c2865419-5262-4272-a42b-ac4a01e759bd"
    case "removal_company":
      return "q-87b12dee-ab60-4c3b-9f59-6ea2e3dfa1b4"
    case "solicitor":
      return "q-2e3cd709-d4b1-44a6-8460-a4a0b4575bbf"
    case "surveyor":
      return "q-3b879b65-a32d-42ed-9c49-a6c4aae5dd1f"
    case "new_build_developer":
      return "q-22dfa348-2f18-407c-a887-697f1680e37b"
    case "packager":
      return "q-e4d71f4f-f495-4270-919a-cc944a27750c"
  }
}
