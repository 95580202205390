<template>
  <div>
    <!-- bind input event to emitted event as the default change event does not detect clear button-->
    <v-select
      ref="select"
      v-model="selectValue"
      :options="options"
      :multiple="isMultiSelect"
      :clearable="isClearable"
      :data-cy="field.form_id"
      label="text"
      :style="attributes.style"
      :class="{ 'select-is-invalid': highlightAsRequired }"
      :placeholder="attributes.placeholder"
      :select-on-tab="true"
      :disabled="disabled"
      :readonly="disabled"
      @input="(e) => emitDelayed('userupdated', e)"
    />
  </div>
</template>

<script>
import FormInputHelpers from "@/plugins/components-plugin/global/modules/mixins/formInputHelpers.js"
import ConversionsMixin from "@/plugins/components-plugin/global/modules/mixins/conversions"
import vSelect from "vue-select"

export default {
  name: "MbSelectInput",
  components: {
    vSelect
  },
  mixins: [ConversionsMixin, FormInputHelpers],
  props: {
    field: {
      type: Object,
      default: null
    },
    prepopOptions: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String, Boolean, Array, Number],
      default: null
    },
    attrs: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlightAsRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectValue: null,
      attributes: {
        placeholder: "",
        style: ""
      },
      selectText: "",
      /**
       * This ready state is needed to not emit a model
       * change on component init, as it causes vee-validate to trigger to early.
       * Due to it coming *after" component mount so vee-validates immediate=false check
       * has already been passed. This won't affect loading of already known values as
       * that is controlled by the child vue-select, this is just a proxy so any
       * changes made on the select will still propogate up as this ready state will have
       * been passed.
       */
      ready: false
    }
  },
  computed: {
    /**
     * The selected option should only be clearable when
     * not a mandatory field
     */
    isClearable() {
      let defaultProvided = !(
        this.field.default_value === null ||
        this.field.default_value === undefined
      )
      let preventClear = this.field.validation.required || defaultProvided
      return !preventClear
    },
    isMultiSelect() {
      return this.field.data_type === "array"
    },
    options() {
      if (this.field && this.prepopOptions.length) {
        return this.prepopOptions
      }
      // If no data return empty field
      if (!this.field?.select_values?.length) {
        return []
      }
      return this.field.select_values
    },
    inputEl() {
      if (!this?.$refs?.select?.$refs?.search) {
        return null
      }
      return this.$refs.select.$refs.search
    }
  },
  watch: {
    disabled: {
      handler() {
        // set readonly attribute on input el
        this.setInputReadonly()
      }
    },
    selectValue(val) {
      if (val === undefined || val === null) {
        this.emit(val)
        return
      }
      if (!this.isMultiSelect) {
        this.selectText = val.text
        this.emit(this.convertToWantedType(this.field.data_type, val.value))
        return
      }
      const ret = []
      const displayTest = []
      for (var value of val) {
        ret.push(this.convertToWantedType(this.field.data_type, value.value))
        displayTest.push(value.text)
      }
      this.selectText = displayTest.toString()
      this.emit(ret)
    },
    value(value) {
      const a = value
      const b = this.selectValue

      if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length || !a.every((v, i) => v === b[i].value)) {
          this.updateSelectValue(value)
        }
      } else if (a !== b) {
        this.updateSelectValue(value)
      }
    }
  },
  beforeMount() {
    // If the dictionary item has an input config apply
    // to the input attributes
    if (this.field.input_config || false) {
      this.attributes = Object.assign(this.attributes, this.field.input_config)
    }
  },
  mounted() {
    this.updateSelectValue(this.value)
    //Apply initial calculated style first
    var minWidth = this.calculateMinWidth(this.options)
    this.attributes.style = `min-width: ${minWidth}px`
    this.attributes = Object.assign(this.attributes, this.attrs)
    this.attributes.style += ";background-color: #fff"
    // set readonly attribute on input el
    this.setInputReadonly()
  },
  methods: {
    emit(value) {
      if (this.ready) return this.$emit("input", value)
      this.ready = true
    },
    updateSelectValue(value) {
      if (!this.isMultiSelect) {
        this.selectValue = this.options.find((opt) => {
          return (
            opt.value ===
            this.convertFromWantedType(this.field.data_type, value)
          )
        })
        return
      }
      if (Array.isArray(value)) {
        this.selectValue = this.options.filter((opt) => {
          return value.includes(
            this.convertToWantedType(this.field.data_type, opt.value)
          )
        })
      }
    },
    calculateMinWidth(options) {
      let iconRightGenerousPad = 72
      if (!Array.isArray(options) || options.length === 0) {
        if (this.attributes.placeholder) {
          //calc min width using placeholder
          return (
            getTextWidthHelper(this.attributes.placeholder) +
            iconRightGenerousPad
          )
        }
        return 90
      }

      var optionWithLongestText = options.reduce(function (a, b) {
        return (a.text?.length ?? 0) > (b.text?.length ?? 0) ? a : b
      })

      return (
        getTextWidthHelper(optionWithLongestText.text) + iconRightGenerousPad
      )

      function getTextWidthHelper(text, padLR = true) {
        const el = document.body

        const fontWeight = getCssStyle(el, "font-weight") || "normal"
        const fontSize = getCssStyle(el, "font-size") || "16px"
        const fontFamily = getCssStyle(el, "font-family") || "Times New Roman"

        const currentFont = `${fontWeight} ${fontSize} ${fontFamily}`
        if (padLR) {
          text = " " + text + " "
        }
        return Math.ceil(getTextWidth(" " + text + " ", currentFont))
      }
      function getTextWidth(text, font) {
        // re-use canvas object for better performance
        const canvas =
          getTextWidth.canvas ||
          (getTextWidth.canvas = document.createElement("canvas"))
        const context = canvas.getContext("2d")
        context.font = font
        const metrics = context.measureText(text)
        return metrics.width
      }

      function getCssStyle(element, prop) {
        return window.getComputedStyle(element, null).getPropertyValue(prop)
      }
    },
    setInputReadonly() {
      const inputEl = this.inputEl
      if (!inputEl) {
        return
      }
      if (this.disabled) {
        inputEl.setAttribute("readonly", "readonly")
      } else {
        inputEl.removeAttribute("readonly")
      }
    }
  }
}
</script>
