import axios from "axios"

export const state = {
  notes: [],
  currentNote: {},
  isNew: false,
  secondaryFilter: ""
}

export const getters = {
  pinnedNotes: (state) =>
    state.notes.filter((note) => note.pinned && !note.is_deleted),
  allNotes: (state) => state.notes.filter((note) => !note.is_deleted),
  requirementNotes: (state) => (requirementId) =>
    state.notes.filter(
      (note) =>
        !note.is_deleted &&
        note.category === "requirement" &&
        note.requirement_id === requirementId
    ),
  currentNote: (state) => state.currentNote,
  isAddMode: (state) => state.isNew,
  secondaryFilter: (state) => state.secondaryFilter
}

export const mutations = {
  clearNotes(state) {
    state.notes = []
  },
  setNotes(state, notes) {
    state.notes = notes
  },
  addNote(state, note) {
    state.notes.push(note)
  },
  // addUpdateNote(state, note) {
  //   var index = state.notes.findIndex(function findById(currentNote) {
  //     return currentNote.id === note.id
  //   })

  //   if (index > -1) {
  //     state.notes[index] = note
  //   } else {
  //     state.notes.push(note)
  //   }
  // },
  setNoteForAdd(state, note) {
    state.isNew = true
    state.currentNote = note
  },
  setNoteForEdit(state, note) {
    state.isNew = false
    state.currentNote = note
  },
  setSecondaryFilter(state, data) {
    state.secondaryFilter = data
  }
}

export const actions = {
  async loadNotes(context) {
    var caseId = context.rootGetters["cases/currentCaseId"]
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    return await axios
      .get(
        `${process.env.VUE_APP_CDS_URL}/casenotes/getforcaseid/${caseId}${snapshotIdQuery}`
      )
      .then((response) => {
        context.commit("clearNotes")
        response.data.forEach((noteData) => {
          mapNotesForList(noteData, context)
        })
        Promise.resolve(true)
      })
  },
  async saveNote({ dispatch }, notes) {
    var payload = mapNotesForSave(notes)
    return await axios
      .post(`${process.env.VUE_APP_CDS_URL}/casenotes/createorupdate`, payload)
      .then(() => {
        return dispatch("loadNotes")
      })
  },

  async toggleNotePin({ dispatch, state }, noteId) {
    const noteToPin = state.notes.find((note) => note.id === noteId)

    if (noteToPin) {
      const notePinToSave = JSON.parse(JSON.stringify(noteToPin))
      notePinToSave.pinned = !notePinToSave.pinned
      const mapNoteToPin = mapNotesForSave(notePinToSave)
      return await axios
        .post(
          `${process.env.VUE_APP_CDS_URL}/casenotes/createorupdate`,
          mapNoteToPin
        )
        .then(() => {
          return dispatch("loadNotes")
        })
    }
  },
  async deleteNote({ state }, noteId) {
    var noteToDelete = state.notes.find((note) => note.id === noteId)

    if (noteToDelete) {
      noteToDelete.is_deleted = true
      const mapNoteToDelete = mapNotesForSave(noteToDelete)

      return await axios
        .post(
          `${process.env.VUE_APP_CDS_URL}/casenotes/createorupdate`,
          mapNoteToDelete
        )
        .then(() => {
          Promise.resolve(true)
        })
    }
  },
  createNoteForAdd(context, { note }) {
    let caseId = context.rootGetters["cases/currentCaseId"]
    let defaultNote = {
      note_text: null,
      case_id: caseId,
      pinned: false,
      category: null,
      case_section: null,
      fact_find_section: null,
      requirement_section: null,
      application_section: null,
      requirement_id: null
    }
    //  let key = getCategorySectionKey(note.category)
    //  if (key && defaultSection) {
    //   note[key] = defaultSection
    // }
    Object.assign(defaultNote, note)
    context.commit("setNoteForAdd", defaultNote)
  },
  fetchNoteForEdit({ state, commit }, noteId) {
    var noteForEdit = state.notes.find((note) => note.id === noteId)
    commit("setNoteForEdit", JSON.parse(JSON.stringify(noteForEdit)))
  }
}

function mapNotesForSave(notes) {
  //Use spread to avoid undesirable GUI issues due to watchers
  const formattedNotes = Array.isArray(notes)
    ? notes.map((note) => ({ ...note }))
    : [{ ...notes }]

  formattedNotes.forEach((note) => {
    Object.keys(note).forEach((nameOfKey) => {
      if (nameOfKey === "category") {
        note.case_area_key = note.category
        delete note.category
      }

      if (nameOfKey === "pinned") {
        note.is_pinned = note.pinned
        delete note.pinned
      }

      if (nameOfKey === "case_section") {
        note.case_area_sub_cat_key = note.case_section
        delete note.case_section
      }
    })
  })
  return formattedNotes
}

function mapNotesForList(noteData, context) {
  Object.keys(noteData).forEach((nameOfKey) => {
    if (nameOfKey === "case_area_key") {
      noteData.category = noteData.case_area_key
      delete noteData.case_area_key
    }

    if (nameOfKey === "is_pinned") {
      noteData.pinned = noteData.is_pinned
      delete noteData.is_pinned
    }

    if (nameOfKey === "case_area_sub_cat_key") {
      noteData.case_section = noteData.case_area_sub_cat_key
      delete noteData.case_area_sub_cat_key
    }
  })

  context.commit("addNote", noteData)
}
