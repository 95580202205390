export function formatClientNamesForList(linkedClientsOnRecord, allClients) {
  let clientNamesArray = linkedClientsOnRecord.map((clientId) => {
    let client = allClients.find((client) => client.id === clientId)
    if (client) return client.fullname
    else return "Other"
  })
  if (clientNamesArray.length === 0) {
    return ""
  } else if (clientNamesArray.length === 1) {
    return clientNamesArray[0]
  } else if (clientNamesArray.length === 2) {
    return clientNamesArray.join(" & ")
  } else {
    var lastElement = clientNamesArray.pop()
    return clientNamesArray.join(", ") + ", & " + lastElement
  }
}
