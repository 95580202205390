import axios from "axios"
import { formatClientNamesForList } from "@/global/modules/helpers/clientHelpers"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"

export const state = {
  clients: [],
  selectedClient: {},
  selectedClients: [],
  selectedPropertyInfo: {},
  selectedAddress: {},
  allProperties: [],
  propertyInsights: {},
  selectedProperty: {},
  isNew: false,
  readonly: false,
  excludedClientsFromSelectionList: []
}

export const mutations = {
  clearProperties(state) {
    state.allProperties = []
    state.propertyInsights = {}
  },
  clearProperty(state) {
    state.selectedProperty = {}
  },
  allProperties(state, data) {
    state.allProperties.push(data)
  },
  setPropertyInsight(state, data) {
    state.propertyInsights = data
  },
  setSelectedClients(state, clients) {
    state.selectedClients = clients
  },
  setSelectedPropertyInfo(state, info) {
    state.selectedPropertyInfo = info
  },
  setSelectedAddress(state, address) {
    state.selectedAddress = address
  },
  setPropertyForAdd(state, property) {
    state.isNew = true
    state.selectedProperty = property
    state.readonly = false
  },
  setPropertyForEdit(state, property) {
    state.isNew = false
    state.selectedProperty = property
    state.readonly = true
  },
  setPropertyReadonly(state, readonly) {
    state.readonly = readonly
  },
  setAddMode(state, isNew) {
    state.isNew = isNew
  },
  setExcludedClientsFromSelectionList(state, data) {
    state.excludedClientsFromSelectionList = data
  }
}

export const actions = {
  async loadProperties(context) {
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    const clientIds = context.rootGetters["clientFile/selectedClients"].map(
      (client) => client.id
    )

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientproperties/getforclientids${snapshotIdQuery}`,
        clientIds
      )
      context.commit("clearProperties")
      if (response.status === 200) {
        response.data.forEach((propertiesData) => {
          mapPropertyForList(
            propertiesData,
            context.rootGetters["clientFile/clients"],
            context
          )
        })
        context.dispatch("calcInsight")
      }
    } catch (err) {
      console.log(err)
    }
  },
  calcInsight(context) {
    const estimatedValues = {
      property_value: 0
    }

    context.state.allProperties.forEach((property) => {
      estimatedValues.property_value += property.avm_asset_amount
    })

    // TODO: Waiting for Insight rules to be defined
    estimatedValues.mortgage_balance = estimatedValues.property_value * 0.6
    estimatedValues.equity =
      estimatedValues.property_value - estimatedValues.mortgage_balance
    estimatedValues.ltv = (
      (estimatedValues.mortgage_balance / estimatedValues.property_value) *
      100
    ).toFixed(2)

    context.commit("setPropertyInsight", estimatedValues)
  },
  deleteProperty({ dispatch, state }, payload) {
    let mainProperty = {}

    // search for the property record by id
    mainProperty = state.allProperties.find((c) => {
      return c.id === payload.id
    })

    mainProperty.is_deleted = true
    mainProperty.deleted_at = new Date()

    //update the record
    dispatch("addUpdateProperty", mainProperty)
  },
  // eslint-disable-next-line no-unused-vars
  async addProperty(
    { dispatch },
    { clients, usage, property_status_all_key, address, mortgaged }
  ) {
    let toAdd = createProperty(
      clients,
      usage,
      property_status_all_key,
      address,
      mortgaged
    )
    return await dispatch("addUpdateProperty", toAdd)
  },

  /**
   *
   * @returns property on save success, otherwise will return null
   */
  async addUpdateProperty({ dispatch, state }, payload) {
    let property = null
    if (!Array.isArray(payload)) payload = [payload]
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientproperties/createorupdate`,
        payload
      )
      if (response.status === 200) {
        let id = null
        if (response.data?.length > 0) {
          id = response.data[0].id
        }

        await dispatch("loadProperties")
        if (id) {
          property = state.allProperties.find((c) => {
            return c.id === id
          })
        }
      }
    } catch (err) {
      console.log(err.message)
    }

    return property
  },
  createPropertyForAdd(
    { commit },
    {
      clients = [],
      usage = "",
      property_status_all_key,
      address,
      mortgaged = null
    }
  ) {
    let property = createProperty(
      clients,
      usage,
      property_status_all_key,
      address,
      mortgaged
    )
    commit("setPropertyForAdd", property)
    return property
  },
  fetchPropertyForEdit({ commit, state }, id) {
    state.selectedProperty = {}
    var foundProperty = state.allProperties.find(
      (property) => property.id === id
    )

    if (foundProperty) {
      commit("setPropertyForEdit", foundProperty)
      return foundProperty
    }
    return null
  }
}

export const getters = {
  allProperties(state) {
    return state.allProperties
  },
  readonly(state) {
    return state.readonly
  },
  isAddMode(state) {
    return state.isNew
  },
  selectedClient(state) {
    return state.selectedClient
  },
  selectedClients(state) {
    return state.selectedClients
  },
  selectedPropertyInfo(state) {
    return state.selectedPropertyInfo
  },
  selectedAddress(state) {
    return state.selectedAddress
  },
  propertyInsights(state) {
    return state.propertyInsights
  },
  selectedProperty(state) {
    return state.selectedProperty
  },
  excludedClientsFromSelectionList(state) {
    return state.excludedClientsFromSelectionList
  },
  validation: (state, rootState, getters, rootGetters) => {
    return {
      list: {
        ...mapRulesForList(rootGetters)
      },
      property: {
        ...mapRulesForProperty(rootGetters)
      }
    }
  }
}

function mapRulesForList(rootGetters) {
  const fields = ["kyc_client_has_properties"]
  return mapRules(rootGetters, "kyc_properties", fields)
}

function mapRulesForProperty(rootGetters) {
  const fields = [
    "property_type_key",
    "property_status_all_key",
    "exchange_date",
    "purchase_target_amount",
    "home_property_valuation_home_report_amount",
    "estimated_full_market_valuation_date",
    "estimated_full_market_valuation_amount",
    "rental",
    "estimated_rental_valuation_date",
    "estimated_rental_monthly_amount",
    "property_style_key",
    "property_construction_year",
    "property_bedroom_count",
    "property_bathroom_count",
    "property_reception_count",
    "property_habitable_room_count",
    "property_heating_type_key",
    "property_tenure_key",
    "property_lease_remaining_years",
    "is_property_ex_local_authority",
    "property_listed_status_key",
    "new_build_warranty_type_key",
    "property_construction_type_key",
    "property_roof_type_key",
    "property_flat_roof_percentage",
    "property_flat_floor_number_key",
    "property_flat_floor_count",
    "is_property_flat_serviced_by_lift",
    "property_construction_type_notes",
    "property_energy_rating_current",
    "property_energy_rating_potential",
    "property_energy_rating_inspection_date",
    "property_annual_ground_rent_amount",
    "property_annual_service_charge_amount",
    "property_usage_key",
    "property_is_mortgaged",
    "mortgage_occupancy_type_key",
    "mortgage_provider_id",
    "mortgage_balance_without_erc_amount",
    "mortgage_account_balance_with_erc_amount",
    "mortgage_occupancy_type_key",
    "special_scheme_type_key",
    "clients_have_lived_in_property",
    "letting_rentable_room_count",
    "letting_expected_rental_amount",
    "letting_rental_frequency_key",
    "letting_actual_rental_amount",
    "letting_type_key",
    "letting_is_licensed",
    "letting_occupant_type_key",
    "hmo_licence_required_key",
    "property_transaction_type_key",
    "property_asking_amount",
    "property_has_offer_accepted",
    "property_agreed_purchase_amount",
    "property_purchase_amount",
    "property_valuation_home_report_amount",
    "property_target_ownership_completion_date",
    "property_exchanged_contracts_date",
    "property_exchange_deadline_date",
    "property_target_exchange_date",
    "property_ownership_completion_date"
  ]

  return mapRules(rootGetters, "property", fields)
}

function mapRules(rootGetters, subSection, fields) {
  const factFind = rootGetters["caseSectionValidation/factfind"]

  return createValidationRules(
    (field) => factFind.required("properties", subSection, field),
    fields
  )
}

function mapPropertyForList(propertyData, clients, context) {
  propertyData.who = formatClientNamesForList(
    propertyData.linked_clients,
    clients
  )

  context.commit("allProperties", propertyData)
}

function createProperty(
  clients,
  usage,
  property_status_all_key,
  address,
  mortgaged
) {
  return {
    linked_clients: clients,
    property_type_key: null,
    property_status_all_key: property_status_all_key,
    address: address,
    exchange_date: null,
    purchase_target_amount: null,
    home_property_valuation_home_report_amount: null,
    estimated_full_market_valuation_date: null,
    estimated_full_market_valuation_amount: null,
    avm_asset_date: null,
    avm_asset_amount: null,
    avm_asset_lower_amount: null,
    avm_asset_higher_amount: null,
    avm_asset_confidence_key: null,
    rental: null,
    estimated_rental_valuation_date: null,
    estimated_rental_monthly_amount: null,
    avm_rental_date: null,
    avm_rental_amount: null,
    avm_rental_lower_amount: null,
    avm_rental_higher_amount: null,
    avm_rental_confidence_key: null,
    avm_full_address: null,
    property_style_key: null,
    property_construction_year: null,
    property_bedroom_count: null,
    property_bathroom_count: null,
    property_reception_count: null,
    property_habitable_room_count: null,
    property_heating_type_key: null,
    property_tenure_key: null,
    property_lease_remaining_years: null,
    is_property_ex_local_authority: null,
    property_listed_status_key: null,
    new_build_warranty_type_key: null,
    property_construction_type_key: null,
    property_roof_type_key: null,
    property_flat_roof_percentage: null,
    property_flat_floor_number_key: null,
    property_flat_floor_count: null,
    is_property_flat_serviced_by_lift: null,
    property_construction_type_notes: null,
    letting_details: [],
    letting_type_key: null,
    letting_occupant_type_key: null,
    letting_actual_rental_amount: null,
    letting_expected_rental_amount: null,
    letting_rental_frequency_key: null,
    hmo_details: {
      letting_rentable_room_count: null,
      letting_is_licensed: null,
      hmo_licence_required_key: null
    },
    property_energy_rating_current: null,
    property_energy_rating_potential: null,
    property_energy_rating_inspection_date: null,
    property_annual_ground_rent_amount: null,
    property_annual_service_charge_amount: null,
    property_usage_key: usage,
    property_is_mortgaged: mortgaged,
    special_scheme_type_key: "none",
    clients_have_lived_in_property: null,
    sale: {
      property_transaction_type_key: "sale",
      property_asking_amount: null,
      property_has_offer_accepted: null,
      property_agreed_purchase_amount: null,
      property_purchase_amount: null,
      property_valuation_home_report_amount: null,
      property_target_ownership_completion_date: null,
      property_exchanged_contracts_date: null,
      property_exchange_deadline_date: null,
      property_target_exchange_date: null,
      property_ownership_completion_date: null
    },
    purchase: {
      property_transaction_type_key: "purchase",
      property_asking_amount: null,
      property_has_offer_accepted: null,
      property_agreed_purchase_amount: null,
      property_purchase_amount: null,
      property_valuation_home_report_amount: null,
      property_target_ownership_completion_date: null,
      property_exchanged_contracts_date: null,
      property_exchange_deadline_date: null,
      property_target_exchange_date: null,
      property_ownership_completion_date: null
    }
  }
}
