export const commissionType = Object.freeze({
  procFee: "procuration_fee",
  protectionCommission: "protection_commission",
  giCommission: "general_insurance_commission",
  clientFee: "client_fee",
  referralFee: "referral_fee"
})

export const commissionProtectionType = Object.freeze({
  indemnity: "indemnity",
  nonIndemnity: "non_indemnity"
})

export const commissionPaymentRoute = Object.freeze({
  direct: "direct",
  club: "club",
  network: "network"
})

export const commissionReconciliationStatus = Object.freeze({
  written: "written",
  payable: "payable",
  receiving: "receiving",
  cancelled: "cancelled",
  received: "received",
  partReceived: "part_received"
})

export const closureWorkflowStatus = Object.freeze({
  open: "open",
  closed: "closed"
})

export const closureWorkflowOutcome = Object.freeze({
  paid: "paid",
  partPaid: "part_paid",
  notProceededWith: "not_proceeded_with"
})

export const commissionWaiverReason = Object.freeze({
  other: "other"
})

export const commissionSplitType = Object.freeze({
  advisor: "advisor",
  introducer: "introducer"
})

export const commissionSplitDeductionMethod = Object.freeze({
  fixedAmount: "fixed_amount",
  percentageAmount: "percentage_amount"
})
