<template>
  <span class="tool-tip">
    <i :ref="toolTipId" class="mdi mdi-help-circle cursor-pointer"></i>
    <b-tooltip
      :id="toolTipId"
      :target="() => $refs[toolTipId]"
      :triggers="triggerType"
      :custom-class="customClass"
    >
      <div v-clickoutsideparent="onClose">
        <div style="height: 1em">
          <i
            class="fa fa-fw fa-times-circle float-right cursor-pointer"
            @click="onClose"
          ></i>
        </div>

        <div v-html="data"></div>
      </div>
    </b-tooltip>
  </span>
</template>

<script>
import { BTooltip } from "bootstrap-vue"

export default {
  name: "MbToolTip",
  components: { BTooltip },
  directives: {
    clickoutsideparent: {
      bind: function (element, binding, vnode) {
        element.clickOutsideEvent = function (event) {
          // check that click was outside the parent element and its children
          let parent = element.parentElement
          if (
            !(
              element === event.target ||
              element.contains(event.target) ||
              event.target === parent
            )
          ) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener("click", element.clickOutsideEvent)
      },
      unbind: function (element) {
        document.body.removeEventListener("click", element.clickOutsideEvent)
      }
    }
  },
  props: {
    triggerType: {
      type: String,
      default: "hover focus"
    },
    data: {
      type: [String],
      default: "Content will be updated soon..."
    },
    customClass: {
      type: [String],
      default: "topright"
    }
  },
  data() {
    return {
      toolTipId: "toolTipId-" + Math.random()
    }
  },
  methods: {
    onOpen() {
      this.$refs.tooltip.$emit("open")
    },
    onClose() {
      this.$root.$emit("bv::hide::tooltip", this.toolTipId)
    }
  }
}
</script>

<style>
body .tooltip.b-tooltip {
  opacity: 1;
  font-size: 13px;
}

.client-header-info .tooltip-inner {
  max-width: 260px;
  padding: 12px 14px;
  color: #495057;
  text-align: left;
  background-color: #ced4da;
}

.mdi-help-circle::before {
  font-size: 14px;
  color: #000 !important;
  line-height: 12px;
}
.float-right {
  float: right;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
