import axios from "axios"
import { businessProcessingConfig } from "@/data/business-processing.js"
import { Mutex } from "async-mutex"

export const state = {
  data: [],
  count: 0,
  loadMutex: new Mutex(),
  loadAxiosController: null
}

function abortLoadAxiosController(state) {
  if (state.loadAxiosController) {
    try {
      state.loadAxiosController.abort("Operation cancelled by the user.")
    } catch (ex) {
      console.error(ex)
    }
  }
}
async function loadWithMutexAsync(state, url, dto) {
  let request = state.loadMutex.runExclusive(async () => {
    abortLoadAxiosController(state)
    state.loadAxiosController = new AbortController()
    return axios.post(url, dto, {
      signal: state.loadAxiosController.signal
    }) //no await, return the raw promise.
  }) //end mutex
  return await request
}

export const getters = {
  data: (state) => state.data,
  count: (state) => state.count
}

export const mutations = {
  setData(state, value) {
    state.data = value
  },
  setCount(state, value) {
    state.count = value
  }
}

export const actions = {
  async fetch({ commit, state }, fetchInfo) {
    let default_sort = businessProcessingConfig.default_sort
    let sort_field = fetchInfo.sort_field
      ? fetchInfo.sort_field
      : default_sort
      ? default_sort.sort_field
      : ""
    let sort_ascending =
      fetchInfo.sort_ascending !== null &&
      fetchInfo.sort_ascending !== undefined
        ? fetchInfo.sort_ascending
        : default_sort
        ? default_sort.sort_ascending
        : true
    let dto = {
      report_name: "BusinessProcessing",
      search_fields: businessProcessingConfig.search_fields,
      page_number: fetchInfo.page_number,
      search_text: fetchInfo.search_text,
      per_page: fetchInfo.per_page,
      sort_field: sort_field,
      filters: fetchInfo.filters,
      secondary_sort_field: fetchInfo.secondary_sort_field,
      sort_ascending: sort_ascending
    }
    try {
      let url = `${process.env.VUE_APP_REPORTS_URL}/reports/runreport`
      let response = await loadWithMutexAsync(state, url, dto)
      // Cancelled by subsequent request.
      if (response === undefined) return
      if (response.status === 200) {
        commit("setData", response.data.Results)
        commit("setCount", response.data.Count)
      }
    } catch (err) {
      console.log(err)
    }
  },
  loadCancel({ state }) {
    abortLoadAxiosController(state)
  }
}
