import axios from "axios"
import { Mutex } from "async-mutex"

export const state = {
  data: [],
  clientCompanies: [],
  loadClientsMutex: new Mutex(),
  loadClientsAxiosController: null,
  loadClientCompaniesMutex: new Mutex(),
  loadClientCompaniesAxiosController: null,
  currentClientId: "",
  addClientActive: false,
  clientFileActive: false,
  clientCasesActive: false,
  associateClientActive: false,
  noteActive: false,
  employments: [],
  soleTraderIncomes: [],
  newAssociation: false,
  newOpportunityActive: false,
  newOpportunityExistingClient: false,
  clientPermissionActive: false,
  createOpportunityActive: false,
  newOpportunityProductType: "",
  newOpportunityProductRecord: {},
  addCompanyActive: false,
  addClientToCompanyActive: false,
  companyIdForEdit: ""
}
function abortLoadClientsAxiosController(state) {
  if (state.loadClientsAxiosController) {
    try {
      state.loadClientsAxiosController.abort("Operation cancelled by the user.")
    } catch (ex) {
      console.error(ex)
    }
  }
}
async function loadClientsWithMutexAsync(state, url) {
  let request = state.loadClientsMutex.runExclusive(async () => {
    abortLoadClientsAxiosController(state)
    state.loadClientsAxiosController = new AbortController()
    return axios.get(url, {
      signal: state.loadClientsAxiosController.signal
    }) //no await, return the raw promise.
  }) //end mutex
  return await request
}
function abortLoadClientCompaniesAxiosController(state) {
  if (state.loadClientCompaniesAxiosController) {
    try {
      state.loadClientCompaniesAxiosController.abort(
        "Operation cancelled by the user."
      )
    } catch (ex) {
      console.error(ex)
    }
  }
}
async function loadClientCompaniesWithMutexAsync(state, url) {
  let request = state.loadClientCompaniesMutex.runExclusive(async () => {
    abortLoadClientCompaniesAxiosController(state)
    state.loadClientCompaniesAxiosController = new AbortController()
    return axios.get(url, {
      signal: state.loadClientCompaniesAxiosController.signal
    }) //no await, return the raw promise.
  }) //end mutex
  return await request
}

export const mutations = {
  setCurrentClientId(state, id) {
    state.currentClientId = id
  },
  setAddClientActive(state, status) {
    if (status) {
      state.associateClientActive = false
      state.noteActive = false
      state.newOpportunityActive = false
      state.addCompanyActive = false
      state.addClientToCompanyActive = false
    }
    state.addClientActive = status
  },
  setClientFileActive(state, status) {
    state.clientFileActive = status
  },
  setClientCasesActive(state, status) {
    state.clientCasesActive = status
  },
  setAssociateClientActive(state, status) {
    if (status) {
      state.addClientActive = false
      state.noteActive = false
      state.newOpportunityActive = false
      state.addCompanyActive = false
      state.addClientToCompanyActive = false
    }
    state.associateClientActive = status
  },
  setNewAssociation(state, status) {
    state.newAssociation = status
  },
  setNoteActive(state, status) {
    if (status) {
      state.addClientActive = false
      state.associateClientActive = false
      state.newOpportunityActive = false
      state.addCompanyActive = false
      state.addClientToCompanyActive = false
    }
    state.noteActive = status
  },
  setClientPermission(state, status) {
    if (status) {
      state.clientPermissionActive = false
    }
    state.clientPermissionActive = status
  },
  setNewOpportunityActive(state, status) {
    if (status) {
      state.addClientActive = false
      state.associateClientActive = false
      state.noteActive = false
      state.addCompanyActive = false
      state.addClientToCompanyActive = false
    }
    state.newOpportunityActive = status
  },
  setAddCompanyActive(state, status) {
    if (status) {
      state.addClientActive = false
      state.associateClientActive = false
      state.noteActive = false
      state.newOpportunityActive = false
      state.addClientToCompanyActive = false
    }
    state.addCompanyActive = status
  },
  setAddClientToCompanyActive(state, status) {
    if (status) {
      state.addClientActive = false
      state.associateClientActive = false
      state.noteActive = false
      state.newOpportunityActive = false
      state.addCompanyActive = false
    }
    state.addClientToCompanyActive = status
  },
  clearClients(state) {
    state.data = []
  },
  clearClientCompanies(state) {
    state.clientCompanies = []
  },
  setCompanyIdForEdit(state, id) {
    state.companyIdForEdit = id
  },
  setNewOpportunityExistingClient(state, status) {
    state.newOpportunityExistingClient = status
  },
  setCreateOpportunityActive(state, { status, type, productRecord }) {
    state.createOpportunityActive = status
    state.newOpportunityProductType = type
    state.newOpportunityProductRecord = productRecord
  }
}

export const getters = {
  data: (state) => state.data,
  clientCompanies: (state) => state.clientCompanies,
  showAddClient: (state) => state.addClientActive,
  showAssociateClient: (state) => state.associateClientActive,
  showNote: (state) => state.noteActive,
  currentClientId: (state) => state.currentClientId,
  soleTraderIncomes: (state) => state.soleTraderIncomes,
  newAssociation: (state) => state.newAssociation,
  getClientName: (state) => (id) =>
    state.data.find((client) => client.id === id)?.full_name || null,
  showEnquiry: (state) => state.newOpportunityActive,
  showClientPermission: (state) => state.clientPermissionActive,
  showAddCompany: (state) => state.addCompanyActive,
  showAddClientToCompany: (state) => state.addClientToCompanyActive,
  companyIdForEdit: (state) => state.companyIdForEdit,
  newOpportunityExistingClient: (state) => state.newOpportunityExistingClient,
  showCreateOpportunity: (state) => state.createOpportunityActive,
  newOpportunityProductType: (state) => state.newOpportunityProductType,
  newOpportunityProductRecord: (state) => state.newOpportunityProductRecord
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  createClient({ state }, data) {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_CDS_URL}/clients`, data, config)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  loadClientsCancel({ state }) {
    abortLoadClientsAxiosController(state)
  },
  loadClientCompaniesCancel({ state }) {
    abortLoadClientCompaniesAxiosController(state)
  },
  async loadClients(
    { state },
    { pageNumber, perPage, filter, sortBy, descending }
  ) {
    let clientsCount = null
    let url = filter
      ? `${process.env.VUE_APP_CDS_URL}/clients/list?PageNumber=${pageNumber}&ItemsPerPage=${perPage}&FilterText=${filter}&SortColumn=${sortBy}&SortDescending=${descending}`
      : `${process.env.VUE_APP_CDS_URL}/clients/list?PageNumber=${pageNumber}&ItemsPerPage=${perPage}&SortColumn=${sortBy}&SortDescending=${descending}`
    let response = await loadClientsWithMutexAsync(state, url)
    if (response === undefined) {
      //response was canceled
      return state.data?.length ?? 0
    }
    if (response.status === 200) {
      clientsCount = response.data.total_count
      state.data = response.data.data.map((client) => mapClientForList(client))
    }
    return clientsCount
  },
  async loadClientCompanies(
    { state },
    { pageNumber, perPage, filter, sortBy, descending }
  ) {
    let clientCompaniesCount = null
    let url = filter
      ? `${process.env.VUE_APP_CDS_URL}/clientcompanies/list?pagenumber=${pageNumber}&itemsperpage=${perPage}&business_name=${filter}&sortcolumn=${sortBy}&sortdescending=${descending}`
      : `${process.env.VUE_APP_CDS_URL}/clientcompanies/list?pagenumber=${pageNumber}&itemsperpage=${perPage}&sortcolumn=${sortBy}&sortdescending=${descending}`
    let response = await loadClientCompaniesWithMutexAsync(state, url)
    if (response === undefined) {
      //response was canceled
      return state.clientCompanies?.length ?? 0
    }
    if (response.status === 200) {
      clientCompaniesCount = response.data.total_count
      state.clientCompanies = response.data.data
    }
    return clientCompaniesCount
  }
}

function getFullName(client) {
  let names = [client.first_name, client.last_name]
  return names.filter((e) => e).join(" ")
}

function getFirstInitialSurname(client) {
  var names = [client.first_name?.charAt(0), client.last_name]
  return names.filter((e) => e).join(" ")
}

function mapClientForList(client) {
  return {
    id: client.id,
    title_key: client.title_key,
    first_name: client.first_name,
    surname: client.last_name,
    full_name: getFullName(client),
    first_initial_surname: getFirstInitialSurname(client),
    birth_date: client.birth_date,
    age: client.age,
    address: client.address,
    phone: client.phone_number,
    email: client.email_address,
    status: client.status,
    created_at: client.created_at,
    client_primary_advisor_id: client.client_primary_advisor_id
  }
}
