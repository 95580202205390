import { getInstance } from "@/plugins/auth0-plugin"
import store from "@/store"

export const authenticationGuard = (to, from, next) => {
  if (process.env.VUE_APP_AUTH_ENABLED == "false") {
    return next()
  }

  const authService = getInstance()

  const guardAction = () => {
    if (authService.isAuthenticated) {
      store
        .dispatch("datadictionary/refresh")
        .then(() => store.dispatch("datadictionary/refresh"))
        .then(() => next())
        .catch((error) => console.log(error))
    } else {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    }
  }

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction()
  }

  authService.$watch("isLoading", (isLoading) => {
    if (isLoading === false) {
      return guardAction()
    }
  })
}
