import axios from "axios"
import { formatClientNamesForList } from "@/global/modules/helpers/clientHelpers"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"

export const state = {
  selectedClients: [],
  clients: [],
  allCreditHistory: [],
  ccjs: [],
  defaults: [],
  dros: [],
  bankruptcies: [],
  ivas: [],
  arrears: [],
  selectedCCJ: {},
  selectedDefault: {},
  selectedArrears: {},
  selectedIVA: {},
  selectedBankruptcy: {},
  selectedDRO: {},
  supportingDocuments: [],
  selectedType: "",
  isNew: false,
  adverseCreditSelected: null,
  excludedClientsFromSelectionList: []
}

export const mutations = {
  clearCreditHistories(state) {
    state.ccjs = []
    state.defaults = []
    state.bankruptcies = []
    state.ivas = []
    state.arrears = []
    state.dros = []
    state.allCreditHistory = []
  },
  addSelectedClientAndType(state, data) {
    state.isNew = true
    state.selectedClients = data.client
    state.selectedType = data.type
  },
  setTypeAndSelectedClientsForFactFind(state, data) {
    state.selectedClients = data.client
    state.selectedType = data.type
  },
  allCreditHistory(state, data) {
    state.allCreditHistory.push(data)
  },
  // CCJ
  addCCJ(state, data) {
    state.ccjs.push(data)
  },
  setCCJ(state, data) {
    state.selectedCCJ = data
  },
  // Bankruptcy
  addBankruptcy(state, data) {
    state.bankruptcies.push(data)
  },
  setBankruptcy(state, data) {
    state.selectedBankruptcy = data
  },
  // Default
  addDefault(state, data) {
    state.defaults.push(data)
  },
  setDefault(state, data) {
    state.selectedDefault = data
  },
  // DRO
  addDRO(state, data) {
    state.dros.push(data)
  },
  setDRO(state, data) {
    state.selectedDRO = data
  },
  // Arrears
  addArrears(state, data) {
    state.arrears.push(data)
  },
  setArrears(state, data) {
    state.selectedArrears = data
  },
  // IVA
  addIVA(state, data) {
    state.ivas.push(data)
  },
  setIVA(state, data) {
    state.selectedIVA = data
  },
  setAdverseCreditSelected(state, data) {
    state.adverseCreditSelected = data
  },
  setExcludedClientsFromSelectionList(state, data) {
    state.excludedClientsFromSelectionList = data
  }
}

export const actions = {
  async loadCreditHistories(context) {
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    const clientIds = context.rootGetters["clientFile/selectedClients"].map(
      (client) => client.id
    )
    await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientcredithistory/getforclientids${snapshotIdQuery}`,
        clientIds
      )
      .then((res) => {
        context.commit("clearCreditHistories")
        res.data.forEach((creditHistoryData) => {
          mapCreditHistoryForList(
            creditHistoryData,
            context.rootGetters["clientFile/clients"],
            context
          )
        })
      })
      .catch(() => context.commit("clearCreditHistories"))
  },
  saveSelectedClientAndType(context, payload) {
    context.commit("addSelectedClientAndType", payload)
  },
  async addUpdateCreditHistory({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientcredithistory/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        await dispatch("loadCreditHistories")
      }
    } catch (err) {
      console.log(err.response.data)
    }
  },
  async deleteCreditHistory({ dispatch }, payload) {
    // set delete properties
    payload.is_deleted = true
    payload.deleted_at = new Date()

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientcredithistory/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        dispatch("loadCreditHistories")
      }
    } catch (err) {
      console.log(err)
    }
  },
  // end Arrears
  fetchCreditHistory({ commit, state }, payload) {
    // init variables
    let mainItem = {}
    let itemToEdit = {}
    state.isNew = false

    // search for the credit history record
    mainItem = state.allCreditHistory.filter((ch) => {
      return ch.id === payload.id
    })[0]

    itemToEdit = {
      id: mainItem.id,
      whose: mainItem.whose,
      credit_issue_type_key: mainItem.credit_issue_type_key,
      linked_clients: mainItem.linked_clients,
      // reason: mainItem.reason,
      // explanation: mainItem.explanation,
      version: mainItem.version
    }

    // set values for factfind
    commit("setTypeAndSelectedClientsForFactFind", {
      client: mainItem.linked_clients,
      type: mainItem.credit_issue_type_key
    })
    switch (payload.type) {
      case "debt_relief_order":
        itemToEdit.dro_details = { ...mainItem.dro_details }

        commit("setDRO", itemToEdit)
        break
      case "default":
        itemToEdit.default_details = { ...mainItem.default_details }
        itemToEdit.default_details.amount =
          itemToEdit.default_details.amount?.toString()

        commit("setDefault", itemToEdit)
        break
      case "county_court_judgement":
        itemToEdit.ccj_details = { ...mainItem.ccj_details }
        itemToEdit.ccj_details.credit_issue_judgement_amount =
          itemToEdit.ccj_details.credit_issue_judgement_amount?.toString()

        commit("setCCJ", itemToEdit)
        break
      case "bankruptcy_sequestration":
        itemToEdit.bankruptcy_details = { ...mainItem.bankruptcy_details }

        commit("setBankruptcy", itemToEdit)
        break
      case "individual_voluntary_arrangement":
        itemToEdit.iva_details = { ...mainItem.iva_details }
        itemToEdit.iva_details.os_balance =
          itemToEdit.iva_details.os_balance?.toString()
        itemToEdit.iva_details.monthlyPayment =
          itemToEdit.iva_details.monthlyPayment?.toString()

        commit("setIVA", itemToEdit)
        break
      case "arrears_missed_payments":
        itemToEdit.arrears_details = { ...mainItem.arrears_details }
        itemToEdit.arrears_details.credit_issue_missed_payment_date =
          mapMissedPaymentDates(
            itemToEdit.arrears_details.credit_issue_missed_payment_date
          )
        itemToEdit.arrears_details.credit_issue_oustanding_balance =
          itemToEdit.arrears_details.credit_issue_oustanding_balance?.toString()

        commit("setArrears", itemToEdit)
        break
      default:
    }
  },
  saveAdverseCreditSelected({ commit }, value) {
    commit("setAdverseCreditSelected", value)
  }
}

export const getters = {
  creditHistoryClients(state) {
    return state.clients
  },
  allCreditHistory(state) {
    return state.allCreditHistory
  },
  isAddMode(state) {
    return state.isNew
  },
  selectedClients(state) {
    return state.selectedClients
  },
  selectedType(state) {
    return state.selectedType
  },
  adverseCreditSelected(state) {
    return state.adverseCreditSelected
  },
  selectedClientNames(state, getters, rootState, rootGetters) {
    return rootGetters["clientFile/selectedClients"]
      .filter((client) => state.selectedClients.includes(client.id))
      .reduce((names, client) => {
        names.push(client.fullname)
        return names
      }, [])
      .join(", ")
  },
  selectedCCJ(state) {
    return state.selectedCCJ
  },
  selectedIVA(state) {
    return state.selectedIVA
  },
  selectedDefault(state) {
    return state.selectedDefault
  },
  selectedDRO(state) {
    return state.selectedDRO
  },
  selectedArrears(state) {
    return state.selectedArrears
  },
  selectedBankruptcy(state) {
    return state.selectedBankruptcy
  },
  bankruptcies(state) {
    return state.bankruptcies
  },
  ccjs(state) {
    return state.ccjs
  },
  defaults(state) {
    return state.defaults
  },
  ivas(state) {
    return state.ivas
  },
  arrears(state) {
    return state.arrears
  },
  dros(state) {
    return state.dros
  },
  excludedClientsFromSelectionList(state) {
    return state.excludedClientsFromSelectionList
  },
  validation: (state, rootState, getters, rootGetters) => {
    return {
      list: {
        ...mapRulesForList(rootGetters)
      },
      iva: {
        ...mapRulesForIva(rootGetters)
      },
      dro: {
        ...mapRulesForDro(rootGetters)
      },
      defaults: {
        ...mapRulesForDefaults(rootGetters)
      },
      ccj: {
        ...mapRulesForCcj(rootGetters)
      },
      bankruptcy: {
        ...mapRulesForBankruptcy(rootGetters)
      },
      arrears: {
        ...mapRulesForArrears(rootGetters)
      }
    }
  }
}

function mapRulesForList(rootGetters) {
  const fields = ["kyc_client_has_adverse_credit"]
  return mapRules(rootGetters, "kyc_credit_history", fields)
}

function mapRulesForIva(rootGetters) {
  const fields = [
    "credit_issue_management_company",
    "credit_issue_registered_date",
    "is_credit_issue_satisfied",
    "credit_issue_satisfied_date",
    "credit_issue_oustanding_balance",
    "credit_issue_method_of_repayment_key",
    "credit_issue_repayment_amount",
    "credit_issue_repayment_has_conducted_satisfactorily",
    "credit_issue_reason_key",
    "credit_issue_notes",
    "is_credit_issue_intended_to_be_satisfied",
    "credit_issue_resolution_key"
  ]

  const conditions = {
    credit_issue_satisfied_date: creditIssueSatisfiedEqualsTrue,
    credit_issue_oustanding_balance: creditIssueSatisfiedEqualsFalse,
    credit_issue_method_of_repayment_key: creditIssueSatisfiedEqualsFalse,
    credit_issue_repayment_amount: {
      "==": [
        { var: "credit_issue_method_of_repayment_key" },
        "monthly_instalments"
      ]
    },
    is_credit_issue_intended_to_be_satisfied: creditIssueSatisfiedEqualsFalse,
    credit_issue_resolution_key: {
      and: [creditIssueSatisfiedEqualsFalse, creditIssueToBeSatisfiedEqualsTrue]
    }
  }

  return mapRules(rootGetters, "credit_issue_iva", fields, conditions)
}

function mapRulesForDro(rootGetters) {
  const fields = [
    "credit_issue_creditor_name",
    "credit_issue_registered_date",
    "credit_issue_is_discharged",
    "credit_issue_discharge_date",
    "credit_issue_reason_key",
    "credit_issue_notes",
    "is_credit_issue_intended_to_be_satisfied",
    "credit_issue_resolution_key"
  ]

  const conditions = {
    credit_issue_discharge_date: creditIssueIsDischargedEqualsTrue,
    is_credit_issue_intended_to_be_satisfied:
      creditIssueIsDischargedEqualsFalse,
    credit_issue_resolution_key: {
      and: [
        creditIssueIsDischargedEqualsFalse,
        creditIssueToBeSatisfiedEqualsTrue
      ]
    }
  }

  return mapRules(rootGetters, "credit_issue_dro", fields, conditions)
}

function mapRulesForDefaults(rootGetters) {
  const fields = [
    "credit_account_type_key",
    "credit_issue_creditor_name",
    "credit_issue_judgement_amount",
    "credit_issue_registered_date",
    "is_credit_issue_satisfied",
    "credit_issue_satisfied_date",
    "credit_issue_reason_key",
    "credit_issue_notes",
    "is_credit_issue_intended_to_be_satisfied",
    "credit_issue_resolution_key"
  ]

  const conditions = {
    credit_issue_satisfied_date: creditIssueSatisfiedEqualsTrue,
    is_credit_issue_intended_to_be_satisfied: creditIssueSatisfiedEqualsFalse,
    credit_issue_resolution_key: {
      and: [creditIssueSatisfiedEqualsFalse, creditIssueToBeSatisfiedEqualsTrue]
    }
  }

  return mapRules(rootGetters, "credit_issue_default", fields, conditions)
}

function mapRulesForCcj(rootGetters) {
  const fields = [
    "credit_account_type_key",
    "credit_issue_creditor_name",
    "credit_issue_judgement_amount",
    "credit_issue_registered_date",
    "is_credit_issue_satisfied",
    "credit_issue_satisfied_date",
    "credit_issue_reason_key",
    "credit_issue_notes",
    "is_credit_issue_intended_to_be_satisfied",
    "credit_issue_resolution_key"
  ]

  const conditions = {
    credit_issue_satisfied_date: creditIssueSatisfiedEqualsTrue,
    is_credit_issue_intended_to_be_satisfied: creditIssueSatisfiedEqualsFalse,
    credit_issue_resolution_key: {
      and: [creditIssueSatisfiedEqualsFalse, creditIssueToBeSatisfiedEqualsTrue]
    }
  }

  return mapRules(rootGetters, "credit_issue_ccj", fields, conditions)
}

function mapRulesForBankruptcy(rootGetters) {
  const fields = [
    "credit_issue_registered_date",
    "credit_issue_is_discharged",
    "credit_issue_discharge_date",
    "credit_issue_reason_key",
    "credit_issue_notes",
    "is_credit_issue_intended_to_be_satisfied",
    "credit_issue_resolution_key"
  ]

  const conditions = {
    credit_issue_discharge_date: creditIssueIsDischargedEqualsTrue,
    is_credit_issue_intended_to_be_satisfied:
      creditIssueIsDischargedEqualsFalse,
    credit_issue_resolution_key: {
      and: [
        creditIssueIsDischargedEqualsFalse,
        creditIssueToBeSatisfiedEqualsTrue
      ]
    }
  }

  return mapRules(rootGetters, "credit_issue_bankruptcy", fields, conditions)
}

function mapRulesForArrears(rootGetters) {
  const fields = [
    "credit_issue_arrears_type_key",
    "credit_issue_creditor_name",
    "credit_issue_missed_payments_six_years_count",
    "is_credit_issue_account_upto_date",
    "credit_issue_oustanding_balance",
    "credit_issue_missed_payment_date",
    "credit_issue_has_payment_plan",
    "credit_issue_payment_plan_notes",
    "credit_issue_reason_key",
    "credit_issue_notes",
    "is_credit_issue_intended_to_be_satisfied",
    "credit_issue_resolution_key"
  ]

  const creditIssueAccountUptoDateEqualsFalse = {
    "==": [{ var: "is_credit_issue_account_upto_date" }, false]
  }

  const creditIssueHasPaymentPlanEqualsTrue = {
    "==": [{ var: "credit_issue_has_payment_plan" }, true]
  }

  const conditions = {
    credit_issue_oustanding_balance: creditIssueAccountUptoDateEqualsFalse,
    credit_issue_has_payment_plan: creditIssueAccountUptoDateEqualsFalse,
    credit_issue_payment_plan_notes: {
      and: [
        creditIssueAccountUptoDateEqualsFalse,
        creditIssueHasPaymentPlanEqualsTrue
      ]
    },
    is_credit_issue_intended_to_be_satisfied:
      creditIssueAccountUptoDateEqualsFalse,
    credit_issue_resolution_key: {
      and: [
        creditIssueAccountUptoDateEqualsFalse,
        creditIssueToBeSatisfiedEqualsTrue
      ]
    }
  }

  return mapRules(rootGetters, "credit_issue_arrears", fields, conditions)
}

const creditIssueSatisfiedEqualsTrue = {
  "==": [{ var: "is_credit_issue_satisfied" }, true]
}

const creditIssueSatisfiedEqualsFalse = {
  "==": [{ var: "is_credit_issue_satisfied" }, false]
}

const creditIssueToBeSatisfiedEqualsTrue = {
  "==": [{ var: "is_credit_issue_intended_to_be_satisfied" }, true]
}

const creditIssueIsDischargedEqualsFalse = {
  "==": [{ var: "credit_issue_is_discharged" }, false]
}

const creditIssueIsDischargedEqualsTrue = {
  "==": [{ var: "credit_issue_is_discharged" }, true]
}

function mapRules(rootGetters, subSection, fields, conditions) {
  const factFind = rootGetters["caseSectionValidation/factfind"]
  return createValidationRules(
    (field) => factFind.required("credit_history", subSection, field),
    fields,
    conditions
  )
}

function mapMissedPaymentDates(dateOfMissedPayments) {
  const missedDates = []

  dateOfMissedPayments.reverse().forEach((ccDate) => {
    //missedDates.push({ missedDate: ccDate })
    missedDates.push(ccDate)
  })
  return missedDates
}

function mapCreditHistoryForList(creditHistoryData, clients, context) {
  creditHistoryData.whose = formatClientNamesForList(
    creditHistoryData.linked_clients,
    clients
  )
  let creditHistory = {}

  //add to the allCreditHistory collection
  context.commit("allCreditHistory", creditHistoryData)

  switch (creditHistoryData.credit_issue_type_key) {
    case "bankruptcy_sequestration":
      creditHistory = {
        id: creditHistoryData.id,
        whose: creditHistoryData.whose,
        type: creditHistoryData.credit_issue_type_key,
        registered:
          creditHistoryData.bankruptcy_details.credit_issue_registered_date,
        discharged:
          creditHistoryData.bankruptcy_details.credit_issue_discharge_date,
        explanation: creditHistoryData.bankruptcy_details.credit_issue_notes,
        toBeSatisfied: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-c428d785-467f-4b91-8d97-6921bd0ace6f",
          creditHistoryData.bankruptcy_details
            .is_credit_issue_intended_to_be_satisfied
        ),
        completed:
          creditHistoryData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addBankruptcy", creditHistory)
      break
    case "county_court_judgement":
      creditHistory = {
        id: creditHistoryData.id,
        whose: creditHistoryData.whose,
        type: creditHistoryData.credit_issue_type_key,
        creditor: creditHistoryData.ccj_details.credit_issue_creditor_name,
        amount: creditHistoryData.ccj_details.credit_issue_judgement_amount,
        registered: creditHistoryData.ccj_details.credit_issue_registered_date,
        satisfied: creditHistoryData.ccj_details.credit_issue_satisfied_date,
        explanation: creditHistoryData.ccj_details.credit_issue_notes,
        toBeSatisfied: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-c428d785-467f-4b91-8d97-6921bd0ace6f",
          creditHistoryData.ccj_details.is_credit_issue_intended_to_be_satisfied
        ),
        completed:
          creditHistoryData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addCCJ", creditHistory)
      break
    case "default":
      creditHistory = {
        id: creditHistoryData.id,
        whose: creditHistoryData.whose,
        type: creditHistoryData.credit_issue_type_key,
        creditor: creditHistoryData.default_details.credit_issue_creditor_name,
        amount: creditHistoryData.default_details.credit_issue_judgement_amount,
        registered:
          creditHistoryData.default_details.credit_issue_registered_date,
        satisfied:
          creditHistoryData.default_details.credit_issue_satisfied_date,
        explanation: creditHistoryData.default_details.credit_issue_notes,
        toBeSatisfied: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-c428d785-467f-4b91-8d97-6921bd0ace6f",
          creditHistoryData.default_details
            .is_credit_issue_intended_to_be_satisfied
        ),
        completed:
          creditHistoryData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addDefault", creditHistory)
      break
    case "debt_relief_order":
      creditHistory = {
        id: creditHistoryData.id,
        whose: creditHistoryData.whose,
        type: creditHistoryData.credit_issue_type_key,
        registered: creditHistoryData.dro_details.credit_issue_registered_date,
        discharged: creditHistoryData.dro_details.credit_issue_discharge_date,
        explanation: creditHistoryData.dro_details.credit_issue_notes,
        toBeSatisfied: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-c428d785-467f-4b91-8d97-6921bd0ace6f",
          creditHistoryData.dro_details.is_credit_issue_intended_to_be_satisfied
        ),
        completed:
          creditHistoryData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addDRO", creditHistory)
      break
    case "individual_voluntary_arrangement":
      creditHistory = {
        id: creditHistoryData.id,
        whose: creditHistoryData.whose,
        type: creditHistoryData.credit_issue_type_key,
        registered: creditHistoryData.iva_details.credit_issue_registered_date,
        discharged: creditHistoryData.iva_details.credit_issue_satisfied_date,
        explanation: creditHistoryData.iva_details.credit_issue_notes,
        toBeSatisfied: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-c428d785-467f-4b91-8d97-6921bd0ace6f",
          creditHistoryData.iva_details.is_credit_issue_intended_to_be_satisfied
        ),
        completed:
          creditHistoryData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addIVA", creditHistory)
      break
    case "arrears_missed_payments":
      creditHistory = {
        id: creditHistoryData.id,
        whose: creditHistoryData.whose,
        type: creditHistoryData.credit_issue_type_key,
        arrears_type:
          creditHistoryData.arrears_details.credit_issue_arrears_type_key,
        creditor: creditHistoryData.arrears_details.credit_issue_creditor_name,
        number:
          creditHistoryData.arrears_details
            .credit_issue_missed_payments_six_years_count,
        explanation: creditHistoryData.arrears_details.credit_issue_notes,
        amountOutstanding:
          creditHistoryData.arrears_details.credit_issue_oustanding_balance,
        dateOfLastIncident:
          creditHistoryData.arrears_details.credit_issue_missed_payment_date
            .sort((a, b) => {
              return new Date(b) - new Date(a)
            })
            .at(0),
        toBeSatisfied: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-c428d785-467f-4b91-8d97-6921bd0ace6f",
          creditHistoryData.arrears_details
            .is_credit_issue_intended_to_be_satisfied
        ),
        completed:
          creditHistoryData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addArrears", creditHistory)
  }
  return creditHistory
}
