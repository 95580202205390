import axios from "axios"
import { functionService } from "@/global/modules/helpers"
import { formatClientNamesForList } from "@/global/modules/helpers/clientHelpers"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"

export const state = {
  data: [],
  selectedDependant: {},
  excludedClientsFromSelectionList: [] //Used to exclude clients from dropdown lists
}

export const mutations = {
  refresh(state, data) {
    state.data = data
  },
  getDependantById(state, id) {
    state.selectedDependant = state.data.find((d) => d.id === id)
  },
  setExcludedClientsFromSelectionList(state, data) {
    state.excludedClientsFromSelectionList = data
  }
}

export const getters = {
  data: (state) => state.data,
  selectedDependant: (state) => state.selectedDependant,
  excludedClientsFromSelectionList: (state) =>
    state.excludedClientsFromSelectionList,
  validation: (state, rootState, getters, rootGetters) => {
    return {
      list: {
        ...mapRulesForList(rootGetters)
      },
      dependant: {
        ...mapRulesForDependant(rootGetters)
      }
    }
  }
}

export const actions = {
  async loadDependants({ commit, rootGetters }) {
    const clientIds = rootGetters["clientFile/selectedClients"].map(
      (client) => client.id
    )

    const snapshotIdQuery = rootGetters["cases/snapshotIdQuery"]

    return axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientdependants/getforclientids${snapshotIdQuery}`,
        clientIds
      )
      .then((response) => {
        commit(
          "refresh",
          mapDependantsAndClients(
            response.data,
            rootGetters["clientFile/clients"]
          )
        )

        return Promise.resolve(true)
      })
  },
  async createUpdateDependants({ commit, rootGetters }, data) {
    return axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientdependants/createorupdate`,
        data
      )
      .then((response) => {
        commit(
          "refresh",
          mapDependantsAndClients(
            response.data,
            rootGetters["clientFile/clients"]
          )
        )

        return Promise.resolve(true)
      })
  },
  async deleteDependants({ dispatch }, data) {
    // set delete properties
    data.is_deleted = true
    data.deleted_at = new Date()

    return axios
      .post(`${process.env.VUE_APP_CDS_URL}/clientdependants/createorupdate`, [
        data
      ])
      .then((response) => {
        if (response.status === 200) {
          dispatch("loadDependants")
        }

        return Promise.resolve(true)
      })
  }
}

function mapRulesForList(rootGetters) {
  const fields = ["kyc_client_has_dependents"]
  return mapRules(rootGetters, "kyc_dependants", fields)
}

function mapRulesForDependant(rootGetters) {
  const fields = [
    "relationship_to_client_key",
    "is_financially_dependant",
    "first_name",
    "middle_names",
    "last_name",
    "birth_date",
    "is_dependent_end_date_known",
    "dependent_until_age",
    "dependant_occupancy_type_key"
  ]

  const conditions = {
    dependent_until_age: {
      "==": [{ var: "is_dependent_end_date_known" }, true]
    }
  }

  return mapRules(rootGetters, "client_dependant", fields, conditions)
}

function mapRules(rootGetters, subSection, fields, conditions) {
  const factFind = rootGetters["caseSectionValidation/factfind"]

  return createValidationRules(
    (field) => factFind.required("dependants", subSection, field),
    fields,
    conditions
  )
}

function mapDependantsAndClients(dependants, clients) {
  return dependants.map((dependant) => ({
    ...dependant,
    dependantOn: formatClientNamesForList(dependant.linked_clients, clients),
    name: `${dependant.first_name} ${dependant.middle_names} ${dependant.last_name}`,
    age: functionService.calcAge(dependant.birth_date),
    remaining: functionService.yearsUntilAge(
      dependant.dependent_until_age,
      dependant.birth_date
    ),
    completed:
      dependant.current_record_validation
        ?.is_record_section_validation_result_valid ?? false
  }))
}
