import axios from "axios"
import { caseCheckingConfig } from "@/data/case-checking.js"
import { Mutex } from "async-mutex"

export const state = {
  caseData: [],
  count: 0,
  loadCasesMutex: new Mutex(),
  loadCasesAxiosController: null
}

function abortLoadCasesAxiosController(state) {
  if (state.loadCasesAxiosController) {
    try {
      state.loadCasesAxiosController.abort("Operation cancelled by the user.")
    } catch (ex) {
      console.error(ex)
    }
  }
}
async function loadCasesWithMutexAsync(state, url, dto) {
  let request = state.loadCasesMutex.runExclusive(async () => {
    abortLoadCasesAxiosController(state)
    state.loadCasesAxiosController = new AbortController()
    return axios.post(url, dto, {
      signal: state.loadCasesAxiosController.signal
    }) //no await, return the raw promise.
  }) //end mutex
  return await request
}

export const getters = {
  data: (state) => state.caseData,
  count: (state) => state.count
}

export const mutations = {
  setCaseData(state, value) {
    state.caseData = value
  },
  setCount(state, value) {
    state.count = value
  }
}

export const actions = {
  async fetchCases({ commit, state }, caseFetchInfo) {
    let default_sort = caseCheckingConfig.default_sort
    let sort_field = caseFetchInfo.sort_field
      ? caseFetchInfo.sort_field
      : default_sort
      ? default_sort.sort_field
      : ""
    let sort_ascending =
      caseFetchInfo.sort_ascending !== null &&
      caseFetchInfo.sort_ascending !== undefined
        ? caseFetchInfo.sort_ascending
        : default_sort
        ? default_sort.sort_ascending
        : true
    let dto = {
      search_fields: caseCheckingConfig.search_fields,
      report_name: "CaseChecking",
      page_number: caseFetchInfo.page_number,
      per_page: caseFetchInfo.per_page,
      search_text: caseFetchInfo.search_text,
      sort_field: sort_field,
      secondary_sort_field: caseFetchInfo.secondary_sort_field,
      sort_ascending: sort_ascending
    }
    try {
      let url = `${process.env.VUE_APP_REPORTS_URL}/reports/runreport`
      let response = await loadCasesWithMutexAsync(state, url, dto)
      // Cancelled by subsequent request.
      if (response === undefined) return
      if (response.status === 200) {
        commit("setCaseData", response.data.Results)
        commit("setCount", response.data.Count)
      }
    } catch (err) {
      console.log(err)
    }
  },
  loadCasesCancel({ state }) {
    abortLoadCasesAxiosController(state)
  }
}
