const datatableConfig = [
  //Administration
  {
    page: "administration",
    component: "task",
    headers: [
      {
        label: "Client(s)",
        key: "client",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "For",
        key: "for",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Item",
        key: "item",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Type",
        key: "type",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: true,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Due",
        key: "due",
        currency: false,
        classContent: "text-start link-dark",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: ["Set Due Date"],
        linkBlacklist: []
      },
      {
        label: "Status",
        key: "status",
        currency: false,
        classContent: "text-center",
        bgColour: true,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  {
    page: "administration",
    component: "preApplication",
    headers: [
      {
        label: "Type",
        key: "type",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Team Member",
        key: "teamMember",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Item",
        key: "item",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Due",
        key: "due",
        currency: false,
        classContent: "text-start link-dark",
        bgColour: true,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: ["Set Due Date"],
        linkBlacklist: []
      },
      {
        label: "Status",
        key: "status",
        currency: false,
        classContent: "text-center",
        bgColour: true,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Last Updated By",
        key: "lastUpdatedBy",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Notes",
        key: "notes",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: [0]
      },
      {
        label: "Docs",
        key: "docs",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: [0]
      }
    ]
  },
  {
    page: "administration",
    component: "postApplication",
    headers: [
      {
        label: "Type",
        key: "type",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Team Member",
        key: "teamMember",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Item",
        key: "item",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Due",
        key: "due",
        currency: false,
        classContent: "text-start link-dark",
        bgColour: true,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: ["Set Due Date"],
        linkBlacklist: []
      },
      {
        label: "Status",
        key: "status",
        currency: false,
        classContent: "text-center",
        bgColour: true,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Last Updated By",
        key: "lastUpdatedBy",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Notes",
        key: "notes",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: [0]
      },
      {
        label: "Docs",
        key: "docs",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: [0]
      }
    ]
  },
  {
    page: "administration",
    component: "fee",
    headers: [
      {
        label: "Client(s)",
        key: "client",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Team Member",
        key: "teamMember",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Type",
        key: "type",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Provider",
        key: "provider",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: true,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Gross Amount",
        key: "grossAmount",
        currency: true,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Status",
        key: "status",
        currency: false,
        classContent: "text-center",
        bgColour: true,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  //Application

  // Admin -  Address book - Company
  {
    page: "addressbook",
    component: "company",
    headers: [
      {
        label: "Company Name",
        key: "business_name",
        currency: false,
        classContent: "text-start",
        thStyle: { width: "200px" },
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Address",
        key: "addressStr",
        thStyle: { width: "300px" },
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Contact",
        key: "defaultPhone",
        thStyle: { width: "200px" },
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Branches",
        key: "branch_count",
        thStyle: { width: "100px" },
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Individuals",
        key: "individuals_count",
        thStyle: { width: "110px" },
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Introducer",
        key: "business_introducer_status_key",
        thStyle: { width: "110px" },
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // Admin - Address book - Individual
  {
    page: "addressbook",
    component: "individuals",
    headers: [
      {
        label: "Name",
        key: "fullname",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Role",
        key: "role_text",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Company Name",
        key: "business_name",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Branch",
        key: "branch",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Email",
        key: "email_address",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Phone",
        key: "main_contact_number",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // Admin - Address book - Individuals within a Company
  {
    page: "addressbook",
    component: "individualsCompany",
    headers: [
      {
        label: "Name",
        key: "fullname",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Role",
        key: "role_text",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Phone",
        key: "main_contact_number",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Email",
        key: "email_address",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // Admin - Address book - Branches
  {
    page: "addressbook",
    component: "branches",
    headers: [
      {
        label: "Branch",
        key: "business_branch_name",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Company Name",
        key: "business_name",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Address",
        key: "addressStr",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Phone",
        key: "default_phone",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Type",
        key: "company_type_text",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Individuals",
        key: "individuals_count",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
      // temporarily hidden
      //{
      //  label: "Introducer?",
      //  key: "introducer_link",
      //  currency: false,
      //  classContent: "text-start",
      //  bgColour: false,
      //  fontColour: false,
      //  link: true,
      //  sortable: true,
      //  linkWhitelist: [],
      //  linkBlacklist: []
      //}
    ]
  },
  // Admin - Address book - Branches within a Company
  {
    page: "addressbook",
    component: "branchesCompany",
    headers: [
      {
        label: "Branch",
        key: "business_branch_name",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: true,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Company Name",
        key: "business_name",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Address",
        key: "addressStr",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Phone",
        key: "default_phone",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // clients - cases- kyc - employment
  {
    page: "case",
    component: "currentEmployment",
    headers: [
      {
        label: "Client",
        key: "client",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Job Title",
        key: "jobTitle",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Company",
        key: "company",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Time in Position",
        key: "timeInPosition",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Income",
        key: "income",
        currency: true,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Sick Pay",
        key: "sickPay",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Death in Service",
        key: "deathInService",
        currency: true,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Planned Retired",
        key: "plannedRetired",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // clients - cases - kyc - benefits
  {
    page: "case",
    component: "benefitIncome",
    headers: [
      {
        label: "Owner",
        key: "owner",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Benefit Type",
        key: "type",
        currency: false,
        classContent: "text-start cellWidth200",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Income",
        key: "income",
        currency: true,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // clients - cases- kyc - previous employment
  {
    page: "case",
    component: "previousEmployment",
    headers: [
      {
        label: "Client",
        key: "client",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Job Title",
        key: "jobTitle",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Company",
        key: "company",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Time in Position",
        key: "timeInPosition",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Income",
        key: "income",
        currency: true,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // clients - cases- kyc - supporting documents
  {
    page: "case",
    component: "supportingDocuments",
    headers: [
      {
        label: "Required",
        key: "required",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Client",
        key: "client",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Document",
        key: "document",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Period",
        key: "period",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Status",
        key: "status",
        currency: false,
        classContent: "text-start",
        bgColour: true,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Rating",
        key: "rating",
        currency: false,
        classContent: "text-center",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // clients - documents
  {
    page: "documents",
    component: "documentList",
    headers: [
      {
        label: "File Name",
        key: "filename",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Description",
        key: "description",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Last Modified",
        key: "lastModified",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // case - document - templates
  {
    page: "documents",
    component: "templateList",
    headers: [
      {
        label: "Name",
        key: "filename",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Description",
        key: "description",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  //Queries - query list
  {
    page: "query",
    component: "querylist",
    headers: [
      {
        label: "Name",
        key: "name",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Description",
        key: "description",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  },
  // case - factfind - budget planner
  {
    page: "budget",
    component: "budgetList",
    headers: [
      {
        label: "Status",
        key: "status",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Description",
        key: "description",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Client(s)",
        key: "who",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      },
      {
        label: "Completion Date",
        key: "completed",
        currency: false,
        classContent: "text-start",
        bgColour: false,
        fontColour: false,
        link: false,
        sortable: true,
        linkWhitelist: [],
        linkBlacklist: []
      }
    ]
  }
]

export { datatableConfig }
