import axios from "axios"
import { displayProviderNameByIdAndType } from "@/global/modules/helpers/providerHelpers.js"
import { formatClientNamesForList } from "@/global/modules/helpers/clientHelpers"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"

export const state = {
  selectedClients: [],
  clients: [],
  allPolicies: [],
  protectionPolicies: [],
  generalPolicies: [],
  asuPolicies: [],
  selectedStatus: "",
  selectedPolicy: {},
  editMode: false,
  parts: [],
  excludedClientsFromSelectionList: []
}

export const mutations = {
  clearPolicies(state) {
    state.allPolicies = []
    state.protectionPolicies = []
    state.generalPolicies = []
    state.asuPolicies = []
  },
  addProtection(state, data) {
    state.protectionPolicies.push(data)
  },
  addGeneral(state, data) {
    state.generalPolicies.push(data)
  },
  addASU(state, data) {
    state.asuPolicies.push(data)
  },
  addToAllPolicies(state, data) {
    state.allPolicies.push(data)
  },
  addSelectedClientAndStatus(state, data) {
    state.editMode = true
    state.selectedClients = data.clients
    state.selectedStatus = data.status
  },
  setSelectedPolicy(state, data) {
    state.selectedPolicy = data
  },

  setEditMode(state, data) {
    state.editMode = data
  },

  addPart(state, data) {
    state.parts.push(data)
  },
  clearParts(state) {
    state.parts = []
  },
  removePart(state, data) {
    state.parts.splice(data, 1)
  },
  setExcludedClientsFromSelectionList(state, data) {
    state.excludedClientsFromSelectionList = data
  }
}

export const actions = {
  async loadPolicies(context) {
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    const clientIds = context.rootGetters["clientFile/selectedClients"].map(
      (client) => client.id
    )
    return await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientpolicies/getforclientids${snapshotIdQuery}`,
        clientIds
      )
      .then((res) => {
        context.commit("clearPolicies")
        res.data.forEach((policy) => {
          mapPolicyForList(
            policy,
            context.rootGetters["clientFile/clients"],
            context
          )
        })
      })
      .catch(() => context.commit("clearPolicies"))
  },
  async deletePolicy({ dispatch }, payload) {
    // set delete properties
    payload.is_deleted = true
    payload.deleted_at = new Date()

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientpolicies/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        dispatch("loadPolicies")
      }
    } catch (err) {
      console.log(err)
    }
  },
  async addUpdatePolicy({ dispatch }, payload) {
    var id = null

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientpolicies/createorupdate`,
        [payload]
      )
      if (response.status === 200) {
        if (response.data?.length > 0) {
          id = response.data[0].id
        }
        await dispatch("loadPolicies")
      }
    } catch (err) {
      console.log(err)
    }
    return id
  },

  saveSelectedClientAndStatus(context, payload) {
    context.commit("addSelectedClientAndStatus", payload)
  },
  addPart(context, data) {
    context.commit("addPart", data)
  },
  clearParts(context) {
    context.commit("clearParts")
  },
  removePart(context, data) {
    context.commit("removePart", data)
  }
}

export const getters = {
  protectionPolicies(state) {
    return state.protectionPolicies
  },
  allPolicies(state) {
    return state.allPolicies
  },
  generalPolicies(state) {
    return state.generalPolicies
  },
  asuPolicies(state) {
    return state.asuPolicies
  },
  selectedClients(state) {
    return state.selectedClients
  },
  selectedStatus(state) {
    return state.selectedStatus
  },
  isAddMode(state) {
    return !state.editMode
  },

  selectedPolicy(state) {
    return state.selectedPolicy
  },

  parts(state) {
    return state.parts
  },
  associatedGeneralPolicies: (state) => (propertyId) => {
    return state.allPolicies
      .filter(
        (a) =>
          a.policy_type_key.toLowerCase() === "buildings_contents" &&
          a.buildings_contents_details.property_id === propertyId
      )
      .map((b) => ({
        id: b.id,
        policy_type_key: b.policy_type_key,
        ...b.buildings_contents_details
      }))
  },
  excludedClientsFromSelectionList(state) {
    return state.excludedClientsFromSelectionList
  },
  validation: (state, rootState, getters, rootGetters) => {
    return {
      list: {
        ...mapRulesForList(rootGetters)
      },
      personal: {
        ...mapRulesForPersonal(rootGetters)
      },
      household: {
        ...mapRulesForHousehold(rootGetters)
      },
      asu: {
        ...mapRulesForAsu(rootGetters)
      }
    }
  }
}

function mapRulesForList(rootGetters) {
  const fields = ["kyc_client_has_protection_policies"]
  return mapRules(rootGetters, "kyc_policies", fields)
}

function mapRulesForPersonal(rootGetters) {
  const fields = [
    "policy_account_status_key",
    "policy_account_review_date",
    "policy_provider_protection_id",
    "policy_provider_other_name",
    "policy_account_number_ref",
    "policy_account_start_date",
    "policy_account_premium_total_amount",
    "policy_plan_description",
    "protection_type_key",
    "protection_cover_type_key",
    "protection_covering_persons",
    "protection_part_status_key",
    "protection_part_start_date",
    "protection_basis_cover_term_key",
    "protection_annual_life_amount",
    "protection_annual_critical_illness_amount",
    "protection_annual_life_or_earlier_critical_illness_amount",
    "protection_lump_sum_life_amount",
    "protection_lump_sum_critical_illness_amount",
    "protection_lump_sum_life_or_earlier_critical_illness_amount",
    "protection_indexation_family_income_key",
    "protection_indexation_level_term_key",
    "protection_policy_decreasing_rate_percentage",
    "has_terminal_illness_benefit_cover",
    "has_total_permanent_disability_cover",
    "has_protection_waiver_of_premium",
    "is_death_benefit_in_trust",
    "has_protection_beneficiaries_been_nominated",
    "is_split_deferment",
    "protection_indexation_individual_income_key",
    "protection_income_benefit_period_amount",
    "protection_income_benefit_deferred_period_key",
    "protection_premium_part_benefit_amount",
    "protection_premium_type_key",
    "protection_term_type_key",
    "protection_term_years",
    "protection_term_months",
    "protection_term_to_age"
  ]

  return mapRules(rootGetters, "policy_personal_protection", fields)
}

function mapRulesForHousehold(rootGetters) {
  const fields = [
    "policy_account_status_key",
    "policy_account_review_date",
    "policy_provider_general_insurance_id",
    "policy_provider_paymentshield_underwriter_name_key",
    "policy_provider_other_name",
    "policy_account_number_ref",
    "policy_account_start_date",
    "policy_account_end_date",
    "policy_account_premium_total_amount",
    "policy_account_premium_frequency_key",
    "has_policy_household_legal_cover",
    "has_policy_household_home_emergency_cover",
    "policy_plan_description",
    "policy_household_benefits_covered_key",
    "policy_household_cover_type_key",
    "policy_household_sum_assured_amount",
    "has_policy_household_accidental_damage",
    "policy_household_excess_amount",
    "policy_household_no_claims_discount_years"
  ]

  const conditions = {
    policy_provider_paymentshield_underwriter_name_key: {
      "==": [{ var: "policy_provider_general_insurance_id" }, "paymentshield"]
    },
    policy_provider_other_name: {
      "==": [{ var: "policy_provider_general_insurance_id" }, "other_provider"]
    }
  }

  return mapRules(rootGetters, "policy_household", fields, conditions)
}

function mapRulesForAsu(rootGetters) {
  const fields = [
    "policy_account_status_key",
    "policy_account_review_date",
    "policy_provider_general_insurance_id",
    "policy_account_number_ref",
    "policy_account_start_date",
    "policy_account_end_date",
    "policy_account_premium_total_amount",
    "policy_account_premium_frequency_key",
    "policy_plan_description",
    "policy_asu_cover_type_key",
    "policy_asu_benefit_monthly_amount",
    "policy_asu_benefit_payable_duration_months",
    "policy_asu_benefit_deferred_period_key",
    "has_policy_asu_back_to_day_one_cover",
    "policy_asu_term_type_key",
    "policy_asu_term_years",
    "policy_asu_term_months",
    "policy_provider_other_name",
    "policy_provider_paymentshield_underwriter_name_key"
  ]

  const fixedTermCondition = {
    "==": [{ var: "policy_asu_term_type_key" }, "fixed_term"]
  }

  const conditions = {
    policy_asu_term_years: fixedTermCondition,
    policy_asu_term_months: fixedTermCondition
  }

  return mapRules(rootGetters, "policy_asu", fields, conditions)
}

function mapRules(rootGetters, subSection, fields, conditions) {
  const factFind = rootGetters["caseSectionValidation/factfind"]
  return createValidationRules(
    (field) => factFind.required("policies", subSection, field),
    fields,
    conditions
  )
}

async function mapPolicyForList(policyData, clients, context) {
  policyData.who = formatClientNamesForList(policyData.linked_clients, clients)

  // add to the allPolicies collection
  context.commit("addToAllPolicies", policyData)
  let policy = {}

  switch (policyData.policy_type_key.toLowerCase()) {
    case "protection":
      policy = {
        id: policyData.id,
        who: policyData.who,
        status: policyData.policy_account_status_key,
        type: policyData.policy_type_key,
        plan_no: policyData.protection_details?.policy_account_number_ref ?? "",
        benefits_cover_type: policyData.protection_details?.benefit_details
          .reduce((benefits, b) => {
            let displayText = null
            if (b.protection_type_key === "income_protection") {
              displayText =
                context.rootGetters[
                  "datadictionary/displayTextByFormIdAndValue"
                ](
                  "q-b724ecde-6b02-4c3b-aa95-5d22b9a0b36a",
                  b.protection_type_key
                ) ?? ""
            } else {
              displayText =
                context.rootGetters[
                  "datadictionary/displayTextByFormIdAndValue"
                ](
                  "q-a013b293-afbb-4bf2-b7ea-9c7ae3d4d59c",
                  b.protection_cover_type_key
                ) ?? ""
            }
            if (displayText) {
              benefits.push(displayText)
            }
            return benefits
          }, [])
          .join(", "),
        plan_type:
          policyData.protection_details !== null
            ? mapPlanType(policyData.protection_details.benefit_details)
            : "",
        provider:
          displayProviderNameByIdAndType(
            policyData.protection_details?.policy_provider_protection_id,
            "protection"
          ) ?? "",
        startDate: policyData.protection_details.policy_account_start_date,
        endDate: policyData.protection_details.end_date,
        payment:
          policyData.protection_details?.policy_account_premium_total_amount ??
          0,
        completed:
          policyData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addProtection", policy)
      break
    case "buildings_contents":
      policy = {
        id: policyData.id,
        who: policyData.who,
        status: policyData.policy_account_status_key,
        type: policyData.policy_type_key,
        provider:
          displayProviderNameByIdAndType(
            policyData.buildings_contents_details
              ?.policy_provider_general_insurance_id,
            "general_insurance"
          ) ?? "",
        startDate:
          policyData.buildings_contents_details.policy_account_start_date,
        endDate: policyData.buildings_contents_details.policy_account_end_date,
        payment:
          policyData.buildings_contents_details
            ?.policy_account_premium_total_amount ?? 0,
        b_cover: policyData.buildings_contents_details?.parts.find(
          (c) => c.policy_household_benefits_covered_key === "buildings"
        )?.buildings.policy_household_sum_assured_amount,
        c_cover: policyData.buildings_contents_details?.parts.find(
          (c) => c.policy_household_benefits_covered_key === "contents"
        )?.contents.policy_household_sum_assured_amount,
        completed:
          policyData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addGeneral", policy)
      break
    case "asu":
      policy = {
        id: policyData.id,
        who: policyData.who,
        status: policyData.policy_account_status_key,
        type: policyData.policy_type_key,
        review_date: policyData.asu_details.policy_account_review_date,
        provider:
          displayProviderNameByIdAndType(
            policyData.asu_details?.policy_provider_general_insurance_id,
            "general_insurance"
          ) ?? "",
        policy_no: policyData.asu_details.policy_account_number_ref,
        start_date: policyData.asu_details.policy_account_start_date,
        end_date: policyData.asu_details.policy_account_end_date,
        total_premium:
          policyData.asu_details.policy_account_premium_total_amount,
        premium_freq:
          policyData.asu_details.policy_account_premium_frequency_key,
        description: policyData.asu_details.policy_plan_description,
        cover_type:
          context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
            "q-9ad6c5c9-7899-4d07-a272-026ac1edee60",
            policyData.asu_details?.policy_asu_cover_type_key
          ) ?? "",
        monthly_benefit_amount:
          policyData.asu_details.policy_asu_benefit_monthly_amount,
        payable_for_months:
          policyData.asu_details.policy_asu_benefit_payable_duration_months,
        deferred_period:
          policyData.asu_details.policy_asu_benefit_deferred_period_key,
        back_to_day_1:
          policyData.asu_details.has_policy_asu_back_to_day_one_cover,
        term_type: policyData.asu_details.policy_asu_term_type_key,
        term_years: policyData.asu_details.policy_asu_term_years,
        term_months: policyData.asu_details.policy_asu_term_months,
        completed:
          policyData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addASU", policy)
      break
  }

  return policy
}

function mapPlanType(benefits) {
  if (benefits === null) {
    return ""
  }

  if (benefits.length === 0) {
    return ""
  }

  return benefits.length > 1 ? "Multi benefit" : "Single benefit"
}
