import axios from "axios"

export const state = {
  milestones: []
}

export const mutations = {
  setMilestones(state, milestones) {
    state.milestones = milestones
  }
}

export const actions = {
  async loadMilestones(context) {
    const response = await axios.get(
      `${process.env.VUE_APP_CDS_URL}/casemilestones/getforcaseid/${context.rootGetters["cases/currentCaseId"]}`
    )
    if (response.status === 200) {
      context.commit("setMilestones", response.data)
    }
  },
  async addUpdateMilestone({ dispatch }, payload) {
    const response = await axios.post(
      `${process.env.VUE_APP_CDS_URL}/casemilestones/createorupdate`,
      [payload]
    )
    if (response.status === 200) {
      dispatch("loadMilestones")
    }
  }
}

export const getters = {
  milestones: (state) => state.milestones
}
