<template>
  <div class="input-group">
    <span id="currency-symbol" class="input-group-text bg-dark text-white">
      £
    </span>
    <input
      ref="inputRef"
      class="form-control"
      type="text"
      :required="required"
      :class="{ 'is-invalid': highlightAsRequired }"
      :data-cy="field.form_id"
      :placeholder="attributes.placeholder"
      :disabled="readonly"
      :readonly="readonly"
      @change="(e) => emitDelayed('userupdated', e)"
    />
  </div>
</template>

<script>
import FormInputHelpers from "@/plugins/components-plugin/global/modules/mixins/formInputHelpers.js"
import ConversionsMixin from "@/plugins/components-plugin/global/modules/mixins/conversions"
import { useCurrencyInput } from "vue-currency-input"
import { watch } from "@vue/composition-api"
export default {
  name: "MbCurrencyInput",
  mixins: [ConversionsMixin, FormInputHelpers],
  props: {
    options: Object,
    field: {
      type: Object,
      default: null
    },
    value: {
      type: [String, Number]
    },
    // Override default input attributes
    attrs: {
      type: Object,
      default: () => {
        return {}
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    highlightAsRequired: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // plugin default value
    let valueRange = undefined

    // If the dictionary item has an input config apply to the vue-currency-input plugin
    if (props.field.input_config || false) {
      if (props.field.input_config.min && props.field.input_config.max) {
        valueRange = {
          min: props.field.input_config.min,
          max: props.field.input_config.max
        }
      }
    }

    let precision = props.field.data_type === "integer" ? 0 : 2

    let options = {
      ...props.options,
      valueRange: valueRange,
      precision: precision
    }

    const { inputRef, setValue } = useCurrencyInput(options)

    watch(
      () => props.value,
      (value) => {
        setValue(value)
      }
    )

    return { inputRef }
  },
  data() {
    return {
      attributes: {
        placeholder: "",
        readonlyDefault: ""
      }
    }
  },
  computed: {
    required() {
      return this.field.validation.required || false
    }
  },
  beforeMount() {
    // Apply any local overrides
    this.attributes = Object.assign(this.attributes, this.attrs)
  }
}
</script>

<style scoped>
/* Hide the arrows */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
