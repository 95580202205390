export const referrals = [
  {
    path: "referrals",
    name: "referrals",
    component: () =>
      import(
        /* webpackChunkName: "ReferralsLanding" */ "@/pages/referrals/ReferralsLanding.vue"
      ),
    children: [
      {
        path: "",
        name: "referrals-overview",
        component: () =>
          import(
            /* webpackChunkName: "ReferralsOverviewLanding" */ "@/pages/referrals/ReferralsOverviewLanding.vue"
          )
      },
      {
        path: ":referralId([0-9a-fA-F]{24})",
        name: "referral-details",
        component: () =>
          import(
            /* webpackChunkName: "ReferralDetailsLanding" */ "@/pages/referrals/ReferralDetailsLanding.vue"
          )
      }
    ]
  }
]
