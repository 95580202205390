import { format, isYesterday, isToday, isTomorrow } from "date-fns"

const businessProcessingConfig = {
  search_fields: [
    { field_name: "provider.text" },
    { field_name: "requirement_type.text" },
    { field_name: "app_ref" },
    { field_name: "case_ref" },
    { field_name: "task_subtype.text" },
    { field_name: "assignee.first_name" },
    { field_name: "assignee.last_name" },
    { field_name: "advisors.first_name" },
    { field_name: "advisors.last_name" },
    { field_name: "clients.first_name" },
    { field_name: "clients.last_name" },
    { field_name: "next_review", field_type: 1 },
    { field_name: "last_updated", field_type: 1 }
  ],
  default_sort: {
    sort_field: "last_updated",
    sort_ascending: false
  },
  fields: [
    {
      key: "task_subtype.text",
      label: "Task",
      sortable: true,
      formatter: (value) => {
        if (!value) return ""
        return value
      }
    },
    {
      key: "case_ref",
      label: "Case",
      sortable: true
    },
    {
      key: "requirement_type.text",
      label: "Type",
      sortable: true
    },
    {
      key: "assignee",
      label: "Assignee",
      sortable: true,
      customSortField: "assignee.first_name",
      secondarySortField: "assignee.last_name",
      formatter: (value) => {
        if (!value) return ""
        return value.first_name + " " + value.last_name
      }
    },
    {
      key: "advisors",
      label: "Advisor(s)",
      sortable: true,
      customSortField: "advisors.first_name",
      secondarySortField: "advisors.last_name",
      formatter: (value) => {
        if (value && value[0])
          return value
            .map((advisor) => advisor.first_name[0] + " " + advisor.last_name)
            .join(", ")
        return ""
      }
    },
    {
      key: "provider.text",
      label: "Lender/Provider",
      sortable: true
    },
    {
      key: "clients",
      label: "Clients",
      sortable: true,
      customSortField: "clients.first_name",
      secondarySortField: "clients.last_name",
      formatter: (value) => {
        if (value[0])
          return value
            .map((client) => client.first_name[0] + " " + client.last_name)
            .join(", ")
        return ""
      }
    },
    {
      key: "app_ref",
      label: "App Ref",
      sortable: true
    },
    {
      key: "next_review",
      label: "Next review",
      sortable: true,
      formatter: (value) => {
        return getDayForSeparator(value)
      }
    },
    {
      key: "last_updated",
      label: "Last updated",
      sortable: true,
      formatter: (value) => {
        return getDayForSeparator(value)
      }
    },
    {
      key: "view",
      label: ""
    }
  ]
}

function getDayForSeparator(dateToDisplay) {
  if (!dateToDisplay) return ""
  const date = new Date(dateToDisplay)
  if (isToday(date)) {
    return `Today`
  } else if (isYesterday(date)) {
    return `Yesterday`
  } else if (isTomorrow(date)) {
    return `Tomorrow`
  }
  return format(date, "dd/LL/yyy")
}

export { businessProcessingConfig }
