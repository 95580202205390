<template>
  <textarea
    class="form-control"
    :required="required"
    :value="value"
    :data-cy="field.form_id"
    :autocomplete="attributes.autocomplete"
    :autofocus="attributes.autofocus"
    :cols="attributes.cols"
    :rows="attributes.rows"
    :maxlength="attributes.maxlength"
    :minlength="attributes.minlength"
    :placeholder="attributes.placeholder"
    :readonly="readonly"
    :disabled="readonly"
    :spellcheck="attributes.spellcheck"
    :class="{ 'is-invalid': highlightAsRequired }"
    :wrap="attributes.wrap"
    @input="$emit('input', $event.target.value)"
    @change="(e) => emitDelayed('userupdated', e)"
  />
</template>

<script>
import FormInputHelpers from "@/plugins/components-plugin/global/modules/mixins/formInputHelpers.js"
export default {
  name: "MbTextAreaInput",
  mixins: [FormInputHelpers],
  props: {
    field: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      default: ""
    },
    attrs: {
      type: Object,
      default: () => {
        return {}
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    highlightAsRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attributes: {
        autocomplete: false,
        autofocus: false,
        cols: "10",
        rows: "5",
        disabled: false,
        maxlength: "",
        minlength: "",
        placeholder: "",
        readonly: false,
        spellcheck: "default",
        wrap: "soft"
      }
    }
  },
  computed: {
    required() {
      return this.field.validation.required || false
    }
  },
  beforeMount() {
    // If the dictionary item has an input config apply
    // to the input attributes
    if (this.field.input_config || false) {
      this.attributes = Object.assign(this.attributes, this.field.input_config)
    }
    // Apply any local overrides
    this.attributes = Object.assign(this.attributes, this.attrs)
  }
}
</script>
