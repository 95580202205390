import axios from "axios"
import { commissionType } from "@/global/modules/enum/commissions"

export const state = {
  commissions: []
}

export const getters = {
  commissions: (state) => state.commissions,
  getCommission: (state) => (id) => {
    // eslint-disable-next-line no-undef
    return structuredClone(state.commissions.find((c) => c.id === id))
  },
  error: (state) => state.error,
  applicationSelectOptions:
    (state, getters, rootState, rootGetters) => (type) => {
      switch (type) {
        case commissionType.procFee:
          return rootGetters["applications/mortgageApplicationSelectOptions"]
        case commissionType.giCommission:
          return rootGetters[
            "applications/generalInsuranceApplicationSelectOptions"
          ]
        case commissionType.protectionCommission:
          return rootGetters["applications/protectionApplicationSelectOptions"]
        default:
          return rootGetters["applications/applicationSelectOptions"]
      }
    }
}

export const actions = {
  async addUpdateCommission({ getters, commit }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_COMMISSIONS_URL}/commissions`,
        [payload]
      )
      if (response.status === 200) {
        const allCommissions = getters["commissions"]

        commit("clearCommissions")

        //Will always be one
        const addedOrUpdatedCommission = response.data[0]

        const commissionIndex = allCommissions.findIndex(
          (c) => c.id === addedOrUpdatedCommission.id
        )

        if (commissionIndex !== -1) {
          allCommissions.splice(commissionIndex, 1, addedOrUpdatedCommission)
        } else {
          allCommissions.push(addedOrUpdatedCommission)
        }

        commit("setCommissions", allCommissions)

        //Consumer should call getCommission with id
        return { success: true, id: addedOrUpdatedCommission.id }
      }
    } catch {
      //Handled by interceptor
    }
    return { success: false }
  },
  async loadCaseCommissions({ commit, rootGetters }) {
    const caseId = rootGetters["cases/currentCaseId"]

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_COMMISSIONS_URL}/commissions/cases/${caseId}`
      )
      if (response.status === 200) {
        commit("setCommissions", response.data)
        return { success: true }
      }
    } catch {
      //Handled by interceptor
    }
    return { success: false }
  },
  async updateCommissionStatus(
    { getters, dispatch },
    { id, reconciliationStatus, workflowStatus, workflowOutcome }
  ) {
    const commissionToUpdate = getters["getCommission"](id)
    commissionToUpdate.commission_reconciliation_status_key =
      reconciliationStatus
    commissionToUpdate.closure_workflow_status_commission_key = workflowStatus
    commissionToUpdate.closure_workflow_outcome_commission_key = workflowOutcome

    return await dispatch("addUpdateCommission", commissionToUpdate)
  }
}

export const mutations = {
  setCommissions(state, payload) {
    state.commissions = payload
  },
  clearCommissions(state) {
    state.commissions = []
  }
}
