import axios from "axios"

export const state = {
  allTasks: [],
  chaseTasks: [],
  currentTask: {},
  chaseTaskActive: false,
  showChaseTaskReadonly: false,
  todoTaskActive: false,
  clientReviewTaskActive: false,
  showTodoTaskReadonly: false,
  isNew: false,
  requestActionType: "",
  todoTaskToEdit: null,
  taskSaveInProgress: false
}

export const mutations = {
  clearTasks(state) {
    state.allTasks = []
    state.chaseTasks = []
  },
  addChaseTask(state, data) {
    state.chaseTasks.push(data)
  },
  setAllTasks(state, tasks) {
    state.allTasks = tasks
  },
  setChaseTaskActive(state, status) {
    state.chaseTaskActive = status.show
    state.showChaseTaskReadonly = status.readonly
  },
  setTodoTaskActive(state, status) {
    state.todoTaskActive = status.show
    state.showTodoTaskReadonly = status.readonly
  },
  setClientReviewTaskActive(state, status) {
    state.clientReviewTaskActive = status.show
  },
  setTaskForEdit(state, task) {
    state.currentTask = task
  },
  setRequestActionType(state, status) {
    state.requestActionType = status
  },
  setTodoTaskToEdit(state, status) {
    state.todoTaskToEdit = status
  }
}

export const getters = {
  allTasks: (state) => state.allTasks,
  chaseTasks: (state) => state.chaseTasks,
  showChaseTask: (state) => state.chaseTaskActive,
  showChaseTaskReadonly: (state) => state.showChaseTaskReadonly,
  showTodoTask: (state) => state.todoTaskActive,
  showClientReviewTask: (state) => state.clientReviewTaskActive,
  showTodoTaskReadonly: (state) => state.showTodoTaskReadonly,
  currentTask: (state) => state.currentTask,
  requestActionType: (state) => state.requestActionType,
  todoTaskToEdit: (state) => state.todoTaskToEdit,
  taskSaving: (state) => state.taskSaveInProgress
}

export const actions = {
  async loadClientReviewTask({ commit }, taskId) {
    await axios
      .get(`${process.env.VUE_APP_TASK_SERVICE}/tasks/${taskId}`)
      .then((response) => {
        commit("clearTasks")
        commit("setAllTasks", [response.data])
      })
      .catch((e) => {
        console.log(e)
        commit("clearTasks")
      })
  },
  async loadTasks(context) {
    var caseId = context.rootGetters["cases/currentCaseId"]

    let payload = {
      metadata: {
        cases: [caseId]
      }
    }

    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    await axios
      .post(
        `${process.env.VUE_APP_TASK_SERVICE}/tasks/get${snapshotIdQuery}`,
        payload
      )
      .then((response) => {
        context.commit("clearTasks")
        context.commit("setAllTasks", response.data)
      })
      .catch(() => context.commit("clearTasks"))
  },
  async saveReviewTask({ dispatch, state }, payload) {
    state.taskSaveInProgress = true
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_TASK_SERVICE}/tasks/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        await dispatch("loadClientReviewTask", payload.id)
      }
    } catch (err) {
      console.log(err.response.data)
    } finally {
      state.taskSaveInProgress = false
    }
  },
  async saveTask({ dispatch, state }, payload) {
    state.taskSaveInProgress = true
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_TASK_SERVICE}/tasks/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        await dispatch("loadTasks")
      }
    } catch (err) {
      console.log(err.response.data)
    } finally {
      state.taskSaveInProgress = false
    }
  },
  async saveSingleTask({ commit }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_TASK_SERVICE}/tasks/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        commit("setTaskForEdit", response.data[0])
      }
    } catch (err) {
      console.log(err.response.data)
    }
  },
  fetchTaskForEdit({ state, commit }, taskId) {
    var taskForEdit = state.allTasks.find((task) => task.id === taskId)
    commit("setTaskForEdit", JSON.parse(JSON.stringify(taskForEdit)))
  },
  async getTask({ commit }, taskId) {
    return await axios
      .get(`${process.env.VUE_APP_TASK_SERVICE}/tasks/${taskId}`)
      .then((response) => {
        if (response.status === 200) {
          commit("setTaskForEdit", response.data)
          return response.data
        }
      })
      .catch((error) => console.error(error))
  },
  async createComplianceTask(_, taskDTO) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_TASK_SERVICE}/integrations/cds/create`,
        [taskDTO]
      )
      if (response.status === 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}
