import axios from "axios"

export const state = {
  protectionProducts: [],
  protectionProductEditMode: false,
  selectedProtectionProduct: {},
  productIsNew: true,
  errorForDisplay: ""
}

export const mutations = {
  clearProtection(state) {
    state.protectionProducts = []
  },
  setProtectionProducts(state, data) {
    state.protectionProducts = data
  },
  setProtectionProductEditMode(state, data) {
    state.protectionProductEditMode = data
  },
  setSelectedProduct(state, data) {
    state.selectedProtectionProduct = data
  }
}

export const actions = {
  async loadProtections(context) {
    const caseId = context.rootGetters["cases/currentCaseId"]
    const requirementId =
      context.rootGetters["requirements/selectedRequirement"].id
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/caserequirementprotectionproduct/cases/${caseId}/requirements/${requirementId}`
      )
      if (response.status === 200) {
        context.commit("setProtectionProducts", response.data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async fetchProtectionForApplication(context) {
    const application = context.rootGetters["applications/selectedApplication"]
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/caserequirementprotectionproduct/cases/${application.case_application.case_id}/requirements/${application.case_application.requirement_id}`
      )
      if (response.status === 200) {
        context.commit("setProtectionProducts", response.data)

        const product = application.case_application.protection_details.product
        context.commit("setSelectedProduct", product)
        return product
      }
    } catch (err) {
      console.log(err)
    }
  },
  async addUpdateProtection(context, payload) {
    const caseId = context.rootGetters["cases/currentCaseId"]
    const requirementId =
      context.rootGetters["requirements/selectedRequirement"].id

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/caserequirementprotectionproduct/cases/${caseId}/requirements/${requirementId}/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        await context.dispatch("loadProtections")
        return true
      }
    } catch (err) {
      console.log(err)
      return false
    }
    return false
  }
}

export const getters = {
  protectionProductEditMode: (state) => state.protectionProductEditMode,
  selectedProtectionProduct: (state) => state.selectedProtectionProduct,
  protectionProducts: (state) => state.protectionProducts
}
