import rawAxios from "axios"

export const namespaced = true

export const state = {
  notifications: [],
  newNotificationsCount: null,
  clientNameMappings: [],
  pollId: null
}

export const mutations = {
  setNotifications(state, payload) {
    state.notifications = payload
  },
  setNewCount(state, payload) {
    state.newNotificationsCount = payload
  },
  setClientNameMappings(state, value) {
    state.clientNameMappings = value
  },
  stopPolling(state) {
    clearTimeout(state.pollId)
  },
  setPollId(state, value) {
    state.pollId = value
  }
}

export const actions = {
  async pollNotifications({ dispatch, commit }) {
    let url = `${process.env.VUE_APP_EVENTS_URL}/notifications/newcount`
    const axios =
      process.env.NODE_ENV === "production" ? this.$mbAxios : rawAxios
    let response = await axios.get(url)
    if (response.status === 200) commit("setNewCount", response.data)
    let timeoutId = setTimeout(() => {
      dispatch("pollNotifications")
    }, process.env.VUE_APP_NOTIFICATION_POLL_INTERVAL)
    commit("setPollId", timeoutId)
  },
  async getNotifications({ commit, rootGetters }) {
    let url = `${process.env.VUE_APP_EVENTS_URL}/notifications/get`
    const axios =
      process.env.NODE_ENV === "production" ? this.$mbAxios : rawAxios
    let response = await axios.get(url)
    if (response.status === 200) {
      let advisorIds = rootGetters["advisors/advisors"].map(
        (advisor) => advisor.value
      )
      // Create set so that we only send unique IDs to the API.
      let clientIdsSet = new Set()
      let clientIds = response.data.filter(
        (notif) => !advisorIds.includes(notif.created_by)
      )
      clientIds.forEach((notif) => clientIdsSet.add(notif.created_by))
      if (clientIdsSet.size !== 0) {
        let clientNameUrl = `${process.env.VUE_APP_CDS_URL}/clients/getnames`
        let clientNameResponse = await axios.post(
          clientNameUrl,
          Array.from(clientIdsSet)
        )
        if (clientNameResponse.status === 200) {
          let clientNameMappings = clientNameResponse.data.map((client) => {
            return {
              text: client.first_name + " " + client.last_name,
              value: client.id
            }
          })
          commit("setClientNameMappings", clientNameMappings)
        }
      }
      commit("setNewCount", 0)
      commit("setNotifications", response.data)
    }
  },
  async markAllNotificationsRead({ dispatch }) {
    let url = `${process.env.VUE_APP_EVENTS_URL}/notifications/markallread`
    const axios =
      process.env.NODE_ENV === "production" ? this.$mbAxios : rawAxios
    let response = await axios.put(url)
    if (response.status === 200) {
      dispatch("getNotifications")
    }
  },
  async markNotificationRead({ dispatch }, notificationId) {
    let url = `${process.env.VUE_APP_EVENTS_URL}/notifications/setread/${notificationId}`
    const axios =
      process.env.NODE_ENV === "production" ? this.$mbAxios : rawAxios
    let response = await axios.put(url)
    if (response.status === 200) {
      dispatch("getNotifications")
    }
  }
}

export const getters = {
  notifications: (state) => state.notifications,
  newCount: (state) => state.newNotificationsCount,
  nameMappings: (state, getters, rootState, rootGetters) =>
    state.clientNameMappings.concat(rootGetters["advisors/advisors"])
}
