import axios from "axios"
import { clientDataService } from "@/global/modules/dataService/client.service"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"
import {
  ukAddressFields,
  internationalAddressFields
} from "@/global/modules/enum/addresses.js"
import { nicotineUse } from "@/global/modules/enum/constants"

export const state = {
  clients: [],
  companies: [],
  previousClientId: "",
  clientFromID: {},
  clientIdValid: false,
  hasClientReadAccess: false,
  clientDialogData: {},
  loadedClientId: "",
  loadedComanyIds: [],
  associations: [],
  refreshRightBarKey: 0,
  showCaseClients: false,
  statePensionAge: 0,
  newClientAssociatedtoCompany: null,
  clientNoAccessPrompt: {}
}

export const mutations = {
  setClients(state, clients) {
    state.clients = clients
  },
  setPreviousClientId(state, id) {
    state.previousClientId = id
  },
  setClientFromId(state, id) {
    state.clientFromID = id
  },
  setClientIdValidState(state, passState) {
    state.clientIdValid = passState
  },
  setClientRerrals(state, value) {
    const referral = value.referral
    state.clientDialogData = {
      title: "",
      dob: "",
      address: "",
      firstName: referral.client_names_array[0],
      lastName: referral.client_names_array[0],
      createdDate: referral.created_at,
      advisor: value.advisor
    }
  },
  setClientOpportunity(state, value) {
    const opportunity = value.opportunity
    state.clientDialogData = {
      title: "",
      dob: "",
      address: "",
      firstName: opportunity.client_names,
      lastName: opportunity.client_names,
      createdDate: opportunity.created_at,
      advisor: value.advisor
    }
  },
  setHasClientReadAccess(state, access) {
    state.hasClientReadAccess = access
  },
  setLoadedClientId(state, id) {
    state.loadedClientId = id
  },
  clearLoadedClientId(state) {
    state.loadedClientId = ""
  },
  refreshRightBar(state) {
    state.refreshRightBarKey++
  },
  setClientToSelected(state, id) {
    const targetClientObj = state.clients.find((client) => client.id === id)
    targetClientObj.selected = true
  },
  setShowCaseClients(state, value) {
    state.showCaseClients = value
  },
  setCompanies(state, companies) {
    state.companies = companies
  },
  setLoadedCompanyIds(state, ids) {
    state.loadedComanyIds = ids
  },
  clearClientDialogData(state) {
    state.clientDialogData = null
  },
  clearCompanies(state) {
    state.companies = []
    state.loadedComanyIds = []
  },
  setStatePensionAge(state, value) {
    state.statePensionAge = value
  },
  setNewClientToCompany(state, value) {
    state.newClientAssociatedtoCompany = value
  },
  setClientNoAccessPrompt(state, data) {
    state.clientNoAccessPrompt = data
  }
}

export const getters = {
  statePensionAge: (state) => state.statePensionAge,
  clients: (state) => state.clients,
  mainClient: (state) =>
    state.clients.find((client) => client.id === state.loadedClientId),
  previousClientId: (state) => state.previousClientId,
  clientFromID: (state) => state.clientFromID,
  selectedClients: (state, getters, rootState, rootGetters) =>
    state.showCaseClients
      ? state.clients.filter((client) =>
          rootGetters["cases/currentCase"].linked_clients.includes(client.id)
        )
      : state.clients.filter((client) => client.selected),
  clientIdValid: (state) => state.clientIdValid,
  loadedClientId: (state) => state.loadedClientId,
  refreshRightBarKey: (state) => state.refreshRightBarKey,
  hasClientReadAccess: (state) => state.hasClientReadAccess,
  loadedClient: (_, getters) =>
    getters.clients.find((client) => client.id === getters.loadedClientId),
  getClientName: (state) => (id) =>
    state.clients.find((client) => client.id === id)?.fullname || null,

  getClientAccessData: (state) => state.clientDialogData,
  selectedClientNames: (state, getters) => {
    const clientNames = getters.selectedClients.map((client) => client.fullname)
    if (clientNames.length <= 2) {
      return clientNames.join(" & ")
    }
    return clientNames
      .slice(0, -1)
      .join(", ")
      .concat(` & ${clientNames[clientNames.length - 1]}`)
  },
  newClient: (state) => state.newClientAssociatedtoCompany,
  companies: (state) => state.companies,
  selectedCompanies: (state, getters, rootState, rootGetters) =>
    state.showCaseClients
      ? state.companies.filter((company) =>
          rootGetters["cases/currentCase"].linked_company_ids.includes(
            company.id
          )
        )
      : state.companies.filter((company) => company.selected),
  selectedClientAndCompanyNames: (state, getters) => {
    var clientAndCompanyNames = [].concat(
      getters.selectedClients.map((client) => client.fullname),
      getters.selectedCompanies.map((company) => company.business_name)
    )
    if (clientAndCompanyNames.length <= 2) {
      return clientAndCompanyNames.join(" & ")
    }
    return clientAndCompanyNames
      .slice(0, -1)
      .join(", ")
      .concat(` & ${clientAndCompanyNames[clientAndCompanyNames.length - 1]}`)
  },
  loadedComanyIds: (state) => state.loadedComanyIds,
  clientNoAccessPrompt: (state) => state.clientNoAccessPrompt,
  showClientNoAccessPrompt: (state) => Boolean(state.clientNoAccessPrompt?.id),
  clientSelectListOptions:
    (state) =>
    (excludedClients = []) => {
      return state.clients.reduce((options, client) => {
        if (!excludedClients.includes(client.id)) {
          options.push({ value: client.id, text: client.fullname })
        }
        return options
      }, [])
    },
  validation: (state, rootState, getters, rootGetters) => {
    return {
      address: {
        ...mapRulesForAddress(rootGetters)
      },
      personal: {
        ...mapRulesForPersonal(rootGetters)
      },
      contact: {
        ...mapRulesForContact(rootGetters)
      }
    }
  }
}

function mapRulesForPersonal(rootGetters) {
  const fields = [
    "title_key",
    "birth_date",
    "salutation",
    "marital_status_key",
    "nicotine_use_key",
    "nicotine_use_quit_date",
    "nicotine_product_type_key",
    "nicotine_consumption_daily_count",
    "nicotine_use_start_date",
    "has_any_name_changed",
    "previous_first_name",
    "previous_middle_names",
    "previous_last_name",
    "name_changed_date",
    "citizen_status_key",
    "nationality_key",
    "has_dual_nationality",
    "nationality_second_key",
    "has_religious_beliefs_key",
    "religious_belief_notes",
    "has_ethical_beliefs_key",
    "ethical_belief_notes",
    "national_insurance_number",
    "state_pension_age",
    "intended_retirement_age",
    "has_client_relationship_to_firm_or_advisor",
    "client_relationship_firm_or_advisor_type_key",
    "linked_user_id"
  ]

  const hasRelationshipToFirmOrAdvisorCondition = {
    "==": [{ var: "has_client_relationship_to_firm_or_advisor" }, true]
  }

  const currentNicotineUseCondition = {
    includes: [
      { var: "nicotine_use_key" },
      [nicotineUse.currentSmoker, nicotineUse.currentUserOfNicotine]
    ]
  }

  const nameChangedCondition = {
    "==": [{ var: "has_any_name_changed" }, true]
  }

  const condition = {
    nicotine_use_quit_date: {
      "==": [{ var: "nicotine_use_key" }, nicotineUse.previouslySmoked]
    },
    nicotine_product_type_key: currentNicotineUseCondition,
    nicotine_consumption_daily_count: currentNicotineUseCondition,
    nicotine_use_start_date: currentNicotineUseCondition,
    previous_first_name: nameChangedCondition,
    previous_last_name: nameChangedCondition,
    name_changed_date: nameChangedCondition,
    nationality_second_key: {
      "==": [{ var: "has_dual_nationality" }, true]
    },
    religious_belief_notes: {
      "==": [{ var: "has_religious_beliefs_key" }, true]
    },
    ethical_belief_notes: {
      "==": [{ var: "has_ethical_beliefs_key" }, true]
    },
    client_relationship_firm_or_advisor_type_key:
      hasRelationshipToFirmOrAdvisorCondition,
    linked_user_id: hasRelationshipToFirmOrAdvisorCondition
  }

  const rules = mapRules(
    rootGetters,
    "personal",
    "client_personal",
    fields,
    condition
  )

  return rules
}

function mapRulesForContact(rootGetters) {
  const fields = [
    "contact_preferred_method_key",
    "advice_delivery_method_key",
    "phone_type_key",
    "email_address"
  ]

  const rules = mapRules(
    rootGetters,
    "personal",
    "client_contact",
    fields,
    null
  )

  return rules
}

function mapRulesForAddress(rootGetters) {
  const fields = [
    "address_occupancy_type_key",
    "residency_start_date",
    ...ukAddressFields,
    ...internationalAddressFields
  ]

  const conditions = {}

  const ukAddressCondition = {
    "==": [{ var: "is_non_uk_address" }, false]
  }

  ukAddressFields.forEach((field) => {
    conditions[field] = ukAddressCondition
  })

  const nonUkAddressCondition = {
    "==": [{ var: "is_non_uk_address" }, true]
  }

  internationalAddressFields.forEach((field) => {
    conditions[field] = nonUkAddressCondition
  })

  const rules = mapRules(
    rootGetters,
    "address",
    "client_address",
    fields,
    conditions
  )

  return rules
}

function mapRules(rootGetters, section, subSection, fields, conditions) {
  const factFind = rootGetters["caseSectionValidation/factfind"]
  return createValidationRules(
    (field) => factFind.required(section, subSection, field),
    fields,
    conditions
  )
}

export const actions = {
  async makeClientSelected({ commit }, clientId) {
    commit("setClientToSelected", clientId)
  },
  async checkClientId({ commit }, data) {
    try {
      const client = await clientDataService.getClient(data.id)

      // TODO: For AuthGuard demo: when clientID = "61bc75c30c608ee173a8f34c (Daniel Craig)"
      // don't allow user to proceed (will delete when Authguard is fully implemented)
      if (client.id === "61bc75c30c608ee173a8f34c") {
        commit("setClientIdValidState", false)
      } else {
        commit("setPreviousClientId", client.id)
        commit("setClientIdValidState", true)
      }
    } catch (error) {
      console.error(error)
    }
  },
  async getCRMClients({ commit }, data) {
    try {
      const client = await clientDataService.getClient(data)
      commit("setClientFromId", client)
    } catch (error) {
      console.error(error)
    }
  },

  async getStatePensionAge({ commit, state }, payload) {
    state.statePensionAge = 0
    return axios
      .post(`${process.env.VUE_APP_CDS_URL}/pension/statepensionage`, payload)
      .then(async (response) => {
        if (response.status === 200) {
          commit("setStatePensionAge", response.data.spa)
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  },
  /**
   *
   * @param {*} param0 - internal store data
   * @param {Client | Client[]} client client or client array to update
   * @returns
   */
  async updateClient({ dispatch, rootGetters }, client) {
    let method = "put"
    let url = `${process.env.VUE_APP_CDS_URL}/clients`

    if (Array.isArray(client)) {
      method = "post"
      url = `${process.env.VUE_APP_CDS_URL}/clients/createorupdate`
    }

    const config = {
      method: method,
      url: url,
      data: client
    }

    return axios(config).then((response) => {
      if (response.status > 204) {
        Promise.reject(response.data ?? response.statusText)
      }
      let id = rootGetters["clients/currentClientId"]
      if (id === "") id = client[0].id

      return dispatch("loadClient", id)
    })
  },
  async loadClient({ state, commit, rootGetters }, id) {
    const snapshotIdQuery = rootGetters["cases/snapshotIdQuery"]
    return axios
      .get(
        `${process.env.VUE_APP_CDS_URL}/clients/${id}/getassociated${snapshotIdQuery}`
      )
      .then((response) => {
        let clients = response.data

        let isReload = state.loadedClientId === id
        commit("setLoadedClientId", id)

        //populate selected clients
        let mainClient = clients.find((e) => e.id === id)

        let clientsToSelect = isReload
          ? state.clients.filter((c) => c.selected).map((e) => e.id)
          : mainClient.default_clients

        clientsToSelect?.forEach((selectedClientId) => {
          let matched = clients.find((e) => e.id === selectedClientId)
          if (matched) matched.selected = true
        })

        //default to client with matching id if none are selected
        if (!clients.find((e) => e.selected)) {
          mainClient.selected = true
        }

        commit(
          "setClients",
          clients.map((client) => ({
            ...client,
            fullname: `${client.first_name} ${client.last_name}`,
            first_initial_surname: getFirstInitialSurname(client),
            selected: !!client.selected //convert to bool even if undefined
          }))
        )

        return Promise.resolve(true)
      })
      .catch((e) => {
        console.log("loadClient failure", e)
        return Promise.reject(false)
      })
  },
  async createAssociation({ dispatch }, payload) {
    const { clientId, clientsToAssociateWith } = payload
    try {
      const clientsToAssociateWithIds = clientsToAssociateWith.map(
        ({ id }) => id
      )
      const selectedClientObject = await axios
        .get(`${process.env.VUE_APP_CDS_URL}/clients/${clientId}`)
        .then((res) => res.data)
      selectedClientObject.associated_clients = !Array.isArray(
        selectedClientObject.associated_clients
      )
        ? [...clientsToAssociateWithIds]
        : [
            ...new Set([
              ...selectedClientObject.associated_clients,
              ...clientsToAssociateWithIds
            ])
          ]

      const clientsArrayToPost = [
        ...clientsToAssociateWith.map((client) => {
          if (!Array.isArray(client.associated_clients)) {
            client.associated_clients = []
          }
          client.associated_clients.push(clientId)
          return client
        }),
        selectedClientObject
      ]

      await dispatch("updateClient", clientsArrayToPost)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async loadClientIfAuthorised({ commit, dispatch }, payload) {
    try {
      commit("clearClientDialogData")

      if (payload.type == "Opportunity") {
        commit("setClientOpportunity", payload)
      } else if (payload.type == "Referral") {
        commit("setClientRerrals", payload)
      }

      if (payload.id) {
        const hasAccess = await dispatch("loadClient", payload.id)
        commit("setHasClientReadAccess", hasAccess)
      } else {
        commit("setHasClientReadAccess", true) // temp client ID
      }
    } catch (error) {
      console.log("Error" + error)
      commit("setHasClientReadAccess", false)
    }
  },
  async loadCompanies({ commit, getters, rootGetters }) {
    const companyIds = getters.mainClient.associated_company_ids

    if (companyIds.length === 0) {
      commit("clearCompanies")
      return
    }

    const snapshotId = rootGetters["cases/selectedSnapshotId"]
    const snapshotIdQuery = snapshotId ? `?snapshot_id=${snapshotId}` : ""

    await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientcompanies/get${snapshotIdQuery}`,
        companyIds
      )
      .then((response) => {
        const companies = response.data

        const isReload = companyIds.some((id) =>
          getters.loadedComanyIds.includes(id)
        )
        commit("setLoadedCompanyIds", companyIds)

        const companiesToSelect = isReload
          ? getters.companies.filter((c) => c.selected).map((c) => c.id)
          : getters.mainClient.default_company_ids

        companiesToSelect.forEach((selectedCompanyId) => {
          let matched = companies.find((e) => e.id === selectedCompanyId)
          if (matched) matched.selected = true
        })

        commit(
          "setCompanies",
          companies.map((company) => ({
            ...company,
            selected: !!company.selected
          }))
        )
      })
  },
  async createAndAssociateCompany({ dispatch, getters, rootGetters }, payload) {
    await axios
      .post(`${process.env.VUE_APP_CDS_URL}/clientcompanies/create`, payload)
      .then(async (response) => {
        if (response.status === 200) {
          const { company_client } = response.data

          await dispatch("loadClient", rootGetters["clients/currentClientId"])
          await dispatch("loadCompanies")

          var companyToSelect = getters.companies.find(
            (e) => e.id === company_client.id
          )
          companyToSelect.selected = true
        }
      })
  },
  async updateCompany({ dispatch }, payload) {
    await axios
      .post(`${process.env.VUE_APP_CDS_URL}/clientcompanies/update`, payload)
      .then(async (response) => {
        if (response.status === 200) {
          await dispatch("loadCompanies")
        }
      })
  },
  newClientToCompany({ commit }, id) {
    commit("setNewClientToCompany", id)
  },
  clearClientNoAccessPrompt({ commit }) {
    commit("setClientNoAccessPrompt", {})
  }
}

function getFirstInitialSurname(client) {
  var names = [client.first_name?.charAt(0), client.last_name]
  return names.filter((e) => e).join(" ")
}
