import store from "@/store"
// Provider list helpers
// Filter providers based on provider type
// providerTypes -> "mortgage", "general_insurance", "protection"
export function getProvidersByType(providerType) {
  let providers = []
  var mappedType = ""
  if (providerType) {
    mappedType = providerMappedKeys(providerType)
  }

  if (!mappedType) {
    providers = store.getters["provider/allProviders"]
  } else if (mappedType == "mortgage_lender") {
    providers = store.getters["providers/mortgageProviders"]
  } else if (mappedType == "protection_provider") {
    providers = store.getters["providers/protectionProviders"]
  } else if (mappedType == "general_insurance_provider") {
    providers = store.getters["providers/generalInsuranceProviders"]
  }

  return providers
}

// Replaces displayTextByFormIdAndValue for providers as new provider list isn't from DD so no guid.
export function displayProviderNameByIdAndType(providerId, type = null) {
  if (!providerId) {
    return
  }

  const providers = getProvidersByType(type)

  return providers?.find((provider) => {
    return provider.id === providerId
  })?.providerDisplayName
}

export function displayProviderNameByDbNameAndType(providerDbName, type) {
  if (!providerDbName) {
    return
  }
  const providers = getProvidersByType(type)

  return providers?.find((provider) => {
    return provider.providerDbName === providerDbName
  })?.providerDisplayName
}

export function getProviderDbNameFromIdAndType(providerId, type) {
  let providers = getProvidersByType(type)

  return providers?.find((provider) => provider.id === providerId)
    ?.providerDbName
}

function providerMappedKeys(providerType) {
  switch (providerType) {
    case "mortgage":
    case "mortgage_lender":
      return "mortgage_lender"
    case "general_insurance":
    case "general_insurance_provider":
      return "general_insurance_provider"
    case "protection":
    case "protection_provider":
      return "protection_provider"
  }
  console.error(providerType + " not supported as a provider type.")
}
