export const MbAddressMixin = {
  methods: {
    formatAddress({ propertyAddress, isAvmAddress = false }, getters) {
      if (!getters) {
        //when called from registered component, we have access to this.$store
        //other stores/modules must provide getters.
        getters = this.$store.getters
      }
      if (!getters) {
        throw "Parameter 'getters' is undefined for 'formatAddress' method"
      }
      if (!propertyAddress) {
        return ""
      }
      if (propertyAddress.is_non_uk_address) {
        return filteredJoin(
          [
            propertyAddress.international_line_1,
            propertyAddress.international_line_2,
            propertyAddress.international_line_3,
            propertyAddress.international_line_4,
            tryGetDisplayText(
              "q-f08fb998-5bcf-4391-867f-73005ac2c487",
              propertyAddress.country_key,
              getters
            ),
            tryGetDisplayText(
              "q-e524e341-8ed5-421c-a985-6f8bb662de3b",
              propertyAddress.economic_region_key,
              getters
            )
          ],
          ", "
        )
      } else {
        //format:
        //flat, house_number building_name
        //flat, building_name
        //house_number, building_name

        //if line one contains 2 or more non empty values, append comma after first value.
        //remove any empty values from line1
        let line1Arr = [
          propertyAddress.flat_number,
          propertyAddress.house_number,
          propertyAddress.building_name
        ].filter((e) => e)

        if (line1Arr.length > 1) {
          line1Arr[0] = line1Arr[0] + "," //no space, as will be added during the filtered join
        }
        let line1 = filteredJoin(line1Arr, " ")
        if (!isAvmAddress) {
          return filteredJoin(
            [
              line1,
              propertyAddress.street,
              propertyAddress.district,
              propertyAddress.town,
              propertyAddress.county,
              propertyAddress.postcode,
              tryGetDisplayText(
                "q-bc0737f5-92de-4871-8997-2efd8ae7ac1a",
                propertyAddress.uk_country_key,
                getters
              )
            ],
            ", "
          )
        } else {
          // avm address: don't include UK Country Code as it now breaks the Hometrack api
          return filteredJoin(
            [
              line1,
              propertyAddress.street,
              propertyAddress.district,
              propertyAddress.town,
              propertyAddress.county,
              propertyAddress.postcode
            ],
            ", "
          )
        }
      }
    }
  }
}

function filteredJoin(arr, joinStr) {
  return arr.filter((e) => e).join(joinStr)
}
function tryGetDisplayText(id, val, getters) {
  let func = getters["datadictionary/displayTextByFormIdAndValue"]
  if (!func) {
    throw "Method 'datadictionary/displayTextByFormIdAndValue' is undefined at 'tryGetDisplayText'"
  }
  //as we are only converting object to string and not validating the object,
  //if the value fails to format, just display it as is.
  return func(id, val) ?? val
}

export default MbAddressMixin
