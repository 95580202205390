import { storeGuards } from "@/global/modules/middleware/checkStore"

export const commissionRoutes = [
  {
    beforeEnter: storeGuards.clearCommission,
    path: "commissions",
    name: "commission-records",
    component: () =>
      import(
        /* webpackChunkName: "commission-records" */ "@/pages/clients/cases/commissions/BaseView.vue"
      ),
    meta: {
      caseNavActive: true
    },
    children: [
      {
        path: "add",
        name: "commission-records-add",
        component: () =>
          import(
            /* webpackChunkName: "commission-records" */ "@/pages/clients/cases/commissions/CreateView.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Commissions & Fees / Add"
        }
      },
      {
        path: "edit/:commissionId([0-9a-fA-F]{24})",
        name: "commission-records-edit",
        component: () =>
          import(
            /* webpackChunkName: "commission-records" */ "@/pages/clients/cases/commissions/EditView.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Commissions & Fees / Add"
        }
      },
      {
        path: "",
        name: "commission-records-list",
        component: () =>
          import(
            /* webpackChunkName: "commission-records" */ "@/pages/clients/cases/commissions/IndexView.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Commissions & Fees"
        }
      }
    ]
  }
]
