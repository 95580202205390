export default {
  methods: {
    emitDelayed(name) {
      //Used by form inputs (e.g. select, radio).
      //Some events are emitted before state data is updated,
      //so this delay is added to give time for state data to update.

      //do not pass payload (yet). We do not want consumers using it till low-level (possibly third party) components are finalised (e.g. v-select, vue-autonumeric)

      // setTimeout(() => this.$emit(name, null), delay)
      this.$nextTick(() => {
        this.$emit(name, null)
      })
    }
  }
}
