import axios from "axios"
import { formatClientNamesForList } from "@/global/modules/helpers/clientHelpers"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"

export const state = {
  clients: [],
  allCommitments: [],
  loans: [],
  cards: [],
  others: [],
  selectedClients: [],
  selectedStatus: "",
  selectedType: "",
  selectedUnsecuredLoan: {},
  selectedPCPLoan: {},
  selectedHirePurchase: {},
  selectedLeaseAgreement: {},
  selectedOverdraftLoan: {},
  selectCreditStoreCards: {},
  selectOtherCommitment: {},
  commitmentInsights: {},
  isNew: true,
  excludedClientsFromSelectionList: []
}

export const mutations = {
  clearCommitments(state) {
    state.loans = []
    state.cards = []
    state.others = []
    state.allCommitments = []
  },
  addLoans(state, data) {
    state.loans.push(data)
  },
  addCards(state, data) {
    state.cards.push(data)
  },
  addOthers(state, data) {
    state.others.push(data)
  },
  addSelectedClientAndStatus(state, data) {
    state.isNew = true
    state.selectedClients = data.clients
    state.selectedStatus = data.status
    state.selectedType = data.type
  },
  allCommitments(state, data) {
    state.allCommitments.push(data)
  },
  setUnsecuredLoan(state, data) {
    state.selectedUnsecuredLoan = data
  },
  setPCPLoan(state, data) {
    state.selectedPCPLoan = data
  },
  setHirePurchase(state, data) {
    state.selectedHirePurchase = data
  },
  setLeaseAgreement(state, data) {
    state.selectedLeaseAgreement = data
  },
  setOverdraftLoan(state, data) {
    state.selectedOverdraftLoan = data
  },
  setCreditStoreCard(state, data) {
    state.selectCreditStoreCards = data
  },
  setOtherCommitment(state, data) {
    state.selectOtherCommitment = data
  },
  setCommitmentInsights(state, data) {
    state.commitmentInsights = data
  },
  setExcludedClientsFromSelectionList(state, data) {
    state.excludedClientsFromSelectionList = data
  }
}

export const actions = {
  async loadCommitments(context) {
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    const clientIds = context.rootGetters["clientFile/selectedClients"].map(
      (client) => client.id
    )

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientcommitments/getforclientids${snapshotIdQuery}`,
        clientIds
      )

      context.commit("clearCommitments")
      if (response.status === 200) {
        response.data.forEach((commitmentsData) => {
          mapCommitmentForList(
            commitmentsData,
            context.rootGetters["clientFile/clients"],
            context
          )
        })
        context.dispatch("calcInsights")
      }
    } catch (err) {
      context.commit("clearCommitments")
      console.log(err)
    }
  },
  saveSelectedClientAndStatus(context, payload) {
    context.commit("addSelectedClientAndStatus", payload)
  },
  async addUpdateCommitment({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientcommitments/createorupdate`,
        [payload.data]
      )

      if (response.status === 200) {
        await dispatch("loadCommitments")
      }
    } catch (err) {
      console.log(err.response.data)
    }
  },
  calcInsights(context) {
    let monthlyTotal = 0
    let debtTotal = 0

    context.state.loans.forEach((x) => {
      if (x.monthly_payment) monthlyTotal += x.monthly_payment
    })
    context.state.cards.forEach((x) => {
      if (x.monthly_payment) monthlyTotal += x.monthly_payment
    })
    context.state.others.forEach((x) => {
      if (x.monthly_payment) monthlyTotal += x.monthly_payment
    })

    context.state.loans.forEach((x) => {
      if (x.balance) debtTotal += x.balance
    })
    context.state.cards.forEach((x) => {
      if (x.balance) debtTotal += x.balance
    })

    context.commit("setCommitmentInsights", {
      TotalMonthly: monthlyTotal,
      TotalDebt: debtTotal
    })
  },
  async deleteCommitment({ dispatch }, payload) {
    // set delete properties
    payload.is_deleted = true
    payload.deleted_at = new Date()

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientcommitments/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        dispatch("loadCommitments")
      }
    } catch (err) {
      console.log(err)
    }
  },
  fetchCommitment({ commit, state }, payload) {
    // init variables
    let mainItem = {}
    let itemToEdit = {}
    state.isNew = false

    // search for the commitment record
    mainItem = state.allCommitments.filter((c) => {
      return c.id === payload.id
    })[0]

    itemToEdit = {
      id: mainItem.id,
      commitment_type_key: mainItem.commitment_type_key,
      linked_clients: mainItem.linked_clients,
      who: mainItem.who,
      commitment_account_status_key: mainItem.commitment_account_status_key,
      version: mainItem.version
    }

    switch (payload.type) {
      case "unsecured_loan":
        itemToEdit.unsecured_loan_details = {
          ...mainItem.unsecured_loan_details
        }
        commit("setUnsecuredLoan", itemToEdit)
        break
      case "personal_contract_purchase":
        itemToEdit.pcp_details = { ...mainItem.pcp_details }
        commit("setPCPLoan", itemToEdit)
        break
      case "hire_purchase":
        itemToEdit.hp_details = { ...mainItem.hp_details }
        commit("setHirePurchase", itemToEdit)
        break
      case "lease_agreement":
        itemToEdit.lease_details = { ...mainItem.lease_details }
        commit("setLeaseAgreement", itemToEdit)
        break
      case "overdraft":
        itemToEdit.overdraft_details = { ...mainItem.overdraft_details }
        commit("setOverdraftLoan", itemToEdit)
        break
      case "credit_store_card":
        itemToEdit.card_details = { ...mainItem.card_details }
        commit("setCreditStoreCard", itemToEdit)
        break
      case "other_commitment":
        itemToEdit.other_details = { ...mainItem.other_details }
        commit("setOtherCommitment", itemToEdit)
        break
      default:
    }
  }
}

export const getters = {
  allCommitments(state) {
    return state.allCommitments
  },
  loans(state) {
    return state.loans
  },
  cards(state) {
    return state.cards
  },
  others(state) {
    return state.others
  },
  isAddMode(state) {
    return state.isNew
  },
  selectedClients(state) {
    return state.selectedClients
  },
  selectedStatus(state) {
    return state.selectedStatus
  },
  selectedType(state) {
    return state.selectedType
  },
  selectedUnsecuredLoan(state) {
    return state.selectedUnsecuredLoan
  },
  selectedPCPLoan(state) {
    return state.selectedPCPLoan
  },
  selectedHirePurchase(state) {
    return state.selectedHirePurchase
  },
  selectedLeaseAgreement(state) {
    return state.selectedLeaseAgreement
  },
  selectedOverdraftLoan(state) {
    return state.selectedOverdraftLoan
  },
  selectCreditStoreCards(state) {
    return state.selectCreditStoreCards
  },
  selectOtherCommitment(state) {
    return state.selectOtherCommitment
  },
  selectedClientNames(state, getters, rootState, rootGetters) {
    return rootGetters["clientFile/selectedClients"]
      .filter((client) => state.selectedClients.includes(client.id))
      .reduce((names, client) => {
        names.push(client.fullname)
        return names
      }, [])
      .join(", ")
  },
  commitmentInsights(state) {
    return state.commitmentInsights
  },
  excludedClientsFromSelectionList: (state) =>
    state.excludedClientsFromSelectionList,
  validation: (state, rootState, getters, rootGetters) => {
    return {
      list: {
        ...mapRulesForList(rootGetters)
      },
      pcpLoan: {
        ...mapRulesForPcpLoan(rootGetters)
      },
      hirePurchase: {
        ...mapRulesForHirePurchase(rootGetters)
      },
      leaseAgreement: {
        ...mapRulesForLeaseAgreement(rootGetters)
      },
      overdraftLoan: {
        ...mapRulesForOverdraftLoan(rootGetters)
      },
      creditStoreCards: {
        ...mapRulesForHireCreditStoreCards(rootGetters)
      },
      unsecuredLoan: {
        ...mapRulesForUnsecuredLoan(rootGetters)
      },
      other: {
        ...mapRulesForOther(rootGetters)
      }
    }
  }
}

function mapRulesForList(rootGetters) {
  const fields = ["kyc_client_has_commitments"]
  return mapRules(rootGetters, "kyc_commitments", fields)
}

function mapRulesForHirePurchase(rootGetters) {
  const fields = [
    "commitment_account_status_key",
    "commitment_provider_name_key",
    "commitment_provider_other_name",
    "commitment_term_months",
    "commitment_borrowed_amount",
    "commitment_interest_rate_percentage",
    "commitment_start_date",
    "commitment_end_date",
    "commitment_monthly_repayment_amount",
    "commitment_outstanding_balance_amount",
    "commitment_outstanding_balance_date",
    "commitment_future_intention_key",
    "commitment_debt_reversal_method_key"
  ]

  const conditions = {
    ...mapOtherProviderNameCondition(),
    commitment_debt_reversal_method_key: futureIntentionCondition
  }

  return mapRules(rootGetters, "commitment_hire_purchase", fields, conditions)
}

function mapRulesForPcpLoan(rootGetters) {
  const fields = [
    "commitment_account_status_key",
    "commitment_provider_name_key",
    "commitment_provider_other_name",
    "commitment_term_months",
    "commitment_borrowed_amount",
    "commitment_final_balloon_payment_amount",
    "commitment_interest_rate_percentage",
    "commitment_start_date",
    "commitment_monthly_repayment_amount",
    "commitment_outstanding_balance_amount",
    "commitment_outstanding_balance_date",
    "commitment_future_intention_key",
    "commitment_debt_reversal_method_key"
  ]

  const conditions = {
    ...mapOtherProviderNameCondition(),
    commitment_debt_reversal_method_key: futureIntentionCondition
  }

  return mapRules(rootGetters, "commitment_pcp", fields, conditions)
}

function mapRulesForHireCreditStoreCards(rootGetters) {
  const fields = [
    "commitment_account_status_key",
    "commitment_provider_name_key",
    "commitment_provider_other_name",
    "commitment_start_date",
    "commitment_agreed_limit_amount",
    "commitment_interest_rate_percentage",
    "commitment_is_credit_card_cleared_monthly",
    "commitment_monthly_repayment_amount",
    "commitment_outstanding_balance_amount",
    "commitment_outstanding_balance_date",
    "commitment_future_intention_key",
    "commitment_debt_reversal_method_key"
  ]

  const cardClearedMonthlyCondition = {
    "==": [{ var: "commitment_is_credit_card_cleared_monthly" }, true]
  }

  const conditions = {
    ...mapOtherProviderNameCondition(),
    commitment_future_intention_key: cardClearedMonthlyCondition,
    commitment_debt_reversal_method_key: {
      and: [cardClearedMonthlyCondition, futureIntentionCondition]
    }
  }

  const rules = mapRules(
    rootGetters,
    "commitment_credit_card",
    fields,
    conditions
  )

  return rules
}

function mapRulesForLeaseAgreement(rootGetters) {
  const fields = [
    "commitment_account_status_key",
    "commitment_provider_name_key",
    "commitment_provider_other_name",
    "commitment_term_months",
    "commitment_start_date",
    "commitment_monthly_repayment_amount",
    "commitment_future_intention_key",
    "commitment_debt_reversal_method_key"
  ]

  const conditions = {
    ...mapOtherProviderNameCondition(),
    commitment_debt_reversal_method_key: futureIntentionCondition
  }

  const rules = mapRules(
    rootGetters,
    "commitment_lease_agreement",
    fields,
    conditions
  )

  return rules
}

function mapRulesForUnsecuredLoan(rootGetters) {
  const fields = [
    "commitment_account_status_key",
    "commitment_provider_name_key",
    "commitment_provider_other_name",
    "commitment_term_months",
    "commitment_borrowed_amount",
    "commitment_interest_rate_percentage",
    "commitment_start_date",
    "commitment_monthly_repayment_amount",
    "commitment_outstanding_balance_amount",
    "commitment_outstanding_balance_date",
    "commitment_future_intention_key",
    "commitment_debt_reversal_method_key"
  ]

  const conditions = {
    ...mapOtherProviderNameCondition(),
    commitment_debt_reversal_method_key: futureIntentionCondition
  }

  return mapRules(rootGetters, "commitment_unsecured_loan", fields, conditions)
}

function mapRulesForOther(rootGetters) {
  const fields = [
    "commitment_account_status_key",
    "commitment_other_type_key",
    "commitment_type_other_description",
    "commitment_borrowed_amount",
    "commitment_monthly_repayment_amount",
    "is_commitment_end_date_known",
    "commitment_end_date",
    "commitment_outstanding_balance_amount",
    "commitment_outstanding_balance_date",
    "commitment_future_intention_key",
    "commitment_debt_reversal_method_key",
    "commitment_other_future_intention_key"
  ]

  return mapRules(rootGetters, "commitment_other", fields)
}

function mapRulesForOverdraftLoan(rootGetters) {
  const fields = [
    "commitment_account_status_key",
    "commitment_provider_name_key",
    "commitment_provider_other_name",
    "commitment_agreed_limit_amount",
    "commitment_outstanding_balance_amount",
    "commitment_outstanding_balance_date",
    "commitment_future_intention_key",
    "commitment_debt_reversal_method_key"
  ]

  const conditions = {
    ...mapOtherProviderNameCondition(),
    commitment_debt_reversal_method_key: futureIntentionCondition
  }

  return mapRules(rootGetters, "commitment_overdraft", fields, conditions)
}

function mapOtherProviderNameCondition() {
  return {
    commitment_provider_other_name: {
      "==": [{ var: "commitment_provider_name_key" }, "other"]
    }
  }
}

const futureIntentionCondition = {
  includes: [
    { var: "commitment_future_intention_key" },
    ["partial_reduction", "redeeming"]
  ]
}

function mapRules(rootGetters, subSection, fields, conditions) {
  const factFind = rootGetters["caseSectionValidation/factfind"]
  return createValidationRules(
    (field) => factFind.required("commitments", subSection, field),
    fields,
    conditions
  )
}

function mapCommitmentForList(commitmentsData, clients, context) {
  commitmentsData.who = formatClientNamesForList(
    commitmentsData.linked_clients,
    clients
  )

  let commitment = {}
  //add to the allCommitments array
  context.commit("allCommitments", commitmentsData)

  switch (commitmentsData.commitment_type_key) {
    case "lease_agreement":
      commitment = {
        id: commitmentsData.id,
        who: commitmentsData.who,
        linked_clients: commitmentsData.linked_clients,
        type: commitmentsData.commitment_type_key,
        status: commitmentsData.commitment_account_status_key,
        provider:
          commitmentsData.lease_details.commitment_provider_name_key === "other"
            ? commitmentsData.lease_details.commitment_provider_other_name
            : getDisplayText(
                context,
                "q-37a86dc7-6e63-495d-9c99-5ef8347c4ae6",
                commitmentsData.lease_details.commitment_provider_name_key
              ),
        term: commitmentsData.lease_details.commitment_term_months,
        start_date: commitmentsData.lease_details.commitment_start_date,
        end_date: commitmentsData.lease_details.commitment_end_date,
        monthly_payment:
          commitmentsData.lease_details.commitment_monthly_repayment_amount,
        intend_to_repay:
          commitmentsData.lease_details.commitment_future_intention_key,
        debt_reversal_method:
          commitmentsData.lease_details.commitment_debt_reversal_method_key,
        version: commitmentsData.version,
        completed:
          commitmentsData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addLoans", commitment)
      break
    case "hire_purchase":
      commitment = {
        id: commitmentsData.id,
        who: commitmentsData.who,
        linked_clients: commitmentsData.linked_clients,
        type: commitmentsData.commitment_type_key,
        status: commitmentsData.commitment_account_status_key,
        provider:
          commitmentsData.hp_details.commitment_provider_name_key === "other"
            ? commitmentsData.hp_details.commitment_provider_other_name
            : getDisplayText(
                context,
                "q-37a86dc7-6e63-495d-9c99-5ef8347c4ae6",
                commitmentsData.hp_details.commitment_provider_name_key
              ),
        term: commitmentsData.hp_details.commitment_term_months,
        balance: getBalance(commitmentsData.hp_details.balance_history),
        start_date: commitmentsData.hp_details.commitment_start_date,
        end_date: commitmentsData.hp_details.commitment_end_date,
        monthly_payment:
          commitmentsData.hp_details.commitment_monthly_repayment_amount,
        intend_to_repay:
          commitmentsData.hp_details.commitment_future_intention_key,
        debt_reversal_method:
          commitmentsData.hp_details.commitment_debt_reversal_method_key,
        version: commitmentsData.version,
        completed:
          commitmentsData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addLoans", commitment)
      break
    case "personal_contract_purchase":
      commitment = {
        id: commitmentsData.id,
        who: commitmentsData.who,
        linked_clients: commitmentsData.linked_clients,
        type: commitmentsData.commitment_type_key,
        status: commitmentsData.commitment_account_status_key,
        provider:
          commitmentsData.pcp_details.commitment_provider_name_key === "other"
            ? commitmentsData.pcp_details.commitment_provider_other_name
            : getDisplayText(
                context,
                "q-37a86dc7-6e63-495d-9c99-5ef8347c4ae6",
                commitmentsData.pcp_details.commitment_provider_name_key
              ),
        term: commitmentsData.pcp_details.commitment_term_months,
        balance: getBalance(commitmentsData.pcp_details.balance_history),
        start_date: commitmentsData.pcp_details.commitment_start_date,
        end_date: commitmentsData.pcp_details.commitment_end_date,
        monthly_payment:
          commitmentsData.pcp_details.commitment_monthly_repayment_amount,
        intend_to_repay:
          commitmentsData.pcp_details.commitment_future_intention_key,
        debt_reversal_method:
          commitmentsData.pcp_details.commitment_debt_reversal_method_key,
        //notes: commitmentsData.pcp_details.notes,
        version: commitmentsData.version,
        completed:
          commitmentsData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addLoans", commitment)
      break
    case "unsecured_loan":
      commitment = {
        id: commitmentsData.id,
        who: commitmentsData.who,
        linked_clients: commitmentsData.linked_clients,
        type: commitmentsData.commitment_type_key,
        status: commitmentsData.commitment_account_status_key,
        provider:
          commitmentsData.unsecured_loan_details
            .commitment_provider_name_key === "other"
            ? commitmentsData.unsecured_loan_details
                .commitment_provider_other_name
            : getDisplayText(
                context,
                "q-37a86dc7-6e63-495d-9c99-5ef8347c4ae6",
                commitmentsData.unsecured_loan_details
                  .commitment_provider_name_key
              ),
        term: commitmentsData.unsecured_loan_details.commitment_term_months,
        balance: getBalance(
          commitmentsData.unsecured_loan_details.balance_history
        ),
        start_date:
          commitmentsData.unsecured_loan_details.commitment_start_date,
        end_date: commitmentsData.unsecured_loan_details.commitment_end_date,
        monthly_payment:
          commitmentsData.unsecured_loan_details
            .commitment_monthly_repayment_amount,
        intend_to_repay:
          commitmentsData.unsecured_loan_details
            .commitment_future_intention_key,
        debt_reversal_method:
          commitmentsData.unsecured_loan_details
            .commitment_debt_reversal_method_key,
        version: commitmentsData.version,
        completed:
          commitmentsData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addLoans", commitment)
      break
    case "overdraft":
      commitment = {
        id: commitmentsData.id,
        who: commitmentsData.who,
        linked_clients: commitmentsData.linked_clients,
        type: commitmentsData.commitment_type_key,
        status: commitmentsData.commitment_account_status_key,
        provider:
          commitmentsData.overdraft_details.commitment_provider_name_key ===
          "other"
            ? commitmentsData.overdraft_details.commitment_provider_other_name
            : getDisplayText(
                context,
                "q-37a86dc7-6e63-495d-9c99-5ef8347c4ae6",
                commitmentsData.overdraft_details.commitment_provider_name_key
              ),
        term: "",
        balance: getBalance(commitmentsData.overdraft_details.balance_history),
        start_date: null,
        end_date: null,
        overdraft_limit:
          commitmentsData.overdraft_details.commitment_agreed_limit_amount,
        intend_to_repay:
          commitmentsData.overdraft_details.commitment_future_intention_key,
        version: commitmentsData.version,
        completed:
          commitmentsData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addOthers", commitment)
      break
    case "credit_store_card":
      commitment = {
        id: commitmentsData.id,
        who: commitmentsData.who,
        linked_clients: commitmentsData.linked_clients,
        type: commitmentsData.commitment_type_key,
        status: commitmentsData.commitment_account_status_key,
        provider:
          commitmentsData.card_details.commitment_provider_name_key === "other"
            ? commitmentsData.card_details.commitment_provider_other_name
            : getDisplayText(
                context,
                "q-37a86dc7-6e63-495d-9c99-5ef8347c4ae6",
                commitmentsData.card_details.commitment_provider_name_key
              ),
        balance: getBalance(commitmentsData.card_details.balance_history),
        start_date: commitmentsData.card_details.commitment_start_date,
        credit_limit:
          commitmentsData.card_details.commitment_agreed_limit_amount,
        monthly_payment:
          commitmentsData.card_details.commitment_monthly_repayment_amount,
        cleared_monthly:
          commitmentsData.card_details
            .commitment_is_credit_card_cleared_monthly,
        version: commitmentsData.version,
        completed:
          commitmentsData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addCards", commitment)
      break
    case "other_commitment":
      commitment = {
        id: commitmentsData.id,
        who: commitmentsData.who,
        linked_clients: commitmentsData.linked_clients,
        type: commitmentsData.commitment_type_key,
        commitment_type:
          commitmentsData.other_details.commitment_other_type_key,
        status: commitmentsData.commitment_account_status_key,
        intend_to_repay:
          commitmentsData.other_details.commitment_other_type_key ===
          "loan_from_family_or_friend"
            ? commitmentsData.other_details.commitment_future_intention_key
            : commitmentsData.other_details
                .commitment_other_future_intention_key,
        end_date: commitmentsData.other_details.commitment_end_date,
        monthly_payment:
          commitmentsData.other_details.commitment_monthly_repayment_amount,
        version: commitmentsData.version,
        completed:
          commitmentsData.current_record_validation
            ?.is_record_section_validation_result_valid ?? false
      }
      context.commit("addOthers", commitment)
      break
  }
}

function getBalance(balanceArray) {
  if (balanceArray.length === 0) return ""
  // sort by balance date to get the most recent
  balanceArray.sort((a, b) => {
    return b - a
  })
  return balanceArray[balanceArray.length - 1]
    .commitment_outstanding_balance_amount
}

function getDisplayText(context, formId, value) {
  const displayText =
    context.rootGetters["datadictionary/displayTextByFormIdAndValue"]
  return displayText(formId, value)
}
