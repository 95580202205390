import { BaseAPI } from "./base"

export class UserApi extends BaseAPI {
  async getSelf() {
    return this.get(`/api/v1/user`).then((userResponse) => {
      if (userResponse.status >= 200 && userResponse.status < 300) {
        return userResponse.json()
      } else {
        throw userResponse
      }
    })
  }

  async updateSelf(userData) {
    return this.put(`/api/v1/user`, userData).then((userResponse) => {
      if (userResponse.status >= 200 && userResponse.status < 300) {
        return userResponse.json()
      } else {
        throw userResponse
      }
    })
  }

  async getProducts() {
    return this.get(`/api/v1/user/products`).then((userResponse) => {
      if (userResponse.status >= 200 && userResponse.status < 300) {
        return userResponse.json()
      } else {
        throw userResponse
      }
    })
  }

  async getPermissions() {
    return this.get(`/api/v1/user/permissions/${this.appId}`).then(
      (userResponse) => {
        if (userResponse.status >= 200 && userResponse.status < 300) {
          return userResponse.json()
        } else {
          throw userResponse
        }
      }
    )
  }
}
