import {
  chainGuards,
  storeGuards
} from "@/global/modules/middleware/checkStore"

export const appicationRoutes = [
  {
    path: "applications",
    beforeEnter: chainGuards([
      storeGuards.loadRequirementsGuard,
      storeGuards.loadApplicationsGuard
    ]),
    component: () =>
      import(
        /* webpackChunkName: "case-applications" */ "@/components/ux/clients/cases/CaseApplications.vue"
      ),
    children: [
      {
        path: "",
        name: "case-applications-list",
        component: () =>
          import(
            /* webpackChunkName: "case-applications-list" */ "@/components/ux/clients/cases/CaseApplicationsList.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Applications"
        }
      },
      {
        path: ":appId",
        beforeEnter: chainGuards([
          storeGuards.editApplicationGuard,
          storeGuards.loadTasksGuard,
          storeGuards.loadPropertiesGuard,
          storeGuards.loadAllTeamMembers,
          storeGuards.loadMortgagesGuard,
          storeGuards.loadPoliciesGuard
        ]),
        name: "case-application",
        component: () =>
          import(
            /* webpackChunkName: "case-application" */ "@/components/ux/clients/cases/CaseApplicationForm.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Applications"
        },
        children: [
          {
            path: "edit",
            name: "case-application-edit",
            component: () =>
              import(
                /* webpackChunkName: "case-application-edit" */ "@/components/ux/clients/cases/CaseApplicationEdit.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Application / Edit"
            }
          },
          {
            path: "security-property",
            name: "case-application-security-property",
            component: () =>
              import(
                /* webpackChunkName: "case-application-security-property" */ "@/components/ux/clients/cases/CaseApplicationSecurityProperty.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Application / Property"
            }
          },
          {
            path: "product-details",
            name: "case-application-product-details",
            component: () =>
              import(
                /* webpackChunkName: "case-application-product-details" */ "@/components/ux/clients/cases/CaseApplicationProductDetails.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Application / Product Details"
            }
          },
          {
            path: "application-protection-product",
            beforeEnter: chainGuards([
              storeGuards.selectRequirementForApplicationGuard,
              storeGuards.viewProtectionProductGuard
            ]),
            name: "case-application-protection-product-details",
            component: () =>
              import(
                /* webpackChunkName: "case-application-protection-product-details" */ "@/components/ux/clients/cases/requirements/protection/Research/AddEditProduct.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Application / Product Details"
            },
            props: {
              applicationRoute: true
            }
          },
          {
            path: "application-gi-product",
            beforeEnter: chainGuards([
              storeGuards.selectRequirementForApplicationGuard,
              storeGuards.viewGIProductGuard
            ]),
            name: "case-application-gi-product-details",
            component: () =>
              import(
                /* webpackChunkName: "case-application-gi-product-details" */ "@/components/ux/clients/cases/requirements/generalinsurance/research/AddEditGiProduct.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Application / Product Details"
            },
            props: {
              applicationRoute: true
            }
          }
        ]
      },
      {
        path: "add/:appId",
        beforeEnter: chainGuards([
          storeGuards.editApplicationGuard,
          storeGuards.loadTasksGuard
        ]),
        name: "case-application-add",
        component: () =>
          import(
            /* webpackChunkName: "case-application-add" */ "@/components/ux/clients/cases/CaseApplicationEdit.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Applications / Add"
        }
      }
    ]
  }
]
