export function getAnnualisedEquivalent(amount, frequency) {
  if (!amount) return 0
  switch (frequency) {
    case "yearly":
      return amount
    case "half_yearly":
      return amount * 2
    case "quarterly":
      return amount * 4
    case "monthly":
      return amount * 12
    case "fortnightly":
      return amount * 26
    case "weekly":
      return amount * 52
    default:
      return amount
  }
}
