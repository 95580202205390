<template>
  <div>
    <page-loading-bar-vue />
    <wait-spinner-overlay></wait-spinner-overlay>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import PageLoadingBarVue from "./components/core/PageLoadingBar.vue"
import WaitSpinnerOverlay from "./components/core/WaitSpinnerOverlay.vue"

export default {
  components: {
    PageLoadingBarVue,
    WaitSpinnerOverlay
  },
  metaInfo: {
    title: "crm",
    titleTemplate: "%s"
  }
}
</script>
