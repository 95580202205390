import * as types from "../mutations/dictionary"
import { getApolloInstance } from "@/plugins/components-plugin/plugins/apollo-plugin"
import DictionaryItems from "../../graphql/DictionaryItems.gql"

export const namespaced = true

export const state = {
  loading: false,
  error: null,
  fields: []
}

export const getters = {
  isLoading: (state) => state.loading,
  error: (state) => state.error,
  fields: (state) => state.fields,
  //fieldsByCollection: (state) => {
  //  return state.fields.reduce((r, field) => {
  //    r[field.collection_name] = r[field.collection_name] || []
  //    r[field.collection_name].push(field)
  //    return r
  //  }, {})
  //},
  //fieldsByCollectionName: (state) => (collection) =>
  //  state.fields.filter((field) => field.collection_name === collection),
  fieldByFormID: (state) => (id) =>
    state.fields.find((field) => field.form_id == id),
  fieldByFormName: (state) => (name) =>
    state.fields.find((field) => field.frontend_name == name),
  displayTextByFormIdAndValue: (state) => (id, value) => {
    if (value == null) {
      return ""
    }
    let result = ""
    const option = state.fields.find((field) => field.form_id === id)

    if (option !== undefined)
      result = option.select_values.find((opt) => {
        return opt.value === value
      })?.text
    if (result === undefined && option !== undefined) {
      result = option.radio_group_values.find((opt) => {
        return opt.value === value.toString()
      })?.text
    }
    return result === "undefined" ? "" : result
  },
  getValueByFormIdAndDisplayText: (state) => (id, text) => {
    let result = null
    const option = state.fields.find((field) => field.form_id === id)

    if (option !== undefined)
      result = option.select_values.find((opt) => {
        return opt.text === text
      })?.value

    if (result === undefined && option !== undefined) {
      result = option.radio_group_values.find((opt) => {
        return opt.text === text
      })?.value
    }

    return result === "undefined" ? null : result
  }
}

export const mutations = {
  [types.SET_FORM_FIELDS_LOADING](state, loading) {
    state.loading = loading
    state.error = null
  },
  [types.SET_FORM_FIELDS_ERROR](state, error) {
    state.error = error
    state.loading = false
  },
  [types.UNSET_FORM_FIELDS_ERROR](state) {
    state.error = null
  },
  [types.SET_FORM_FIELDS](state, fields) {
    state.loading = false
    state.error = null

    fields?.forEach((item) => {
      if (!item.input_config) {
        console.error(
          `null input_config: ${item.version} > ${item.label} > ${item.form_id} `
        )
        item.input_config = {}
      }

      if (!item.input_config.maxlength) {
        if (item.input_type === "text") {
          item.input_config.maxlength = 100
        } else if (item.input_type === "textarea") {
          item.input_config.maxlength = 500
        }
      }
    })
    state.fields = fields
  }
}

export const actions = {
  async refresh({ commit }) {
    commit(types.UNSET_FORM_FIELDS_ERROR)
    commit(types.SET_FORM_FIELDS_LOADING, true)
    try {
      if (process.env.VUE_APP_DATA_DICTIONARY_PROTOCOL == "graphql") {
        const graphql = getApolloInstance()
        const response = await graphql.query({
          query: DictionaryItems,
          variables: {
            limit: 3000
          }
        })
        commit(types.SET_FORM_FIELDS, response.data.dictionary_items)
      } else {
        const result = await fetch(process.env.VUE_APP_DATA_DICTIONARY_URL)
        const fields = await result.json()
        commit(types.SET_FORM_FIELDS, fields)
      }
    } catch (error) {
      commit(types.SET_FORM_FIELDS_ERROR, error)
    }
  }
}

//POC code, removing for now
// function autoAssignValidationRule(item) {
//   if (!item.validation) {
//     item.validation = {
//       rules: ""
//     }
//   }
//   //rules is a string which represents '|' separated string array
//   let rulesArray = item.validation.rules.split("|")
//   let autoAssignMaxLength = rulesArray.find(e => e && e.startsWith("max:"));//max:N -> max length rule

//   if (autoAssignMaxLength) {
//     let maxLength = item.validation.maxlength ?? item.input_config.maxlength
//     if (maxLength) {
//       if (item.validation.rules) item.validation.rules += "|"
//       item.validation.rules += "max:" + maxLength
//     }
//   }
// }
