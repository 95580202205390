import axios from "axios"
export const state = {
  selectedLender: null,
  showSubmissionsLenderRightbar: false,
  allLenders: _getLenderConfigs(),
  lenderRefData: {},
  displayLenderQuestions: false
}

export const mutations = {
  setSelectedLender(state, lender) {
    state.selectedLender = lender
  },
  setShowSubmissionsLenderRightbar(state, value) {
    state.showSubmissionsLenderRightbar = value
  },
  setLenderAuth(state, allLendersAuth) {
    state.allLenders.forEach((lender) => {
      const match = allLendersAuth.find((e) => e.name === lender.name)
      // lender must have their credentials filled out
      if (match && match.credentials) {
        lender.auth = true
      } else if (match && !match.credentials) {
        lender.auth = false
      } else {
        console.warn(`${lender.name} not found`)
      }
    })
  },
  setLenderRefData(state, value) {
    state.lenderRefData = value
  },
  setDisplayLenderQuestions(state, value) {
    state.displayLenderQuestions = value
  }
}

const _getLenderRefData = async (lenderName, referenceData) => {
  return axios
    .post(`${process.env.VUE_APP_CDS_URL}/caserequirements/dip/referencedata`, {
      auth_meta: {},
      lender: lenderName,
      application_meta: {},
      reference_data: referenceData
    })
    .then(async (e) => {
      await _handleCdsDfaError(e)
      return Promise.resolve(e.data)
    })
    .catch((e) => {
      return Promise.reject(e)
    })
}

export const actions = {
  async showLenderPopup(context, lender) {
    context.commit("setSelectedLender", lender)
    context.commit("setShowSubmissionsLenderRightbar", true)
    context.commit("setDisplayLenderQuestions", false)
    document.body.classList.toggle("right-bar-enabled")

    return context
      .dispatch("getCurrentLenderRefData")
      .then((data) => {
        let dataAsObj = {}
        data.forEach((item) => {
          dataAsObj[item.referenceData] = { ...item }
        })
        context.commit("setLenderRefData", dataAsObj)
        context.commit("setDisplayLenderQuestions", true) //only set to true if success
        return Promise.resolve()
      })
      .catch(() => Promise.reject())
  },
  async getCurrentLenderRefData(context) {
    var ok = true
    var lookups = []
    try {
      const refDataLookup = {
        halifax: [
          {
            guid: "q-a5a06215-af4d-42af-b029-11560d5020b9",
            referenceData: "payee_number",
            selectOptions: []
          },
          {
            guid: "q-d393946c-b47a-4004-a40b-7a9ed0ca48bf",
            referenceData: "shared_ownership",
            selectOptions: []
          }
        ],
        nationwide: [
          {
            guid: "q-a5a06215-af4d-42af-b029-11560d5020b9",
            referenceData: "lending_centres",
            selectOptions: []
          }
        ]
      }

      let currentLender = context.state.selectedLender
      lookups = refDataLookup[currentLender.lenderKey] ?? []
      if (lookups.length) {
        let promises = []
        for (let i = 0; i < lookups.length; i++) {
          promises.push(
            _getLenderRefData(
              currentLender.lenderKey,
              lookups[i].referenceData
            ).then((selectOptions) => {
              lookups[i].selectOptions = selectOptions
            })
          )
        }
        await Promise.all(promises).catch(() => (ok = false))
      }
    } catch (e) {
      ok = false
    }

    if (ok) return Promise.resolve(lookups)
    await window.confirmAsync("Sorry, we are unable to load the lender form.")
    return Promise.reject()
  },
  async setAllLenderAuth(context) {
    return await axios
      .get(
        process.env.VUE_APP_ADMIN3_SERVICE_URL +
          "/api/v1/user/integrations/submissions"
      )
      .then((e) => {
        let integrations = e?.data?.data
        if (integrations) {
          context.commit("setLenderAuth", integrations)
          return Promise.resolve()
        }
        return Promise.reject("failed to get lender auth data")
      })
      .catch(() => {
        return Promise.reject("failed to get lender auth data")
      })
  },
  async submitDIP({ commit }, dipInfo) {
    try {
      if (
        !dipInfo.requirementId ||
        !dipInfo.lender ||
        !dipInfo.lender.lenderKey ||
        !dipInfo.answers ||
        !dipInfo.lender.lenderId
      )
        throw "argument null exception"
      return await axios
        //just halifax for now
        .post(`${process.env.VUE_APP_CDS_URL}/caserequirements/dip`, {
          lender: dipInfo.lender.lenderKey,
          lender_id: dipInfo.lender.lenderId,
          requirement_id: dipInfo.requirementId,
          application_meta: {
            halifax: {
              custom_application: {
                custom_halifax_shared_ownership_company_key:
                  dipInfo.answers.custom_halifax_shared_ownership_company_key
              }
            },
            nationwide: {}
          },
          auth_meta: {
            halifax_payment_centre: dipInfo.answers.halifax_payment_centre,
            nationwide_lending_centre_code:
              dipInfo.answers.nationwide_lending_centre_code,
            fakebank_lending_centre_code: null,
            is_shared_ownership: Boolean(dipInfo.answers.is_shared_ownership)
          }
        })
        .then((response) => {
          let url = response.data.url
          console.info("opening url", url)
          window.open(url, "_blank")

          commit(
            "requirements/setRequirement",
            response.data.case_requirement,
            { root: true }
          )

          window.confirmAsync(
            "We have created a DIP record for you. Once you have submitted your DIP, please wait up to 30 seconds and reload this page."
          )

          return Promise.resolve(url)
        })
        .catch(async (e) => {
          await _handleCdsDfaError(e)
          return Promise.reject(e)
        })
    } catch (err) {
      console.error(err)

      return Promise.reject()
    }
  }
}

export const getters = {
  selectedLender(state) {
    return state.selectedLender
  },
  showSubmissionsLenderRightbar(state) {
    return state.showSubmissionsLenderRightbar
  },
  allLenders(state) {
    return state.allLenders
  },
  lenderRefData(state) {
    return state.lenderRefData
  },
  displayLenderQuestions(state) {
    return state.displayLenderQuestions
  }
}
async function _handleCdsDfaError(err) {
  let status = err.response?.status
  switch (status) {
    case 400:
      return await _handleValidationErrors(err.response.data)
  }
}
async function _handleValidationErrors(err) {
  if (process.env.VUE_APP_ENV === "development") {
    await window.confirmAsync(JSON.stringify(err, null, 2))
  }
  //TODO process errors and show them to user in a clean way
  //for now, only show error if development
}

function _getLenderConfigs() {
  //TODO logo to be loaded in future by call to mb lender api
  const result = [
    // {
    //   name: "Accord",
    //   lenderKey: "accord",
    //   lenderId: "accord",
    //   logo: "https://res.cloudinary.com/descxs58m/image/upload/v1686652737/lenders/accord.png",
    //   disabled: true,
    //   auth: null
    // },
    {
      name: "Halifax",
      lenderKey: "halifax",
      lenderId: "halifax",
      logo: "https://res.cloudinary.com/descxs58m/image/upload/v1701848659/lenders/halifax_logo.png",
      disabled: true,
      auth: null
    },
    // {
    //   name: "Leeds",
    //   lenderKey: "leeds",
    //   lenderId: "leeds_bs",
    //   logo: "https://res.cloudinary.com/descxs58m/image/upload/v1686652731/lenders/leeds-building-society-for-intermediaries.png",
    //   disabled: true,
    //   auth: null
    // },
    {
      name: "Nationwide",
      lenderKey: "nationwide",
      lenderId: "nationwide_bs",
      logo: "https://res.cloudinary.com/descxs58m/image/upload/v1701848746/lenders/nationwide_logo.png",
      disabled: true,
      auth: null
    },
    {
      name: "Santander",
      lenderKey: "santanderv2",
      lenderId: "santander",
      logo: "https://res.cloudinary.com/descxs58m/image/upload/v1686652734/lenders/santander-for-intermediairies-lender_logo.png",
      disabled: true,
      auth: null
    }
    // {
    //   name: "TSB",
    //   lenderKey: "tsb",
    //   lenderId: "tsb",
    //   logo: "https://res.cloudinary.com/descxs58m/image/upload/v1688139569/lenders/tsb.png",
    //   disabled: true,
    //   auth: null
    // }
  ]

  const enabledLenders =
    process.env.VUE_APP_SUB_BRAIN_LENDERS_ENABLED.split(",")

  result.forEach((lender) => {
    if (process.env.VUE_APP_ENV === "development") {
      lender.disabled = false
    } else {
      lender.disabled = !enabledLenders.includes(lender.lenderKey)
    }
  })

  return result
}
