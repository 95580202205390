const caseCheckingConfig = {
  search_fields: [
    { field_name: "advisors.firstname" },
    { field_name: "advisors.surname" },
    { field_name: "firm_name" },
    { field_name: "case_ref" },
    { field_name: "status" }
  ],
  fields: [
    {
      key: "firm_name",
      label: "AR Firm",
      sortable: true
    },
    {
      key: "case_ref",
      label: "Case Ref",
      sortable: true
    },
    {
      key: "advisors",
      label: "Case Advisors",
      sortable: true,
      customSortField: "advisors.firstname",
      secondarySortField: "advisors.surname"
    },
    {
      key: "status",
      sortable: true
    },
    {
      key: "view",
      label: ""
    }
  ]
}

export { caseCheckingConfig }
