import axios from "axios"

export const state = {
  previousCaseId: "",
  caseIdValid: false,
  casesList: [],
  currentCaseMockData: {
    caseInfo: {
      ref: "",
      date: "",
      owners: [],
      advisors: [],
      admins: [],
      introducers: [],
      leadSource: "",
      initialCaseNotes: ""
    },
    caseChecklist: {
      issueDisclosureDocument: false,
      idv: false
    }
  },
  currentCase: {},
  currentCaseId: "",
  showAddCaseDocuments: false,
  showGenerateDocuments: false,
  ffSectionAdminAction: "",
  caseOverview: {},
  currentImportedSale: {},
  importedSales: [],
  names: [],
  administrators: [],
  principals: [],
  allTeamMembers: [],
  showCreateApplication: false,
  showCreateApplicationGi: false,
  showCreateProtectionApplication: false,
  selectedSnapshotId: null,
  snapshots: [],
  complianceUsers: [],
  showAssignComplianceOfficer: false
}

export const mutations = {
  addCase(state, item) {
    state.casesList.push(item)
  },
  removeCases(state) {
    state.casesList = []
  },
  setPreviousCaseId(state, id) {
    state.previousCaseId = id
  },
  setCaseIdValidState(state, passState) {
    state.caseIdValid = passState
    state.casesList = []
  },
  setCurrentCase(state, item) {
    state.currentCase = item
  },
  setCurrentCaseMockData(state, item) {
    state.currentCaseMockData = item
  },
  setCurrentCaseId(state, id) {
    state.currentCaseId = id
  },
  setShowAddCaseDocuments(state, value) {
    state.showAddCaseDocuments = value
  },
  setShowGenerateDocuments(state, value) {
    state.showGenerateDocuments = value
  },
  setShowAssignComplianceOfficer(state, value) {
    state.showAssignComplianceOfficer = value
  },
  setSectionAdminAction(state, data) {
    state.ffSectionAdminAction = data
  },
  setCurrentCaseOverview(state, value) {
    state.caseOverview = value
  },
  setImportedSales(state, data) {
    state.importedSales = data
  },
  setCurrentImportedSale(state, data) {
    state.currentImportedSale = data
  },
  setAllNames(state, data) {
    state.names = data
  },
  setAdministrators(state, data) {
    state.administrators = data
  },
  setPrincipals(state, data) {
    state.principals = data
  },
  setComplianceUsers(state, data) {
    state.complianceUsers = data
  },
  clearCaseData(state) {
    state.administrators = []
  },
  clearPrincipals(state) {
    state.principals = []
  },
  clearComplianceUsers(state) {
    state.complianceUsers = []
  },
  setAllTeamMembers(state, data) {
    state.allTeamMembers = data
  },
  setShowCreateApplicationMortgage(state, value) {
    state.showCreateApplication = value
  },
  setShowCreateApplicationGi(state, value) {
    state.showCreateApplicationGi = value
  },
  setShowCreateProtectionApplication(state, value) {
    state.showCreateProtectionApplication = value
  },
  setSnapshotId(state, value) {
    state.selectedSnapshotId = value
  },
  clearSelectedSnapshotId(state) {
    state.selectedSnapshotId = null
  },
  setSnapshots(state, value) {
    state.snapshots = value
  }
}

export const getters = {
  cases: (state) => state.casesList,
  previousCaseId: (state) => state.previousCaseId,
  caseIdValid: (state) => state.caseIdValid,
  currentCaseMockData: (state) => state.currentCaseMockData,
  currentCase: (state) => state.currentCase,
  currentCaseId: (state) => state.currentCaseId,
  showAddCaseDocuments: (state) => state.showAddCaseDocuments,
  showGenerateDocuments: (state) => state.showGenerateDocuments,
  showAssignComplianceOfficer: (state) => state.showAssignComplianceOfficer,
  getCaseRef: (_, getters) => (id) =>
    getters.cases.find((c) => c.id === id)?.mb_case_reference,
  ffSectionAdminAction: (state) => state.ffSectionAdminAction,
  caseOverview: (state) => state.caseOverview,
  getImportedSales: (state) => state.importedSales,
  getCurrentImportedSale: (state) => state.currentImportedSale,
  getNames: (state) => state.names,
  getAdministrators: (state) => state.administrators,
  getPrincipals: (state) => state.principals,
  getcomplianceUsers: (state) => state.complianceUsers,
  getAllTeamMembers: (state) => state.allTeamMembers,
  showCreateApplicationMortgage: (state) => state.showCreateApplication,
  showCreateApplicationGi: (state) => state.showCreateApplicationGi,
  showCreateProtectionApplication: (state) =>
    state.showCreateProtectionApplication,
  selectedSnapshotId: (state) => state.selectedSnapshotId,
  snapshots: (state) => state.snapshots,
  snapshotIdQuery: (state) =>
    state.selectedSnapshotId ? `?snapshot_id=${state.selectedSnapshotId}` : "",
  selectedSnapshot: () =>
    state.snapshots?.find((s) => s.id === state.selectedSnapshotId),
  currentCaseAdvisorSelectOptions: (state, getters, rootState, rootGetters) =>
    rootGetters["advisors/advisors"].filter((a) =>
      state.currentCase.linked_advisor_ids.includes(a.value)
    ),
  currentCaseLinkedClientSelectOptions: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    return rootGetters["clientFile/clients"].reduce((options, client) => {
      if (state.currentCase.linked_clients.includes(client.id)) {
        options.push({ text: client.fullname, value: client.id })
      }
      return options
    }, [])
  }
}

export const actions = {
  async createCase({ commit }, info) {
    return await axios.post(`${process.env.VUE_APP_CDS_URL}/cases`, info).then(
      (response) => {
        let newCaseId = response.data.id
        if (newCaseId) {
          commit("setCurrentCaseId", newCaseId)
        } else {
          commit("setCurrentCaseId", "")
        }
      },
      (error) => {
        return Promise.reject(error.response.data)
      }
    )
  },

  async updateAdministrator({ commit }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/cases/reassignAdvisor`,
        payload
      )

      if (response.status === 200) {
        console.log(response.data)
        commit("setCurrentCase", response.data)
        return true
      }
    } catch (err) {
      console.log(err)
    }
    return false
  },
  async updateAdvisor({ commit }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/cases/reassignAdvisor`,
        payload
      )

      if (response.status === 200) {
        console.log(response.data)
        commit("setCurrentCase", response.data)
        return true
      }
    } catch (err) {
      console.log(err)
    }
    return false
  },

  async updateCase({ commit }, info) {
    try {
      const response = await axios.put(
        `${process.env.VUE_APP_CDS_URL}/cases`,
        info
      )

      if (response.status === 200) {
        commit("setCurrentCase", response.data)
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
    return false
  },

  async loadCases({ commit, rootGetters }) {
    const clientId = rootGetters["clientFile/loadedClientId"]
    await axios
      .post(`${process.env.VUE_APP_CDS_URL}/cases/getforclientids`, [clientId])
      .then(
        (response) => {
          commit("removeCases")

          if (response.data.length < 1) {
            return
          }

          // add cases with associated client names attached
          axios
            .get(
              `${process.env.VUE_APP_CDS_URL}/clients/${clientId}/getassociated`
            )
            .then((responseClients) => {
              const associatedClients = responseClients.data.map((client) => ({
                id: client.id,
                name: `${client.first_name} ${client.last_name}`
              }))

              response.data.forEach((caseObject) => {
                commit(
                  "addCase",
                  Object.assign(caseObject, {
                    clients: caseObject.linked_clients.map((id) => {
                      return associatedClients.find(
                        (client) => id === client.id
                      )
                    })
                  })
                )
              })
            })
            .catch((err) => {
              throw err
            })
        },
        (error) => {
          commit("removeCases")
          throw error
        }
      )
  },
  async getCase({ commit, getters }, id) {
    const snapshotIdQuery = getters["snapshotIdQuery"]
    return await axios
      .get(`${process.env.VUE_APP_CDS_URL}/cases/${id}${snapshotIdQuery}`)
      .then(
        (response) => {
          let data = {
            caseInfo: {
              ref: response.data.mb_case_reference,
              date: "Friday, 15th October 2021",
              owners: ["Jason Hegarty", "Victoria Rance"],
              advisors: ["Lyle Houghton"],
              admins: ["Lyle Houghton"],
              introducers: [],
              leadSource: "Existing Client",
              initialCaseNotes:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin vel purus ac porttitor. Quisque eu diam erat. Curabitur varius vel ex in maximus."
            },
            caseChecklist: {
              issueDisclosureDocument: true,
              idv: false
            }
          }
          commit("setCurrentCaseMockData", data)
          commit("setCurrentCase", response.data)
        },
        (error) => console.log(error)
      )
  },
  async checkCaseId({ commit }, data) {
    try {
      commit("setPreviousCaseId", data.id)
      commit("setCaseIdValidState", true)
    } catch (error) {
      console.error(error)
    }
  },
  commitPreviousCaseId({ commit }, value) {
    commit("setPreviousCaseId", value)
  },
  async getCaseOverview({ commit, getters }, id) {
    const snapshotIdQuery = getters["snapshotIdQuery"]
    return await axios
      .get(
        `${process.env.VUE_APP_CDS_URL}/cases/${id}/overview${snapshotIdQuery}`
      )
      .then((response) => {
        commit("setCurrentCaseOverview", response.data)
      })
  },

  async loadKeyImportedSales({ commit }, ids) {
    return await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/keyimportcases/getforclientids`,
        ids
      )
      .then((response) => {
        commit("setImportedSales", response.data)
      })
  },
  async getKeyImportedSale({ commit }, id) {
    return await axios
      .get(`${process.env.VUE_APP_CDS_URL}/keyimportcases/${id}`)
      .then((response) => {
        commit("setCurrentImportedSale", response.data)
      })
  },
  async getNames({ commit }, ids) {
    axios
      .post(`${process.env.VUE_APP_CDS_URL}/clients/getnames`, ids)
      .then((response) => {
        commit("setAllNames", response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  async loadComplianceUsers({ commit }) {
    commit("clearComplianceUsers")
    return await axios
      .get(
        `${process.env.VUE_APP_ADMIN3_SERVICE_URL}/api/v1/account/users/gate/is_compliance`
      )
      .then((response) => {
        if (response.status === 200) {
          commit("setComplianceUsers", response.data.data)
        }
      })
      .catch((error) => console.error(error))
  },
  async loadPrincipals({ commit }) {
    commit("clearPrincipals")
    return await axios
      .get(
        `${process.env.VUE_APP_ADMIN3_SERVICE_URL}/api/v1/account/users/gate/is_principal`
      )
      .then((response) => {
        if (response.status === 200) {
          commit("setPrincipals", response.data.data)
        }
      })
      .catch((error) => console.error(error))
  },
  async loadAdmins({ commit }) {
    commit("clearCaseData")
    return await axios
      .get(
        `${process.env.VUE_APP_ADMIN3_SERVICE_URL}/api/v1/account/users/gate/can_administer_cases`
      )
      .then((response) => {
        if (response.status === 200) {
          commit("setAdministrators", response.data.data)
        }
      })
      .catch((error) => console.error(error))
  },
  loadAllTeamMembers({ commit, rootGetters }) {
    commit("setAllTeamMembers", [])
    const allAdvisors = rootGetters["advisors/advisors"]
    const allAdmins = rootGetters["cases/getAdministrators"]
    const allPrincipals = rootGetters["cases/getPrincipals"]

    let admins = allAdmins.map((obj) => {
      return {
        text: `${obj.first_name} ${obj.last_name}`,
        value: obj._id
      }
    })
    let principals = allPrincipals.map((obj) => {
      return {
        text: `${obj.first_name} ${obj.last_name}`,
        value: obj._id
      }
    })

    let ids = new Set(allAdvisors.map((d) => d.value))

    let mergedArray = [
      ...allAdvisors,
      ...admins.filter((d) => !ids.has(d.value))
    ]
    let mergedArrayWithPrincipals = []

    if (principals?.length > 0) {
      let mergedArrayIds = new Set(mergedArray.map((d) => d.value))
      mergedArrayWithPrincipals = mergedArray.concat(
        ...principals.filter((d) => !mergedArrayIds.has(d.value))
      )
    } else {
      mergedArrayWithPrincipals = mergedArray
    }

    commit("setAllTeamMembers", mergedArrayWithPrincipals)
  },
  async loadSnapshots({ commit }, caseId) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/cases/${caseId}/snapshots`
      )
      commit("setSnapshots", data)
    } catch (e) {
      console.error(e)
    }
  },
  async getComplianceCheck({ getters }) {
    try {
      let caseId = getters["currentCaseId"]
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/case/${caseId}/high-risk`
      )
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  },
  async getHighRiskData({ getters }) {
    try {
      let caseId = getters["currentCaseId"]
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/case/${caseId}/high-risk/get`
      )
      if (response.status === 200) {
        return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
}
