<template>
  <button :class="buttonClass" :disabled="disabled" @click="click">
    <div class="d-flex gap-1">
      <div style="width: 1.5em" class="loading-container">
        <div class="loading-container-content">
          <span v-show="loading" class="spinner-border spinner-border-sm" />
        </div>
      </div>

      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    buttonClass: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      required: true
    },
    allowClickOnLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["click"],
  methods: {
    click() {
      if (this.allowClickOnLoading || !this.loading) {
        this.$emit("click")
      }
    }
  }
}
</script>

<style scoped>
.loading-container-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading-container {
  position: relative;
}
</style>
