import {
  chainGuards,
  storeGuards
} from "@/global/modules/middleware/checkStore"

export const giRequirementRoutes = [
  {
    path: "add/gi-requirement/:requirementId",
    name: "client-case-requirement-gi",
    beforeEnter: chainGuards([
      storeGuards.selectedRequirementGuard,
      storeGuards.loadCaseTasksGuard,
      storeGuards.loadRequirementDocumentsGuard,
      storeGuards.loadNotesGuard,
      storeGuards.loadPropertiesGuard,
      storeGuards.loadGIProducts
    ]),
    component: () =>
      import(
        /* webpackChunkName: "client-case-requirements-gi" */ "@/components/ux/clients/cases/requirements/generalinsurance/GiOverview.vue"
      ),
    children: [
      {
        path: "add/needs-preferences",
        beforeEnter: storeGuards.loadRequirementsGuard,
        name: "client-case-requirement-gi-needs-preferences",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-gi-needs-preferences" */ "@/components/ux/clients/cases/requirements/generalinsurance/NeedsPreferences.vue"
          ),
        meta: {
          title: "Needs Preferences"
        }
      },
      {
        path: "edit/needs-preferences",
        beforeEnter: storeGuards.editRequirementsBuildingsContentGuard,
        name: "client-case-requirement-gi-edit-needs-preferences",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-gi-edit-needs-preferences" */ "@/components/ux/clients/cases/requirements/generalinsurance/NeedsPreferences.vue"
          ),
        meta: {
          title: "Needs Preferences"
        }
      },
      {
        path: "property",
        beforeEnter: storeGuards.loadPropertiesGuard,
        name: "client-case-requirement-gi-property",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-gi-property" */ "@/components/ux/clients/cases/requirements/generalinsurance/Property.vue"
          ),
        meta: {
          title: "Property"
        }
      },
      {
        path: "research",
        name: "client-case-requirement-gi-research",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-gi-research" */ "@/components/ux/clients/cases/requirements/generalinsurance/research/Research.vue"
          ),
        children: [
          {
            path: "",
            beforeEnter: storeGuards.loadProductsGuard,
            name: "client-case-requirement-gi-research-product-list",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-gi-research-product-list" */ "@/components/ux/clients/cases/requirements/generalinsurance/research/ResearchList.vue"
              )
          },
          {
            path: "addproduct",
            beforeEnter: storeGuards.addGIProductGuard,
            name: "client-case-requirement-gi-research-product-create",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-gi-research-product-create" */ "@/components/ux/clients/cases/requirements/generalinsurance/research/AddEditGiProduct.vue"
              ),
            meta: {
              excludeFromSnapshot: true
            }
          },
          {
            path: ":productId/details",
            beforeEnter: storeGuards.editGIProductGuard,
            name: "client-case-requirement-gi-research-product-details",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-gi-research-product-details" */ "@/components/ux/clients/cases/requirements/generalinsurance/research/AddEditGiProduct.vue"
              )
          }
        ],
        meta: {
          //requiresAuthCase: true,
          caseNavActive: true,
          title: "Research"
        }
      },
      {
        path: "recommendation",
        name: "client-case-requirement-gi-recommend",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-gi-recommend" */ "@/components/ux/clients/cases/requirements/generalinsurance/recommendation/GiRecommend.vue"
          ),
        children: [
          {
            path: "",
            beforeEnter: storeGuards.loadWorkflowConfigurationsGuard,
            name: "client-case-requirement-gi-recommend-apply",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-gi-recommend-apply" */ "@/components/ux/clients/cases/requirements/generalinsurance/recommendation/GiRecommendApplyList.vue"
              ),
            meta: {
              title: "Recommend & Apply"
            }
          },
          {
            path: "insurance-generate-document",
            name: "client-case-requirement-insurance-generate-document",
            beforeEnter: storeGuards.selectedInsuranceLoadTemplatesGuard,
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-insurance-generate-document" */ "@/components/ux/shared/documents/LinkedDocument.vue"
              ),
            meta: {
              title: "Create Sutability Letter"
            }
          }
        ]
      },
      {
        path: "notes",
        name: "client-case-requirement-gi-notes",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-gi-notes" */ "@/components/ux/clients/cases/requirements/generalinsurance/Notes.vue"
          ),
        meta: {
          title: "Notes"
        }
      },
      {
        path: "tasks",
        name: "client-case-requirement-gi-tasks",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-gi-tasks" */ "@/components/ux/clients/cases/requirements/generalinsurance/Tasks.vue"
          ),
        meta: {
          title: "Tasks"
        }
      },
      {
        path: "documents",
        name: "client-case-requirement-gi-documents",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-gi-documents" */ "@/components/ux/clients/cases/requirements/generalinsurance/Documents.vue"
          ),
        meta: {
          title: "Documents"
        }
      }
    ],
    meta: {
      //requiresAuthCase: true,
      caseNavActive: true,
      title: "GI Requirement"
    }
  }
]
