import * as axios from "axios"

const getClient = async function (id) {
  const response = await axios.get(
    `${process.env.VUE_APP_CDS_URL}/clients/${id}`
  )
  let client = parseItem(response, 200)
  return client
}

// const parseList = (response) => {
//   if (response.status !== 200) throw Error(response.message)
//   if (!response.data) return []
//   let list = response.data
//   if (typeof list !== "object") {
//     list = []
//   }
//   return list
// }

export const parseItem = (response, code) => {
  if (response.status !== code) throw Error(response.message)
  let item = response.data
  if (typeof item !== "object") {
    item = undefined
  }
  return item
}

export const clientDataService = {
  getClient
}
