<template>
  <div class="page-heading">
    <h1 v-if="type === 1">{{ title }}</h1>
    <h2 v-else-if="type === 2">{{ title }}</h2>
    <h3 v-else-if="type === 3">{{ title }}</h3>
    <h4 v-else-if="type === 4">{{ title }}</h4>
    <h5 v-else-if="type === 5">{{ title }}</h5>
    <h6 v-else-if="type === 6">{{ title }}</h6>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: [String, Number],
      default: 1
    },
    title: {
      type: [String, Number],
      default: "Heading will be updated soon..."
    }
  }
}
</script>
