import axios from "axios"
import moment from "moment"
import { MbAddressMixin } from "@/plugins/components-plugin/entry.js"
import { functionService } from "@/global/modules/helpers"
import { format } from "date-fns"
import { dbDateFormat } from "@/global/modules/enum/constants"
import { formatClientNamesForList } from "@/global/modules/helpers/clientHelpers"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"
import { displayProviderNameByIdAndType } from "@/global/modules/helpers/providerHelpers.js"
export const state = {
  clients: [],
  selectedClients: [],
  selectedCompanies: [],
  selectedType: "",
  selectedStatus: "",
  allMortgages: [],
  mortgagesForList: [],
  selectedMortgage: {},
  isNew: true,
  errorForDisplay: "",
  excludedClientsFromSelectionList: []
}

export const mutations = {
  clearMortgages(state) {
    state.allMortgages = []
    state.mortgagesForList = []
  },
  resetSelectedClientTypeAndStatus(state) {
    state.isNew = true
    state.selectedClients = {}
    state.selectedType = ""
    state.selectedStatus = ""
  },
  addSelectedClientTypeAndStatus(state, data) {
    state.isNew = true
    state.selectedClients = data.clients
    state.selectedCompanies = data.companies
    state.selectedType = data.type
    state.selectedStatus = data.status
  },
  allMortgages(state, data) {
    state.allMortgages.push(data)
  },
  addMortgagesForList(state, data) {
    state.mortgagesForList.push(data)
  },
  setSelectedMortgageInfo(state, data) {
    state.isNew = false
    state.selectedMortgage = data
  },
  addErrorForDisplay(state, error) {
    state.errorForDisplay = error
  },
  clearErrorForDisplay(state) {
    state.errorForDisplay = ""
  },
  setExcludedClientsFromSelectionList(state, data) {
    state.excludedClientsFromSelectionList = data
  }
}

export const actions = {
  storeSelectedClientTypeAndStatus(context, payload) {
    context.commit("addSelectedClientTypeAndStatus", payload)
  },
  async loadMortgages(context) {
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    const clientIds = context.rootGetters["clientFile/selectedClients"].map(
      (client) => client.id
    )

    return await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientmortgages/getforclientids${snapshotIdQuery}`,
        clientIds
      )
      .then((response) => {
        context.commit("clearMortgages")

        if (response.status === 200) {
          response.data.forEach((mortgageData) => {
            mapMortgageForList(
              mortgageData,
              context.rootGetters["clientFile/clients"],
              context
            )
          })
        }
      })
      .catch((err) => {
        context.commit("clearMortgages")
        console.log(err)
      })
  },
  async deleteMortgage({ dispatch, state }, payload) {
    let mainMortgage = {}

    // search for the mortgage record by id
    mainMortgage = state.allMortgages.find((c) => {
      return c.id === payload.id
    })

    mainMortgage.is_deleted = true
    mainMortgage.deleted_at = new Date()

    //update the record
    await dispatch("addUpdateMortgage", mainMortgage)
  },
  async addUpdateMortgage({ dispatch, commit, getters }, payload) {
    var id = null

    try {
      commit("clearErrorForDisplay")
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientmortgages/createorupdate`,
        [payload]
      )
      if (response.status === 200) {
        if (response.data?.length > 0) {
          id = response.data[0].id
        }

        await dispatch("loadMortgages")

        var foundMortgage = getters["allMortgages"].find((e) => e.id === id)

        commit("setSelectedMortgageInfo", foundMortgage)
      } else {
        commit("addErrorForDisplay", JSON.stringify(response.message))
      }
    } catch (err) {
      let errMsg = "Error during mortgage save. Reason: " + err.message
      commit("addErrorForDisplay", errMsg)
    }

    return id
  },
  createMortgageForAdd(
    context,
    { type, status, linkedClients, linkedCompanies }
  ) {
    return createMortgage(type, status, linkedClients, linkedCompanies)
  }
}

export const getters = {
  isAddMode(state) {
    return state.isNew
  },
  selectedClient(state) {
    return state.selectedClient
  },
  selectedClients(state) {
    return state.selectedClients
  },
  selectedCompanies(state) {
    return state.selectedCompanies
  },
  selectedStatus(state) {
    return state.selectedStatus
  },
  selectedType(state) {
    return state.selectedType
  },
  mortgageList(state) {
    return state.mortgagesForList
  },
  allMortgages(state) {
    return state.allMortgages
  },
  selectedMortgage(state) {
    return state.selectedMortgage
  },
  errorForDisplay(state) {
    return state.errorForDisplay
  },
  associatedMortgage: (_, getters) => (propertyId) =>
    getters.allMortgages.find((a) => a.property_id === propertyId),
  associatedMortgages: (_, getters) => (propertyId) =>
    getters.allMortgages.filter((a) => a.property_id === propertyId),
  excludedClientsFromSelectionList(state) {
    return state.excludedClientsFromSelectionList
  },
  validation: (_0, _1, _2, rootGetters) => {
    return {
      list: {
        ...mapRulesForList(rootGetters)
      },
      mortgage: {
        ...mapRulesForMortgage(rootGetters)
      }
    }
  }
}

function mapRulesForList(rootGetters) {
  const fields = ["kyc_client_has_mortgages"]
  return mapRules(rootGetters, "kyc_mortgages", fields)
}

function mapRulesForMortgage(rootGetters) {
  const fields = [
    "mortgage_occupancy_type_key",
    "mortgage_account_status_key",
    "mortgage_provider_id",
    "mortgage_provider_other_name",
    "mortgage_account_number",
    "mortgage_account_start_date",
    "mortgage_monthly_payment_amount",
    "is_mortgage_monthly_payment_amount_calculated_from_parts",
    "mortgage_account_review_date",
    "current_balances",
    "mortgage_balance_without_erc_amount",
    "mortgage_account_balance_with_erc_amount",
    "mortgage_account_balance_date",
    "is_mortgage_portable",
    "linked_clients",
    "property_id",
    "repayment_method_parts",
    "mortgage_sub_account_status_key",
    "mortgage_sub_account_start_date",
    "mortgage_repayment_method_key",
    "mortgage_product_rate_inital_type_key",
    "mortgage_product_rate_end_date",
    "mortgage_product_rate_percentage",
    "has_mortgage_product_erc_charges",
    "mortgage_product_erc_expiry_date",
    "mortgage_product_reversionary_rate_type_key",
    "mortgage_sub_account_number",
    "are_mortgage_product_overpayments_accepted",
    "is_mortgage_product_offset",
    "mortgage_part_monthly_payment_amount",
    "repayment_parts",
    "mortgage_part_repayment_method_key",
    "mortgage_part_initial_advance_amount",
    "mortgage_part_term_years",
    "mortgage_part_term_months"
  ]

  const conditions = {
    mortgage_product_erc_expiry_date: {
      "==": [{ var: "has_mortgage_product_erc_charges" }, true]
    }
  }

  return mapRules(rootGetters, "mortgage", fields, conditions)
}

function mapRules(rootGetters, subSection, fields, conditions) {
  const factFind = rootGetters["caseSectionValidation/factfind"]
  return createValidationRules(
    (field) => factFind.required("mortgages", subSection, field),
    fields,
    conditions
  )
}

async function mapMortgageForList(mortgageData, clients, context) {
  mortgageData.who = formatClientNamesForList(
    mortgageData.linked_clients,
    clients
  )

  let mortgage = {}

  let property = fetchProperty(mortgageData.property_id, context.rootGetters)
  mortgageData.property_usage_copy = property?.property_usage_key //inject property usage

  //add to allMortgages array
  context.commit("allMortgages", mortgageData)
  let addressStr = property
    ? MbAddressMixin.methods.formatAddress(
        { propertyAddress: property.address },
        context.rootGetters
      )
    : "--"
  mortgage = {
    id: mortgageData.id,
    mortgage_occupancy_type_key: mortgageData.mortgage_occupancy_type_key,
    mortgage_account_status_key: `${getDisplayText(
      context,
      "q-583526d6-0e6a-44a1-a78c-055f36c4cb05",
      mortgageData.mortgage_account_status_key
    )} | ${mortgageData.who}`,

    mortgage_provider_id: displayProviderNameByIdAndType(
      mortgageData.mortgage_provider_id,
      "mortgage"
    ),

    mortgage_account_number: mortgageData.mortgage_account_number,
    property: addressStr,
    mortgage_account_start_date:
      mortgageData.mortgage_account_start_date === null
        ? ""
        : moment(mortgageData.mortgage_account_start_date).format("DD/MM/YYYY"),
    balance_and_date: latestBalanceAndDate(mortgageData.current_balances),
    mortgage_balance_without_erc_amount:
      mortgageData.mortgage_balance_without_erc_amount,
    current_balances: mortgageData.current_balances,
    monthly_payment: mortgageData.mortgage_monthly_payment_amount,
    completed:
      mortgageData.current_record_validation
        ?.is_record_section_validation_result_valid,
    who: mortgageData.who
  }
  context.commit("addMortgagesForList", mortgage)
}

function latestBalanceAndDate(current_balances) {
  if (!current_balances || current_balances.length == 0) return ""
  if (
    current_balances.some(
      (e) =>
        !e.mortgage_account_balance_date &&
        !e.mortgage_balance_without_erc_amount
    )
  ) {
    return ""
  } else {
    let balancesWithDates = current_balances.map((obj) => {
      return { ...obj, date: new Date(obj.mortgage_account_balance_date) }
    })

    let latestBalance = balancesWithDates.sort(
      (objA, objB) => Number(objB.date) - Number(objA.date)
    )[0]

    return `${functionService.formatNumberAsCurrency(
      latestBalance.mortgage_balance_without_erc_amount
    )} | ${moment(latestBalance.mortgage_account_balance_date).format(
      "DD/MM/YYYY"
    )}`
  }
}

function fetchProperty(propertyAddressId, rootGetters) {
  if (!propertyAddressId) return "--"

  let properties = rootGetters["properties/allProperties"]
  if (properties?.length > 0) {
    let match = properties.find((e) => e.id === propertyAddressId)
    if (match) return match
  }
  return null
}

function getDisplayText(context, formId, value) {
  const displayText =
    context.rootGetters["datadictionary/displayTextByFormIdAndValue"]
  return displayText(formId, value)
}

function createMortgage(type, status, linkedClients, linkedCompanies) {
  var key = `0${Date.now()}`
  return {
    id: null,
    property_usage_copy: null,
    mortgage_occupancy_type_key: type,
    mortgage_account_status_key: status,
    linked_clients: linkedClients,
    linked_company_ids: linkedCompanies,
    mortgage_provider_id: null,
    mortgage_account_number: "",
    mortgage_account_start_date: null,
    mortgage_monthly_payment_amount: 0,
    is_mortgage_monthly_payment_amount_calculated_from_parts: false,
    mortgage_balance_without_erc_amount: 0,
    mortgage_account_balance_with_erc_amount: 0,
    mortgage_account_balance_date: null,
    current_balances: [
      {
        mortgage_balance_without_erc_amount: 0,
        mortgage_account_balance_with_erc_amount: 0,
        mortgage_account_balance_date: format(new Date(), dbDateFormat)
      }
    ],
    is_mortgage_portable: null,
    overpayments: null,
    offset: null,
    property_id: null,
    repayment_method_parts: [
      {
        mortgage_sub_account_status_key: "current",
        mortgage_sub_account_number: null,
        mortgage_sub_account_start_date: null,
        mortgage_repayment_method_key: null,
        mortgage_product_rate_inital_type_key: null,
        mortgage_product_rate_end_date: null,
        mortgage_product_reversionary_rate_type_key: null,
        mortgage_product_rate_percentage: null,
        has_mortgage_product_erc_charges: null,
        mortgage_product_erc_expiry_date: null,
        are_mortgage_product_overpayments_accepted: null,
        is_mortgage_product_offset: null,
        mortgage_part_monthly_payment_amount: null,
        repayment_parts: [],
        key: key //for indexing locally
      }
    ],
    linked_case_applications_ids: null,
    linked_opportunities_ids: null
  }
}
