import rawAxios from "axios"

export const namespaced = true

export const state = {
  list: [],
  fetching: false,
  errorForDisplay: "",
  importStatus: 0
}
export const getters = {
  list: (state) => state.list,
  fetching: (state) => state.fetching,
  errorForDisplay: (state) => state.errorForDisplay,
  importStatus: (state) => state.importStatus
}

export const mutations = {
  addValuation(state, data) {
    let dict = null
    if (data.avm.additionalAttributes) {
      dict = Object.assign(
        {},
        ...data.avm.additionalAttributes.map((x) => ({ [x.name]: x.value }))
      )
    }
    var flatRoofPercent = GetAdditionalAttribute(dict, "flat_roof_percentage")
    if (flatRoofPercent !== null) {
      //TODO quick fix, idealy we want to use DD to conver to non-decimal number
      flatRoofPercent = "" + Math.round(flatRoofPercent)
    }
    state.list.push({
      id: data.valuationId,
      postcode: data.postcode,
      address: data.address,
      fullAddress: GetAdditionalAttribute(dict, "full_address"),
      effectiveDate: data.avm.effectiveDate,
      yearBuilt: GetAdditionalAttribute(dict, "year_built"),
      bedrooms:
        GetAdditionalAttribute(dict, "number_of_bedrooms") == null
          ? null
          : GetAdditionalAttribute(dict, "number_of_bedrooms").toString(), //convert number to string for select-input
      propertyType: GetAdditionalAttribute(dict, "property_type"),
      propertyStyle: GetAdditionalAttribute(dict, "property_style"),
      periodBuilt: GetAdditionalAttribute(dict, "period_built"),
      receptions: GetAdditionalAttribute(dict, "number_of_reception_rooms"),
      heating: GetAdditionalAttribute(dict, "heating_info"),
      tenure: GetAdditionalAttribute(dict, "tenure"),
      listedStatus: GetAdditionalAttribute(dict, "listed"),
      exLocalAuthority: JSON.parse(
        GetAdditionalAttribute(dict, "ex_local_authority")
      ),
      constructionType: GetAdditionalAttribute(dict, "wall_type"),
      roofType: GetAdditionalAttribute(dict, "roof_type"),
      flatRoofPercent: flatRoofPercent,
      floorNumber: GetAdditionalAttribute(dict, "floor_number"),
      bathrooms: GetAdditionalAttribute(dict, "number_of_bathrooms"),
      numberOfFloors: GetAdditionalAttribute(dict, "number_of_floors"),
      numberOfHabitableRooms: GetAdditionalAttribute(
        dict,
        "number_of_habitable_rooms"
      ),
      avmValuation: data.avm.capital.capitalAmount,
      avmValuationLower: data.avm.capital.lowerCapitalAmount,
      avmValuationUpper: data.avm.capital.upperCapitalAmount,
      avmValuationConfidence: data.avm.capital.confidenceBand,
      avmRental: data.avm.rental.rentalAmount,
      avmRentalLower: data.avm.rental.lowerRentalAmount,
      avmRentalUpper: data.avm.rental.upperRentalAmount,
      avmRentalConfidence: data.avm.rental.confidenceBand
    })
    state.importStatus = 1
  },
  addErrorForDisplay(state, error) {
    state.errorForDisplay = error
    state.importStatus = -1
  },
  clearErrorForDisplay(state) {
    state.errorForDisplay = ""
    state.importStatus = 0
  }
}
function GetAdditionalAttribute(dict, attr) {
  //hometrack API says these are nullable, so we should handle that
  if (dict == null) return null
  return dict[attr] ?? null
}

export const actions = {
  initalise({ commit }) {
    commit("clearErrorForDisplay")
  },
  async getValuation({ commit, state }, info) {
    state.fetching = true
    state.list = []
    commit("clearErrorForDisplay")

    // patch solution for https://mortgageengine.atlassian.net/browse/CB-1744
    const axios =
      process.env.NODE_ENV === "production" ? this.$mbAxios : rawAxios

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_PROPERTY_URL}/PropertyValuation/GetValuation`,
        info
      )
      //ensure error is added on failure as its required by PropertyDetails
      if (response.status !== 200) {
        commit("addErrorForDisplay", response.message)
      } else {
        const data = response.data
        commit("addValuation", data)
      }
    } catch (err) {
      commit("addErrorForDisplay", err.response.data)
      console.log(err)
    }
    state.fetching = false
    return state.list.length > 0
  }
}
