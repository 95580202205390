// setup custom axios
import axios from "axios"
import store from "../store"
import errorsConfig from "../../display-api-errors.config.json"

let axiosSilent = axios.create()
export { axiosSilent, axios }

//options.silent = true will prevent all of the default popups from being show.
let axiosInstances = [
  { client: axios, options: {} },
  { client: axiosSilent, options: { silent: true } }
]

axiosInstances.forEach((axiosInstance) => {
  axiosInstance.client.interceptors.request.use((config) => {
    const token = store.getters["auth/token"]
    config.headers["content-type"] = "application/json"
    if (store.getters["auth/sendToken"]) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    return config
  })

  axiosInstance.client.interceptors.response.use(
    async (res) => res,
    async (err) => {
      if (err.code === "ERR_CANCELED") {
        //we may reach here when client paged search is canceled by a newer request
        if (process.env.VUE_APP_ENV == "development") {
          console.info("Request canceled: " + JSON.stringify(err))
        }

        return //exit interceptor early
      }
      let displayErrorToUser = Boolean(!axiosInstance.options.silent)
      try {
        errorsConfig?.forEach((cfg) => {
          if (!displayErrorToUser) return //stop searching (exit foreach loop only)
          //build base url
          let baseUrl = ""
          switch (cfg.type) {
            case "env":
              baseUrl = process.env[cfg.baseUrl]
              break
            case "plain":
            default:
              baseUrl = cfg.baseUrl
          }
          //build suffix array
          let suffix = cfg.matchSuffix
          if (!(suffix?.length > 0)) {
            suffix = [""]
          }

          //attempt to match any baseUrl+suffix+status
          let matchedUrl = suffix.find((s) =>
            err.config.url.startsWith(baseUrl + s)
          )
          if (matchedUrl !== undefined) {
            let matchedStatus = cfg.ignore.find(
              (i) => i == err.response?.status
            )
            if (matchedStatus) {
              displayErrorToUser = false
            }
          }
        })
      } catch (exception) {
        //if something goes wrong, ensure we still show error to user
        //its also a good idea we don't throw a new error, and instead throw the intended error
        console.error(exception)
      }
      let data = err?.response?.data
      let dataOriginal = data
      if (Array.isArray(data)) data = data.join(", ")
      if (typeof data === "object") data = JSON.stringify(data)
      data = data?.toString()
      if (displayErrorToUser) {
        //Important, hide the wait spinner first
        store.commit("layout/setShowWaitSpinnerFlag", false)
        switch (err.response?.status) {
          case 401:
          case 403:
            if (canShowPermissionFailure()) {
              await window.alertAsync(
                `Sorry authorisation failed for the action you have requested.`
              )
            }
            break
          case 400:
            if (data?.includes("Access to this data has been denied")) {
              await window.alertAsync(
                `Sorry you do not have access to perform this operation on this data.`
              )
            } else if (
              data?.includes(
                "This record has been updated by another user, please refresh page and try again"
              )
            ) {
              await window.alertAsync(
                `This record has been updated by another user, please refresh page and try again.<br/>Any unsaved changes will be lost.`
              )
            } else {
              await window.alertAsync(
                `Sorry a system error has occurred. Please check the form and try again.`
              )
            }
            if (dataOriginal)
              console.info("Request failed with status 400", dataOriginal)
            break
          case 404:
            if (canShowPermissionFailure()) {
              await window.alertAsync(
                `Sorry the data you have requested was not found or access was denied.`
              )
            }
            break
          case 422:
            // 422 is unique to broker lender credentials errors https://mortgageengine.atlassian.net/browse/MWSB-549
            await window.alertAsync(
              "Unfortunately the broker credentials provided are unauthorised with this lender. Please check and update your Integration Settings before trying again."
            )
            break
          case 500:
            await window.alertAsync(`Sorry a system error has occurred.`)
            break
        }
      }

      throw err

      function canShowPermissionFailure() {
        const canShow =
          !err.response.config.url.includes("opportunities") &&
          !err.response.config.url.includes("referral") &&
          !err.response.config.url.includes("getassociated")
        return canShow
      }
    }
  )
})
