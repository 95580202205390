import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import router from "./router"
import { registerLicense } from "@syncfusion/ej2-base"

// axios setup
// TODO: This is a patch solution. Custom axios instance should be an injected plugin or utility
import "./utils/setupAxios"
import axios from "axios"

// Import the Auth0 configuration and plugin
let domain = process.env.VUE_APP_AUTH_ZERO_DOMAIN
let clientId = process.env.VUE_APP_AUTH_ZERO_CLIENT_ID
let audience = process.env.VUE_APP_AUTH_ZERO_AUDIENCE

import { Auth0Plugin, getInstance } from "@/plugins/auth0-plugin"
import { ApolloPlugin } from "@/plugins/apollo-plugin"
import {
  DataDictionaryPlugin,
  CommonUIPlugin,
  MbPageHeading
} from "@/plugins/components-plugin/entry.js"

import ActionButton from "@/components/core/form/ActionButton.vue"
import StyledMessage from "@/components/core/form/StyledMessage.vue"
import MbFormInputGroup from "@/components/core/form/MbFormInputGroup.vue"
import MBRouterLink from "@/components/core/MBRouterLink.vue"
Vue.component("ActionButton", ActionButton)
Vue.component("StyledMessage", StyledMessage)
Vue.component("MbFormInputGroup", MbFormInputGroup)
Vue.component("MbRouterLink", MBRouterLink)

// Bootstrap assets
import "./bootstrap"
import "./validation"
import "bootstrap-vue/dist/bootstrap-vue.css"
//https://bootstrap-vue.org/docs/icons/#icon-components
import "bootstrap-vue/dist/bootstrap-vue-icons.min.js"

console.info(`Running Vue application in ${process.env.VUE_APP_ENV} mode`)

import Meta from "vue-meta"
Vue.use(Meta)

// syncfusion -- removed for MVP
// "@syncfusion/ej2-vue-buttons": "^19.3.53",
// "@syncfusion/ej2-vue-documenteditor": "^19.3.54",
// "@syncfusion/ej2-vue-dropdowns": "^20.1.60",
// "@syncfusion/ej2-vue-lists": "^19.3.53",
// "@syncfusion/ej2-vue-popups": "^19.3.53",
// "@syncfusion/ej2-vue-querybuilder": "^20.1.51",
// "@syncfusion/ej2-vue-splitbuttons": "^19.3.53",
import { QueryBuilderPlugin } from "@syncfusion/ej2-vue-querybuilder"
Vue.use(QueryBuilderPlugin)
import { DropDownTreePlugin } from "@syncfusion/ej2-vue-dropdowns"
Vue.use(DropDownTreePlugin)

// import { configure } from "vee-validate"
// const config = {
//   mode: "passive"
// }
// configure(config)

import { MbModal } from "@/plugins/components-plugin/entry.js"
let alertHandler = new MbModal()

//register methods window.confirmAsync, window.alertAsync, window.showPopupAsync
//and begin processing
alertHandler.register()

let showToastOnError =
  process.env.VUE_APP_SHOW_TOAST_ON_ERROR === "true" &&
  process.env.VUE_APP_ENV === "development"
if (showToastOnError) {
  //this could cause infinite loops,
  //so it should not be used on live environments.
  let originalConsoleError = console.error
  console.error = function (err) {
    try {
      window.showToast(err, "error")
    } catch (internalErr) {
      originalConsoleError(internalErr)
    }

    originalConsoleError(err)
  }
}

// Install the authentication plugin
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

Vue.use(ApolloPlugin)

//Install MB User plugin
import { MbUserPlugin } from "@/plugins/user-plugin/entry.js"
Vue.use(MbUserPlugin, {
  store,
  auth0: () => {
    return getInstance()
  },
  settings: {
    appId: process.env.VUE_APP_ADMIN3_APP_ID,
    basePath: process.env.VUE_APP_ADMIN3_SERVICE_URL,
    env: process.env.VUE_APP_ADMIN3_ENV,
    debug: process.env.VUE_APP_ENV === "development"
  }
})

// Google analytics GTAG, v1.16.0 used for Vue 2 compat
import VueGtag from "vue-gtag"

let gtagID = ""
if (process.env.VUE_APP_ENV == "development") {
  gtagID = process.env.VUE_APP_GOOGLE_ANALYTICS_DEV_ID
} else if (process.env.VUE_APP_ENV == "production") {
  gtagID = process.env.VUE_APP_GOOGLE_ANALYTICS_PROD_ID
}

Vue.use(
  VueGtag,
  {
    config: { id: gtagID }
  },
  router
)

// Install the common UI plugin
Vue.use(DataDictionaryPlugin, {
  store,
  auth0: () => {
    return getInstance()
  },
  settings: () => {
    return {
      id: process.env.VUE_APP_DATA_DICTIONARY_APP_ID,
      url: process.env.VUE_APP_DATA_DICTIONARY_URL
    }
  }
})
Vue.use(CommonUIPlugin, { store, mbAxios: axios })

Vue.mixin({
  methods: {
    //Rename to alertBoxPopulate()
    async alertBoxCreation(message, containerID = this.componentId) {
      var container = document.getElementById(containerID)
      if (!container) {
        console.log("Error: " + message)
        await window.alertAsync(message)
        return
      }
      if (!container?.hasChildNodes()) {
        var alertDiv = document.createElement("div")
        alertDiv.setAttribute("role", "alert")
        alertDiv.setAttribute("style", "display: inline-block")
        alertDiv.className = "alert alert-danger alert-dismissible mt-3"

        var alertHeader = document.createElement("h6")
        alertHeader.className = "alert-link"
        var alertHeaderStyle = document.createElement("b")
        alertHeaderStyle.innerText = "Warning!"

        alertHeader.appendChild(alertHeaderStyle)
        alertDiv.appendChild(alertHeader)

        var alertContent = document.createElement("p")
        alertContent.innerText = message

        alertDiv.appendChild(alertContent)

        var alertButton = document.createElement("button")
        alertButton.className = "btn-close"
        alertButton.setAttribute("data-bs-dismiss", "alert")
        alertButton.setAttribute("aria-label", "Close")
        alertDiv.appendChild(alertButton)

        container.appendChild(alertDiv)
      } else {
        await window.alertAsync(message)
      }
    },

    async alertBoxClear(containerId = this.componentId) {
      var deleteContainer = document.getElementById(containerId)
      deleteContainer.innerHTML = ""
    }
  }
})
// Register global components from mb-common-ui
Vue.component("MbPageHeading", MbPageHeading)

Vue.config.productionTip = false
registerLicense(process.env.VUE_APP_DOCUMENT_EDITOR_LICENCE_KEY)

Vue.prototype.$waitSpinner = {
  show: async () => {
    store.commit("layout/setShowWaitSpinnerFlag", true)
    return Promise.resolve()
  },
  hide: () => store.commit("layout/setShowWaitSpinnerFlag", false),
  /**
   * @param {*} callback The function to call to perform a long running process
   * @returns Returns a Promise with the result returned from the callback
   */
  wrap: async function (callback) {
    return this.show()
      .then(() => callback())
      .catch((err) => {
        this.hide()
        throw err
      })
      .finally(() => this.hide())
  }
}

const rootVue = new Vue({
  store,
  router,

  render: (h) => h(App)
}).$mount("#app")

axios.interceptors.response.use(
  async (res) => {
    rootVue.$emit("action-done")
    return res
  },
  async (err) => {
    rootVue.$emit("action-done")
    throw err
  }
)

if (window.Cypress) {
  window.__store__ = store
}
