import axios from "axios"

export const state = {
  providers: [],
  mortgage_providers: [],
  protection_providers: [],
  general_insurance_providers: []
}

export const mutations = {
  setAllProviders: (state, data) => (state.providers = data),
  setMortgageProviders: (state, data) => (state.mortgage_providers = data),
  setProtectionProviders: (state, data) => (state.protection_providers = data),
  setGeneralInsuranceProviders: (state, data) =>
    (state.general_insurance_providers = data)
}

export const getters = {
  allProviders: (state) => state.providers,
  mortgageProviders: (state) => state.mortgage_providers,
  protectionProviders: (state) => state.protection_providers,
  generalInsuranceProviders: (state) => state.general_insurance_providers
}

export const actions = {
  async loadAllProviders({ commit }) {
    let url = `${process.env.VUE_APP_CDS_URL}/utilities/datadictionary/providers`
    const response = await axios.get(url)

    if (response.status == 200) {
      const providers = response.data.providerLookup

      commit("setAllProviders", providers)

      const mortgageProviders = []
      const protectionProviders = []
      const generalInsuranceProviders = []

      providers.forEach((provider) => {
        provider.providerTypes.forEach((type) => {
          if (type.providerTypeKey === "mortgage_lender") {
            mortgageProviders.push(provider)
          } else if (type.providerTypeKey === "protection_provider") {
            protectionProviders.push(provider)
          } else if (type.providerTypeKey === "general_insurance_provider") {
            generalInsuranceProviders.push(provider)
          }
        })
      })

      commit("setMortgageProviders", mortgageProviders)
      commit("setProtectionProviders", protectionProviders)
      commit("setGeneralInsuranceProviders", generalInsuranceProviders)
    }
  }
}
