import {
  chainGuards,
  storeGuards
} from "@/global/modules/middleware/checkStore"

export const overviewRoutes = [
  {
    path: "overview",
    beforeEnter: chainGuards([storeGuards.loadAllTeamMembers]),
    name: "case-overview",
    component: () =>
      import(
        /* webpackChunkName: "case-overview" */ "@/components/ux/clients/cases/CaseOverview.vue"
      ),
    meta: {
      caseNavActive: true,
      title: "Case Overview"
    }
  }
]
