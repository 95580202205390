import axios from "axios"
import { functionService } from "@/global/modules/helpers"
import { Mutex } from "async-mutex"

export const state = {
  listData: [],
  showCreateReferralSideMenu: false,
  selectedReferral: null,
  loadReferralsMutex: new Mutex(),
  loadReferralsAxiosController: null,
  referralPartners: [],
  referralPartnerIndividuals: []
}

export const mutations = {
  setCreateReferralSideMenuStatus(state, status) {
    state.showCreateReferralSideMenu = status.show
  },
  setSelectedReferral(state, data) {
    state.selectedReferral = data
  },
  setData(state, data) {
    state.listData = data
  },
  clearReferralPartners(state) {
    state.referralPartners = []
  },
  clearReferralPartnerIndividuals(state) {
    state.referralPartnerIndividuals = []
  },
  addReferralPartners(state, data) {
    state.referralPartners = data
  },
  addReferralPartnerIndividuals(state, data) {
    state.referralPartnerIndividuals = data
  }
}

export const getters = {
  showCreateReferralSideMenu: (state) => state.showCreateReferralSideMenu,
  selectedReferral: (state) => state.selectedReferral,
  listData: (state) => state.listData,
  referralPartners: (state) => state.referralPartners,
  referralPartnerOptions: (state) =>
    state.referralPartners.map((partner) => ({
      text: partner.business_name,
      value: partner.id
    })),
  referralPartnerIndividuals: (state) => state.referralPartnerIndividuals,
  referralPartnerIndividualsOptions: (state) =>
    state.referralPartnerIndividuals.map((individual) => ({
      text: `${individual.first_name} ${individual.last_name}`,
      value: individual.id
    }))
}

export const actions = {
  async createReferral(_, payload) {
    return axios
      .post(`${process.env.VUE_APP_CDS_URL}/referrals`, payload)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data.referral.id)
        }
      })
  },
  async updateReferral(context, payload) {
    return axios
      .post(`${process.env.VUE_APP_CDS_URL}/referrals`, payload)
      .then((response) => {
        if (response.status === 200) {
          context.commit("setSelectedReferral", response.data)
        }
      })
      .catch((err) => {
        console.error(err)
        //Handled by interceptor, no re-throw required
      })
  },
  async getReferral(context, id) {
    return axios
      .get(`${process.env.VUE_APP_CDS_URL}/referrals/${id}`)
      .then((response) => {
        if (response.status === 200) {
          context.commit("setSelectedReferral", response.data)
        }
      })
      .catch((err) => {
        console.error(err)
        //Handled by interceptor, no re-throw required
      })
  },
  async loadReferrals({ commit }, payload) {
    let queryString = functionService.objectToQueryString(payload)
    let url = `${process.env.VUE_APP_CDS_URL}/referrals/?${queryString}`
    let response = await loadReferralsWithMutexAsync(state, url)
    let referralsCount = null
    if (response === undefined) {
      //response was canceled
      return state.data?.length ?? 0
    }
    if (response.status === 200) {
      referralsCount = response.data.total_count
      commit("setData", response.data.data)
    }
    return referralsCount
  },
  async loadReferralPartners({ commit }) {
    commit("clearReferralPartners")
    await axios
      .get(`${process.env.VUE_APP_CONTACTS_URL}/v1/businesses`)
      .then((response) => {
        if (response.status === 200) {
          commit("addReferralPartners", mapReferralPartners(response.data))
        }
      })
      .catch(() => {
        //Do nothing for now, interceptor will handle
      })
  },
  async loadReferralPartnersByStatus({ commit }, status = null) {
    let urlString = "v1/businesses/names"
    if (status) {
      urlString = `${urlString}?introducerStatus=${status}`
    }
    commit("clearReferralPartners")
    await axios
      .get(`${process.env.VUE_APP_CONTACTS_URL}/${urlString}`)
      .then((response) => {
        if (response.status === 200) {
          commit(
            "addReferralPartners",
            mapReferralPartnersByStatus(response.data)
          )
        }
      })
      .catch(() => {
        //Do nothing for now, interceptor will handle
      })
  },
  async getReferralPartnerIndividuals(context, id) {
    context.commit("clearReferralPartnerIndividuals")
    if (!id) return
    return axios
      .get(
        `${process.env.VUE_APP_CONTACTS_URL}/v1/businesses/${id}/individuals`
      )
      .then((response) => {
        if (response.status === 200) {
          context.commit("addReferralPartnerIndividuals", response.data)
        }
      })
      .catch((err) => {
        console.error(err)
        //Do nothing for now, interceptor will handle
      })
  }
}

function mapReferralPartners(data) {
  return data.reduce((partners, company) => {
    partners.push({ id: company.id, business_name: company.business_name })
    return partners
  }, [])
}

function mapReferralPartnersByStatus(data) {
  return data.reduce((partners, company) => {
    partners.push({
      id: company.company_id,
      business_name: company.business_name
    })
    return partners
  }, [])
}

function abortLoadReferralsAxiosController(state) {
  if (state.loadReferralsAxiosController) {
    try {
      state.loadReferralsAxiosController.abort(
        "Operation cancelled by the user."
      )
    } catch (ex) {
      console.error(ex)
    }
  }
}
async function loadReferralsWithMutexAsync(state, url) {
  let request = state.loadReferralsMutex.runExclusive(async () => {
    abortLoadReferralsAxiosController(state)
    state.loadReferralsAxiosController = new AbortController()
    return axios.get(url, {
      signal: state.loadReferralsAxiosController.signal
    }) //no await, return the raw promise.
  }) //end mutex
  return await request
}
