<template>
  <div class="d-flex flex-column gap-3">
    <div v-if="!readonly && editMode" class="mb-3">
      <div v-if="!addressReadonly">
        <!-- Tabbing -->
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              :id="`uk-tab${_uid}`"
              class="nav-link"
              :class="[ukAddress && 'active']"
              data-bs-toggle="tab"
              :data-bs-target="`#uk-${_uid}`"
              type="button"
              role="tab"
              :aria-controls="`uk-${_uid}`"
              aria-selected="true"
              @click="showUKAddress"
            >
              UK Address
            </button>
          </li>
          <li v-if="showOverseasTab" class="nav-item" role="presentation">
            <button
              :id="`is_non_uk_address-tab${_uid}`"
              class="nav-link"
              :class="[!ukAddress && 'active']"
              data-bs-toggle="tab"
              :data-bs-target="`#is_non_uk_address-${_uid}`"
              type="button"
              role="tab"
              :aria-controls="`is_non_uk_address-${_uid}`"
              aria-selected="false"
              @click="showOverseasAddress"
            >
              Overseas Address
            </button>
          </li>
        </ul>
        <div class="tab-content p-4">
          <div
            :id="`uk-${_uid}`"
            class="tab-pane fade"
            :class="[ukAddress && 'show active']"
            role="tabpanel"
            aria-labelledby="uk-tab"
          >
            <div class="d-flex flex-column gap-3 mb-4">
              <!-- Search Address -->
              <div>Address lookup</div>
              <div class="dropdown">
                <input
                  id="address_search"
                  v-model="searchText"
                  type="search"
                  class="form-control"
                  autocomplete="none"
                />
                <div
                  id="dropdown_address_content"
                  class="dropdown-content"
                  :class="dropdownDisplay"
                >
                  <ul>
                    <li
                      v-for="(address, index) in addresses"
                      :key="index"
                      @click="selectionChanged(address)"
                    >
                      {{ address.fullAddress }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-xl-3 align-self-end">
                <mb-form-input
                  v-model="address.flat_number"
                  :is-address="true"
                  guid="q-c114f262-a695-4dc2-806e-ffdb5f531412"
                  label
                  :highlight-as-required="required('flat_number')"
                />
              </div>
              <div class="col-xl-3">
                <mb-form-input
                  v-model="address.house_number"
                  :is-address="true"
                  guid="q-61eaa12e-0a92-49d0-9092-81c75ba0eac4"
                  label
                  :highlight-as-required="required('house_number')"
                />
              </div>
              <div class="col-xl-6 align-self-end">
                <mb-form-input
                  v-model="address.building_name"
                  :is-address="true"
                  guid="q-40875f65-f76b-4469-a720-f380891a5401"
                  label
                  :highlight-as-required="required('building_name')"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.street"
                  :is-address="true"
                  guid="q-1d5b0bb8-80ca-4b5e-a8e6-e908adb2d6c9"
                  label
                  :highlight-as-required="required('street')"
                />
              </div>
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.district"
                  :is-address="true"
                  guid="q-657f78a9-a5b0-4e90-a146-413b8897c26c"
                  label
                  :highlight-as-required="required('district')"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.town"
                  :is-address="true"
                  guid="q-2ae7185b-e855-4ff9-a391-ecc678acc6b5"
                  label
                  :highlight-as-required="required('town')"
                />
              </div>
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.county"
                  :is-address="true"
                  guid="q-580c007b-434a-47e4-8fab-bcadd49a862e"
                  label
                  :highlight-as-required="required('county')"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.postcode"
                  :is-address="true"
                  guid="q-9b631da6-d868-4a65-9955-24c864aaee3c"
                  label
                  :highlight-as-required="required('postcode')"
                />
              </div>
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.uk_country_key"
                  :is-address="true"
                  :attrs="{
                    style: 'min-width: none'
                  }"
                  guid="q-bc0737f5-92de-4871-8997-2efd8ae7ac1a"
                  label
                />
              </div>
            </div>
          </div>
          <div
            :id="`is_non_uk_address-${_uid}`"
            class="tab-pane fade"
            :class="[!ukAddress && 'show active']"
            role="tabpanel"
            aria-labelledby="is_non_uk_address-tab"
          >
            <div class="row mb-3">
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.international_line_1"
                  :is-address="true"
                  guid="q-03fb532b-1e69-4d2c-ae13-8bab1b4d6359"
                  label
                  :highlight-as-required="required('international_line_1')"
                />
              </div>
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.international_line_2"
                  :is-address="true"
                  guid="q-0769fc4e-d549-4248-9246-a6b567ea95de"
                  label
                  :highlight-as-required="required('international_line_2')"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.international_line_3"
                  :is-address="true"
                  guid="q-872be973-5aea-421f-896a-c30b42f30901"
                  label
                  :highlight-as-required="required('international_line_3')"
                />
              </div>
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.international_line_4"
                  :is-address="true"
                  guid="q-f839a7c6-953e-4121-83b1-29e5b24631de"
                  label
                  :highlight-as-required="required('international_line_4')"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.country_key"
                  :is-address="true"
                  :attrs="{
                    style: 'min-width: none'
                  }"
                  guid="q-f08fb998-5bcf-4391-867f-73005ac2c487"
                  label
                  :highlight-as-required="required('country_key')"
                />
              </div>
              <div class="col-lg-12 col-xl-6">
                <mb-form-input
                  v-model="address.economic_region_key"
                  :is-address="true"
                  :attrs="{
                    style: 'min-width: none'
                  }"
                  guid="q-e524e341-8ed5-421c-a985-6f8bb662de3b"
                  label
                  :highlight-as-required="required('economic_region_key')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="d-flex flex-column">
        <mb-page-heading
          v-if="!hideTitle"
          :type="2"
          :title="'Property Address'"
          class="mb-3"
        />
        <span>{{ formattedAddress }}</span>
        <span v-show="address.uprn && showUprn">{{
          "UPRN " + address.uprn
        }}</span>
        <a class="hyperlink mt-1" style="width: 4em" @click="editAddress(true)"
          >Clear</a
        >
      </div>
    </div>
    <!-- Display address -->
    <div v-else class="d-flex flex-column">
      <span>{{ formattedAddress }}</span>
      <span v-show="address.uprn && showUprn">{{
        "UPRN " + address.uprn
      }}</span>
      <a
        v-show="!readonly"
        class="hyperlink mt-1"
        href="#"
        @click.prevent="editAddress(true)"
        >Clear</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { MbFunctionService } from "@/plugins/components-plugin/lib-modules/helpers.js"
import MbPageHeading from "@/plugins/components-plugin/lib-components/core/headings/MbPageHeading.vue"
import MbAddressMixin from "@/plugins/components-plugin/lib-modules/mixins/address-mixins"

const createDefaultAddressObject = () =>
  Object.assign(
    {},
    {
      flat_number: null,
      house_number: null,
      building_name: null,
      street: null,
      district: null,
      town: null,
      county: null,
      uk_country_key: null,
      postcode: null,
      uprn: null,
      is_non_uk_address: false,
      international_line_1: null,
      international_line_2: null,
      international_line_3: null,
      international_line_4: null,
      country_key: null,
      economic_region_key: null
    }
  )

export default {
  components: {
    MbPageHeading
  },
  mixins: [MbAddressMixin],
  model: {
    prop: "value",
    event: "addressUpdated"
  },
  props: {
    showOverseasTab: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: Object,
      default: () => createDefaultAddressObject()
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    showUprn: {
      type: Boolean,
      default: () => true
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    highlightAsRequired: {
      type: Boolean,
      default: () => false
    },
    validationRules: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["editMode"],
  data() {
    return {
      address: this.value,
      searchText: "",
      dropdownDisplay: "none",
      editMode: true,
      showCancel: false,
      displaySearch: true,
      displayAddress: false,
      addressReadonly: false,
      tempStoreUkAddress: {},
      tempStoreOverseasAddress: {}
    }
  },
  computed: {
    ...mapGetters({
      addresses: "mbAddressLookup/addresses"
    }),
    ukAddress() {
      return !this.address.is_non_uk_address
    },
    formattedAddress() {
      let address = this.formatAddress({ propertyAddress: this.address })

      if (!address) {
        return this.readonly ? "-" : "Please click 'Clear' to find an address"
      }
      return address
    }
  },
  watch: {
    searchText: MbFunctionService.debounce(function (text) {
      this.searchAddress(text)
    }, 300),
    address: {
      handler(val) {
        this.$emit("addressUpdated", val)
      },
      deep: true
    }
  },
  created() {
    if (!this.address || Object.keys(this.address).length === 0) {
      this.address = createDefaultAddressObject()
    }
  },
  methods: {
    ...mapActions({
      performLookup: "mbAddressLookup/search",
      clearAddresses: "mbAddressLookup/clear"
    }),
    async searchAddress(text) {
      if (text) {
        this.performLookup(text)
          .then(() => {
            this.showDropDownContent(true)
          })
          .catch((error) => console.log(error))
      } else {
        this.clearAddresses()
        this.showDropDownContent(false)
      }
    },
    selectAddressMode(value) {
      this.editMode = value
      this.$emit("editMode", value)
    },
    showOverseasAddress() {
      if (!this.address.is_non_uk_address) {
        this.tempStoreUkAddress = {
          flatNo: this.address.flat_number,
          houseNo: this.address.house_number,
          buildingName: this.address.building_name,
          street: this.address.street,
          district: this.address.district,
          town: this.address.town,
          county: this.address.county,
          ukCountryKey: this.address.uk_country_key,
          postcode: this.address.postcode
        }
        this.clear()
        if (Object.keys(this.tempStoreOverseasAddress).length !== 0) {
          this.address.international_line_1 =
            this.tempStoreOverseasAddress.intlLine1
          this.address.international_line_2 =
            this.tempStoreOverseasAddress.intlLine2
          this.address.international_line_3 =
            this.tempStoreOverseasAddress.intlLine3
          this.address.international_line_4 =
            this.tempStoreOverseasAddress.intlLine4
          this.address.country_key = this.tempStoreOverseasAddress.country_key
          this.address.economic_region_key =
            this.tempStoreOverseasAddress.intlLocation
        }
      }
      this.address.is_non_uk_address = true
    },
    showUKAddress() {
      if (!this.ukAddress) {
        this.tempStoreOverseasAddress = {
          intlLine1: this.address.international_line_1,
          intlLine2: this.address.international_line_2,
          intlLine3: this.address.international_line_3,
          intlLine4: this.address.international_line_4,
          country_key: this.address.country_key,
          intlLocation: this.address.economic_region_key
        }
        this.clear()
        if (Object.keys(this.tempStoreUkAddress).length !== 0) {
          this.address.flat_number = this.tempStoreUkAddress.flatNo
          this.address.house_number = this.tempStoreUkAddress.houseNo
          this.address.building_name = this.tempStoreUkAddress.buildingName
          this.address.street = this.tempStoreUkAddress.street
          this.address.district = this.tempStoreUkAddress.district
          this.address.town = this.tempStoreUkAddress.town
          this.address.county = this.tempStoreUkAddress.county
          this.address.uk_country_key = this.tempStoreUkAddress.ukCountryKey
          this.address.postcode = this.tempStoreUkAddress.postcode
        }
      }
      this.address.is_non_uk_address = false
    },
    selectionChanged(val) {
      this.address.flat_number = val.flat_Number
      this.address.house_number = val.house_Number
      this.address.building_name = val.house_Name
      this.address.street = val.street
      this.address.district = val.district
      this.address.town = val.town
      this.address.county = val.county
      this.address.uk_country_key = val.uk_country_key
      this.address.postcode = val.postcode
      this.address.uprn = val.uprn
      this.searchText = ""
      this.showDropDownContent(false)
      this.addressReadonly = true
    },
    addAddress() {
      this.editMode = false
      this.$emit("editMode", this.editMode)
    },
    editAddress(clear = false) {
      this.selectAddressMode(true)
      this.showCancel = true
      this.addressReadonly = false
      if (clear) {
        this.clear()
        // send null value back up to parent component
        this.$emit("addressUpdated", null)
      }
    },
    cancelEdit() {
      this.selectAddressMode(false)
      this.showCancel = false
    },
    showDropDownContent(show) {
      if (show) {
        this.dropdownDisplay = "show"
      } else {
        this.dropdownDisplay = ""
      }
    },
    hasAddress() {
      var address = this.address

      if (address.is_non_uk_address) {
        return address.international_line_1 !== null
      } else {
        return (
          (address.flat_number !== null ||
            address.house_number !== null ||
            address.building_name !== null) &&
          address.postcode !== null
        )
      }
    },
    clear() {
      this.searchText = ""
      this.address = createDefaultAddressObject()
      this.addressReadonly = false
    },
    required(field) {
      return (
        this.validationRules &&
        this.validationRules[field]?.required &&
        this.highlightAsRequired
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1000;
  padding: 5px 0px 10px;
  margin: 0;
  width: 100%;
  max-height: 300px;
  min-width: 160px;
  overflow-y: auto;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-top-style: none;
  border-radius: 0 0 4px 4px;
  text-align: left;
  list-style: none;
  background: #fff;
}

.dropdown-content ul {
  padding: 0px;
}

.dropdown-content ul > li {
  line-height: 1.42857143;
  /* Normalize line height */
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333;
  /* Overrides most CSS frameworks */
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-content ul > li:hover {
  background-color: #5897fb;
  color: #fff;
}

.show {
  display: block;
}

.nav-tabs {
  border-bottom: none;
}

.tab-content {
  border: 1px solid #ced4da;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.hyperlink {
  text-decoration: underline !important;
  text-decoration-color: blue;
  cursor: pointer;
}
</style>
