import { mapActions, mapGetters } from "vuex"
export const MbValuationMixin = {
  computed: {
    ...mapGetters({
      isFetchingValuations: "mbValuations/fetching"
    })
  },
  methods: {
    ...mapActions({
      getValuation: "mbValuations/getValuation"
    }),
    /**
     *
     * @returns [status, importedDetails] where status= -1:fail, 0:still fetching, 1:OK
     */
    async tryImportAvmDetails(property, ttl = null) {
      if (this.isFetchingValuations) return [0, undefined]

      let request = {
        address: {
          postcode: property.address.postcode,
          address: this.formatAddress({
            propertyAddress: property.address,
            isAvmAddress: true
          })
        },
        ttl: ttl
      }

      if (property.address.uprn) {
        request.address.uprn = property.address.uprn
      }

      if (!(await this.getValuation(request))) {
        return [-1, undefined]
      }
      let valuationsArr = this.$store.state.mbValuations.list
      let result = {}
      if (valuationsArr && valuationsArr.length > 0) {
        let valuation = valuationsArr[valuationsArr.length - 1]

        result.avm_full_address = valuation.fullAddress
        //*-- Valuation Section --*
        result.avm_valuation_date = valuation.effectiveDate
        result.avm_valuation = valuation.avmValuation
        result.avm_valuation_lower = valuation.avmValuationLower

        result.avm_valuation_upper = valuation.avmValuationUpper

        result.avm_valuation_confidence = valuation.avmValuationConfidence

        result.avm_rental_date = valuation.effectiveDate
        result.avm_rental = valuation.avmRental
        result.avm_rental_lower = valuation.avmRentalLower
        result.avm_rental_upper = valuation.avmRentalUpper
        result.avm_rental_confidence = valuation.avmRentalConfidence

        //these data points need to be tracked as the user can update them,
        //the tracking enables imported data to have contrasting look
        result.type = valuation.propertyType
        result.style = valuation.propertyStyle
        result.year_built = valuation.yearBuilt
        result.bedrooms = valuation.bedrooms
        result.bathrooms = valuation.bathrooms
        result.receptions = valuation.receptions
        result.heating_info = valuation.heating
        result.tenure = valuation.tenure
        //result.remaining_lease = valuation.
        result.listed_status = valuation.listedStatus
        result.ex_la = valuation.exLocalAuthority
        //result.nb_warranty
        result.construction = valuation.constructionType
        result.roof_type = valuation.roofType
        result.flat_roof_percent = valuation.flatRoofPercent
        result.flat_floor_no = valuation.floorNumber
        result.number_of_floors = valuation.numberOfFloors
        //result.lift
        return [1, result]
      }
      return [-1, undefined]
    }
  }
}

export default MbValuationMixin
