// Date formats
export const ageDateFormat = "dd/MM/yyyy"
export const standardDateFormat = "dd/MM/yyyy"
export const momentStandardDateFormat = "DD/MM/YYYY"
export const dateTimeStamp = "dd/MM/yyy HH:mm:ss"
export const shortDateTimeStamp = "dd/MM/yyy HH:mm"
export const footerCopyRightDate = "yyyy"
export const headerDateFormat = "EEEE, MMM do yyyy, HH:mm"
export const profileDateFormat = "EEEE, do LLLL HH:mm"
export const dbDateFormat = "yyyy-MM-dd"
export const momentdbDateFormat = "YYYY-MM-DD"
export const monthYearFormat = "MMMM, yyyy"
export const hoursMinutes = "k:mm"
export const longDayMonthYear = "do MMMM yyyy"
export const caseListDateFormat = "EE, MMM do yyyy"
export const exportFileDateFormat = "ddMMyyyy"

// MB.Common.Models.Events.Enums (EventDatabaseOperation)
export const eventDatabaseOperation = Object.freeze({
  Insert: 1,
  Update: 2,
  Delete: 3
})

export const generalInsuranceCoverType = Object.freeze({
  buildingsInsurance: "buildings",
  contentsInsurance: "contents",
  buildingsContentsInsurance: "buildings_contents_insurance"
})

export const requirementType = Object.freeze({
  protection: "protection",
  residentialMortgage: "residential_mortgage",
  buyToLetMortgage: "buy_to_let_mortgage",
  generalInsurance: "buildings_contents_insurance"
})

export const closureReason = Object.freeze({
  Other: "other"
})

export const requirementStatus = Object.freeze({
  Open: "open",
  Closed: "closed",
  Other: "other"
})

export const applicationStatus = Object.freeze({
  Open: "open",
  Closed: "closed"
})

export const applicationOutcome = Object.freeze({
  notProceededWith: "not_proceeded_with",
  completed: "completed"
})

export const generalStatus = Object.freeze({
  All: "All",
  Current: "current",
  Previous: "previous"
})

export const residentialMortgageTransactionType = Object.freeze({
  purchase: "purchase",
  existingMortgageReview: "existing_mortgage_review",
  mortgageOnUnencumberedProperty: "mortgage_on_unencumbered_property"
})

export const mortgageRepaymentType = Object.freeze({
  capitalInterest: "capital_interest",
  interestOnly: "interest_only",
  partAndPart: "part_and_part"
})

export const mortgageProductRateDuration = Object.freeze({
  periodFromCompletion: "period_from_completion",
  specificEndDate: "specific_end_date"
})

export const mortgageProductRatePeriod = Object.freeze({
  all: "all",
  twoYears: "two_years",
  threeYears: "three_years",
  fiveYears: "five_years",
  tenYears: "ten_years",
  custom: "custom"
})

export const protectionNeedsType = Object.freeze({
  mortgage: "mortgage",
  additional: "additional",
  familyIncome: "family_income",
  income: "income"
})

export const protectionType = Object.freeze({
  familyIncome: "family_income_benefit",
  income: "income_protection",
  term: "term"
})

export const coverType = Object.freeze({
  lifeOnly: "life",
  criticalIllnessOnly: "critical_illness",
  lifeAndCriticalIllness: "dual_life_critical_illness",
  lifeOrEarlierCriticalIllness: "life_or_earlier_critical_illness"
})

export const complianceTaskType = Object.freeze({
  disclosure: "disclosure",
  termsOfBusiness: "terms_of_business",
  clientPrivacy: "client_privacy",
  feeAgreement: "fee_agreement",
  proofOfIdentification: "proof_of_identification",
  proofOfAddress: "proof_of_address",
  bankStatements: "bank_statements",
  proofOfIncome: "proof_of_income",
  chaseClient: "client"
})

export const chaseTaskWho = Object.freeze({
  client: "client",
  lender: "lender",
  insurance_provider: "insurance_provider",
  solicitor: "solicitor"
})

export const opportunityType = Object.freeze({
  newEnquiry: "new_enquiry",
  existingClientMortgageReview: "existing_client_mortgage_review",
  existingClientProtectionPolicyReview:
    "existing_client_protection_policy_review",
  existingClientHouseholdInsuranceReview:
    "existing_client_household_insurance_review"
})

export const opportunityOriginationChannelKey = Object.freeze({
  clientPortal: "client_portal"
})

export const propertyStatus = Object.freeze({
  proposed: "proposed",
  purchasing: "purchasing",
  notProceeding: "not_proceeding",
  owned: "owned",
  selling: "selling",
  sold: "sold",
  rented: "rented",
  previouslyRented: "previous_rental"
})

export const documentCategoriesCrmKey = Object.freeze({
  firmDisclosure: "firm_disclosure",
  generalCase: "general_case",
  generalClient: "general_client",
  mortgageAdvice: "mortgage_advice",
  housekeeping: "housekeeping",
  protectionAdvice: "protection_advice",
  homeInsuranceAdvice: "home_insurance_advice"
})

export const productType = Object.freeze({
  protection: "protection",
  mortgage: "mortgage",
  generalInsurance: "general_insurance"
})

export const nothingRecordForSnapshotMessage =
  "Nothing had been recorded at the time of snapshot"

export const idvCheckType = Object.freeze({
  identification: "id",
  sanction: "sanctions",
  pep: "pep"
})

export const clientRelationships = Object.freeze({
  staffAdvisor: "staff_advisor",
  relative: "relative",
  staffMemberNonAdvisor: "staff_non_advisor"
})

export const propertyUsage = Object.freeze({
  mainResidence: "main_residence",
  rentedOut: "rented_out",
  dependentHome: "dependent_home",
  secondHome: "second_home",
  holidayHome: "holiday_home"
})

export const alertMessagePrompts = Object.freeze({
  formInvalidNoSaveOnLeave:
    "The changes are invalid and will not be saved, do you still wish to leave the page?",
  unsavedChangesOnLeave:
    "Do you wish to save your changes before leaving the page?"
})

export const originationSource = Object.freeze({
  existingClientRecommendation: "existing_client_recommendation",
  existingClientEnquiry: "existing_client_enquiry",
  introducerReferral: "introducer_referral",
  nonIntroducerReferral: "non_introducer_referral",
  internet: "internet",
  wordOfMouth: "word_of_mouth",
  other: "other"
})

export const requirementProductActionType = Object.freeze({
  application: "application",
  recommendation: "recommendation"
})

export const nicotineUse = Object.freeze({
  currentSmoker: "current_smoker",
  currentUserOfNicotine: "current_user_of_nicotine_products",
  previouslySmoked: "previously_smoked_or_used_nicotine_products",
  neverSmoked: "never_smoked_or_used_nicotine_products"
})

export const providerType = Object.freeze({
  mortgage: "mortgage_lender",
  protection: "protection_provider",
  generalInsurance: "general_insurance_provider"
})

export const complianceReviewWorkflowStatus = Object.freeze({
  awaitingCheck: "awaiting_check",
  inProgress: "in_progress",
  completed: "completed",
  awaitingAdvisorAction: "awaiting_advisor_action",
  cancelled: "cancelled"
})

export const employmentIncomeType = Object.freeze({
  overtime: "overtime",
  bonus: "bonus",
  commission: "commission"
})
