import Vue from "vue"
import Vuex from "vuex"
import { AdvisorStore } from "@/plugins/components-plugin/entry.js"

Vue.use(Vuex)

// Load store modules dynamically.
const requireContext = require.context("./modules", false, /.*\.js$/)

const modules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true
    }

    return { ...modules, [name]: module }
  }, {})

export default new Vuex.Store({
  // Left commented out by default as it slows down local development (delays actions being able to be taken when the page loads)
  // likely due to how much data we have in the store eg. all the dd fields
  // Link to the docs explaining its use: https://vuex.vuejs.org/guide/strict.html
  //strict: process.env.NODE_ENV === "development",
  modules,
  AdvisorStore
})
