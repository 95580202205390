import Swal from "sweetalert2" //https://sweetalert2.github.io/#usage
import sanitizeHtml from "sanitize-html"

export default class MbModal {
  constructor() {
    this.alertQueue = []
  }

  register() {
    window.confirmAsync = async (message) => {
      return await this.push({
        title: message,
        showCancelButton: true
      })
    }
    window.alertAsync = async (message) => {
      return await this.push({
        title: message,
        showCancelButton: false
      })
    }
    window.showPopupAsync = async (config) => {
      if (typeof config === "string") {
        return await window.confirmAsync(config)
      } else {
        return await this.push(config)
      }
    }
    //TODO POC code, move to mb-common-ui with css from main.css
    window.showToast = (message, icon = "error", maxSize = 100) => {
      if (message) {
        if (typeof message !== "string") {
          message = JSON.stringify(message)
        }
        if (message.length > maxSize) {
          //sweetalert2 also does its own truncating,
          //so ours may not always be used
          let suffix = "..."
          message = message.substring(0, maxSize - suffix.length) + suffix
        }
      }
      //console.info("Generating toast: " + message)//this is useful when testing word-break
      let cfg = {
        toast: true,
        position: "top-right",
        iconColor: "white",
        customClass: {
          popup: "colored-toast",
          closeButton: "align-self-start"
        },
        showConfirmButton: false,
        showCancelButton: false,
        timer: 5000,
        timerProgressBar: true
      }
      cfg.title = message
      cfg.icon = icon

      window.showPopupAsync(cfg)
    }

    this.processTick()
  }

  sanitise(args) {
    let toSanitise = ["title", "titleText", "text", "html", "footer"]
    toSanitise.forEach((e) => {
      if (args[e]) {
        args[e] = sanitizeHtml(stringifyNonStrings(args[e]))
        if (args[e]) {
          let bearerIndex = args[e].indexOf("Bearer")
          if (bearerIndex > 0) {
            args[e] = args[e].substring(0, bearerIndex)
          }
        }
      }
    })
    return args
  }

  ensurePopupIsPopulated(cfg) {
    let found = null
    let expectedProps = ["title", "titleText", "text", "html"] //at least one of these must be provided
    expectedProps.forEach((element) => {
      const value = cfg[element]
      if (value) {
        found = true
      }
    })

    if (found) return true

    //if the popup does not contain sufficient information, we should throw an error
    //this is so that the user is not accepting/rejecting an erroneous popup
    let msg = `Unable to generate popup. Reason: at least one of '[${expectedProps.toString()}]' must be provided`
    console.error(msg)
    throw `msg`
  }

  /**
   * https://sweetalert2.github.io/#configuration
   *
   * @param {PopupConfig} cfg
   * @returns Promise - boolean (unless cfg.getSwalResponse is true)
   */
  push(cfg) {
    cfg = JSON.parse(JSON.stringify(cfg)) // clone so we don't edit original object
    this.ensurePopupIsPopulated(cfg)
    cfg = this.sanitise(cfg) //sanitise (called last)

    let args = {
      showCloseButton: true,
      allowOutsideClick: true,
      showCancelButton: true,
      reverseButtons: true,
      allowEnterKey: true, //todo not working cb 1171
      confirmButtonText: "Proceed",
      customClass: {
        popup: "popup-default-height popup-default-width",
        confirmButton: "btn btn-primary fw-bold col-md-5",
        cancelButton: "btn btn-outline-secondary fw-bold col-md-5",
        actions: "w-100 d-flex justify-content-center px-5 gap-3",
        title: "popup-title-size m-3",
        htmlContainer: "popup-text-size popup-z-index popup-break-text",
        container: "popup-text-size popup-z-index popup-break-text"
      },
      buttonsStyling: false
    }
    if (cfg.toast) {
      //remove these from default config if toast as they are unsupported.
      delete args.allowOutsideClick
      delete args.allowEnterKey
      delete args.backdrop
    }

    if (cfg.customClass) {
      //handle nested override
      Object.assign(args.customClass, cfg.customClass)
      delete cfg.customClass
    }
    Object.assign(args, cfg) //override args with provided args

    if (args.allowOutsideClick) args.backdrop = true //required
    let obj = { args: args }
    let promise = new Promise((resolve, reject) => {
      obj.resolve = resolve
      obj.reject = reject
    })

    this.alertQueue.push(obj)
    return promise
  }
  async handle(obj) {
    let response = await Swal.fire(obj.args)
    let resolveValue = response.isConfirmed

    if (obj.args.getSwalResponse) {
      resolveValue = response
    }

    obj.resolve(resolveValue)
  }
  async processTick() {
    try {
      if (this.alertQueue.length > 0) {
        let obj = this.alertQueue[0]
        await this.handle(obj)
        this.alertQueue.splice(0, 1)
      }
    } catch (err) {
      console.error("Error processing alert queue")
    } finally {
      setTimeout(() => this.processTick(), 300)
    }
  }
}
function stringifyNonStrings(val) {
  if (val === null || val === undefined) {
    val = `${val}`
  }
  if (typeof val === "string") {
    return val
  }
  return JSON.stringify(val)
}
