import * as jsonLogic from "json-logic-js/logic.js"

/**
 * @param {Object} rules The rules to validate against
 * @param {Object} data An object to validate
 * @param {Boolean} deep Set true all nested objects will be validated
 * @returns Boolean
 */
export function validate(rules, data, deep = false) {
  for (let field in data) {
    let valid = _validate(rules, data, field, data)

    if (!valid) return false
    if (!deep || data[field] == null) continue

    if (typeof data[field] === "object") {
      valid = validateNested(rules, data[field], field, data)
    }

    if (!valid) return false
  }
  return true
}

function validateNested(rules, data, outerKey, outerData) {
  for (let field in data) {
    let valid = _validate(rules, data, field, outerData)

    if (!valid) return false
    if (data[field] == null) continue

    if (typeof data[field] === "object") {
      const _outerData = data instanceof Array ? outerData : data
      valid = validateNested(rules, data[field], field, _outerData)
    } else if (data instanceof Array) {
      const arrayData = { [outerKey]: data[field] }
      valid = validateNested(rules, arrayData, outerKey, outerData)
    }

    if (!valid) return false
  }

  return true
}

function _validate(rules, data, field, outerData) {
  const rule = rules[field]
  if (!rule?.required) return true

  const required = rule.condition
    ? jsonLogic.apply(rule.condition, outerData) ||
      jsonLogic.apply(rule.condition, data)
    : true
  const invalid = data[field] == null || data[field] === ""

  if (required && invalid) {
    return false
  }

  return true
}

/**
 * @param {Object} rules The rules to validate against
 * @param {Array} array Containing objects to validate
 * @returns Boolean
 */
export function validateMany(rules, array) {
  return array.every((data) => validate(rules, data))
}

/**
 * @param {Function} predicate (field) => Boolean
 * @param {Array} fields The fields to create the rules from
 * @param {Object} conditions And object containing conditions to be used when validating
 * @returns {Object} Object containing the rules
 */
export function createValidationRules(predicate, fields, conditions) {
  const rules = {}
  const _conditions = conditions ?? {}

  fields.forEach((field) => {
    const { alias, name } = typeof field === "object" ? field : { name: field }
    rules[alias ?? name] = {
      required: predicate(name),
      condition: _conditions[alias ?? name]
    }
  })

  return rules
}

jsonLogic.add_operation("includes", function (value, arr) {
  return arr.includes(value)
})
