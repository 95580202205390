<template>
  <div class="d-flex flex-wrap gap-3 flex-row">
    <div>
      <label class="col-form-label fw-dark mt-2"
        >AVM {{ typeDisplayValue }} value</label
      >
      <mb-currency-input
        v-model="value"
        class="mt-2"
        :readonly="true"
        :field="{ validation: { required: false } }"
        :attrs="{ readonlyDefault: '-' }"
      />
    </div>
    <div style="border-right: 1px solid black"></div>
    <div>
      <label class="col-form-label fw-light mt-2">Confidence band</label>
      <div class="mt-2">
        <label class="col-form-label">{{ bandConfidence || "-" }}</label>
      </div>
    </div>
    <div>
      <label class="col-form-label fw-light mt-2">Low range</label>
      <mb-currency-input
        v-model="bandLower"
        class="mt-2"
        :readonly="true"
        :field="{ validation: { required: false } }"
        :attrs="{ readonlyDefault: '-' }"
      />
    </div>
    <div>
      <label class="col-form-label fw-light mt-2">High range</label>
      <mb-currency-input
        v-model="bandUpper"
        class="mt-2"
        :readonly="true"
        :field="{ validation: { required: false } }"
        :attrs="{ readonlyDefault: '-' }"
      />
    </div>
    <div>
      <label class="col-form-label fw-light mt-2">Valuation date</label>
      <div class="mt-2">
        <label class="col-form-label">{{
          formatDate(date, "--/--/----")
        }}</label>
      </div>
    </div>
    <div v-if="displayClientEstimate" class="d-flex flex-column">
      <label class="mt-2"
        ><mb-form-input
          v-model="clientEstimateVal"
          :guid="clientEstimateValueGuid"
          label-class="col-form-label fw-light"
          label
          :readonly="readonly"
          @input="$emit('update:clientEstimate', clientEstimateVal)"
        ></mb-form-input
      ></label>
    </div>
    <div v-if="displayClientEstimate" class="d-flex flex-column">
      <label class="mt-2"
        ><mb-form-input
          v-model="clientEstimateDateVal"
          :guid="clientEstimateDateGuid"
          label-class="col-form-label fw-light"
          label
          :readonly="readonly"
          @input="$emit('update:clientEstimateDate', clientEstimateDateVal)"
        ></mb-form-input
      ></label>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns"
import MbCurrencyInput from "@/plugins/components-plugin/lib-components/MbCurrencyInput"
export default {
  components: { MbCurrencyInput },
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: null
    },
    /**
     * readonly status of client estimate / date only.
     * Other values are always readonly as they are updated by AVM import not the user.
     */
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    date: {
      type: String,
      required: false,
      default: null
    },
    bandUpper: {
      type: [Number, String],
      required: false,
      default: null
    },
    bandLower: {
      type: [Number, String],
      required: false,
      default: null
    },
    bandConfidence: {
      type: String,
      required: false,
      default: null
    },
    dateFormat: {
      type: String,
      required: true
    },
    /**
     * Must be 'property' or 'rental'. Its important the correct value is used.
     */
    type: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return ["property", "rental"].includes(value)
      }
    },
    displayClientEstimate: {
      type: Boolean,
      required: false,
      default: false
    },
    clientEstimateDate: {
      type: String,
      required: false,
      default: null
    },
    clientEstimate: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ["update:clientEstimateDate", "update:clientEstimate"],
  data() {
    return {
      clientEstimateDateVal: null,
      clientEstimateVal: null
    }
  },
  computed: {
    typeDisplayValue() {
      return this.isRental ? "rental" : "property" //right now its identical, but this might change.
    },
    isRental() {
      return this.type === "rental"
    },
    clientEstimateDateGuid() {
      return this.isRental
        ? "q-98e108d0-9af0-49ad-9d98-9d2dd5854976"
        : "q-c11b5998-15a9-493c-8726-c6b4222182f7"
    },
    clientEstimateValueGuid() {
      return this.isRental
        ? "q-a5959c21-0d1f-462c-9531-5f298d2d494d"
        : "q-99832a60-5dab-4c2d-8756-2baaf29866cc"
    }
  },
  watch: {
    clientEstimateDate: function (newVal) {
      this.clientEstimateDateVal = newVal
    },
    clientEstimate: function (newVal) {
      this.clientEstimateVal = newVal
    }
  },
  methods: {
    formatDate(date, defaultValue) {
      let result = defaultValue
      if (date) {
        result = format(new Date(date), this.dateFormat)
      }
      return result
    }
  }
}
</script>
