import axios from "axios"
import { Mutex } from "async-mutex"

export const state = {
  data: [],
  loadClientsMutex: new Mutex(),
  loadClientsAxiosController: null
}
function abortLoadClientsAxiosController(state) {
  if (state.loadClientsAxiosController) {
    try {
      state.loadClientsAxiosController.abort("Operation cancelled by the user.")
    } catch (ex) {
      console.error(ex)
    }
  }
}
async function loadClientsWithMutexAsync(state, url) {
  let request = state.loadClientsMutex.runExclusive(async () => {
    abortLoadClientsAxiosController(state)
    state.loadClientsAxiosController = new AbortController()
    return axios.get(url, {
      signal: state.loadClientsAxiosController.signal
    }) //no await, return the raw promise.
  }) //end mutex
  return await request
}

export const getters = {
  data: (state) => state.data
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async loadClients(
    { state },
    { pageNumber, perPage, filter, sortBy, descending }
  ) {
    let clientsCount = null
    let url = filter
      ? `${process.env.VUE_APP_CDS_URL}/clients/list?PageNumber=${pageNumber}&ItemsPerPage=${perPage}&FilterText=${filter}&SortColumn=${sortBy}&SortDescending=${descending}`
      : `${process.env.VUE_APP_CDS_URL}/clients/list?PageNumber=${pageNumber}&ItemsPerPage=${perPage}&SortColumn=${sortBy}&SortDescending=${descending}`
    let response = await loadClientsWithMutexAsync(state, url)
    if (response === undefined) {
      //response was canceled
      return state.data?.length ?? 0
    }
    if (response.status === 200) {
      clientsCount = response.data.total_count
      state.data = response.data.data.map((client) => mapClientForList(client))
    }
    return clientsCount
  }
}

function getFullName(client) {
  let names = [client.first_name, client.last_name]
  return names.filter((e) => e).join(" ")
}

function getFirstInitialSurname(client) {
  var names = [client.first_name?.charAt(0), client.last_name]
  return names.filter((e) => e).join(" ")
}

function mapClientForList(client) {
  return {
    id: client.id,
    first_name: client.first_name,
    surname: client.last_name,
    full_name: getFullName(client),
    first_initial_surname: getFirstInitialSurname(client),
    birth_date: client.birth_date,
    age: client.age,
    address: client.address,
    phone: client.phone_number,
    email: client.email_address,
    status: client.status
  }
}
