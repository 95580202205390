<template>
  <div id="mb-user-dropdown" class="dropdown d-inline-block">
    <button
      id="page-header-user-dropdown"
      type="button"
      class="btn header-item waves-effect"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        class="rounded-circle header-profile-user"
        :src="avatar"
        alt="Header Avatar"
      />
      <span key="t-henry" class="d-none d-xl-inline-block ms-1">{{
        username
      }}</span>
      <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end">
      <!-- item-->
      <a class="dropdown-item" href="#" @click="show_modal = true"
        ><i class="bx bx-user font-size-16 align-middle me-1"></i>
        <span key="t-profile">Profile</span></a
      >
      <!-- <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="#">Change Password</a> -->
      <div class="dropdown-divider"></div>
      <a class="dropdown-item text-danger logout-link" @click="logout"
        ><i
          class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
        ></i>
        <span key="t-logout">Logout</span></a
      >
    </div>
    <b-modal v-model="show_modal" title="My Profile" ok-only scrollable>
      <div v-if="profile">
        <p><strong>Firm:</strong> {{ profile.account.name }}</p>
        <p><strong>First name:</strong> {{ profile.first_name }}</p>
        <p><strong>Surname:</strong> {{ profile.last_name }}</p>
        <p><strong>Email:</strong> {{ profile.email }}</p>
        <p v-if="profile.contacts && profile.contacts.length">
          <strong>Phone Numbers:</strong>
          {{
            profile.contacts.map((contact) => contact.phone_number).join(", ")
          }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "MbUserDropdown",
  components: {},
  data() {
    return {
      show_modal: false
    }
  },
  computed: {
    avatar() {
      if (
        !this.$auth ||
        !this.$auth.user ||
        process.env.VUE_APP_AUTH_ENABLED == "false"
      ) {
        return "http://skote.vuejs-light.themesbrand.com/img/avatar-1.67e2b9d7.jpg"
      }
      return this.$auth.user.picture
    },
    username() {
      if (
        !this.$auth ||
        !this.$auth.user ||
        process.env.VUE_APP_AUTH_ENABLED == "false"
      ) {
        return false
      }
      return this.$auth.user.name
    },
    profile() {
      if (this.$mbuser.isLoading) return false

      return this.$mbuser.user
    }
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>

<style scoped>
a.logout-link:hover {
  cursor: pointer;
}
</style>
