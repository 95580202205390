import axios from "axios"
import { formatClientNamesForList } from "@/global/modules/helpers/clientHelpers"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"

export const state = {
  clients: [],
  allSavings: [],
  selectedClients: [],
  selectedSavings: {},
  selectedStatus: "",
  selectedType: "",
  isNew: true,
  savingsInsights: {},
  excludedClientsFromSelectionList: []
}

export const mutations = {
  clearSavings(state) {
    state.allSavings = []
  },
  addToAllSavings(state, data) {
    state.allSavings.push(data)
  },
  setSelectedClientAndStatusAndType(state, data) {
    state.isNew = true
    state.selectedClients = data.clients
    state.selectedStatus = data.status
    state.selectedType = data.type
  },
  setSaving(state, data) {
    state.selectedSavings = data
  },
  setSavingsInsights(state, data) {
    state.savingsInsights = data
  },
  setExcludedClientsFromSelectionList(state, data) {
    state.excludedClientsFromSelectionList = data
  }
}
export const actions = {
  async loadSavings(context) {
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    const clientIds = context.rootGetters["clientFile/selectedClients"].map(
      (client) => client.id
    )
    await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientsavings/getforclientids${snapshotIdQuery}`,
        clientIds
      )
      .then((res) => {
        context.commit("clearSavings")
        res.data.forEach((saving) => {
          mapSavingsForList(
            saving,
            context.rootGetters["clientFile/clients"],
            context
          )
        })
        context.dispatch("calcInsights")
      })
      .catch(() => context.commit("clearSavings"))
  },
  async addUpdateSavings({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientsavings/createorupdate`,
        [payload]
      )
      if (response.status === 200) {
        await dispatch("loadSavings")
        let ids = response.data.map((e) => e.id)
        return ids
      }
    } catch (err) {
      console.log(err)
    }
    return false
  },
  async fetchSaving({ commit, state }, payload) {
    //init variables
    let mainItem = {}
    let itemToEdit = {}

    state.isNew = false

    // search for the saving record
    mainItem = state.allSavings.find((c) => {
      return c.id === payload.id
    })

    itemToEdit = {
      ...mainItem,

      //type: mainItem.type.toLowerCase(),
      type: mainItem.savings_plan_type_key,
      who: mainItem.who,
      balance_history: mainItem.balance_history.sort(sortDateBA)
    }

    commit("setSaving", itemToEdit)
  },
  calcInsights(context) {
    let monthlyContributions = 0
    let savings = 0

    /*   context.state.allSavings.forEach((x) => {
      if (x.monthly_payment) monthlyContributions += x.monthly_payment
    }) */

    context.state.allSavings.forEach((x) => {
      //balance is a calculated property - not to be consused with .balance_history.savings_balance_amount
      if (x.balance) savings += x.balance
    })

    context.commit("setSavingsInsights", {
      TotalMonthlyContributions: monthlyContributions, //DDV2 - set to 0 as monthly_payment is being removed.
      TotalSavings: savings
    })
  },
  async deleteSavings({ dispatch }, payload) {
    // set delete properties
    payload.is_deleted = true
    payload.deleted_at = new Date()

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientsavings/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        dispatch("loadSavings")
      }
    } catch (err) {
      console.log(err)
    }
  },
  setSelectedClientAndStatusAndType(context, payload) {
    context.commit("setSelectedClientAndStatusAndType", payload)
  }
}

export const getters = {
  allSavings(state) {
    return state.allSavings
  },
  selectedSavings(state) {
    return state.selectedSavings
  },
  isAddMode(state) {
    return state.isNew
  },
  selectedClients(state) {
    return state.selectedClients
  },
  selectedStatus(state) {
    return state.selectedStatus
  },
  selectedType(state) {
    return state.selectedType
  },
  savingsInsights(state) {
    return state.savingsInsights
  },
  excludedClientsFromSelectionList: (state) =>
    state.excludedClientsFromSelectionList,
  validation: (state, rootState, getters, rootGetters) => {
    return {
      list: {
        ...mapRulesForList(rootGetters)
      },
      savings: {
        ...mapRulesForSavings(rootGetters)
      }
    }
  }
}

function mapRulesForList(rootGetters) {
  const fields = ["kyc_client_has_saving_investments"]
  return mapRules(rootGetters, "kyc_savings_investments", fields)
}

function mapRulesForSavings(rootGetters) {
  const fields = [
    "savings_plan_type_key",
    "savings_account_status_key",
    "savings_provider_name",
    "savings_purpose_key",
    "savings_notes",
    "has_savings_contributions",
    "savings_contribution_amount",
    "savings_contribution_frequency_key",
    "savings_balance_amount",
    "savings_balance_date",
    "savings_contribution_future_intention_recorded_date",
    "is_savings_fund_withdrawal_intended",
    "savings_fund_withdrawal_revised_balance_amount",
    "is_savings_contribution_change_intended",
    "savings_contribution_revised_amount",
    "savings_contribution_revised_frequency_key"
  ]

  const contributionCondition = {
    "==": [{ var: "has_savings_contributions" }, true]
  }

  const conditions = {
    savings_contribution_amount: contributionCondition,
    savings_contribution_frequency_key: contributionCondition
  }

  return mapRules(rootGetters, "savings", fields, conditions)
}

function mapRules(rootGetters, subSection, fields, conditions) {
  const factFind = rootGetters["caseSectionValidation/factfind"]

  return createValidationRules(
    (field) => factFind.required("savings_and_investments", subSection, field),
    fields,
    conditions
  )
}

function mapSavingsForList(savingsData, clients, context) {
  savingsData.who = formatClientNamesForList(
    savingsData.linked_clients,
    clients
  )

  // add to the allSavings collection
  let balanceObj = getBalance(savingsData.balance_history)
  let saving = {
    ...savingsData,
    balance: balanceObj.savings_balance_amount,
    balanceDate: balanceObj.savings_balance_date,
    completed:
      savingsData.current_record_validation
        ?.is_record_section_validation_result_valid ?? false
  }

  context.commit("addToAllSavings", saving)
}

function sortDateAB(a, b) {
  var dateA = new Date(a.savings_balance_date).getTime()
  var dateB = new Date(b.savings_balance_date).getTime()
  return dateA > dateB ? 1 : -1
}
function sortDateBA(a, b) {
  var dateA = new Date(a.savings_balance_date).getTime()
  var dateB = new Date(b.savings_balance_date).getTime()
  return dateB > dateA ? 1 : -1
}

function getBalance(balanceArray) {
  if (balanceArray.length === 0)
    return { savings_balance_amount: 0, savings_balance_date: null }
  // sort by balance date to get the most recent
  balanceArray.sort(sortDateAB)
  return balanceArray[balanceArray.length - 1]
}
