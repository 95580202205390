import axios from "axios"
import moment from "moment"

export const state = {
  selectedClients: [],
  allBudgets: [],
  selectedBudget: {},
  mortgagePayments: [],
  insurancePayments: [],
  commitmentPayments: [],
  isNew: true,
  budgetDescription: "",
  errorForDisplay: ""
}
export const mutations = {
  clearBudgetList(state) {
    state.allBudgets = []
  },
  clearExpenditures(state) {
    state.mortgagePayments = []
    state.insurancePayments = []
    state.commitmentPayments = []
  },
  addBudgetToList(state, data) {
    state.allBudgets.push(data)
  },
  addSelectedClientAndDesciption(state, data) {
    state.isNew = true
    state.selectedClients = data.clients
    state.budgetDescription = data.description
  },
  addMortgageForList(state, data) {
    state.mortgagePayments.push(data)
  },
  addInsuranceForList(state, data) {
    state.insurancePayments.push(data)
  },
  addCommitmentForList(state, data) {
    state.commitmentPayments.push(data)
  },
  setSelectedBudget(state, data) {
    state.isNew = false
    state.selectedBudget = data
    state.selectedClients = data.linked_clients
  }
}
export const getters = {
  isAddMode: (state) => state.isNew,
  budgetList: (state) => state.allBudgets,
  selectedClients: (state) => state.selectedClients,
  selectedBudget: (state) => state.selectedBudget,
  budgetDescription: (state) => state.budgetDescription,
  mortgagePayments: (state) => state.mortgagePayments,
  insurancePayments: (state) => state.insurancePayments,
  commitmentPayments: (state) => state.commitmentPayments
}
export const actions = {
  fetchBudgetForList(context) {
    context.commit("clearBudgetList")
    const currentCase = context.rootGetters["cases/currentCase"]

    if (currentCase.fact_find.budget_planner !== null) {
      const clients = context.rootGetters["clientFile/selectedClients"]

      const budgetList = JSON.parse(
        JSON.stringify(currentCase.fact_find.budget_planner)
      )

      budgetList.forEach((budget) => {
        budget.who = clients
          .filter((client) => budget.linked_clients.includes(client.id))
          .reduce((names, client) => {
            names.push(client.fullname)
            return names
          }, [])
          .join(" & ")
        budget.completed =
          budget.completed === null
            ? null
            : moment(budget.completed).format("DD/MM/YYYY")
        context.commit("addBudgetToList", budget)
      })
    }
  },
  fetchBudget(context, payload) {
    const currentCase = context.rootGetters["cases/currentCase"]

    const budget = currentCase.fact_find.budget_planner.find((b) => {
      return b.id === payload
    })

    if (budget !== null) {
      mapBudgetForSelectedBudget(budget, context)
    }
  },
  storeSelectedClientAndDescription(context, payload) {
    context.commit("addSelectedClientAndDesciption", payload)
  },
  async loadOtherExpenditures(context) {
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]
    context.commit("clearExpenditures")

    const requestMortgages = axios.post(
      `${process.env.VUE_APP_CDS_URL}/clientmortgages/getforclientids${snapshotIdQuery}`,
      state.selectedClients
    )
    const requestInsurances = axios.post(
      `${process.env.VUE_APP_CDS_URL}/clientpolicies/getforclientids${snapshotIdQuery}`,
      state.selectedClients
    )
    const requestCommitments = axios.post(
      `${process.env.VUE_APP_CDS_URL}/clientcommitments/getforclientids${snapshotIdQuery}`,
      state.selectedClients
    )

    return await axios
      .all([requestMortgages, requestInsurances, requestCommitments])
      .then(
        axios.spread((...responses) => {
          const responseMortgages = responses[0].data
          const responseInsurances = responses[1].data
          const responseCommitments = responses[2].data

          // use the results
          responseMortgages.forEach((mortgageData) => {
            mapMortgageForList(mortgageData, context)
          })

          responseInsurances.forEach((insuranceData) => {
            mapInsuranceForList(insuranceData, context)
          })

          responseCommitments.forEach((commitmentData) => {
            mapCommitmentForList(commitmentData, context)
          })
        })
      )
      .catch((err) => console.log(err))
  }
}

function mapMortgageForList(mortgageData, context) {
  const clients = context.rootGetters["clientFile/selectedClients"]

  mortgageData.who = clients
    .filter((client) => mortgageData.linked_clients.includes(client.id))
    .reduce((names, client) => {
      names.push(client.fullname)
      return names
    }, [])
    .join(", ")

  //use single_monthly_payment first
  const mortgage = {
    type: mortgageData.mortgage_occupancy_type_key,
    monthly_payment: mortgageData.mortgage_monthly_payment_amount,
    who: mortgageData.who
  }
  context.commit("addMortgageForList", mortgage)
}

function mapInsuranceForList(insuranceData, context) {
  const clients = context.rootGetters["clientFile/selectedClients"]

  insuranceData.who = clients
    .filter((client) => insuranceData.linked_clients.includes(client.id))
    .reduce((names, client) => {
      names.push(client.fullname)
      return names
    }, [])
    .join(", ")

  let insurance

  switch (insuranceData.policy_type_key) {
    case "protection":
      insurance = {
        type: insuranceData.policy_type_key,
        monthly_payment:
          insuranceData.protection_details.policy_account_premium_total_amount,
        who: insuranceData.who
      }
      break
    case "buildings_contents":
      insurance = {
        type: insuranceData.policy_type_key,
        monthly_payment:
          insuranceData.buildings_contents_details
            .policy_account_premium_total_amount > 0
            ? calcMonthlyPayment(
                insuranceData.buildings_contents_details
                  .policy_account_premium_frequency_key,
                insuranceData.buildings_contents_details
                  .policy_account_premium_total_amount
              )
            : 0,
        who: insuranceData.who
      }
      break
    case "asu":
      insurance = {
        type: insuranceData.policy_type_key,
        monthly_payment:
          insuranceData.asu_details.policy_account_premium_total_amount > 0
            ? calcMonthlyPayment(
                insuranceData.asu_details.policy_account_premium_frequency_key,
                insuranceData.asu_details.policy_account_premium_total_amount
              )
            : 0,
        who: insuranceData.who
      }
      break
    default:
      break
  }
  context.commit("addInsuranceForList", insurance)
}

function mapCommitmentForList(commitmentData, context) {
  const clients = context.rootGetters["clientFile/selectedClients"]

  commitmentData.who = clients
    .filter((client) => commitmentData.linked_clients.includes(client.id))
    .reduce((names, client) => {
      names.push(client.fullname)
      return names
    }, [])
    .join(", ")

  let commitment = null

  switch (commitmentData.commitment_type_key) {
    case "hire_purchase":
      commitment = {
        type: commitmentData.commitment_type_key,
        current_payment:
          commitmentData.hp_details.commitment_monthly_repayment_amount,
        future_payment: commitmentData.hp_details
          .commitment_future_intention_key
          ? commitmentData.hp_details.commitment_monthly_repayment_amount
          : 0,
        who: commitmentData.who
      }
      break
    case "unsecured_loan":
      commitment = {
        type: commitmentData.commitment_type_key,
        current_payment:
          commitmentData.unsecured_loan_details
            .commitment_monthly_repayment_amount,
        future_payment: commitmentData.unsecured_loan_details
          .commitment_future_intention_key
          ? commitmentData.unsecured_loan_details
              .commitment_monthly_repayment_amount
          : 0,
        who: commitmentData.who
      }
      break
    case "personal_contract_purchase":
      commitment = {
        type: commitmentData.commitment_type_key,
        current_payment:
          commitmentData.pcp_details.commitment_monthly_repayment_amount,
        future_payment: commitmentData.pcp_details
          .commitment_future_intention_key
          ? commitmentData.pcp_details.commitment_monthly_repayment_amount
          : 0,
        who: commitmentData.who
      }
      break
    case "lease_agreement":
      commitment = {
        type: commitmentData.commitment_type_key,
        current_payment:
          commitmentData.lease_details.commitment_monthly_repayment_amount,
        future_payment: commitmentData.lease_details
          .commitment_future_intention_key
          ? commitmentData.lease_details.commitment_monthly_repayment_amount
          : 0,
        who: commitmentData.who
      }
      break
    default:
      break
  }

  if (commitment !== null) context.commit("addCommitmentForList", commitment)
}

function mapBudgetForSelectedBudget(budget, context) {
  const formattedBudget = { ...budget }

  let expenditures = {
    general: [],
    mortgage: [],
    insurance: [],
    commitment: []
  }

  // general
  expenditures.general = formattedBudget.expenditures.filter((item) => {
    if (item.group === "general") {
      return true
    }
    return false
  })

  // mortgage
  expenditures.mortgage = formattedBudget.expenditures.filter((item) => {
    if (item.group === "mortgage") {
      return true
    }
    return false
  })

  // insurance
  expenditures.insurance = formattedBudget.expenditures.filter((item) => {
    if (item.group === "insurance") {
      return true
    }
    return false
  })

  // commitment
  expenditures.commitment = formattedBudget.expenditures.filter((item) => {
    if (item.group === "commitment") {
      return true
    }
    return false
  })

  // delete array and replace with an object as the component expects an object
  delete formattedBudget.expenditures
  formattedBudget.expenditures = expenditures

  context.commit("setSelectedBudget", formattedBudget)
}

function calcMonthlyPayment(frequency, premium) {
  let monthlyAmount = 0
  switch (frequency) {
    case "weekly":
      monthlyAmount = (premium * 52) / 12
      break
    case "annually":
      monthlyAmount = premium / 12
      break
    default:
      monthlyAmount = premium
      break
  }

  const hasDecimal = monthlyAmount - Math.floor(monthlyAmount) !== 0

  return hasDecimal ? parseFloat(monthlyAmount.toFixed(2)) : monthlyAmount
}
