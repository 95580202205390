<template>
  <div v-if="!config.isEmpty" class="form-input-group">
    <!-- eslint-disable vue/no-v-html -->
    <label
      v-if="useStandardLabelComponents && showLabel"
      :for="inputIdentifier"
      :class="labelClass"
      v-html="labelText"
    >
    </label>
    <mb-tool-tip
      v-if="useStandardLabelComponents && tooltipTextToDisplay"
      class="ms-1"
      :custom-class="'client-header-info'"
      :data="tooltipTextToDisplay"
    />
    <validation-provider
      v-if="!labelOnly"
      v-slot="{ errors }"
      :rules="validationRules"
      :vid="validationId"
      :name="config.label"
    >
      <mb-date-input
        v-if="isDateInput"
        :id="inputIdentifier"
        v-model="inputValue"
        :readonly="readonly"
        :field="config"
        :class="[{ updated: isValUpdated }, inputClass]"
        :suffix="$vnode.key"
        :data-cy="formId"
        :attrs="attrs"
        :data-initial-val="finalInitialData"
        :highlight-as-required="highlightAsIncomplete || !!errors[0]"
        @keyup="isValUpdated"
        @userupdated="(e) => $emit('userupdated', e)"
      />

      <mb-checkbox-input
        v-else-if="isCheckboxInput"
        v-model="inputValue"
        :readonly="readonly"
        :field="config"
        :suffix="$vnode.key"
        :data-cy="formId"
        :attrs="attrs"
        :data-initial-val="finalInitialData"
        :class="[{ updated: isValUpdated }, inputClass]"
        :tooltip="tooltipTextToDisplay"
        :label-text="labelText"
        @keyup="isValUpdated"
        @userupdated="(e) => $emit('userupdated', e)"
      />

      <mb-radio-group-input
        v-else-if="isRadioGroupInput"
        :id="inputIdentifier"
        v-model="inputValue"
        :readonly="readonly"
        :field="config"
        :data-cy="formId"
        :input-label-class="inputClass"
        :highlight-as-required="highlightAsIncomplete || !!errors[0]"
        @userupdated="(e) => $emit('userupdated', e)"
      />

      <mb-select-input
        v-else-if="isSelectInput"
        :id="inputIdentifier"
        v-model="inputValue"
        :disabled="readonly"
        :prepop-options="selectoptions"
        :field="config"
        :suffix="$vnode.key"
        :readonly="readonly"
        :data-cy="formId"
        :attrs="attrs"
        :data-initial-val="finalInitialData"
        :class="[{ updated: isValUpdated }, inputClass]"
        :highlight-as-required="highlightAsIncomplete || !!errors[0]"
        @keyup="isValUpdated"
        @userupdated="(e) => $emit('userupdated', e)"
      />

      <mb-currency-input
        v-else-if="isCurrencyInput"
        :id="inputIdentifier"
        v-model="inputValue"
        :options="{
          currency: 'GBP',
          currencyDisplay: 'hidden',
          hideGroupingSeparatorOnFocus: false,
          hideNegligibleDecimalDigitsOnFocus: false
        }"
        :field="config"
        :readonly="readonly"
        :suffix="$vnode.key"
        :attrs="attrs"
        :class="[{ updated: isValUpdated }, inputClass]"
        :data-initial-val="finalInitialData"
        :highlight-as-required="highlightAsIncomplete || !!errors[0]"
        @keyup="isValUpdated"
        @userupdated="(e) => $emit('userupdated', e)"
      />

      <mb-number-input
        v-else-if="isNumberInput"
        :id="inputIdentifier"
        v-model="inputValue"
        :field="config"
        :readonly="readonly"
        :class="[{ updated: isValUpdated }, inputClass]"
        :suffix="$vnode.key"
        :data-cy="formId"
        :attrs="attrs"
        :data-initial-val="finalInitialData"
        :highlight-as-required="highlightAsIncomplete || !!errors[0]"
        @keyup="isValUpdated"
        @userupdated="(e) => $emit('userupdated', e)"
      />

      <mb-text-area-input
        v-else-if="isTextAreaInput"
        :id="inputIdentifier"
        v-model="inputValue"
        :field="config"
        :class="[{ updated: isValUpdated }, inputClass]"
        :suffix="$vnode.key"
        :data-cy="formId"
        :attrs="attrs"
        :data-initial-val="finalInitialData"
        :highlight-as-required="highlightAsIncomplete || !!errors[0]"
        :readonly="readonly"
        @keyup="isValUpdated"
        @userupdated="(e) => $emit('userupdated', e)"
      />

      <mb-text-input
        v-else-if="isTextInput"
        :id="inputIdentifier"
        v-model="inputValue"
        :field="config"
        :readonly="readonly"
        :class="[{ updated: isValUpdated }, inputClass]"
        :suffix="$vnode.key"
        :data-cy="formId"
        :attrs="computedAttrs"
        :data-initial-val="finalInitialData"
        :highlight-as-required="highlightAsIncomplete || !!errors[0]"
        @keyup="isValUpdated"
        @userupdated="(e) => $emit('userupdated', e)"
      />
      <small v-if="noLabelFormIdText">{{ noLabelFormIdText }}</small>
      <span class="text-danger">{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate"
import { mapGetters } from "vuex"
import MbDateInput from "./MbDateInput"
import MbCheckboxInput from "./MbCheckboxInput"
import MbTextInput from "./MbTextInput"
import MbTextAreaInput from "./MbTextAreaInput"
import MbSelectInput from "./MbSelectInput"
import MbCurrencyInput from "./MbCurrencyInput"
import MbNumberInput from "./MbNumberInput"
import MbRadioGroupInput from "./MbRadioGroupInput"
import MbToolTip from "./MbToolTip"

export default {
  name: "MbFormInput",
  components: {
    ValidationProvider,
    MbDateInput,
    MbCheckboxInput,
    MbTextInput,
    MbSelectInput,
    MbCurrencyInput,
    MbNumberInput,
    MbTextAreaInput,
    MbRadioGroupInput,
    MbToolTip
  },
  props: {
    value: {
      type: [String, Number, Boolean, Array],
      default: null
    },
    /**
     * Set the label attribute to show the label
     *
     * <form-input label />
     */
    label: {
      type: Boolean,
      default: false
    },
    /**
     * Set the label-only attribute to show only the label
     *
     * <form-input label-only />
     */
    labelOnly: {
      type: Boolean,
      default: false
    },
    /**
     * Set the class for the label to change default styling
     */
    labelClass: {
      type: String,
      default: "col-form-label fw-light"
    },
    /**
     * Set the class for the input to change default styling
     */
    inputClass: {
      type: String,
      default: ""
    },
    /**
     * Set field as readonly
     * <form-input readonly />
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * Set field as plaintext
     *
     * Only applied to text-input's
     *
     * <form-input plaintext />
     */
    plaintext: {
      type: Boolean,
      default: false
    },
    /**
     * Load field config by frontend_name
     */
    name: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Load field config by form_id guid
     */
    guid: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Appends a unique ID to validation error id's
     * that can be used to identify which data object
     * the validation relates to.
     *
     * For example if done on a loop through clients
     * there each has the same field on the form. i.e First name
     * then you could set the client id as the validation suffix
     * so when validation errors are found they get displayed on the
     * correct First Name field.
     */
    vidSuffix: {
      type: String,
      required: false,
      default: null
    },
    selectoptions: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    /**
     * Allow ovveride of input attributes set via DD like min/max/placeholder
     *
     * <form-input :attrs="{placeholder: 'Mr Smith', ...}" />
     */
    attrs: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dataInitialVal: {
      type: [String, Number, Boolean],
      default: "N-A"
    },
    /**
     * Override label from data dictionary if needed.
     */
    customLabel: {
      type: String,
      required: false
    },
    isAddress: {
      type: Boolean,
      default: false
    },
    /**
     * Hides required asterisk.
     */
    hideAsterisk: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Hides tooltip
     */
    hideTooltip: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * dateValidation: {type: string, date: string, message: string, dateFormat: string}
     *
     * type refers to before, after, before_or_equal, after_or_equal
     * date is the date to be compared to
     * message is the error validation message
     * dateformat defaults to "YYYY-MM-DD"
     */
    dateValidation: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * highlightAsRequired: true | false
     * Specifies whether an input should show a red border as if required
     * but this does not effect validation or flag an input is invalid.
     */
    highlightAsRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: ["userupdated"],
  data() {
    return {
      inputValue: null,
      config: null
    }
  },
  computed: {
    ...mapGetters({
      dictionary: "datadictionary/fieldByFormName",
      altDictionary: "datadictionary/fieldByFormID"
    }),
    isValueEmpty() {
      return (
        this.value === undefined ||
        this.value === null ||
        (typeof this.value === "string" && this.value.trim() === "") ||
        (this.value instanceof Array && this.value.length === 0)
      )
    },
    highlightAsIncomplete() {
      return this.highlightAsRequired && this.isValueEmpty
    },
    tooltipTextToDisplay() {
      if (this.showTooltip) return this.config.tooltip
      return null
    },
    useStandardLabelComponents() {
      //some components implement their own label.
      //So we should not display label/tooltip when they do.
      //the inputs must display them directly.
      return !this.isCheckboxInput
    },
    showTooltip() {
      return Boolean(
        !this.hideAsterisk && this.config.tooltip && this.showLabel
      )
    },
    showLabel() {
      return Boolean(this.labelOnly || this.label || !this.labelText)
    },
    formId() {
      // If we are in a v-for loop we can access the :key property via Vue.$vnode.
      // When this is the case we can just append the key to the form id
      if (this.$vnode.key) {
        return `${this.config.form_id}-${this.$vnode.key}`
      }
      return this.config.form_id
    },
    validationId() {
      if (this.vidSuffix) {
        return `${this.config.form_id}:${this.vidSuffix}`
      }
      return this.config.form_id
    },
    labelText() {
      let rtn = this.customLabel ?? this.config.label
      if (this.config.validation.required && !this.hideAsterisk) {
        rtn = rtn + '<span class="text-danger"> *</span>'
      }
      if (window.localStorage.getItem("dev-mode") == "true") {
        rtn = rtn + ` <small>${this.config.form_id}</small>`
      }
      return rtn
    },
    noLabelFormIdText() {
      if (
        !this.showLabel &&
        window.localStorage.getItem("dev-mode") == "true"
      ) {
        return this.config.form_id
      }
      return null
    },
    isTextInput() {
      return this.config.input_type == "text"
    },
    isTextAreaInput() {
      return this.config.input_type == "textarea"
    },
    isCurrencyInput() {
      return this.config.input_type == "currency"
    },
    isNumberInput() {
      return this.config.input_type == "number"
    },
    isDateInput() {
      return this.config.input_type == "date"
    },
    isCheckboxInput() {
      return this.config.input_type == "checkbox"
    },
    isSelectInput() {
      return this.config.input_type == "select"
    },
    isRadioGroupInput() {
      return this.config.input_type == "radio_group"
    },
    validationRules() {
      let rules = []
      if (this.config.validation.required ?? false) {
        rules.push("required")
      }
      if (this.isDateInput && this.dateValidation) {
        let valRules = ["after", "after_or_equal", "before", "before_or_equal"]
        if (valRules.includes(this.dateValidation.type)) {
          rules.push(
            this.dateValidation.type +
              ":" +
              this.dateValidation.date +
              "," +
              this.dateValidation.dateFormat +
              "," +
              this.dateValidation.message
          )
        } else
          console.error(
            `date-validation object field: type: ${this.dateValidation.type} is not recognised.`
          )
      }

      // Todo process config.setting_validation to construct remaining rules required
      if (this.config.validation.rules) {
        rules.push(this.config.validation.rules)
      }

      return rules.join("|")
    },
    finalInitialData() {
      return this.checkAndTrimDate(this.dataInitialVal)
    },
    isValUpdated() {
      if (this.finalInitialData == "N-A") {
        return false
      }
      return this.finalInitialData != this.value && !this.readonly
    },
    inputIdentifier() {
      if (!this.isAddress) {
        return this.formId
      }
      return `${Date.now()}-${this.formId}`
    },
    // TODO: temp solution for https://mortgageengine.atlassian.net/browse/CB-1783 whil scripts rely on static input IDs
    computedAttrs() {
      if (!this.isAddress) {
        return this.attrs
      }
      return Object.assign({}, this.attrs, {
        autocomplete: this.inputIdentifier
      })
    }
  },
  watch: {
    value(value) {
      this.setInputValue(value)
    },
    inputValue(val) {
      this.$emit("input", val)
    },
    guid() {
      //if there is an issue with this, then we need to bind :key to each component.
      //Vue will reuse components to minimise DOM updates, so state needs to be re initalised
      this.init()
    },
    name() {
      this.init()
    }
  },
  beforeMount() {
    this.init()
  },
  methods: {
    init() {
      const emptyConfig = {
        isEmpty: true
      }
      this.config = emptyConfig
      if (this.guid) {
        this.config = this.altDictionary(this.guid) || emptyConfig
      } else if (this.name) {
        this.config = this.dictionary(this.name) || emptyConfig
      }
      if (this.config.isEmpty)
        console.error(
          `DD entry not found for guid:${this.guid} name:${this.name}`
        )

      this.setInputValue(this.value)
    },
    checkAndTrimDate(value) {
      if (value === "" && this.isDateInput) return null
      if (value !== null && this.isDateInput && value.indexOf("T") !== -1) {
        return value.split("T")[0]
      } else {
        return value
      }
    },
    /**
     * This should be used when setting InputValue from parent to apply default value
     */
    setInputValue(newVal) {
      let hasDefaultValue = this.config?.default_value !== undefined
      if (hasDefaultValue && (newVal === null || newVal === undefined)) {
        newVal = this.config.default_value
      }

      this.inputValue = this.checkAndTrimDate(newVal)
    }
  }
}
</script>
