import { authenticationGuard } from "../global/modules/middleware/checkauth"
import {
  chainGuards,
  storeGuards
} from "../global/modules/middleware/checkStore"
import { mapCaseRoutes } from "./clients/cases/case"
import { referrals } from "./referrals"

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "mainlayout" */ "../layouts/MainLayout.vue"),
    redirect: "/dashboard",
    beforeEnter: chainGuards([
      authenticationGuard,
      storeGuards.loadAllTeamMembers
    ]),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        beforeEnter: chainGuards([
          storeGuards.loadDashboardGuard,
          storeGuards.loadAdvisorsGuard,
          storeGuards.loadPrincipalsGuard
        ]),
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../pages/Dashboard.vue")
      },
      {
        path: "clients",
        name: "clients",
        component: () =>
          import(/* webpackChunkName: "clients" */ "../pages/Clients.vue"),
        meta: {}
      },
      {
        path: "case/:caseId([0-9a-fA-F]{24})",
        name: "case-without-client",
        component: () =>
          import(
            /* webpackChunkName: "client-file" */ "../pages/CaseWithoutClient.vue"
          )
      },
      {
        path: "case/:caseId([0-9a-fA-F]{24})/requirement/:requirementId([0-9a-fA-F]{24})",
        name: "base-requirement",
        beforeEnter: storeGuards.baseRequirementRouterGuard,
        component: () =>
          import(
            /* webpackChunkName: "base-requirement" */ "../pages/BaseRequirementRouter.vue"
          )
      },
      {
        path: "clients/:id([0-9a-fA-F]{24})",
        beforeEnter: chainGuards([
          storeGuards.loadConfigurationsGuard,
          storeGuards.loadProvidersGuard
        ]),
        name: "client",
        component: {
          render: (c) => c("router-view")
        },
        meta: {
          title: "Clients"
        },
        children: [
          {
            path: "clientfile",
            beforeEnter: chainGuards([
              storeGuards.loadClientGuard,
              storeGuards.clientAccessGuard,
              storeGuards.loadCompaniesGuard
            ]),
            name: "client-file",
            component: () =>
              import(
                /* webpackChunkName: "client-file" */ "../pages/clients/ClientFile.vue"
              ),
            meta: {
              title: "Client File"
            },
            children: [
              {
                path: "overview",
                beforeEnter: chainGuards([
                  storeGuards.loadAdvisorsGuard,
                  storeGuards.loadPrincipalsGuard
                ]),
                name: "client-overview",
                component: () =>
                  import(
                    /* webpackChunkName: "client-overview" */ "@/components/ux/clients/files/ClientOverview.vue"
                  ),
                meta: {
                  clientFileNavActive: true,
                  title: "Overview"
                }
              },
              {
                path: "companies",
                name: "associated-companies",
                component: () =>
                  import(
                    /* webpackChunkName: "associated-companies" */ "@/components/ux/clients/files/AssociatedCompanies.vue"
                  ),
                meta: {
                  clientFileNavActive: true,
                  title: "Companies"
                }
              },
              {
                path: "details",
                beforeEnter: chainGuards([
                  storeGuards.loadAdvisorsGuard,
                  storeGuards.loadPrincipalsGuard
                ]),
                name: "client-details",
                component: () =>
                  import(
                    /* webpackChunkName: "client-details" */ "@/components/ux/clients/files/PersonalDetails.vue"
                  ),
                meta: {
                  ////requiresAuthClient: true, removing as there is an issue here but we are looking into other methods anyway
                  clientFileNavActive: true,
                  title: "Personal"
                }
              },
              {
                path: "verification",
                beforeEnter: chainGuards([
                  storeGuards.loadAdvisorsGuard,
                  storeGuards.loadPrincipalsGuard
                ]),
                name: "client-verification",
                component: () =>
                  import("@/components/ux/clients/files/Verifications.vue"),
                children: [
                  {
                    path: "",
                    name: "client-idchecks",
                    component: () =>
                      import(
                        "@/components/ux/clients/files/VerificationList.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "ID Checks"
                    }
                  },
                  {
                    path: "add",
                    name: "client-idcheck-add",
                    component: () =>
                      import(
                        "@/components/ux/clients/files/VerificationDetails.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Add ID Check"
                    }
                  },
                  {
                    path: "edit/:idv_id([0-9a-fA-F]{24})",
                    name: "client-idcheck-edit",
                    component: () =>
                      import(
                        "@/components/ux/clients/files/VerificationDetails.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "ID Check"
                    }
                  }
                ],
                meta: {
                  clientFileNavActive: true,
                  title: "Identify Verification"
                }
              },
              {
                path: "addresses",
                component: () =>
                  import(
                    /* webpackChunkName: "client-address-history" */ "@/components/ux/clients/files/AddressHistory.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "client-address-history-summary",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-address-history-summary" */ "@/components/ux/clients/files/addresses/AddressHistorySummary.vue"
                      ),
                    meta: {
                      clientFileNavActive: true,
                      title: "Addresses"
                    }
                  },
                  {
                    path: "add",
                    name: "client-address-history-add",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-address-history-add" */ "@/components/ux/clients/files/addresses/AddAddressHistory.vue"
                      ),
                    meta: {
                      clientFileNavActive: true,
                      title: "Address / Add"
                    }
                  },
                  {
                    path: "edit/:selectedClientId([0-9a-fA-F]{24})/:selectedAddressId([0-9a-fA-F]{24})",
                    name: "client-address-history-edit",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-address-history-edit" */ "@/components/ux/clients/files/addresses/EditAddressHistory.vue"
                      ),
                    meta: {
                      clientFileNavActive: true,
                      title: "Address / Details"
                    }
                  }
                ]
              },
              // {
              //   path: "idv",
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "client-idv" */ "@/components/ux/clients/files/VerificationList.vue"
              //     ),
              //   children: [
              //     {
              //       path: "",
              //       beforeEnter: storeGuards.loadIDVDocumentsGuard,
              //       name: "client-idv-list",
              //       component: () =>
              //         import(
              //           /* webpackChunkName: "client-idv-list" */ "@/components/ux/clients/files/IDVList.vue"
              //         ),
              //       meta: {
              //         //requiresAuthClient: true,
              //         clientFileNavActive: true,
              //         title: "ID & V",
              //         blockIfProduction: true
              //       }
              //     },
              //     {
              //       path: "add",
              //       name: "client-idv-add",
              //       component: () =>
              //         import(
              //           /* webpackChunkName: "client-idv-add" */ "@/components/ux/clients/files/IDVDetails.vue"
              //         ),
              //       meta: {
              //         //requiresAuthClient: true,
              //         clientFileNavActive: true,
              //         title: "ID & V / Add"
              //       }
              //     },
              //     {
              //       path: "details/:idvClientId([0-9a-fA-F]{24})/:idvId([0-9a-fA-F]{24})",
              //       name: "client-idv-details",
              //       component: () =>
              //         import(
              //           /* webpackChunkName: "client-idv-details" */ "@/components/ux/clients/files/IDVDetails.vue"
              //         ),
              //       meta: {
              //         //requiresAuthClient: true,
              //         clientFileNavActive: true,
              //         title: "ID & V / Details"
              //       }
              //     }
              //   ]
              // },
              {
                path: "documents",
                beforeEnter: chainGuards([
                  storeGuards.loadClientDocumentsGuard,
                  storeGuards.loadAllTeamMembers
                ]),
                name: "client-documents",
                component: () =>
                  import(
                    /* webpackChunkName: "client-idv-details" */ "@/components/ux/clients/files/ClientDocumentList.vue"
                  ),
                meta: {
                  //requiresAuthClient: true,
                  clientFileNavActive: true,
                  title: "Documents"
                }
              },
              {
                path: "dependants",
                beforeEnter: chainGuards([storeGuards.loadDependantsGuard]),
                name: "client-dependants",
                component: () =>
                  import(
                    /* webpackChunkName: "client-dependants" */ "@/components/ux/clients/files/Dependants.vue"
                  ),
                meta: {
                  //requiresAuthClient: true,
                  clientFileNavActive: true
                },
                children: [
                  {
                    path: "",
                    name: "client-dependants-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "dependants-list" */ "@/components/ux/shared/dependant/DependantList.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Dependants"
                    }
                  },
                  {
                    path: "add",
                    name: "client-dependants-add",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-dependants-add" */ "@/components/ux/shared/dependant/DependantDetailAddEditView.vue"
                      ),
                    props: { routerEditMode: "Add" },
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Dependants / Add"
                    }
                  },
                  {
                    path: ":dependantId([0-9a-fA-F]{24})",
                    name: "client-dependants-detail",
                    beforeEnter: storeGuards.selectedDependantGuard,
                    component: () =>
                      import(
                        /* webpackChunkName: "client-dependants-detail" */ "@/components/ux/shared/dependant/DependantDetailAddEditView.vue"
                      ),
                    props: { routerEditMode: "View" },
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Dependants"
                    }
                  }
                ]
              },
              {
                path: "client-credit",
                beforeEnter: storeGuards.loadCreditHistoryGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "client-credit" */ "@/components/ux/clients/files/credit/CreditHistory.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "credit-history-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "credit-history-list" */ "@/components/ux/shared/credit/CreditHistoryList.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Credit History"
                    }
                  },
                  {
                    path: "add",
                    name: "credit-history-add",
                    component: () =>
                      import(
                        /* webpackChunkName: "credit-history-add" */ "@/components/ux/shared/credit/CreditHistoryAdd.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Credit History / Add"
                    }
                  },
                  {
                    path: "creditHistory/CCJ",
                    name: "credit-history-ccj",
                    component: () =>
                      import(
                        /* webpackChunkName: "credit-history-ccj" */ "@/components/ux/shared/credit/CreditHistoryCCJ.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Credit History / CCJ"
                    }
                  },
                  {
                    path: "creditHistory/Default",
                    name: "credit-history-default",
                    component: () =>
                      import(
                        /* webpackChunkName: "credit-history-default" */ "@/components/ux/shared/credit/CreditHistoryDefault.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true
                    }
                  },
                  {
                    path: "creditHistory/Arrears",
                    name: "credit-history-arrears",
                    component: () =>
                      import(
                        /* webpackChunkName: "credit-history-arrears" */ "@/components/ux/shared/credit/CreditHistoryArrears.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Credit History / Arrears"
                    }
                  },
                  {
                    path: "creditHistory/dro",
                    name: "credit-history-dro",
                    component: () =>
                      import(
                        /* webpackChunkName: "credit-history-dro" */ "@/components/ux/shared/credit/CreditHistoryDRO.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Credit History / DRO"
                    }
                  },
                  {
                    path: "creditHistory/bankruptcy",
                    name: "credit-history-bankruptcy",
                    component: () =>
                      import(
                        /* webpackChunkName: "credit-history-bankruptcy" */ "@/components/ux/shared/credit/CreditHistoryBankruptcy.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Credit History / Bankruptcy"
                    }
                  },
                  {
                    path: "creditHistory/iva",
                    name: "credit-history-iva",
                    component: () =>
                      import(
                        /* webpackChunkName: "credit-history-iva" */ "@/components/ux/shared/credit/CreditHistoryIVA.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Credit History / IVA"
                    }
                  }
                ]
              },
              {
                path: "employment-income",
                beforeEnter: storeGuards.loadEmploymentIncomeGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "client-income" */ "@/components/ux/clients/files/employment/EmploymentIncomeLanding.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "client-employment-income-summary",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-employment-income-summary" */ "@/components/ux/clients/files/EmploymentIncome.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Employment & Income"
                    }
                  },
                  {
                    path: "add",
                    name: "client-employment-income-add",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-employment-income-add" */ "@/components/ux/clients/files/employment/AddNewRecord.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Employment & Income / Add"
                    }
                  },
                  {
                    path: "details",
                    name: "client-employment-income-details-view",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-employment-income-details-view" */ "@/components/ux/clients/files/employment/AddNewRecord.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Employment & Income / Details"
                    }
                  },
                  {
                    path: "details/:incomeId([0-9a-fA-F]{24})/:incomeType",
                    name: "client-employment-income-details",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-employment-income-details" */ "@/components/ux/clients/files/employment/Details.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Employment & Income / Details"
                    }
                  }
                ]
              },
              {
                path: "commitments",
                beforeEnter: storeGuards.loadCommitmentsGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "client-commitments" */ "@/components/ux/clients/files/LoansCreditCommitments.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "client-commitments-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-list" */ "@/components/ux/shared/commitments/LoansCreditCommitmentsList.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Commitments"
                    }
                  },
                  {
                    path: "add",
                    name: "client-commitments-add",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-add" */ "@/components/ux/clients/files/LoansCreditCommitmentsAdd.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Commitments / Add"
                    }
                  },
                  {
                    path: "add/hire-purchase",
                    name: "client-commitments-add-hire-purchase",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-add-hire-purchase" */ "@/components/ux/shared/commitments/AddHirePurchase.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Commitments / Hire Purchase"
                    }
                  },
                  {
                    path: "add/other-commitments",
                    name: "client-commitments-add-other-commitments",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-add-other-commitments" */ "@/components/ux/shared/commitments/OtherCommitmentAddEdit.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Commitments / Other Commitments"
                    }
                  },
                  {
                    path: "add/overdraft",
                    name: "client-commitments-add-overdraft",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-add-overdraft" */ "@/components/ux/shared/commitments/OverdraftAddEdit.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Commitments / Overdraft"
                    }
                  },
                  {
                    path: "add/pcp",
                    name: "client-commitments-add-pcp",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-add-pcp" */ "@/components/ux/shared/commitments/PcpAddEdit.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Commitments / Pcp"
                    }
                  },
                  {
                    path: "add/unsecured-loan",
                    name: "client-commitments-add-unsecured-loan",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-add-unsecured-loan" */ "@/components/ux/shared/commitments/UnsecuredLoanAddEdit.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Commitments / Unsecured Loan"
                    }
                  },
                  {
                    path: "add/lease-agreement",
                    name: "client-commitments-add-lease-agreements",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-add-lease-agreement" */ "@/components/ux/shared/commitments/LeaseAgreementAddEdit.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Commitments / Lease Agreement"
                    }
                  },
                  {
                    path: "add/credit-store-card",
                    name: "client-commitments-add-credit-store-card",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-commitments-add-credit-store-card" */ "@/components/ux/shared/commitments/CreditStoreCardsAddEdit.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Commitments / Credit/Store Card"
                    }
                  }
                ]
              },
              {
                path: "client-properties",
                beforeEnter: chainGuards([
                  storeGuards.loadPropertiesGuard,
                  storeGuards.loadMortgagesGuard,
                  storeGuards.loadPoliciesGuard
                ]),
                component: () =>
                  import(
                    /* webpackChunkName: "client-properties" */ "@/components/ux/clients/files/Properties.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "client-property-summary",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-property-summary" */ "@/components/ux/shared/properties/PropertiesList.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Properties"
                    }
                  },
                  {
                    path: "property-address",
                    name: "client-property-add",
                    beforeEnter: storeGuards.createPropertyForAddGuard,
                    component: () =>
                      import(
                        /* webpackChunkName: "client-property-add-edit" */ "@/components/ux/shared/properties/PropertyAddEdit.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Properties / Property Address"
                    }
                  },
                  {
                    path: "details/:selectedPropertyId([0-9a-fA-F]{24})?",
                    beforeEnter: storeGuards.selectedClientPropertyInfoGuard,
                    name: "client-property-details",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-property-add-edit" */ "@/components/ux/shared/properties/PropertyAddEdit.vue"
                      ),
                    props: {
                      openMortgageRecordRoute: {
                        name: "client-mortgage-edit-residential-standard"
                      },
                      openPolicyRecordRoute: {
                        name: "client-policy-edit-buildingcontents"
                      }
                    },
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Properties / Details"
                    },
                    children: [
                      {
                        path: "edit",
                        beforeEnter: storeGuards.propertyNotReadonlyGuard,
                        name: "client-property-details-edit",
                        component: () =>
                          import(
                            /* webpackChunkName: "client-property-add-edit" */ "@/components/ux/shared/properties/PropertyAddEdit.vue"
                          ),
                        props: {
                          openMortgageRecordRoute: {
                            name: "client-mortgage-edit-residential-standard"
                          },
                          openPolicyRecordRoute: {
                            name: "client-policy-edit-buildingcontents"
                          }
                        },
                        meta: {
                          //requiresAuthCase: true,
                          clientFileNavActive: true
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: "mortgages",
                beforeEnter: chainGuards([
                  storeGuards.loadPropertiesGuard,
                  storeGuards.loadMortgagesGuard
                ]),
                name: "client-mortgages",
                component: () =>
                  import(
                    /* webpackChunkName: "client-mortgages" */ "@/components/ux/clients/files/Mortgages.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "client-mortgage-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-mortgage-list" */ "@/components/ux/shared/mortgage/MortgageList.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Mortgages"
                    }
                  },
                  {
                    path: "add",
                    name: "client-mortgage-add",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-mortgage-add" */ "@/components/ux/shared/mortgage/MortgageAdd.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Mortgage / Add"
                    }
                  },
                  {
                    path: "add/residential-standard",
                    name: "client-mortgage-add-residential-standard",
                    props: {
                      onSaveRoute: { name: "client-mortgage-list" },
                      onCancelRoute: { name: "client-mortgage-list" }
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "client-mortgage-add-residential-standard" */ "@/components/ux/shared/mortgage/AddEditMortgageDetails.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Mortgages / Residential Standard"
                    }
                  },
                  {
                    path: "add/buy-to-let",
                    name: "client-mortgage-add-buy-to-let",
                    props: {
                      onSaveRoute: { name: "client-mortgage-list" },
                      onCancelRoute: { name: "client-mortgage-list" }
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "client-mortgage-add-residential-standard" */ "@/components/ux/shared/mortgage/AddEditMortgageDetails.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Mortgages / Buy to Let"
                    }
                  },
                  {
                    path: "edit/residential-standard/:selectedMortgageId([0-9a-fA-F]{24})?",
                    beforeEnter: storeGuards.editMortgageInfoGuard,
                    name: "client-mortgage-edit-residential-standard",
                    props: {
                      onSaveRoute: { name: "client-mortgage-list" },
                      onCancelRoute: { name: "client-mortgage-list" }
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "client-mortgage-edit-residential-standard" */ "@/components/ux/shared/mortgage/AddEditMortgageDetails.vue"
                      ),
                    meta: {
                      clientFileNavActive: true,
                      title: "Mortgages / Residential Standard"
                    }
                  }
                ]
              },
              {
                path: "policies",
                beforeEnter: storeGuards.loadPoliciesGuard,
                name: "client-policies",
                component: () =>
                  import(
                    /* webpackChunkName: "client-policies" */ "@/components/ux/clients/files/Policies.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "client-policy-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-policy-list" */ "@/components/ux/shared/policy/PoliciesList.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Policies"
                    }
                  },
                  {
                    path: "add",
                    name: "client-policy-add",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-policy-add" */ "@/components/ux/shared/policy/PolicyAdd.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Policies / Add"
                    }
                  },
                  {
                    path: "add/protection",
                    name: "client-policy-add-protection",
                    beforeEnter: storeGuards.addModePoliciesGuard,
                    component: () =>
                      import(
                        /* webpackChunkName: "client-policy-add-protection" */ "@/components/ux/shared/policy/AddEditProtection.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Policies / Protection"
                    }
                  },
                  {
                    path: "add/buildingcontents",
                    beforeEnter: chainGuards([
                      storeGuards.addModePoliciesGuard,
                      storeGuards.loadPropertiesGuard
                    ]),
                    name: "client-policy-add-buildingcontents",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-policy-add-buildingcontents" */ "@/components/ux/shared/policy/AddEditBuildingContents.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Policies / Buildings & Contents"
                    }
                  },
                  {
                    path: "add/asu",
                    name: "client-policy-add-asu",
                    beforeEnter: storeGuards.addModePoliciesGuard,
                    props: {
                      isASU: true,
                      onSaveRoute: { name: "client-policy-list" },
                      onCancelRoute: { name: "client-policy-list" }
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "client-policy-add-asu" */ "@/components/ux/shared/policy/AddEditPolicy.vue"
                      ),
                    meta: {
                      clientFileNavActive: true,
                      title: "Policies / ASU"
                    }
                  },
                  {
                    path: "details/:policyId([0-9a-fA-F]{24})?/protection",
                    name: "client-policy-edit-protection",
                    beforeEnter: chainGuards([
                      storeGuards.editModePoliciesGuard,
                      storeGuards.loadPropertiesGuard
                    ]),
                    component: () =>
                      import(
                        /* webpackChunkName: "client-policy-edit-protection" */ "@/components/ux/shared/policy/AddEditProtection.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Policies / Protection"
                    }
                  },
                  {
                    path: "details/:policyId([0-9a-fA-F]{24})?/buildingcontents",
                    beforeEnter: chainGuards([
                      storeGuards.editModePoliciesGuard,
                      storeGuards.loadPropertiesGuard
                    ]),
                    name: "client-policy-edit-buildingcontents",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-policy-edit-buildingcontents" */ "@/components/ux/shared/policy/AddEditBuildingContents.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Policies / Buildings & Contents"
                    }
                  },
                  {
                    path: "details/:policyId([0-9a-fA-F]{24})?/asu",
                    name: "client-policy-edit-asu",
                    beforeEnter: storeGuards.editModePoliciesGuard,
                    props: {
                      isASU: true,
                      onSaveRoute: { name: "client-policy-list" },
                      onCancelRoute: { name: "client-policy-list" }
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "client-policy-edit-asu" */ "@/components/ux/shared/policy/AddEditPolicy.vue"
                      ),
                    meta: {
                      clientFileNavActive: true,
                      title: "Policies / ASU"
                    }
                  }
                ]
              },
              {
                path: "savings",
                beforeEnter: storeGuards.loadSavingsGuard,
                name: "client-savings",
                component: () =>
                  import(
                    /* webpackChunkName: "client-savings" */ "@/components/ux/clients/files/SavingsInvestments.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "client-savings-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-savings-list" */ "@/components/ux/shared/savings/SavingsInvestmentsList.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Savings & Investments"
                    }
                  },
                  {
                    path: "add/savings-account",
                    name: "client-savings-add-savings-account",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-savings-add-savings-account" */ "@/components/ux/shared/savings/AddSavingsAcc.vue"
                      ),
                    meta: {
                      //requiresAuthClient: true,
                      clientFileNavActive: true,
                      title: "Savings & Investments"
                    }
                  }
                ]
              },
              {
                path: "vulnerable-customer",
                beforeEnter: storeGuards.loadClientsGuard,
                name: "vulnerable-customer",
                component: () =>
                  import(
                    /* webpackChunkName: "vulnerable-customer" */ "@/components/ux/clients/files/VulnerableCustomer.vue"
                  ),
                children: [
                  {
                    path: "",
                    name: "client-vulnerable-customer",
                    component: () =>
                      import(
                        /* webpackChunkName: "client-vulnerable-customer" */ "@/components/ux/shared/vulnerablecustomer/VulnerableCustomer.vue"
                      ),
                    meta: {
                      //requiresAuthCase: true,
                      clientFileNavActive: true,
                      title: "Vulnerable Customer"
                    }
                  }
                ]
              }
            ]
          },
          {
            path: "cases",
            beforeEnter: chainGuards([
              storeGuards.loadClientGuard,
              storeGuards.clientAccessGuard,
              storeGuards.loadCasesGuard,
              storeGuards.loadAdvisorsGuard,
              storeGuards.loadPrincipalsGuard,
              storeGuards.loadKeyImportedSalesGuard
            ]),
            name: "client-cases",
            component: () =>
              import(
                /* webpackChunkName: "client-cases" */ "../pages/clients/Cases.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Cases"
            }
          },
          {
            path: "caseSetup",
            beforeEnter: chainGuards([
              storeGuards.loadClientGuard,
              storeGuards.clientAccessGuard,
              storeGuards.loadCompaniesGuard,
              storeGuards.loadAdvisorsGuard,
              storeGuards.loadPrincipalsGuard
            ]),
            name: "client-case-setup",
            component: () =>
              import(
                /* webpackChunkName: "client-case-setup" */ "../pages/clients/CaseSetup.vue"
              ),
            meta: {
              caseNavActive: true
            }
          },
          ...mapCaseRoutes(),
          {
            path: "reviews",
            name: "client-reviews",
            component: () =>
              import(
                /* webpackChunkName: "client-reviews" */ "../pages/clients/Reviews.vue"
              ),
            meta: { blockIfProduction: true }
          },
          {
            path: "documents",
            name: "client-docs",
            component: () =>
              import(
                /* webpackChunkName: "client-docs" */ "../pages/clients/Documents.vue"
              ),
            meta: { blockIfProduction: true }
          },
          {
            path: "insights",
            name: "client-insights",
            component: () =>
              import(
                /* webpackChunkName: "client-insights" */ "../pages/clients/Insights.vue"
              ),
            meta: { blockIfProduction: true }
          },
          {
            path: "fees",
            name: "client-fees",
            component: () =>
              import(
                /* webpackChunkName: "client-fees" */ "../pages/clients/CommissionFees.vue"
              ),
            meta: { blockIfProduction: true }
          },
          {
            path: "notes",
            name: "client-notes",
            component: () =>
              import(
                /* webpackChunkName: "client-notes" */ "../pages/clients/Notes.vue"
              ),
            meta: { blockIfProduction: true }
          },
          {
            path: "keyimports/:keyImportId",
            beforeEnter: chainGuards([
              storeGuards.keyCurrentSaleGuard,
              storeGuards.loadClientGuard,
              storeGuards.loadKeyImportedSalesGuard
            ]),

            name: "key-import-sales",
            component: () =>
              import(
                /* webpackChunkName: "key-import-sales" */ "../pages/keyimports/KeyImportSales.vue"
              ),
            meta: {
              caseNavActive: true
            },
            children: [
              {
                path: "dashboard",
                name: "key-import-nav",
                component: () =>
                  import(
                    /* webpackChunkName: "key-import-nav" */ "../pages/keyimports/KeyImportNav.vue"
                  ),
                children: [
                  {
                    path: "overview",
                    name: "key-import-overview",
                    component: () =>
                      import(
                        /* webpackChunkName: "key-import-overview" */ "../pages/keyimports/KeyImportOverview.vue"
                      )
                  },
                  {
                    path: "requirements",
                    name: "key-import-requirements",
                    component: () =>
                      import(
                        /* webpackChunkName: "key-import-requirements" */ "../pages/keyimports/KeyImportRequirements.vue"
                      )
                  },
                  {
                    path: "products",
                    name: "key-import-products",
                    component: () =>
                      import(
                        /* webpackChunkName: "key-import-products" */ "../pages/keyimports/KeyImportProducts.vue"
                      )
                  },

                  {
                    path: "documents",
                    name: "key-import-documents-list",
                    component: () =>
                      import(
                        /* webpackChunkName: "key-import-documents-list" */ "../pages/keyimports/KeyImportDocumentsList.vue"
                      )
                  },
                  {
                    path: "notes",
                    name: "key-import-notes",
                    component: () =>
                      import(
                        /* webpackChunkName: "key-import-notes" */ "../pages/keyimports/KeyImportNotes.vue"
                      )
                  }
                ]
              },
              {
                path: "products/:productId",
                name: "key-import-selected-product",
                component: () =>
                  import(
                    /* webpackChunkName: "key-import-selected-product" */ "../pages/keyimports/products/KeyImportSelectedProduct.vue"
                  ),
                children: [
                  {
                    path: "dashboard",
                    name: "key-import-product-nav",
                    component: () =>
                      import(
                        /* webpackChunkName: "key-import-product-nav" */ "../pages/keyimports/products/KeyImportProductNav.vue"
                      ),
                    children: [
                      {
                        path: "overview",
                        name: "key-import-product-overview",
                        component: () =>
                          import(
                            /* webpackChunkName: "key-import-product-overview" */ "../pages/keyimports/products/KeyImportProductOverview.vue"
                          )
                      },
                      {
                        path: "applications",
                        name: "key-import-product-applications",
                        component: () =>
                          import(
                            /* webpackChunkName: "key-import-product-applications" */ "../pages/keyimports/products/KeyImportProductApplication.vue"
                          )
                      },
                      {
                        path: "commissions",
                        name: "key-import-product-commissions",
                        component: () =>
                          import(
                            /* webpackChunkName: "key-import-product-commissions" */ "../pages/keyimports/products/KeyImportProductCommission.vue"
                          )
                      },
                      {
                        path: "property",
                        name: "key-import-product-property",
                        component: () =>
                          import(
                            /* webpackChunkName: "key-import-product-property" */ "../pages/keyimports/products/KeyImportProductProperty.vue"
                          )
                      },
                      {
                        path: "compliance",
                        name: "key-import-product-compliance",
                        component: () =>
                          import(
                            /* webpackChunkName: "key-import-product-compliance" */ "../pages/keyimports/products/KeyImportProductCompliance.vue"
                          )
                      },
                      {
                        path: "documents",
                        beforeEnter: storeGuards.loadKeyImportDocuments,
                        name: "key-import-product-documents",
                        component: () =>
                          import(
                            /* webpackChunkName: "key-import-product-documents" */ "../pages/keyimports/products/KeyImportProductDocuments.vue"
                          )
                      },
                      {
                        path: "notes",
                        name: "key-import-product-notes",
                        component: () =>
                          import(
                            /* webpackChunkName: "key-import-product-notes" */ "../pages/keyimports/products/KeyImportProductNotes.vue"
                          )
                      }
                    ]
                  }
                ]
              }
            ]
          },

          {
            path: "portal",
            beforeEnter: chainGuards([
              storeGuards.loadClientGuard,
              storeGuards.clientAccessGuard
            ]),
            name: "client-portal",
            component: () =>
              import(
                /* webpackChunkName: "client-portal" */ "../pages/clients/ClientPortal.vue"
              ),
            meta: {
              title: "Client Portal"
            }
          },
          {
            path: "debug",
            name: "client-debug",
            component: () =>
              import(
                /* webpackChunkName: "client-debug" */ "../pages/clients/ClientDebug.vue"
              ),
            meta: {
              title: "Debug",
              blockIfProduction: true
            }
          },
          {
            path: "valuations",
            name: "client-valuations",
            component: () =>
              import(
                /* webpackchunkName: "client-valuations" */ "../pages/clients/Valuations.vue"
              ),
            meta: { blockIfProduction: true }
          }
        ]
      },
      {
        path: "opportunities",
        name: "opportunities",
        beforeEnter: storeGuards.clearLoadedAndExistingClient,
        component: () =>
          import(
            /* webpackChunkName: "opportunities" */ "../pages/Opportunities.vue"
          )
      },
      ...referrals,
      {
        path: "opportunities/details/:opportunityId([0-9a-fA-F-]{24})",
        name: "opportunity-details",
        beforeEnter: chainGuards([
          storeGuards.loadAdvisorsGuard,
          storeGuards.loadPrincipalsGuard,
          storeGuards.selectedOpportunityGuard
        ]),
        component: () =>
          import(
            /* webpackChunkName: "opportunity-details" */ "../pages/opportunities/OpportunityDetails.vue"
          )
      },
      {
        path: "reporting",
        component: {
          render: (c) => c("router-view")
        },
        children: [
          {
            path: "",
            name: "reporting",
            component: () =>
              import(
                /* webpackChunkName: "reporting" */ "../pages/Reporting.vue"
              )
          }
        ]
      },
      {
        path: "insights",
        name: "insights",
        component: () =>
          import(
            /* webpackChunkName: "insights" */ "../pages/insights/Insights.vue"
          )
      },
      {
        path: "case-check",
        name: "case-check",
        beforeEnter: storeGuards.loadCaseCheckingGuard,
        component: () =>
          import(/* webpackChunkName: "case-check" */ "../pages/CaseCheck.vue"),
        meta: { blockIfProduction: true }
      },
      {
        path: "business-processing",
        name: "business-processing",
        beforeEnter: storeGuards.loadAllTeamMembers,
        component: () =>
          import(
            /* webpackChunkName: "case-check" */ "../pages/BusinessProcessing.vue"
          )
      },
      {
        path: "case-admin",
        name: "case-admin",
        component: () =>
          import(/* webpackChunkName: "caseadmin" */ "../pages/CaseAdmin.vue"),
        meta: { blockIfProduction: true }
      },
      {
        path: "compliance",
        name: "compliances",
        component: () =>
          import(
            /* webpackChunkName: "compliance" */ "../pages/Compliance2.vue"
          ),
        meta: { blockIfProduction: false }
      },
      {
        path: "commissions",
        name: "commissions",
        component: () =>
          import(
            /* webpackChunkName: "commissions" */ "../pages/Opportunities.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "addressbook",
        component: {
          render: (c) => c("router-view")
        },
        meta: { title: "Address Book" },
        children: [
          {
            path: "",
            name: "addressbook",
            component: () =>
              import(
                /* webpackChunkName: "addressbook" */ "../pages/AddressBook.vue"
              )
          },
          {
            path: "add/company",
            name: "add-address-book-company",
            beforeEnter: storeGuards.addAddressBookCompanyGuard,
            component: () =>
              import(
                /* webpackchunkName: "add-address-book-company" */ "../pages/addressbook/AddEditCompany.vue"
              ),
            meta: {
              title: "Add a Company"
            }
          },
          {
            path: "edit/company/:companyId([0-9a-fA-F]{24})?",
            name: "edit-address-book-company",
            beforeEnter: storeGuards.editAddressBookCompanyGuard,
            component: () =>
              import(
                /* webpackchunkName: "edit-address-book-company" */ "../pages/addressbook/AddEditCompany.vue"
              ),
            meta: {
              title: "Edit a Company"
            }
          },
          {
            path: "company/details/:companyId([0-9a-fA-F]{24})?",
            name: "company-details-view",
            beforeEnter: storeGuards.viewAddressBookCompanyGuard,
            component: () =>
              import(
                /* webpackchunkName: "company-details-view" */ "../pages/addressbook/CompanyDetailsView.vue"
              ),
            meta: {
              title: "Company Details"
            }
          },
          {
            path: "add/branch",
            name: "add-address-book-company-branch",
            beforeEnter: storeGuards.addAddressBookBranchGuard,
            component: () =>
              import(
                /* webpackchunkName: "add-address-book-company-branch" */ "../pages/addressbook/AddEditBranch.vue"
              ),
            meta: {
              title: "Branch / Add a Branch"
            }
          },
          {
            path: "edit/branch/:branchId([0-9a-fA-F]{24})?",
            name: "edit-address-book-company-branch",
            beforeEnter: storeGuards.editAddressBookBranchGuard,
            component: () =>
              import(
                /* webpackchunkName: "edit-address-book-company-branch" */ "../pages/addressbook/AddEditBranch.vue"
              ),
            meta: {
              title: "Branch / Edit a Branch"
            }
          },
          {
            path: "branch/details/:branchId([0-9a-fA-F]{24})?",
            name: "branch-details-view",
            beforeEnter: storeGuards.viewAddressBookBranchGuard,
            component: () =>
              import(
                /* webpackchunkName: "branch-details-view" */ "../pages/addressbook/BranchDetailsView.vue"
              ),
            meta: {
              title: "Branches / Branch Details"
            }
          },
          {
            path: "add/individual",
            name: "add-individual",
            beforeEnter: storeGuards.addAddressBookIndividualGuard,
            component: () =>
              import(
                /* webpackchunkName: "add-individual" */ "../pages/addressbook/AddEditIndividual.vue"
              ),
            meta: {
              title: "Individuals / Add an Individual",
              pageHeader: "Add an Individual"
            }
          },
          {
            path: "edit/individual/:individualId([0-9a-fA-F]{24})?",
            name: "edit-individual",
            beforeEnter: storeGuards.editAddressBookIndividualGuard,
            component: () =>
              import(
                /* webpackchunkName: "edit-individual" */ "../pages/addressbook/AddEditIndividual.vue"
              ),
            meta: {
              title: "Individuals / Edit an Individual",
              pageHeader: "Edit an Individual"
            }
          }
        ]
      },
      {
        path: "diary",
        name: "diary",
        component: () =>
          import(/* webpackChunkName: "diary" */ "../pages/Diary.vue"),
        meta: { blockIfProduction: true }
      },
      {
        path: "dictionary",
        name: "dctionary",
        component: () =>
          import(
            /* webpackChunkName: "dctionary" */ "../pages/DataDictionary.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development",
        name: "development",
        component: () =>
          import(
            /* webpackchunkName: "development" */ "../pages/development/Development.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/idvdemo",
        name: "development-idv-demo",
        component: () =>
          import(
            /* webpackchunkName: "development-idv-demo" */ "../pages/development/IdvDemo.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/userinfo",
        name: "development-user-info",
        component: () =>
          import(
            /* webpackchunkName: "development-user-info" */ "../pages/development/UserInfo.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "tutorials",
        name: "tutorials",
        component: () =>
          import(
            /* webpackchunkName: "tutorials" */ "../pages/support/Tutorials.vue"
          )
      },
      {
        path: "development/document-editor",
        name: "document-editor",
        component: () =>
          import(
            /* webpackchunkName: "document-editor" */ "../components/ux/shared/documents/DocumentEditor.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/notes",
        name: "dev-notes",
        component: () =>
          import(
            /* webpackchunkName: "dev-notes" */ "../pages/development/Notes.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/apphealth",
        name: "app-health",
        component: () =>
          import(
            /* webpackchunkName: "app-health" */ "../pages/development/AppHealth.vue"
          ),
        meta: { blockIfProduction: false } //do not block, it will only be hidden on side bar
      },
      {
        path: "development/secure-messaging-poc",
        beforeEnter: chainGuards([
          storeGuards.loadMessagesGuard,
          storeGuards.loadAdvisorsGuard,
          storeGuards.loadPrincipalsGuard,
          storeGuards.loadClientGuard
        ]),

        name: "secure-messaging-poc",
        component: () =>
          import(
            /* webpackchunkName: "secure-messaging-poc" */ "../pages/development/SecureMessagingPOC.vue"
          ),
        meta: {
          title: "Secure Messaging",
          pageHeader: "Secure Messaging POC",
          blockIfProduction: true
        }
      },
      {
        path: "development/case-without-clientid",

        name: "route-without-clientid-demo",
        component: () =>
          import(
            /* webpackchunkName: "secure-messaging-poc" */ "../pages/development/RouteWithoutClientId.vue"
          ),
        meta: {
          title: "",
          pageHeader: "Route to Case without Client ID demo (dev only)",
          blockIfProduction: true
        }
      },
      {
        path: "development/modal-demo",
        name: "development-model-demo",
        component: () =>
          import(
            /* webpackchunkName: "modal-demo" */ "../pages/development/DemoModelDialog.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/form-events",
        name: "development-form-events",
        component: () =>
          import(
            /* webpackchunkName: "form-events" */ "../pages/development/FormInputEventsDemo.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/query-builder",
        name: "query-builder",
        component: {
          render: (c) => c("router-view")
        },
        meta: { blockIfProduction: true },
        children: [
          {
            path: "",
            name: "query-builder-list",
            component: () =>
              import(
                /* webpackchunkName: "query-builder-list" */ "../pages/development/QueryBuilderList.vue"
              )
          },
          {
            path: "edit/:id",
            name: "query-builder-edit",
            component: () =>
              import(
                /* webpackChunkName: "query-builder-edit" */ "../pages/development/QueryBuilderEditCreate.vue"
              )
          },
          {
            path: "create",
            name: "query-builder-create",
            component: () =>
              import(
                /* webpackChunkName: "query-builder-create" */ "../pages/development/QueryBuilderEditCreate.vue"
              )
          }
        ]
      },
      {
        path: "development/scrollable-tabs",
        name: "scrollable-tabs",
        component: () =>
          import(
            /* webpackchunkName: "scrollable-tabs" */ "../pages/development/ScrollableTabsDemo.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/style-guide",
        name: "style-guide",
        component: () =>
          import(
            /* webpackchunkName: "style-guide" */ "../pages/development/StyleGuide.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/notification-demo",
        name: "notifications-demo",
        component: () =>
          import(
            /* webpackchunkName: "notifications-demo" */ "../pages/development/NotificationDemo.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/highlight-as-required",
        name: "highlight-as-required",
        component: () =>
          import(
            /* webpackchunkName: "highlight-as-required" */ "../pages/development/HighlightAsRequired.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/propsdowneventsup",
        name: "props-down-events-up",
        component: () =>
          import(
            /* webpackchunkName: "development" */ "../pages/development/PropsDownEventsUp/pages/ExamplePage.vue"
          ),
        meta: { blockIfProduction: true }
      },
      {
        path: "development/dd-driven-form-inputs",
        name: "dd-driven-form-inputs",
        component: () =>
          import(
            /* webpackchunkName: "development" */ "../pages/development/DataDictionaryFromInputs/pages/ExamplePage.vue"
          ),
        meta: { blockIfProduction: true }
      }
    ]
  },
  {
    path: "/documents/:accountId([0-9a-fA-F]{24})/:documentId([0-9a-fA-F]{24})",
    beforeEnter: authenticationGuard,
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "@/pages/documents/documentView.vue"
      ),
    props: (route) => ({
      accountId: route.params.accountId,
      documentId: route.params.documentId
    })
  },
  {
    path: "/404",
    name: "notfound404",
    component: require("../pages/404.vue").default
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    path: "/status",
    name: "status",
    component: () =>
      import(/* webpackchunkName: "app-health" */ "../pages/Status.vue")
  }
]

export default routes
