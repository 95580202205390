import store from "@/store"

const loadClientGuard = (to, _, next) => {
  var currentClientId = store.getters["clients/currentClientId"]
  store
    .dispatch("clientFile/loadClient", currentClientId)
    .then(() => next())
    .catch((error) => {
      console.log(error)
    })
}

const clientAccessGuard = (to, from, next) => {
  const user = store.getters["mbuser/user"]
  const client = store.getters["clientFile/mainClient"]

  if (user.account_id !== client.account_id) {
    next({ name: "notfound404" })
  } else {
    next()
  }
}

const loadDashboardGuard = (to, _, next) => {
  store
    .dispatch("dashboard/loadDashboard")
    .then(() => next())
    .catch((error) => {
      console.log(error)
    })
}

const loadDependantsGuard = (to, _, next) => {
  store
    .dispatch("dependants/loadDependants")
    .then(() => next())
    .catch((error) => {
      console.log(error)
    })
}

const loadCreditHistoryGuard = (to, _, next) => {
  store
    .dispatch("creditHistory/loadCreditHistories")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadEmploymentIncomeGuard = (to, _, next) => {
  store
    .dispatch("employmentIncomes/loadEmploymentIncomes")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadCommitmentsGuard = (to, _, next) => {
  store
    .dispatch("commitments/loadCommitments")
    .then(() => next())
    .catch((error) => console.log(error))
}

const selectedCommissionGuard = (to, _, next) => {
  let selectedComm = store.getters["commissions/commissions"]?.find(
    (comm) => comm.id === to.params.commissionId
  )
  if (selectedComm) {
    store.commit("commissions/setSelectedCommission", selectedComm)
    next()
  }
}

const loadRequirementsGuard = (to, _, next) => {
  store
    .dispatch("requirements/loadRequirements")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadProductsGuard = (to, _, next) => {
  store
    .dispatch("protection/loadProtections")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadPropertiesGuard = (to, _, next) => {
  store
    .dispatch("properties/loadProperties")
    .then(() => next())
    .catch((error) => console.log(error))
}

const createPropertyForAddGuard = (to, _, next) => {
  store
    .dispatch("properties/createPropertyForAdd", {
      clients: [],
      usage: null,
      property_status_all_key: null,
      address: {}
    })
    .then(() => next())
    .catch((error) => console.log(error))
}

const selectedRequirementGuard = (to, _, next) => {
  let requirementId = to.params.requirementId
  if (requirementId) {
    store.dispatch("requirements/fetchRequirement", {
      id: requirementId
    })
    next()
  }
}
const selectedRequirementPropertyGuard = (to, _, next) => {
  let selectedRequirement = store.getters["requirements/selectedRequirement"]
  let propertyId = selectedRequirement.mortgage_details?.property_id
  if (selectedRequirement) {
    if (!propertyId) {
      next()
      return
    }
    let found = store.getters["properties/allProperties"]?.find(
      (e) => e.id === propertyId
    )
    if (found) {
      store.commit("properties/setPropertyForEdit", found)
      next()
    } else {
      alert(
        "Cannot find property for requirement mortgage details, the property may have been deleted."
      )
      console.log(
        "Cannot find property for requirement mortgage details, the property may have been deleted."
      )
    }
  }
}

const selectedClientPropertyInfoGuard = (to, _, next) => {
  if (to.params.selectedPropertyId) {
    let found = store.getters["properties/allProperties"]?.find(
      (e) => e.id === to.params.selectedPropertyId
    )
    if (found) {
      store.commit("properties/setPropertyForEdit", found)
    }
  }

  let property = store.getters["properties/selectedProperty"]

  if (to.params.selectedPropertyId) {
    if (Object.keys(property).length === 0) {
      next({ name: "client-property-summary", params: to.params })
    } else {
      next()
    }
  } else {
    //var clients = store.getters["properties/selectedProperty"]

    if (Object.keys(property).length === 0) {
      next({ name: "client-property-add", params: to.params })
    } else {
      next()
    }
  }
}
const propertyNotReadonlyGuard = (to, _, next) => {
  store.commit("properties/setPropertyReadonly", false)
  next()
}

const loadMortgagesGuard = (to, _, next) => {
  store
    .dispatch("mortgages/loadMortgages")
    .then(() => next())
    .catch((error) => console.log(error))
}
const editMortgageInfoGuard = (to, _, next) => {
  if (to.params.selectedMortgageId) {
    let found = store.getters["mortgages/allMortgages"]?.find(
      (e) => e.id === to.params.selectedMortgageId
    )
    if (found) {
      store.commit("mortgages/setSelectedMortgageInfo", found)
      let mortgage = store.getters["mortgages/selectedMortgage"]
      if (Object.keys(mortgage).length === 0) {
        next({ name: to.params.fallbackRoute })
      } else {
        next()
      }
    } else {
      next({ name: to.params.fallbackRoute })
    }
  }
}

const loadPoliciesGuard = (to, _, next) => {
  store
    .dispatch("policies/loadPolicies")
    .then(() => next())
    .catch((error) => console.log(error))
}

const editModePoliciesGuard = async (to, _, next) => {
  let policy = store.getters["policies/allPolicies"].find((c) => {
    return c.id === to.params.policyId
  })
  if (!policy) {
    // Redirect to appropriate policy list if record cannot be found.
    await window.alertAsync(
      "The policy could not be found and may have been deleted."
    )
    if (to.name.includes("kyc"))
      next({
        name: "kyc-policies-list",
        params: { id: to.params.id, caseId: to.params.caseId }
      })
    else next({ name: "client-policy-list", params: { id: to.params.id } })
  } else {
    store.commit("policies/setEditMode", true)
    store.commit("policies/clearParts")
    if (policy.policy_type_key === "buildings_contents") {
      policy.buildings_contents_details.parts.forEach((part) =>
        store.commit("policies/addPart", { ...part })
      )
    }

    store.commit("policies/setSelectedPolicy", policy)
    next()
  }
}

const addModePoliciesGuard = (to, _, next) => {
  store.commit("policies/setEditMode", false)
  next()
}

const loadSavingsGuard = (to, _, next) => {
  store
    .dispatch("savings/loadSavings")
    .then(() => next())
    .catch((error) => console.log(error))
}

const selectedClientAndTypeFactFindCreditHistoryGuard = (to, _, next) => {
  var clients = store.getters["creditHistory/selectedClients"]
  var type = store.getters["creditHistory/selectedType"]

  if (clients.length === 0 || !type) {
    next({ name: "kyc-credit-history-add", params: to.params })
  } else {
    next()
  }
}

const selectedSavingsGuard = (to, _, next) => {
  var clients = store.getters["savings/selectedClients"]
  var type = store.getters["savings/selectedType"]
  var status = store.getters["savings/selectedStatus"]

  if (clients.length === 0 || !type || !status) {
    next({ name: "client-savings-add", params: to.params })
  } else {
    next()
  }
}

const selectedCommissionsTypeGuard = (to, _, next) => {
  var type = store.getters["commissions/type"]
  if (!type) next({ name: "commissions-and-fees-add", params: to.params })
  else next()
}

const loadCasesGuard = (to, _, next) => {
  store
    .dispatch("cases/loadCases")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadCaseGuard = (to, _, next) => {
  let caseId = store.getters["cases/currentCaseId"]
  store.commit("clientFile/setLoadedClientId", "")
  store
    .dispatch("cases/getCase", caseId)
    .then(() => {
      store.commit("clientFile/setShowCaseClients", true)
      next()
    })
    .catch((error) => console.log(error))
}

const pollUnreadMessages = (to, _, next) => {
  store.commit("messages/stopPolling")
  store
    .dispatch("messages/pollNewMessagesCount")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadCaseClientsGuard = (to, _, next) => {
  let clientId = store.getters["cases/currentCase"].linked_clients[0]
  store
    .dispatch("clientFile/loadClient", clientId)
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadReportGuard = (to, _, next) => {
  let defaultRunReport = {
    name: to.params.reportName,
    per_page: 25,
    page_number: 1,
    search_text: "",
    sort_field: ""
  }
  store
    .dispatch("reports/fetchReport", defaultRunReport)
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadCaseCheckingGuard = (to, _, next) => {
  let defaultRunReport = {
    per_page: 25,
    page_number: 1,
    search_text: "",
    sort_field: ""
  }
  store
    .dispatch("caseChecking/fetchCases", defaultRunReport)
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadNotesGuard = (to, _, next) => {
  store
    .dispatch("caseNotes/loadNotes")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadMessagesGuard = (to, _, next) => {
  store
    .dispatch("messages/loadMessages")
    .then(() => next())
    .catch((err) => console.log(err))
}

const loadClientPortalGuard = (to, _, next) => {
  store
    .dispatch("clientportal/loadUsers")
    .then(() => next())
    .catch((err) => console.log(err))
}

const addProductGuard = (to, _, next) => {
  store.commit("protection/setProtectionProductEditMode", false)
  next()
}

const editProductGuard = (to, _, next) => {
  if (to.params.productId) {
    let found = store.getters["protection/protectionProducts"]?.find(
      (e) => e.id === to.params.productId
    )
    if (found) {
      store.commit("protection/setSelectedProduct", found)
      store.commit("protection/setProtectionProductEditMode", true)
      next()
    } else {
      next({ name: "requirement-protection-research-product-list" })
    }
  } else {
    next({ name: "requirement-protection-research-product-list" })
  }
}

const addGIProductGuard = (to, _, next) => {
  store.commit("requirements/setGiProductsEditMode", false)
  next()
}

const editGIProductGuard = (to, _, next) => {
  if (to.params.productId) {
    let found = store.getters["generalInsuranceProducts/products"]?.find(
      (e) => e.id === to.params.productId
    )
    if (found) {
      store.commit("requirements/setSelectedProduct", found)
      store.commit("requirements/setGiProductsEditMode", true)
      next()
    } else {
      next({ name: "client-case-requirement-gi-research-product-list" })
    }
  } else {
    next({ name: "client-case-requirement-gi-research-product-list" })
  }
}

const loadCaseTasksGuard = (to, _, next) => {
  store
    .dispatch("caseTasks/loadTasks")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadTasksGuard = (to, _, next) => {
  store
    .dispatch("tasks/loadTasks")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadCaseDocumentsGuard = (to, _, next) => {
  let info = {
    cases: [store.getters["cases/currentCaseId"]]
  }
  store
    .dispatch("documents/getDocuments", {
      accountId: store.getters["documents/accountId"],
      info: info
    })
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadClientDocumentsGuard = (to, _, next) => {
  let info = {
    clients: [store.getters["clientFile/loadedClientId"]]
  }
  store
    .dispatch("documents/getDocuments", {
      accountId: store.getters["documents/accountId"],
      info: info
    })
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadRequirementDocumentsGuard = (to, _, next) => {
  let info = {
    requirements: [store.getters["requirements/selectedRequirement"].id],
    cases: [store.getters["cases/currentCaseId"]]
  }
  store
    .dispatch("documents/getDocuments", {
      accountId: store.getters["documents/accountId"],
      info: info
    })
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadIDVDocumentsGuard = (to, _, next) => {
  let idvIds = store.getters["clientFile/selectedClients"]
    .map((client) => {
      return client.id_and_v.docs.map((doc) => doc.doc_id)
    })
    .flat()
  var clientIds = store.getters["clientFile/selectedClients"].map(
    (client) => client.id
  )
  if (idvIds.length > 0) {
    let info = { idvs: idvIds, clients: clientIds }
    store
      .dispatch("documents/getDocuments", {
        accountId: store.getters["documents/accountId"],
        info: info
      })
      .then(() => next())
      .catch((error) => console.log(error))
  } else {
    next()
  }
}
const loadAdvisorsGuard = (to, _, next) => {
  store
    .dispatch("advisors/loadAdvisors")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadAdminsGuard = (to, _, next) => {
  store
    .dispatch("cases/loadAdmins")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadPrincipalsGuard = (to, _, next) => {
  store
    .dispatch("cases/loadPrincipals")
    .then(() => next())
    .catch((error) => console.log(error))
}
const addAddressBookCompanyGuard = (to, _, next) => {
  store.commit("addressBook/setAddModeCompany")
  next()
}

const viewAddressBookCompanyGuard = (to, _, next) => {
  if (to.params.companyId) {
    let found = store.getters["addressBook/companies"]?.find(
      (e) => e.id === to.params.companyId
    )
    if (found) {
      store.commit("addressBook/setSelectedCompanyForView", found)
      store.commit("addressBook/setBranchesForCompany", found.id)
      store.commit("addressBook/setIndividualsForCompany", found.id)
      next()
      return
    }
  }
  next({ name: "addressbook" })
}

const editAddressBookCompanyGuard = (to, _, next) => {
  if (to.params.companyId) {
    let found = store.getters["addressBook/companies"]?.find(
      (e) => e.id === to.params.companyId
    )
    if (found) {
      store.commit("addressBook/setSelectedCompany", found)
      next()
      return
    }
  }
  next({ name: "addressbook" })
}

const addAddressBookBranchGuard = (to, _, next) => {
  store.commit("addressBook/setAddModeBranch")
  next()
}

const viewAddressBookBranchGuard = (to, _, next) => {
  if (to.params.branchId) {
    let found = store.getters["addressBook/branches"]?.find(
      (e) => e.id === to.params.branchId
    )
    if (found) {
      store.commit("addressBook/setSelectedBranchForView", found)
      store.commit("addressBook/setIndividualsForBranch", found.id)
      next()
      return
    }
  }
  next({ name: "addressbook" })
}

const editAddressBookBranchGuard = (to, _, next) => {
  if (to.params.branchId) {
    let found = store.getters["addressBook/branches"]?.find(
      (e) => e.id === to.params.branchId
    )
    if (found) {
      store.commit("addressBook/setSelectedBranchForEdit", found)
      next()
      return
    }
  }
  next({ name: "addressbook" })
}

const addAddressBookIndividualGuard = (to, _, next) => {
  let company = store.getters["addressBook/selectedCompany"]
  store.commit("addressBook/setAddModeIndividual")
  store.commit(
    "addressBook/setRoleSelectGuid",
    company.business_service_type_key
  )
  store.dispatch("addressBook/getBranchOptions", company.id).then(() => next())
}

const editAddressBookIndividualGuard = (to, _, next) => {
  if (to.params.individualId) {
    let individual = store.getters["addressBook/individuals"]?.find(
      (e) => e.id === to.params.individualId
    )
    if (individual) {
      store.commit("addressBook/setSelectedIndividualForEdit", individual)
      let companies = store.getters["addressBook/companies"]
      let company = companies?.find((e) => e.id === individual.business_id)
      store.commit("addressBook/setSelectedCompany", company)
      store.commit(
        "addressBook/setRoleSelectGuid",
        company.business_service_type_key
      )
      store
        .dispatch("addressBook/getBranchOptions", company.id)
        .then(() => next())
      return
    }
  }
  next({ name: "addressbook" })
}

const loadApplicationsGuard = (to, _, next) => {
  store
    .dispatch("applications/loadApplications")
    .then(() => next())
    .catch((error) => console.log(error))
}

const editApplicationGuard = (to, _, next) => {
  store.commit("applications/getApplicationById", to.params.appId)
  next()
}

const selectRequirementForApplicationGuard = async (to, _, next) => {
  const application = store.getters["applications/selectedApplication"]
  await store.dispatch("requirements/loadRequirementsForApplication", {
    caseId: application.case_application.case_id
  })
  await store
    .dispatch("requirements/fetchRequirement", {
      id: application.linked_requirement
    })
    .then(() => next())
    .catch((error) => console.log(error))
}

const viewProtectionProductGuard = async (to, _, next) => {
  await store
    .dispatch("protection/fetchProtectionForApplication")
    .then(() => {
      store.commit("protection/setProtectionProductEditMode", true)
      next()
    })
    .catch((error) => console.log(error))
}

const viewGIProductGuard = async (to, _, next) => {
  await store
    .dispatch("generalInsuranceProducts/fetchGIProductForApplication")
    .then(() => {
      next()
    })
    .catch((error) => console.log(error))
}

const loadScenariosForApplicationGuard = (to, _, next) => {
  const application = store.getters["applications/selectedApplication"]
  store
    .dispatch(
      "requirements/loadMortgageScenarios",
      application.linked_requirement.id
    )
    .then(() => next())
    .catch((error) => console.log(error))
}

const addPurchaseRequirementPropertyGuard = (to, _, next) => {
  if (store.getters["properties/selectedAddress"]) next()
  else next({ name: "client-case-requirement-purchase-property" })
}

const loadScenariosGuard = (to, _, next) => {
  store
    .dispatch("requirements/loadMortgageScenarios", to.params.requirementId)
    .then(() => next())
    .catch((error) => console.log(error))
}

const editProductInfoGuard = (to, _, next) => {
  store.commit("requirements/productIsNew")
  next()
}

const loadCasesOverviewGuard = (to, _, next) => {
  let caseId = store.getters["cases/currentCaseId"]
  store
    .dispatch("cases/getCaseOverview", caseId)
    .then(() => next())
    .catch((error) => console.log(error))
}

const selectedOpportunityGuard = (to, _, next) => {
  var opportunityId = to.params.opportunityId
  if (opportunityId) {
    store.dispatch("opportunities/loadOpportunity", opportunityId).then(() => {
      next()
    })
  }
}

const loadDocumentsTemplateGuard = (to, _, next) => {
  const accountId = store.getters["documents/accountId"]
  store
    .dispatch("documents/getTemplateDocuments", {
      accountId: accountId,
      info: { template: ["general"] }
    })
    .then(() => next())
    .catch((error) => console.log(error))
}
const createDocumentTemplateGuard = (to, _, next) => {
  store.commit("documents/setIsAddMode")
  next()
}

const editDocumentTemplateGuard = async (to, _, next) => {
  await store
    .dispatch("documents/fetchTemplateDocument", {
      id: to.params.documentId
    })
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadBudgetPlannerGuard = (to, _, next) => {
  store
    .dispatch("budget/fetchBudgetForList")
    .then(() => next())
    .catch((error) => console.log(error))
}

const editBudgetPlannerGuard = async (to, _, next) => {
  await store.dispatch("cases/getCase", to.params.caseId)
  const currentCase = store.getters["cases/currentCase"]
  const budget = currentCase?.fact_find?.budget_planner.find((b) => {
    return b.id === to.params.selectedPlannerId
  })

  if (budget !== undefined) {
    store
      .dispatch("budget/fetchBudget", to.params.selectedPlannerId)
      .then(() => next())
      .catch((error) => console.log(error))
  } else {
    next()
  }
}

const loadGIProducts = async (to, _, next) => {
  store
    .dispatch("generalInsuranceProducts/loadGIProducts")
    .then(() => next())
    .catch((error) => console.log(error))
}

const loadKeyImportedSalesGuard = (to, _, next) => {
  var currentClientId = [store.getters["clients/currentClientId"]]
  if (currentClientId) {
    store
      .dispatch("cases/loadKeyImportedSales", currentClientId)
      .then(() => next())
      .catch((error) => console.error(error))
  }
}

const keyCurrentSaleGuard = (to, _, next) => {
  var keyImportId = to.params.keyImportId
  if (keyImportId) {
    store
      .dispatch("cases/getKeyImportedSale", keyImportId)
      .then(() => next())
      .catch((error) => console.error(error))
  }
}

const loadKeyImportDocuments = async (to, _, next) => {
  var importedSale = store.getters["cases/getCurrentImportedSale"]
  let id = importedSale.account_id
  let info = {
    clients: importedSale.linked_clients,
    keyproductid: [to.params.productId]
  }
  await store
    .dispatch("documents/getDocuments", {
      accountId: id,
      info: info
    })
    .then(() => {
      const ids = []
      const documentList = store.getters["documents/documentList"]
      const allNames = documentList.map((doc) => doc.metadata.clients)
      allNames.forEach((element) => {
        element.forEach((id) => {
          if (!ids.includes(id)) {
            ids.push(id)
          }
        })
      })
      if (ids.length > 0) {
        store
          .dispatch("cases/getNames", ids)
          .then(() => next())
          .catch((error) => console.error(error))
      }
    })
    .catch((error) => console.log(error))
}

const editRequirementsBuildingsContentGuard = (to, _, next) => {
  store
    .dispatch("requirements/loadRequirements")
    .then(() => {
      store.commit("requirements/bcProductIsNewState", false)
      next()
    })
    .catch((error) => console.log(error))
}

const loadCompaniesGuard = async (to, _, next) => {
  await store
    .dispatch("clientFile/loadCompanies")
    .then(() => next())
    .catch((error) => console.log(error))
}
const addDipGuard = (to, _, next) => {
  store.commit("requirements/dipIsNewState", true)
  next()
}
const editDipGuard = (to, _, next) => {
  if (to.params.dipId) {
    let found = store.getters[
      "requirements/selectedRequirement"
    ]?.mortgage_details.dips.find((e) => e.id === to.params.dipId)
    if (found) {
      store.commit("requirements/setSelectedDip", found)
      next()
    } else {
      next({ name: "client-case-requirement-mortgage-dip-list" })
    }
  } else {
    next({ name: "client-case-requirement-mortgage-dip-list" })
  }
}

const baseRequirementRouterGuard = async (to, _, next) => {
  if (to.params.caseId && to.params.requirementId) {
    store.commit("cases/setCurrentCaseId", to.params.caseId)
    await store.dispatch("requirements/loadRequirements")
    next()
  }
}

const loadAllTeamMembers = (to, _, next) => {
  store
    .dispatch("cases/loadAdmins")
    .then(() => store.dispatch("cases/loadAllTeamMembers"))
    .then(() => next())
    .catch((error) => console.log(error))
}

const clearLoadedAndExistingClient = (to, _, next) => {
  store.commit("clientFile/clearLoadedClientId")
  store.commit("clients/setNewOpportunityExistingClient", false)
  next()
}

function operate(guards, from, to, lastNext, i) {
  let guard = guards[i]
  if (guards.length === i + 1) {
    guard(from, to, lastNext)
  } else {
    guard(from, to, function (nextArg) {
      switch (typeof nextArg) {
        case "undefined":
          operate(guards, from, to, lastNext, i + 1)
          break
        case "object":
          lastNext(nextArg)
          break
        case "boolean":
          lastNext(nextArg)
          break
        case "string":
          lastNext(nextArg)
          break
      }
    })
  }
}

function chainGuards(ListOfGuards) {
  return function (from, to, next) {
    operate(ListOfGuards, from, to, next, 0)
  }
}

const selectedMortgageLoadTemplatesGuard = (to, _, next) => {
  const docConfig = store.getters["documents/templateFormObject"]

  if (
    docConfig?.category === "undefined" ||
    docConfig?.category !== "mortgage_advice"
  ) {
    next({ name: "requirement-recommendation-list", params: to.params })
  } else {
    next()
  }
}

const selectedProtectionLoadTemplatesGuard = (to, _, next) => {
  const docConfig = store.getters["documents/templateFormObject"]

  if (
    docConfig?.category === "undefined" ||
    docConfig?.category !== "protection_advice"
  ) {
    next({
      name: "client-case-requirement-protection-recommend-apply",
      params: to.params
    })
  } else {
    next()
  }
}

const selectedInsuranceLoadTemplatesGuard = (to, _, next) => {
  const docConfig = store.getters["documents/templateFormObject"]

  if (
    docConfig?.category === "undefined" ||
    docConfig?.category !== "home_insurance_advice"
  ) {
    next({
      name: "client-case-requirement-gi-recommend-apply",
      params: to.params
    })
  } else {
    next()
  }
}

function clearSnapshotIdGuard(to, from, next) {
  store.commit("cases/clearSelectedSnapshotId")
  next()
}

function setSnapshotIdGuard(to, from, next) {
  store.commit("cases/setSnapshotId", to.params.snapshotId)
  next()
}

async function loadSnapshotsGuard(to, from, next) {
  await store
    .dispatch("cases/loadSnapshots", to.params.caseId)
    .then(() => next())
}

function clearSelectedRequirement(to, from, next) {
  store.commit("requirements/clearSelectedRequirement")
  next()
}

function selectedDependantGuard(to, from, next) {
  if (Object.keys(store.getters["dependants/selectedDependant"]).length > 0) {
    next()
  } else {
    const name = to.name.includes("kyc-")
      ? "kyc-dependants-list"
      : "client-dependants-list"
    next({ name: name, params: to.params })
  }
}

function setExcludedClientsForCaseDependantsGuard(to, from, next) {
  const exludedClients = _getExcludedClientsForCurrentCaseFactfindKyc(
    "kyc_client_has_dependents"
  )
  store.commit("dependants/setExcludedClientsFromSelectionList", exludedClients)
  next()
}

function setExcludedClientsForCaseCommitmentsGuard(to, from, next) {
  const exludedClients = _getExcludedClientsForCurrentCaseFactfindKyc(
    "kyc_client_has_commitments"
  )
  store.commit(
    "commitments/setExcludedClientsFromSelectionList",
    exludedClients
  )
  next()
}

function setExcludedClientsForCaseCreditHistoryGuard(to, from, next) {
  const exludedClients = _getExcludedClientsForCurrentCaseFactfindKyc(
    "kyc_client_has_adverse_credit"
  )
  store.commit(
    "creditHistory/setExcludedClientsFromSelectionList",
    exludedClients
  )
  next()
}

function setExcludedClientsForCaseSavingsGuard(to, from, next) {
  const exludedClients = _getExcludedClientsForCurrentCaseFactfindKyc(
    "kyc_client_has_saving_investments"
  )
  store.commit("savings/setExcludedClientsFromSelectionList", exludedClients)
  next()
}

function setExcludedClientsForCasePoliciesGuard(to, from, next) {
  const exludedClients = _getExcludedClientsForCurrentCaseFactfindKyc(
    "kyc_client_has_protection_policies"
  )
  store.commit("policies/setExcludedClientsFromSelectionList", exludedClients)
  next()
}

function setExcludedClientsForCaseMortgagesGuard(to, from, next) {
  const exludedClients = _getExcludedClientsForCurrentCaseFactfindKyc(
    "kyc_client_has_mortgages"
  )
  store.commit("mortgages/setExcludedClientsFromSelectionList", exludedClients)
  next()
}

function setExcludedClientsForCasePropertiesGuard(to, from, next) {
  const exludedClients = _getExcludedClientsForCurrentCaseFactfindKyc(
    "kyc_client_has_properties"
  )
  store.commit("properties/setExcludedClientsFromSelectionList", exludedClients)
  next()
}

function _getExcludedClientsForCurrentCaseFactfindKyc(key) {
  return store.getters["cases/currentCase"].fact_find.kyc.reduce((ids, kyc) => {
    if (!kyc[key]) {
      ids.push(kyc.client_id)
    }
    return ids
  }, [])
}

function selectedSavingsClientsGuard(to, from, next) {
  if (store.getters["savings/selectedClients"].length > 0) {
    next()
  } else {
    const name = to.name.includes("kyc-")
      ? "kyc-savings-list"
      : "client-savings-list"
    next({ name: name, params: to.params })
  }
}

function clearCommission(to, from, next) {
  store.commit("commissions/clearCommissions")
  next()
}

async function loadConfigurationsGuard(to, from, next) {
  await store.dispatch("caseSectionValidation/loadConfigurations")
  next()
}

async function loadWorkflowConfigurationsGuard(to, from, next) {
  await store.dispatch("requirements/loadWorkflowConfigurations")
  next()
}

async function loadProvidersGuard(to, from, next) {
  await store.dispatch("providers/loadAllProviders")
  next()
}

//Add store getters in here..
const storeGetters = {
  getSelectedSnapshotId: () => store.getters["cases/selectedSnapshotId"]
}

//Add all store guards in here..
const storeGuards = {
  loadDashboardGuard,
  loadClientGuard,
  clientAccessGuard,
  loadDependantsGuard,
  loadCreditHistoryGuard,
  addProductGuard,
  editProductGuard,
  baseRequirementRouterGuard,
  loadEmploymentIncomeGuard,
  loadCommitmentsGuard,
  loadRequirementsGuard,
  loadProductsGuard,
  loadPropertiesGuard,
  createPropertyForAddGuard,
  selectedClientPropertyInfoGuard,
  propertyNotReadonlyGuard,
  loadMortgagesGuard,
  editMortgageInfoGuard,
  loadPoliciesGuard,
  addModePoliciesGuard,
  editModePoliciesGuard,
  selectedClientAndTypeFactFindCreditHistoryGuard,
  loadSavingsGuard,
  selectedSavingsGuard,
  loadCasesGuard,
  loadCaseGuard,
  loadCaseClientsGuard,
  loadNotesGuard,
  loadMessagesGuard,
  loadReportGuard,
  selectedCommissionGuard,
  selectedCommissionsTypeGuard,
  loadCaseDocumentsGuard,
  loadClientDocumentsGuard,
  pollUnreadMessages,
  loadClientPortalGuard,
  loadRequirementDocumentsGuard,
  loadIDVDocumentsGuard,
  loadCaseTasksGuard,
  selectedRequirementGuard,
  selectedRequirementPropertyGuard,
  loadAdvisorsGuard,
  loadApplicationsGuard,
  loadGIProducts,
  editApplicationGuard,
  addPurchaseRequirementPropertyGuard,
  loadScenariosGuard,
  editProductInfoGuard,
  viewAddressBookCompanyGuard,
  loadCaseCheckingGuard,
  editAddressBookCompanyGuard,
  editAddressBookBranchGuard,
  viewAddressBookBranchGuard,
  addAddressBookCompanyGuard,
  addAddressBookBranchGuard,
  addAddressBookIndividualGuard,
  editAddressBookIndividualGuard,
  loadCasesOverviewGuard,
  selectedOpportunityGuard,
  loadDocumentsTemplateGuard,
  createDocumentTemplateGuard,
  editDocumentTemplateGuard,
  loadKeyImportedSalesGuard,
  keyCurrentSaleGuard,
  loadBudgetPlannerGuard,
  editBudgetPlannerGuard,
  loadKeyImportDocuments,
  editRequirementsBuildingsContentGuard,
  loadCompaniesGuard,
  editGIProductGuard,
  addGIProductGuard,
  addDipGuard,
  editDipGuard,
  loadTasksGuard,
  loadAdminsGuard,
  loadPrincipalsGuard,
  loadAllTeamMembers,
  selectRequirementForApplicationGuard,
  loadScenariosForApplicationGuard,
  viewProtectionProductGuard,
  viewGIProductGuard,
  clearLoadedAndExistingClient,
  selectedMortgageLoadTemplatesGuard,
  selectedProtectionLoadTemplatesGuard,
  selectedInsuranceLoadTemplatesGuard,
  loadSnapshotsGuard,
  clearSnapshotIdGuard,
  setSnapshotIdGuard,
  clearSelectedRequirement,
  selectedDependantGuard,
  setExcludedClientsForCaseDependantsGuard,
  setExcludedClientsForCaseCreditHistoryGuard,
  setExcludedClientsForCaseCommitmentsGuard,
  setExcludedClientsForCaseSavingsGuard,
  setExcludedClientsForCasePoliciesGuard,
  setExcludedClientsForCaseMortgagesGuard,
  setExcludedClientsForCasePropertiesGuard,
  selectedSavingsClientsGuard,
  clearCommission,
  loadConfigurationsGuard,
  loadWorkflowConfigurationsGuard,
  loadProvidersGuard
}

export { chainGuards, storeGuards, storeGetters }
