import * as types from "../mutations/layout"

export const state = {
  sidebarCollapsed: false,
  casePageWidth: "100%",
  showPageFooter: true,
  showAdminSection: true,
  showWaitSpinner: false
}

export const getters = {
  sidebarCollapsed: (state) => state.sidebarCollapsed,
  casePageWidth: (state) => state.casePageWidth,
  showPageFooter: (state) => state.showPageFooter,
  showAdminSection: (state) => state.showAdminSection,
  showWaitSpinner: (state) => state.showWaitSpinner
}

export const mutations = {
  [types.TOGGLE_SIDEBAR](state, collapsed) {
    state.sidebarCollapsed = collapsed
  },
  setCasePageWidth(state, width) {
    state.casePageWidth = width + "%"
  },
  setFooterDisplayFlag(state, data) {
    state.showPageFooter = data
  },
  setAdminSectionFlag(state, data) {
    state.showAdminSection = data
  },
  setShowWaitSpinnerFlag(state, data) {
    state.showWaitSpinner = data
  }
}

export const actions = {
  toggleSidebar({ state, commit }) {
    const collapsed = state.sidebarCollapsed ? false : true
    if (collapsed) {
      window.document.body.classList.add("vertical-collpsed")
    } else {
      window.document.body.classList.remove("vertical-collpsed")
    }
    commit(types.TOGGLE_SIDEBAR, collapsed)
  }
}
