<template>
  <multiselect
    :id="componentId"
    v-model="localValue"
    style="min-width: 150px"
    select-label=""
    :track-by="trackBy"
    :label="label"
    :multiple="true"
    :close-on-select="false"
    :clear-on-select="true"
    :options="filterOptionsWithAll"
    :hide-selected="true"
    :searchable="true"
    :preselect-first="true"
  >
  </multiselect>
</template>
<script>
import { Multiselect } from "vue-multiselect"

export default {
  name: "MultiSelectFilter",
  components: {
    Multiselect
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: "text"
    },
    trackBy: {
      type: String,
      required: false,
      default: "value"
    },
    preSelect: {
      type: Boolean,
      default: true,
      required: false
    },
    value: {
      type: Array,
      default: () => null
    },
    forProviders: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        /**
         * If they deselect every option then automatically change selection to all i.e. no filter.
         * If they have most recently selected 'All' then remove other options to avoid confusion.
         */
        if (
          value.length === 0 ||
          value.at(-1).value === "all" ||
          value.at(-1).providerDbName === "all"
        )
          if (!this.forProviders) {
            value = [{ text: "All", value: "all" }]
          } else {
            value = [{ providerDisplayName: "All", providerDbName: "all" }]
          }
        // If 'All' is selected and then they select something else then remove 'All' so that we can filter
        // on whatever they've selected.
        else if (value[0]?.value === "all") value = value.slice(1)
        else if (value[0]?.providerDbName === "all") value = value.slice(1)

        this.$emit("input", value)
      }
    },
    filterOptionsWithAll() {
      if (!this.forProviders) {
        return [{ text: "All", value: "all" }].concat(this.options)
      } else
        return [{ providerDisplayName: "All", providerDbName: "all" }].concat(
          this.options
        )
    }
  }
}
</script>

<style>
.multiselect__select {
  background: white !important;
  border-radius: 10px;
  width: 25px;
  height: 35px;
}
</style>
