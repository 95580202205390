<template>
  <div class="dropdown d-inline-block">
    <button
      id="page-header-notifications-dropdown"
      type="button"
      class="btn header-item noti-icon waves-effect"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="loadNotifications"
    >
      <i class="bx bx-bell" :class="{ 'bx-tada': count }"></i>
      <span v-if="count" class="badge bg-primary rounded-pill">{{
        displayCount
      }}</span>
    </button>
    <div
      id="notifMenu"
      class="dropdown-menu dropdown-menu-lg notif-menu-end p-0"
      aria-labelledby="page-header-notifications-dropdown"
    >
      <div class="p-3">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="m-0">Notifications</h5>
          </div>
          <div class="col-auto">
            <button
              v-if="hasUnreadNotifications"
              class="btn btn-link text-decoration-underline"
              @click="markAllRead"
            >
              Mark all as read
            </button>
          </div>
        </div>
      </div>
      <div v-if="loading" class="text-center text-primary mb-3">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ms-2">Loading...</strong>
      </div>
      <div v-else-if="notifications.length === 0" class="text-center mb-3">
        <strong>You have no notifications currently.</strong>
      </div>
      <simplebar v-else style="max-height: 80vh">
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="text-reset notification-item"
        >
          <div class="media">
            <div class="media-body">
              <span class="font-size-10 text-muted">{{
                formatCreatedDate(notification.created_at)
              }}</span>
              <h6 class="mb-2">{{ getName(notification.created_by) }}</h6>
              <div>
                <div class="row">
                  <div class="col-2">
                    <span class="user-initials font-size-16">
                      {{ getNameInitials(notification.created_by) }}
                    </span>
                  </div>

                  <div class="col-8">
                    <template
                      v-for="parsedText in parseNotificationText(
                        mergeText(notification.text, notification.merge_fields)
                      )"
                    >
                      <!-- eslint-disable vue/require-v-for-key -->
                      <span v-if="!parsedText.link">{{ parsedText.text }}</span>
                      <span
                        v-else
                        class="
                          link-primary
                          text-decoration-underline
                          route-link
                        "
                        @click="
                          emitLinkInfo(
                            notification.link_info,
                            parsedText.linkIndex
                          )
                        "
                      >
                        {{ parsedText.text }}
                      </span>
                    </template>
                  </div>
                  <div class="col-2">
                    <span
                      v-if="!notification.read_at"
                      class="dot"
                      @click="markRead(notification.id)"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="notifications.length > 0" class="p-2 border-top d-grid">
          <p class="my-3 text-center">
            That's all your notifications for the last 30 days.
          </p>
        </div>
      </simplebar>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { formatDistanceToNowStrict, isAfter, subMinutes } from "date-fns"
import simplebar from "simplebar-vue"
import { parseLinkString } from "@/plugins/components-plugin/helpers/linkGeneratorHelpers"
export default {
  name: "NotificationDrawer",
  components: { simplebar },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      notifications: "notifications/notifications",
      count: "notifications/newCount",
      nameMappings: "notifications/nameMappings"
    }),
    displayCount() {
      return this.count > 9 ? "9+" : this.count
    },
    hasUnreadNotifications() {
      return this.notifications.some((noti) => noti.read_at === null)
    }
  },
  methods: {
    ...mapActions({
      startPolling: "notifications/pollNotifications",
      getNotifications: "notifications/getNotifications",
      markAllRead: "notifications/markAllNotificationsRead",
      markRead: "notifications/markNotificationRead",
      loadAdvisors: "advisors/loadAdvisors"
    }),
    mergeUsername(mergeFieldData) {
      // read merge-field data and get the user name
      let userData = mergeFieldData.find(function (x) {
        return x.merge_field_name === "username"
      })
      let userType = userData?.merge_field_data?.user_type
      let userId = userData?.merge_field_data?.user_id

      if (!userType || !userId || userType != "1") {
        //Admin user only
        //unable to merge user name
        console.warn("Unable to merge provided user data.")
        console.warn(JSON.parse(JSON.stringify(mergeFieldData)))
        return "Somebody"
      } else return this.getName(userId)
    },
    mergeText(text, mergeFieldData) {
      let self = this
      //e.g. '{{username}} assigned you a task'
      let mergedText = text.replace(/\{\{(.*?)\}\}/g, function (i, match) {
        if (match === "username") {
          if (mergeFieldData) {
            return self.mergeUsername(mergeFieldData)
          } else console.log("mergeFieldData is undefined")
        }
        return match
      })
      return mergedText
    },

    parseNotificationText(text) {
      return parseLinkString(text)
    },
    async loadNotifications() {
      this.loading = true
      await this.getNotifications()
      this.loading = false
    },
    formatCreatedDate(createdDate) {
      const date = new Date(createdDate)
      if (isAfter(date, subMinutes(new Date(), 1)))
        return "Less than a minute ago"
      return formatDistanceToNowStrict(date, { addSuffix: true })
    },
    emitLinkInfo(linkInfo, linkIndex) {
      this.$emit("linkClicked", { linkInfo: linkInfo, linkIndex: linkIndex })
    },
    getNameInitials(id) {
      let fullName = this.getName(id)
      if (!fullName) return ""
      let names = fullName.split(" ")
      if (names && names.length == 2) return names[0][0] + names[1][0]
      return ""
    },
    getName(id) {
      return (
        this.nameMappings.find((mapping) => mapping.value === id)?.text ?? ""
      )
    }
  },
  created() {
    this.loadAdvisors()
    this.$store.commit("notifications/stopPolling")
    this.startPolling()
  },
  mounted() {
    // Prevent clicks from hiding the notifications
    let notifMenu = document.getElementById("notifMenu")
    notifMenu.addEventListener("click", (event) => {
      event.stopPropagation()
    })
  }
}
</script>

<style scoped>
.dot {
  height: 10px;
  width: 10px;
  background-color: blue;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.user-initials {
  height: 40px;
  width: 40px;
  align-items: center;
  background-color: rgba(85, 110, 230, 1);
  color: white;
  display: flex;
  font-weight: 400;
  justify-content: center;
  border-radius: 50%;
}
.notif-menu-end[style] {
  left: 0 !important;
  right: auto !important;
}
.dropdown-menu-lg {
  width: 360px !important;
}
.route-link {
  cursor: pointer;
}
</style>
