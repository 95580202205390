import {
  chainGuards,
  storeGuards
} from "@/global/modules/middleware/checkStore"

export const protectionRequirementRoutes = [
  {
    path: "add/protectionrequirement/:requirementId",
    name: "client-case-requirement-protection",
    beforeEnter: chainGuards([
      storeGuards.selectedRequirementGuard,
      storeGuards.loadCaseTasksGuard,
      storeGuards.loadRequirementDocumentsGuard,
      storeGuards.loadNotesGuard
    ]),
    component: () =>
      import(
        /* webpackChunkName: "client-case-requirements-protection" */ "@/components/ux/clients/cases/requirements/protection/MainMenu.vue"
      ),
    children: [
      {
        path: "summary",
        name: "client-case-requirement-protection-summary",
        beforeEnter: storeGuards.loadNotesGuard,
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-protection-summary" */ "@/components/ux/clients/cases/requirements/protection/Summary.vue"
          ),
        meta: {
          title: "Summary"
        }
      },
      {
        path: "tasks",
        name: "client-case-requirement-protection-tasks",
        beforeEnter: storeGuards.loadNotesGuard,
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-protection-tasks" */ "@/components/ux/clients/cases/requirements/protection/Tasks.vue"
          ),
        meta: {
          title: "Tasks"
        }
      },
      {
        path: "notes",
        beforeEnter: storeGuards.loadNotesGuard,
        name: "client-case-requirement-protection-notes",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-protection-notes" */ "@/components/ux/clients/cases/requirements/protection/Notes.vue"
          ),
        meta: {
          title: "Notes"
        }
      },
      {
        path: "documents",
        name: "client-case-requirement-protection-documents",
        beforeEnter: storeGuards.loadNotesGuard,
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-protection-documents" */ "@/components/ux/clients/cases/requirements/protection/Documents.vue"
          ),
        meta: {
          title: "Documents"
        }
      },
      {
        path: "needs-analysis",
        name: "client-case-requirement-protection-needs-analysis",
        beforeEnter: storeGuards.loadNotesGuard,
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-protection-needs-analysis" */ "@/components/ux/clients/cases/requirements/protection/NeedsAnalysis/NeedsAnalysis.vue"
          ),
        meta: {
          title: "Needs Analysis"
        }
      },
      {
        path: "needs-preferences",
        name: "client-case-requirement-protection-needs-preferences",
        beforeEnter: storeGuards.loadNotesGuard,

        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-protection-needs-preferences" */ "@/components/ux/clients/cases/requirements/protection/NeedsPreferences/NeedsPreferences.vue"
          ),
        meta: {
          title: "Needs Preferences"
        }
      },
      {
        path: "research",
        name: "client-case-requirement-protection-research",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadProductsGuard
        ]),
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-protection-research" */ "@/components/ux/clients/cases/requirements/protection/Research/Research.vue"
          ),
        children: [
          {
            path: "",
            name: "requirement-protection-research-product-list",
            component: () =>
              import(
                /* webpackChunkName: "requirement-protection-research-product-list" */ "@/components/ux/clients/cases/requirements/protection/Research/ResearchAndSolutions.vue"
              ),
            meta: {
              title: "Research & Solutions"
            }
          },
          {
            path: "addproduct",
            beforeEnter: storeGuards.addProductGuard,
            name: "requirement-protection-research-product-create",
            component: () =>
              import(
                /* webpackChunkName: "requirement-protection-research-product-create" */ "@/components/ux/clients/cases/requirements/protection/Research/AddEditProduct.vue"
              ),
            meta: {
              excludeFromSnapshot: true
            }
          },
          {
            path: ":productId/details",
            beforeEnter: storeGuards.editProductGuard,
            name: "requirement-protection-research-product-details",
            component: () =>
              import(
                /* webpackChunkName: "requirement-protection-research-product-details" */ "@/components/ux/clients/cases/requirements/protection/Research/AddEditProduct.vue"
              )
          }
        ]
      },
      {
        path: "recommendation",
        name: "client-case-requirement-protection-recommend",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadProductsGuard
        ]),
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-protection-recommend" */ "@/components/ux/clients/cases/requirements/protection/recommendation/Recommend.vue"
          ),
        children: [
          {
            path: "",
            beforeEnter: storeGuards.loadWorkflowConfigurationsGuard,
            name: "client-case-requirement-protection-recommend-apply",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-protection-recommend-apply" */ "@/components/ux/clients/cases/requirements/protection/recommendation/RecommendApplyList.vue"
              ),
            meta: {
              title: "Recommend & Apply"
            }
          },
          {
            path: ":productId/details",
            beforeEnter: storeGuards.editProductGuard,
            name: "requirement-protection-recommendation-product-details",
            component: () =>
              import(
                /* webpackChunkName: "requirement-protection-recommendation-product-details" */ "@/components/ux/clients/cases/requirements/protection/Research/AddEditProduct.vue"
              )
          },
          {
            path: "protection-generate-document",
            name: "client-case-requirement-protection-generate-document",
            beforeEnter: storeGuards.selectedProtectionLoadTemplatesGuard,
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-protection-generate-document" */ "@/components/ux/shared/documents/LinkedDocument.vue"
              ),
            meta: {
              title: "Create Suitability Letter"
            }
          }
        ]
      }
    ],
    meta: {
      //requiresAuthCase: true,
      caseNavActive: true,
      title: "Requirement"
    }
  }
]
