<template>
  <div v-if="showOverlay" class="overlay">
    <div v-if="showSpinner" class="spinner-background">
      <div class="spinner-border text-primary"></div>
    </div>
    <div v-if="showText" class="spinner-text-background">
      <div class="spinner-text fs-3 text-primary">
        Apologies, almost done...
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      showOverlay: false,
      showSpinner: false,
      showSpinnerTimeoutId: null,
      showText: false,
      showTextTimeoutId: null
    }
  },
  computed: {
    ...mapGetters({
      shown: "layout/showWaitSpinner"
    })
  },
  created() {
    this.$watch("shown", (value) => {
      if (value) {
        this.show()
      } else {
        this.hide()
      }
    })
  },
  methods: {
    show() {
      this.showOverlay = true
      this.showSpinnerTimeoutId = setTimeout(
        () => (this.showSpinner = true),
        1000
      )
      this.showTextTimeoutId = setTimeout(() => (this.showText = true), 3000)
    },
    hide() {
      clearTimeout(this.showSpinnerTimeoutId)
      clearTimeout(this.showTextTimeoutId)
      this.showOverlay = false
      this.showSpinner = false
      this.showText = false
    }
  }
}
</script>

<style scoped>
.overlay {
  pointer-events: visible;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
}

.spinner-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #ffffff8c;
}

.spinner-border {
  position: relative;
  width: 7rem;
  height: 7rem;
  border-width: 0.5rem;
  z-index: 100002;
}

.spinner-text-background {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff3d;
}

.spinner-text {
  position: relative;
  margin-top: 13rem;
  z-index: 100001;
}
</style>
