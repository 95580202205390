import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"
import {
  ukAddressFields,
  internationalAddressFields
} from "@/global/modules/enum/addresses.js"

export const getters = {
  validation: (state, rootState, getters, rootGetters) => {
    return {
      company: {
        ...mapRulesForClientCompany(rootGetters)
      }
    }
  }
}

function mapRulesForClientCompany(rootGetters) {
  const fields = [
    "business_name",
    "business_type_key",
    "business_legal_status_key",
    "business_start_date",
    "companies_house_number",
    "financial_year_end_day",
    "financial_year_end_month_key",
    "business_sic_code_keys",
    "financial_year_ending_date",
    "currency_key",
    "ltd_company_net_profit_before_corporation_tax_amount",
    "ltd_company_net_profit_after_corporation_tax_amount",
    "is_business_director",
    "business_shareholding_percentage",
    ...ukAddressFields,
    ...internationalAddressFields
  ]

  const ukAddressCondition = {
    "==": [{ var: "is_non_uk_address" }, false]
  }

  const nonUkAddressCondition = {
    "==": [{ var: "is_non_uk_address" }, true]
  }

  const conditions = {}

  ukAddressFields.forEach((field) => {
    conditions[field] = ukAddressCondition
  })

  internationalAddressFields.forEach((field) => {
    conditions[field] = nonUkAddressCondition
  })

  const factFind = rootGetters["caseSectionValidation/factfind"]

  const rules = createValidationRules(
    (field) => factFind.required("company", "client_company", field),
    fields,
    conditions
  )

  return rules
}
