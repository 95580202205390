//TODO use mb-common-ui and move any new changes to mb-common-ui
import moment from "moment"
import { momentStandardDateFormat } from "@/global/modules/enum/constants"
require("twix")

import PQueue from "p-queue/dist"
const queue = new PQueue({ concurrency: 1 })

const calcAge = function (dateString) {
  if (!dateString) return ""
  const today = new Date()
  const birthDate = new Date(dateString)

  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

/**
 * Recursively searches object and child objects for any value that is not contained in the whitelist argument
 * Examples:
 * null returns false
 * {hello:null} returns false
 * {hello:[]} returns false
 * {hello:[null]} returns true (as all the arrays must be empty)
 * @param {*} objectOrValue
 * @param {*} whitelist (optional - defaults to [0, "", null, undefined]) these values are *ignored* when checking if the object contains any values
 */
const objectHasAnyValue = function (objectOrValue, whitelist) {
  if (whitelist === undefined) whitelist = [0, "", null, undefined]
  if (objectOrValue) {
    if (Array.isArray(objectOrValue)) {
      return Boolean(objectOrValue.length)
    }
    if (typeof objectOrValue == "object") {
      return Object.values(objectOrValue).some((v) => objectHasAnyValue(v))
    }
  }

  return !whitelist.some((e) => e === objectOrValue)
}

const yearsUntilAge = function (futureAge, dateString) {
  const ageUntil = parseInt(futureAge)

  if (isNaN(ageUntil)) {
    return "N/A"
  }

  const currentAge = functionService.calcAge(dateString)

  if (isNaN(currentAge)) {
    return "N/A"
  }
  return `${ageUntil - currentAge} years`
}

const getCellVariant = function (rowData) {
  const cellVariants = {}
  let variant = ""

  // set status variant
  if ("status" in rowData) {
    switch (rowData.status) {
      case "Incomplete":
        variant = "danger"
        break
      case "Not Yet Due":
        variant = ""
        break
      default:
        variant = "success"
        break
    }
    cellVariants.status = variant
  }

  // set due variant
  if ("due" in rowData) {
    switch (rowData.due) {
      case "Today":
        variant = "danger"
        break
      default:
        variant = ""
        break
    }
    cellVariants.due = variant
  }

  return cellVariants
}

const timeBetweenDates = function (dateFrom, dateTo) {
  if (!dateFrom || !dateTo) {
    return "-"
  }
  const start = moment(dateFrom, "YYYY-MM-DD")
  const end = moment(dateTo, "YYYY-MM-DD")
  const years = end.diff(start, "years")
  start.add(years, "years")
  const months = end.diff(start, "months")
  start.add(months, "months")
  const days = end.diff(start, "days")
  const yearsString = `${
    years > 0 ? `${years} ${years === 1 ? "Year" : "Years"} ` : ""
  }`
  const monthsString = `${
    months > 0 ? `${months} ${months === 1 ? "Month" : "Months"} ` : ""
  }`
  const daysString = `${
    days > 0 ? `${days} ${days === 1 ? "Day" : "Days"}` : ""
  }`
  return `${yearsString}${monthsString}${daysString}`
}

const timeRemaining = function (dateFrom, dateTo) {
  if (dateFrom) {
    if (dateTo) {
      var from = moment(dateFrom)
      var to = moment(dateTo)
      var years = to.diff(from, "year")
      from.add(years, "years")
      var months = to.diff(from, "months")
      from.add(months, "months")
      var yearsString = years === 1 ? "Year" : "Years"
      var monthsString = months === 1 ? "Month" : "Months"
      return `${years} ${yearsString} ${months} ${monthsString}`
    }
  }
  return "-"
}

const addMonthsToDate = function (dateFrom, monthsToAdd) {
  if (dateFrom && monthsToAdd) {
    var from = moment(dateFrom)
    var future_date = moment(from).add(monthsToAdd, "months")
    return moment(future_date).format("YYYY-MM-DD")
  }
  return null
}

const addDaysToDate = function (dateFrom, daysToAdd) {
  if (dateFrom && daysToAdd) {
    var from = moment(dateFrom)
    var future_date = moment(from).add(daysToAdd, "days")
    return moment(future_date).format("YYYY-MM-DD")
  }
  return null
}

const formatDateForDisplay = function (
  date,
  emptyValueString = "",
  format = null
) {
  if (emptyValueString == "default_placeholder") emptyValueString = "--/--/----"
  if (!date) {
    return emptyValueString
  }
  date = moment(date)
  if (!format) format = momentStandardDateFormat
  return date.format(format)
}

const formatNumberAsCurrency = function (
  value,
  alwaysShowPence = false,
  emptyValueString = ""
) {
  if (value !== 0 && !value) return emptyValueString
  let wholeNumberInput = value % 1 == 0
  // Set the minimum decimal places to 2 if there is a pence value to avoid showing eg. £100.1 for 100.10
  let minDecimals = alwaysShowPence || !wholeNumberInput ? 2 : 0
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: minDecimals
  }).format(value)
}

const debounce = function (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

const numberTwoDecimalPlace = function (value) {
  const valueTocheck = parseFloat(value)
  if (isNaN(valueTocheck)) {
    return 0
  } else if (Number.isInteger(valueTocheck)) return valueTocheck
  else return valueTocheck.toFixed(2)
}

const datesOverlap = function (startDateA, endDateA, startDateB, endDateB) {
  var d1 = moment(startDateA).twix(endDateA)
  var d2 = moment(startDateB).twix(endDateB)
  return d1.overlaps(d2)
}

const dateDiffDays = function (dateA, dateB) {
  var a = moment(dateA)
  var b = moment(dateB)
  return a.diff(b, "days")
}

const dateBetweenDateDays = function (sampleDate, dateFrom, dateTo) {
  var a = moment(sampleDate)
  var b = moment(dateFrom)
  var c = moment(dateTo)
  return a.isSameOrAfter(b, "days") && a.isSameOrBefore(c, "days")
}

/**
 *
 * See https://momentjs.com/docs/#/displaying/difference/ for more info
 *
 * @param {*} dateA
 * @param {*} dateB
 * @param {*} unitOfTime e.g. 'seconds', 'hours', 'days', 'weeks', 'months', 'years'
 * @param {*} precise By default, will truncate the result to zero decimal places, returning an integer. If you want a floating point number, pass true
 * @returns difference between dateA and dateB, using specified unitOfTime measurement and rounding/precision scheme
 *
 */
const dateDiff = function (dateA, dateB, unitOfTime = null, precise = false) {
  var a = moment(dateA)
  var b = moment(dateB)
  if (unitOfTime != null) return a.diff(b, unitOfTime, precise)
  return a.diff(b)
}

const removeDuplicateObjectUsingId = function (obj) {
  if (obj !== null) {
    let filteredObj = []
    filteredObj = obj.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
    return filteredObj
  }
}

const calculatePixelWidthOfString = function (value, el = document.body) {
  //const el = document.body

  const fontWeight = getCssStyle(el, "font-weight") || "normal"
  const fontSize = getCssStyle(el, "font-size") || "16px"
  const fontFamily = getCssStyle(el, "font-family") || "Times New Roman"

  const currentFont = `${fontWeight} ${fontSize} ${fontFamily}`

  return Math.ceil(getTextWidth(" " + value + " ", currentFont))

  function getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement("canvas"))
    const context = canvas.getContext("2d")
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }

  function getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop)
  }
}

const calculateMinWidthForSelectInput = function (options, label) {
  if (!Array.isArray(options) || options.length === 0) {
    return 80
  }

  var optionWithLongestText = options.reduce(function (a, b) {
    return (a[label]?.length ?? 0) > (b[label]?.length ?? 0) ? a : b
  })

  return calculatePixelWidthOfString(optionWithLongestText[label]) + 72
}

const calculateMinWidthForTextInput = function (text, el = document.body) {
  return calculatePixelWidthOfString(text, el) + 20
}

const queueAction = async function (fn) {
  return queue.add(fn)
}

const joinArrayByCommasAndAmpersand = function (arr, ampersandWhen2 = true) {
  if (!arr) return ""
  if (arr.length == 1) return arr[0]
  if (arr.length == 2) {
    var sep = ampersandWhen2 ? " & " : ", "
    return arr[0] + sep + arr[1]
  }
  return arr.slice(0, -1).join(", ") + " & " + arr[arr.length - 1]
}

const objectToQueryString = function (obj) {
  const queryString = Object.keys(obj)
    .map((key) => {
      if (Array.isArray(obj[key])) {
        return obj[key]
          .map(
            (value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&")
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      }
    })
    .join("&")
  return queryString
}

const filterObject = function (predicate, obj) {
  return Object.keys(obj)
    .filter((key) => predicate(key, obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {})
}

export const functionService = {
  objectHasAnyValue,
  calcAge,
  getCellVariant,
  timeBetweenDates,
  yearsUntilAge,
  addMonthsToDate,
  addDaysToDate,
  timeRemaining,
  formatNumberAsCurrency,
  formatDateForDisplay,
  debounce,
  datesOverlap,
  dateDiffDays,
  dateDiff,
  removeDuplicateObjectUsingId,
  numberTwoDecimalPlace,
  calculatePixelWidthOfString,
  calculateMinWidthForSelectInput,
  calculateMinWidthForTextInput,
  queueAction,
  joinArrayByCommasAndAmpersand,
  dateBetweenDateDays,
  objectToQueryString,
  filterObject
}
