import * as axios from "axios"
import moment from "moment"
import { momentdbDateFormat } from "@/global/modules/enum/constants"
export const state = {
  list: [],
  fetching: false,
  errorForDisplay: ""
}

export const mutations = {
  addEpc(state, data) {
    data.currentEnergyRating = data.currentEnergyRating?.toLowerCase()
    data.potentialEnergyRating = data.potentialEnergyRating?.toLowerCase()
    data.inspectionDate = moment(data.inspectionDate).format(momentdbDateFormat)
    data.fullAddress = epcFormatAddress(data)
    state.list.push(data)
  },
  addErrorForDisplay(state, error) {
    state.errorForDisplay = error
  },
  clearErrorForDisplay(state) {
    state.errorForDisplay = ""
  }
}

export const actions = {
  async getEpc({ commit, state }, info) {
    state.fetching = true

    state.list = []
    commit("clearErrorForDisplay")

    try {
      const response = await axios.post(
        process.env.VUE_APP_PROPERTY_URL + "/epc",
        info
      )
      //ensure error is added on failure as its required by PropertyDetails
      if (response.status !== 200) {
        commit("addErrorForDisplay", response.message)
      } else {
        const data = response.data
        commit("addEpc", data)
      }
    } catch (err) {
      commit("addErrorForDisplay", err.response.data)
      console.log(err)
    }
    state.fetching = false
  }
}
function epcFormatAddress(epc) {
  //this function is specifically different from the AddessMixin function,
  //as it is formatting result from EPC, not our address obj
  var toFormat = [
    epc.addressLine1,
    epc.addressLine2,
    epc.addressLine3,
    filteredJoin([epc.postTown, epc.postcode], " ")
  ]
  return filteredJoin(toFormat, ", ")
}
function filteredJoin(arr, joinStr) {
  return arr.filter((e) => e).join(joinStr)
}
