// import Vue from "vue"
import axios from "axios"
import store from "@/store"

export const state = {
  messages: [],
  currentMessage: {},
  unreadMessagesCount: 0,
  unreadPollId: null,
  reloadMessagesIfUnread: false
}
export const mutations = {
  setMessages(state, messages) {
    state.messages = messages
  },
  setUnreadCount(state, count) {
    state.unreadMessagesCount = count
  },
  setTimeoutId(state, timeoutId) {
    state.unreadPollId = timeoutId
  },
  stopPolling(state) {
    clearTimeout(state.unreadPollId)
  },
  setReloadMessages(state, value) {
    state.reloadMessagesIfUnread = value
  }
}

export const getters = {
  getMessages: (state) => state.messages,
  getUnreadCount: (state) => state.unreadMessagesCount
}

export const actions = {
  async loadMessages({ commit }) {
    var caseId = store.getters["cases/currentCaseId"]
    return await axios
      .get(`${process.env.VUE_APP_CDS_URL}/message/${caseId}`)
      .then((response) => {
        commit("setMessages", response.data)
        commit("setUnreadCount", 0)
        Promise.resolve(true)
      })
  },
  async pollNewMessagesCount({ dispatch, commit, state }) {
    let url = `${process.env.VUE_APP_CDS_URL}/message/${store.getters["cases/currentCaseId"]}/count`
    let response = await axios.get(url)
    if (response.status === 200) {
      commit("setUnreadCount", response.data)
      // Reload messages if there are unread messages.
      if (response.data != 0 && state.reloadMessagesIfUnread)
        dispatch("loadMessages")
    }
    let timeoutId = setTimeout(() => {
      dispatch("pollNewMessagesCount")
    }, process.env.VUE_APP_MESSAGE_COUNT_POLL_INTERVAL)
    commit("setTimeoutId", timeoutId)
  },

  async sendMessages({ dispatch }, messages) {
    var payload = messages
    return await axios
      .post(`${process.env.VUE_APP_CDS_URL}/message`, payload)
      .then(() => {
        return dispatch("loadMessages")
      })
  }
}
