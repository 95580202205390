import { ApolloClient } from "apollo-client"
import { setContext } from "apollo-link-context"
import { InMemoryCache } from "apollo-cache-inmemory"
import * as Realm from "realm-web"
import { createHttpLink } from "@apollo/client/core"
import { getDictionaryInstance } from "@/plugins/components-plugin/plugins/dictionary"

let app_id = process.env.VUE_APP_DATA_DICTIONARY_APP_ID
let app_url = process.env.VUE_APP_DATA_DICTIONARY_URL
let apolloInstance2

export const getApolloInstance = () => apolloInstance2

const app = new Realm.App(app_id)

async function getBearerToken() {
  if (!app.currentUser) {
    const authService = getDictionaryInstance().getAuthClient()
    const token = await authService.getTokenSilently()
    await app.logIn(Realm.Credentials.jwt(token))
  } else {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    await app.currentUser.refreshCustomData()
  }
  return app.currentUser.accessToken
}
const httpLink = createHttpLink({
  uri: app_url
})

const authLink = setContext(async (_, { headers }) => {
  let token = await getBearerToken()
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  }
})

export const createApolloClient = (options) => {
  // assign values from the main project
  app_id = options.id
  app_url = options.url

  if (apolloInstance2) return apolloInstance2

  apolloInstance2 = new ApolloClient({
    // Provide the URL to the API server.
    link: authLink.concat(httpLink),
    // Using a cache for blazingly
    // fast subsequent queries.
    cache: new InMemoryCache()
  })
}

export const ApolloPlugin = {
  install(Vue, options) {
    Vue.prototype.$apollo2 = createApolloClient(options)
  }
}
