<template>
  <input
    class="form-control"
    type="number"
    :required="required"
    :class="{ 'is-invalid': highlightAsRequired }"
    :value="convertFromWantedType(field.data_type, value)"
    :data-cy="field.form_id"
    :min="attributes.min"
    :max="attributes.max"
    :step="attributes.step"
    :placeholder="attributes.placeholder"
    :disabled="readonly"
    :readonly="readonly"
    @input="
      $emit('input', convertToWantedType(field.data_type, $event.target.value))
    "
    @change="(e) => emitDelayed('userupdated', e)"
  />
</template>

<script>
import FormInputHelpers from "@/plugins/components-plugin/global/modules/mixins/formInputHelpers.js"
import ConversionsMixin from "@/plugins/components-plugin/global/modules/mixins/conversions"
export default {
  name: "MbNumberInput",
  mixins: [ConversionsMixin, FormInputHelpers],
  props: {
    field: {
      type: Object,
      default: null
    },
    value: {
      type: [String, Number],
      default: ""
    },
    // Override default input attributes
    attrs: {
      type: Object,
      default: () => {
        return {}
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    highlightAsRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attributes: {
        min: "",
        max: "",
        step: "",
        placeholder: ""
      }
    }
  },
  computed: {
    required() {
      return this.field.validation.required || false
    }
  },
  beforeMount() {
    // If the dictionary item has an input config apply
    // to the input attributes
    if (this.field.input_config || false) {
      this.attributes = Object.assign(this.attributes, this.field.input_config)
    }
    // Apply any local overrides
    this.attributes = Object.assign(this.attributes, this.attrs)
  }
}
</script>

<style scoped>
/* Hide the arrows */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
