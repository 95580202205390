<template>
  <multiselect
    :id="componentId"
    v-model="localValue"
    style="min-width: 250px"
    :class="{
      'multiselect-required-border': highlightAsRequired
    }"
    :placeholder="placeholder"
    select-label=""
    :track-by="'text'"
    :label="'text'"
    :readonly="true"
    :multiple="true"
    :close-on-select="false"
    :clear-on-select="true"
    :options="getOptions()"
    :hide-selected="true"
    :searchable="false"
    :preselect-first="preselectOnSingleOption"
    :disabled="disabled"
  >
    <!--
      :limit="x" // will limit the amount of items shown selected
      :limit-text="function" // function which recieves a function that returns a string to show
      when :limit is reached.
      :options-limit // limits amount you are able to put in the list
      :hide-selected="true" // once an item is selected, it will be removed from the list.
    -->
  </multiselect>
</template>
<script>
import { Multiselect } from "vue-multiselect"

export default {
  name: "MultiSelect2", //Note to who ever moves everything over to this multi select,
  //ensure the other one is renamed ClientMultiSelect and this one MultiSelect
  components: {
    Multiselect
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: null
    },

    placeholder: {
      type: String,
      required: false,
      default: "Select all that apply..."
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    guid: {
      type: String,
      default: null,
      required: false
    },
    display: {
      type: Array,
      default: null,
      required: false
    },
    preSelect: {
      type: Boolean,
      default: true,
      required: false
    },
    value: {
      type: Array,
      default: () => null
    },
    highlightAsRequired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    preselectOnSingleOption() {
      return this.options.length == 1 ? (this.preSelect ? true : false) : false
    },
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  watch: {
    disabled: function () {
      this.disabledProperties()
    }
  },
  mounted() {
    this.disabledProperties()
  },
  methods: {
    selectedValues() {
      let multiSelect = document.querySelector(
        `[aria-owns="listbox-${this.componentId}"]`
      )
      if (multiSelect) {
        if (this.localValue?.length == this.options?.length) {
          let optionDialog = multiSelect.getElementsByClassName(
            "multiselect__content"
          )[0]
          optionDialog?.classList.add("d-none")
        } else {
          let optionDialog = multiSelect.getElementsByClassName(
            "multiselect__content"
          )[0]
          optionDialog?.classList.remove("d-none")
        }
      }
    },

    disabledProperties() {
      let multiSelect = document.querySelector(
        `[aria-owns="listbox-${this.componentId}"]`
      )
      if (multiSelect) {
        let selectIcon = multiSelect.getElementsByClassName(
          "multiselect__select"
        )[0]
        let removeIcon = multiSelect.getElementsByClassName(
          "multiselect__tag-icon"
        )
        if (this.disabled) {
          selectIcon?.classList.add("d-none")
          for (let icon of removeIcon) {
            icon?.classList.add("d-none")
          }
        } else {
          selectIcon?.classList.remove("d-none")
          for (let icon of removeIcon) {
            icon?.classList.remove("d-none")
          }
        }
      }
    },
    limitText(amount) {
      return "and " + amount + " more.."
    },
    getOptions() {
      if (this.options) {
        this.selectedValues()
        return this.options
      }
    }
  }
}
</script>
<style>
.multiselect__select {
  background: white !important;
  border-radius: 10px;
  width: 25px;
  height: 35px;
}
.multiselect__tag {
  line-height: 0.9;
}
.multiselect__tags {
  min-height: 36px;
  line-height: 0.9 !important;
}
.multiselect--disabled {
  min-height: 36px;
  opacity: 0.7;
}
.multiselect__placeholder {
  margin-top: 5px;
  color: black !important;
  font-size: 13px;
}
.multiselect {
  min-width: 150px;
  width: fit-content;
}
</style>
