import axios from "axios"
import { Mutex } from "async-mutex"

export const state = {
  data: [],
  loadOpportunitiesMutex: new Mutex(),
  loadOpportunitiesAxiosController: null,
  opportunities: [],
  selectedOpportunity: {},
  opportunityMeta: {}
}
function abortLoadOpportunitiesAxiosController(state) {
  if (state.loadOpportunitiesAxiosController) {
    try {
      state.loadOpportunitiesAxiosController.abort(
        "Operation cancelled by the user."
      )
    } catch (ex) {
      console.error(ex)
    }
  }
}
async function loadOpportunitiesWithMutexAsync(state, url) {
  let request = state.loadOpportunitiesMutex.runExclusive(async () => {
    abortLoadOpportunitiesAxiosController(state)
    state.loadOpportunitiesAxiosController = new AbortController()
    return axios.get(url, {
      signal: state.loadOpportunitiesAxiosController.signal
    }) //no await, return the raw promise.
  }) //end mutex
  return await request
}

export const mutations = {
  setOpportunities: (state, data) => (state.opportunities = data),
  setSelectedOpportunity: (state, data) => (state.selectedOpportunity = data),
  setOpportunityMetadata: (state, data) => {
    state.opportunityMeta = data
  },
  clearOpportunities(state) {
    state.data = []
  }
}

export const getters = {
  selectedOpportunity: (state) => state.selectedOpportunity,
  opportunityMetaData: (state) => state.opportunityMeta,
  allOpportunities: (state) => state.opportunities,
  data: (state) => state.data
}

export const actions = {
  async createOpportunity({ dispatch }, payload) {
    return await axios
      .post(`${process.env.VUE_APP_CDS_URL}/opportunities`, payload)
      .then(async (response) => {
        if (response.status >= 200 && response.status <= 299) {
          await dispatch("loadOpportunity", response.data)
          return Promise.resolve(response.data)
        }
        return Promise.resolve(null)
      })
  },
  async loadAllOpportunities({ commit }, ids) {
    return await axios
      .post(`${process.env.VUE_APP_CDS_URL}/opportunities/getforclientids`, ids)
      .then((response) => {
        if (response.status === 200) {
          commit("setOpportunities", response.data)
          return Promise.resolve(true)
        }
      })
  },
  async loadOpportunity({ commit }, id) {
    return await axios
      .get(`${process.env.VUE_APP_CDS_URL}/opportunities/${id}`)
      .then((response) => {
        if (response.status === 200) {
          commit("setSelectedOpportunity", response.data)
          return Promise.resolve(true)
        }
      })
  },
  async updateOpportunity({ dispatch }, payload) {
    return await axios
      .put(`${process.env.VUE_APP_CDS_URL}/opportunities`, payload)
      .then(async (response) => {
        if (response.status >= 200 && response.status <= 299) {
          return await dispatch("loadOpportunity", payload.opportunity.id)
        }
      })
  },
  async convertToFullCase(context, payload) {
    return await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/opportunities/converttofullcase`,
        payload
      )
      .then(async (response) => {
        if (response.status >= 200 && response.status <= 299) {
          return Promise.resolve(response.data)
        }
        return Promise.reject("Error converting to full case")
      })
  },
  async loadOpportunities(
    { state },
    {
      pageNumber,
      perPage,
      descending,
      advisorIds,
      includeClosed,
      filter,
      sortColumn,
      followUpdate,
      opportunityType
    }
  ) {
    let opportunitiesCount = null
    let url = `${process.env.VUE_APP_CDS_URL}/opportunities/list?PageNumber=${pageNumber}&ItemsPerPage=${perPage}&SortDescending=${descending}`

    if (advisorIds) {
      if (advisorIds[0] != "all") {
        let filterAdvisorIdsList = ""
        advisorIds.forEach((id) => {
          if (id === "unassigned") {
            filterAdvisorIdsList += "&advisor_ids="
          } else {
            filterAdvisorIdsList += `&advisor_ids=${id}`
          }
        })
        url = `${url}${filterAdvisorIdsList}`
      }
    }

    if (includeClosed) {
      url = `${url}&FilterIncludeClosedItems=true`
    }

    if (filter) {
      let text = encodeURIComponent(filter)
      url = `${url}&FilterText=${text}`
    }

    if (sortColumn) {
      url = `${url}&SortColumn=${sortColumn}`
    }

    if (followUpdate) {
      if (followUpdate != "all") {
        url = `${url}&follow_up_date=${followUpdate}`
      }
    }

    if (opportunityType) {
      if (opportunityType != "all") {
        url = `${url}&opportunity_type=${opportunityType}`
      }
    }

    let response = await loadOpportunitiesWithMutexAsync(state, url)
    if (response === undefined) {
      //response was canceled
      return state.data?.length ?? 0
    }
    if (response.status === 200) {
      opportunitiesCount = response.data.total_count
      state.data = response.data.data
    }
    return opportunitiesCount
  },
  loadOpportunitiesCancel({ state }) {
    abortLoadOpportunitiesAxiosController(state)
  }
}
