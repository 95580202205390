import axios from "axios"
export const state = {
  productTypeData: [],
  hasProtectionAppData: [],
  loanAmountData: [],
  offerStatusData: []
}

export const actions = {
  async getApplicationProductTypesSummary({ commit }, filters) {
    try {
      let url = `${process.env.VUE_APP_REPORTS_URL}/applicationinsights/producttypes`
      let response = await axios.post(url, filters)
      if (response.status === 200) {
        commit("setProductTypeData", response.data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async getHasProtectionAppSummary({ commit }, filters) {
    try {
      let url = `${process.env.VUE_APP_REPORTS_URL}/caseinsights/hasprotectionapplication`
      let response = await axios.post(url, filters)
      if (response.status === 200) {
        commit("setHasProtectionAppData", response.data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async getLoanAmountSummary({ commit }, { filters, maxProviders }) {
    try {
      let url = maxProviders
        ? `${process.env.VUE_APP_REPORTS_URL}/applicationinsights/loanamounts?maxProviders=${maxProviders}`
        : `${process.env.VUE_APP_REPORTS_URL}/applicationinsights/loanamounts`
      let response = await axios.post(url, filters)
      if (response.status === 200) {
        commit("setLoanAmountData", response.data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async getOfferStatusSummary({ commit }, filters) {
    try {
      let url = `${process.env.VUE_APP_REPORTS_URL}/applicationinsights/offerstatus`
      let response = await axios.post(url, filters)
      if (response.status === 200) {
        commit("setOfferStatusData", response.data)
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export const getters = {
  productTypeData: (state) => {
    return (
      state.productTypeData
        .filter((summaryData) => summaryData.label)
        // Sort alphabetically to make sure we have consistent colouring applied for each label
        .sort((a, b) => a.label.localeCompare(b.label))
        // Group mortgage counts together in chart
        .sort((a, b) => {
          if (
            a.label.toLowerCase().includes("mortgage") &&
            !b.label.toLowerCase().includes("mortgage")
          )
            return -1
          return 1
        })
    )
  },
  offerStatusData: (state) =>
    state.offerStatusData.map((data) => {
      return {
        ...data,
        label: data.is_true ? "Post-offer" : "Pre-offer"
      }
    }),
  hasProtectionAppData: (state) =>
    state.hasProtectionAppData
      .filter(
        (summaryData) =>
          summaryData.is_true === true || summaryData.is_true === false
      )
      .map((summaryData) => {
        return {
          ...summaryData,
          label: summaryData.is_true
            ? "Mortgage and Protection Cases"
            : "Mortgage only Cases"
        }
      }),
  totalPerProvider: (state) =>
    state.loanAmountData.sort((a, b) => {
      if (a.provider_display.toLowerCase() > b.provider_display.toLowerCase())
        return 1
      return -1
    }) ?? [],
  totalLoanAmount: (state) =>
    state.loanAmountData.reduce((prev, current) => prev + current.total_loan, 0)
}

export const mutations = {
  setProductTypeData(state, data) {
    state.productTypeData = data
  },
  setHasProtectionAppData(state, data) {
    state.hasProtectionAppData = data
  },
  setLoanAmountData(state, data) {
    state.loanAmountData = data
  },
  setOfferStatusData(state, data) {
    state.offerStatusData = data
  }
}
