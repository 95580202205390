import rawAxios from "axios"

export const namespaced = true

export const state = {
  advisors: []
}

export const mutations = {
  clearAdvisors(state) {
    state.advisors = []
  },
  addAdvisor(state, data) {
    state.advisors.push(data)
  }
}

export const getters = {
  advisors: (state) => state.advisors,
  getAdvisorUserName: (state) => (id) =>
    state.advisors.find((advisor) => advisor.value === id)?.text || null
}

export const actions = {
  async loadAdvisors(context) {
    const axios =
      process.env.NODE_ENV === "production" ? this.$mbAxios : rawAxios
    await axios
      .get(
        `${process.env.VUE_APP_ADMIN3_SERVICE_URL}/api/v1/account/users/gate/is_advisor`
      )
      .then((response) => {
        context.commit("clearAdvisors")
        response.data.data.forEach((advisorData) => {
          mapAdvisorsForList(advisorData, context)
        })
      })
  }
}

function mapAdvisorsForList(advisorData, context) {
  let advisorForList = {
    text: `${advisorData.first_name} ${advisorData.last_name}`,
    value: advisorData._id
  }
  context.commit("addAdvisor", advisorForList)
}
