<template>
  <div class="row d-flex">
    <div
      class="btn-group fi-radio-group-btns"
      role="group"
      :class="{ 'radio-group-is-invalid': highlightAsRequired }"
    >
      <div v-for="(radio, index) in field.radio_group_values" :key="index">
        <input
          :id="`${radioGroupId}-${index}`"
          v-model="radiovalue"
          type="radio"
          class="btn-check"
          autocomplete="off"
          :disabled="readonly"
          :readonly="readonly"
          :name="`btn-${radioGroupId}`"
          :value="radio.value"
          :data-cy="dataCy + '-option-' + index"
          @change="(e) => emitDelayed('userupdated', e)"
        />
        <label
          :class="'btn btn-outline-primary mb-0 ' + inputLabelClass"
          :for="`${radioGroupId}-${index}`"
          :value="value"
        >
          {{ radio.text }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputHelpers from "@/plugins/components-plugin/global/modules/mixins/formInputHelpers.js"
import ConversionsMixin from "@/plugins/components-plugin/global/modules/mixins/conversions"
export default {
  mixins: [ConversionsMixin, FormInputHelpers],
  props: {
    field: {
      type: Object,
      default: null
    },
    value: {
      type: [String, Boolean],
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dataCy: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlightAsRequired: {
      type: Boolean,
      default: false
    },
    inputLabelClass: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      radiovalue: ""
    }
  },
  computed: {
    required() {
      return this.field.validation.required ?? false
    },
    radioGroupId() {
      return "radio-" + this.componentId
    }
  },
  watch: {
    radiovalue(val) {
      if (val != null) {
        this.$emit("input", this.convertToWantedType(this.field.data_type, val))
      }
    },
    value(val) {
      this.radiovalue = this.convertFromWantedType(this.field.data_type, val)
    }
  },
  mounted() {
    this.radiovalue = this.convertFromWantedType(
      this.field.data_type,
      this.value
    )
  }
}
</script>

<style lang="scss" scoped>
.fi-radio-group-btns div:not(:last-of-type) label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fi-radio-group-btns div:not(:first-of-type) label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fi-radio-group-btns div:not(:first-child) label {
  margin-left: -1px;
}
</style>
