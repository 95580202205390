import * as axios from "axios"
export const state = {
  list: [],
  templateList: [],
  unifiedPlatformTemplateList: [],
  selectedDocument: {},
  fetching: false,
  errorForDisplay: "",
  selectedDocumentId: "",
  selectedDocumentFilename: "",
  selectedDocumentDescription: "",
  selectedClientsDetails: [],
  leaseKey: "",
  mergeDocumentData: {},
  selectedCategory: "",
  selectedSubCategories: [],
  selectedSection: "",
  caseDocument: {},
  templateFormObject: {},
  documentProperties: {},
  currentTemplate: {},
  showGenerateDocument: false,
  showUploadDocument: false,
  showCaseDocumentEditor: false,
  isNew: true,
  forceRefresh: false,
  documentTemplateSettingsConfig: null
}

export const getters = {
  documentList: (state) => state.list,
  templateDocumentList: (state) => state.templateList,
  unifiedPlatformTemplateList: (state) => state.unifiedPlatformTemplateList,
  accountId: (state, getters, rootState, rootGetters) =>
    rootGetters["mbuser/user"].account_id,
  selectedDocumentId: (state) => state.selectedDocumentId,
  selectedDocument: (state) => state.selectedDocument,
  selectedDocumentFilename: (state) => state.selectedDocumentFilename,
  selectedDocumentDescription: (state) => state.selectedDocumentDescription,
  leaseKey: (state) => state.leaseKey,
  mergeDocumentData: (state) => state.mergeDocumentData,
  selectedClientsDetails: (state) => state.selectedClientsDetails,
  selectedCategory: (state) => state.selectedCategory,
  selectedSubCategories: (state) => state.selectedSubCategories,
  selectedSection: (state) => state.selectedSection,
  isAddMode: (state) => state.isNew,
  caseDocument: (state) => state.caseDocument,
  templateFormObject: (state) => state.templateFormObject,
  showGenerateDocument: (state) => state.showGenerateDocument,
  showUploadDocument: (state) => state.showUploadDocument,
  showCaseDocumentEditor: (state) => state.showCaseDocumentEditor,
  documentProperties: (state) => state.documentProperties,
  currentTemplate: (state) => state.currentTemplate,
  docForceRefresh: (state) => state.forceRefresh,
  isInNetwork: (_0, _1, _2, rootGetters) =>
    Boolean(rootGetters["mbuser/user"].network_id),
  documentTemplateSettingsConfig: (state) =>
    state.documentTemplateSettingsConfig
}
export const mutations = {
  addDocument(state, { id, metadata, properties, clients, auth }) {
    let clientNames = clients
      .filter((client) => metadata.clients?.includes(client.id))
      .reduce((names, client) => {
        names.push(client.fullname)
        return names
      }, [])
      .join(", ")
    state.list.push({
      id: id,
      filename: properties.filename,
      description: metadata.description?.toString(),
      lastModified: properties.lastmodified ?? null,
      tags: metadata.tags,
      period: metadata.periods ? metadata.periods.toString() : null,
      req: metadata.reqs ? metadata.reqs.toString() : null,
      dateAdded: metadata.date_added ? metadata.date_added.toString() : null,
      addedBy: metadata.added_by ? metadata.added_by[0] : null,
      category: metadata.category,
      subcategories: metadata.subcategories,
      section: metadata.section,
      clients: clientNames,
      route_name: metadata.route_name,
      metadata: metadata,
      auth: auth
    })
  },
  addTemplateDocument(state, { id, metadata, properties }) {
    state.templateList.push({
      id: id,
      filename: properties.filename,
      description: metadata.description[0],
      lastModified: properties.lastmodified ?? null,
      tags: metadata.tags,
      dateAdded: metadata.date_added ? metadata.date_added[0] : null,
      addedBy: metadata.added_by ? metadata.added_by[0] : null,
      updatedBy: metadata.updated_by ? metadata.updated_by[0] : null
    })
  },
  addUnifiedPlatformTemplate(state, payload) {
    state.unifiedPlatformTemplateList.push(payload)
  },
  addErrorForDisplay(state, error) {
    state.errorForDisplay = error
  },
  clearDocuments(state) {
    state.list = []
  },
  setTemplateInfo(state, { docId, filename, description }) {
    state.isNew = false
    state.selectedDocumentId = docId
    state.selectedDocumentFilename = filename
    state.selectedDocumentDescription = description
  },
  setTemplateFormObject(state, payload) {
    state.templateFormObject = payload
    state.showGenerateDocument = payload.isAdd
    state.isNew = payload.isAdd
  },
  setCurrentTemplate(state, payload) {
    state.currentTemplate = payload
  },
  setDocumentProperties(state, payload) {
    state.documentProperties = payload
    state.isNew = payload.isAdd
  },
  setShowGenerateDocument(state, value) {
    state.showGenerateDocument = value
  },
  setShowCaseDocuments(state, value) {
    state.showUploadDocument = value
  },
  setShowCaseDocumentEditor(state, value) {
    state.showCaseDocumentEditor = value
  },
  setLeaseKey(state, key) {
    state.leaseKey = key
  },
  removeLeaseKey(state) {
    state.leaseKey = ""
  },
  setIsAddMode(state) {
    state.isNew = true
  },
  setDraftDocProperties(state, payload) {
    state.documentProperties = {}

    const docDetails = {
      template: payload.metadata.file_content[0],
      reportDetails: {
        category: payload.metadata.category[0]
      },
      generatedDocument: {
        documentid: payload.id,
        name: payload.metadata.name[0],
        description: payload.metadata.description[0],
        clients: payload.metadata.clients,
        cases: payload.metadata.cases[0],
        category: payload.metadata.category[0],
        subcategories: payload.metadata.subcategories[0],
        dateAdded: payload.metadata.date_added[0],
        addedBy: payload.metadata.added_by[0],
        isEditable: true
      },
      toRoute: state.templateFormObject.toRoute,
      isAdd: false
    }

    //optional metatdata properties
    if (Object.prototype.hasOwnProperty.call(payload.metadata, "description")) {
      docDetails.generatedDocument.description = payload.metadata.description[0]
    }
    if (Object.prototype.hasOwnProperty.call(payload.metadata, "section")) {
      docDetails.generatedDocument.section = payload.metadata.section[0]
    }
    if (
      Object.prototype.hasOwnProperty.call(payload.metadata, "requirementid")
    ) {
      docDetails.generatedDocument.requirementid =
        payload.metadata.requirementid[0]
    }
    if (
      Object.prototype.hasOwnProperty.call(payload.metadata, "requirement_type")
    ) {
      docDetails.generatedDocument.requirement_type =
        payload.metadata.requirement_type[0]
    }
    state.documentProperties = docDetails
  },
  createDocumentInfo(state, payLoad) {
    state.caseDocument = {}
    state.isNew = payLoad.isAdd
    state.caseDocument.documentId = payLoad.documentId
    state.caseDocument.filename = payLoad.filename
    state.caseDocument.description = payLoad.description
    state.caseDocument.clientDetails = payLoad.clientDetails
    state.caseDocument.clients = payLoad.clients
    state.caseDocument.case = payLoad.case
    state.caseDocument.added_by = payLoad.dateAdded
    state.caseDocument.date_added = payLoad.addedBy
  },
  removeDocument(state, docId) {
    let docIndex = state.list.findIndex((doc) => doc.id === docId)
    if (docIndex != -1) state.list.splice(docIndex, 1)
  },
  setSelectedFormFields(
    state,
    { selectedCategory, selectedSubCategories, selectedSection }
  ) {
    state.selectedCategory = selectedCategory
    state.selectedSubCategories = selectedSubCategories
    state.selectedSection = selectedSection
  },
  clearSelectedFormFields(state) {
    state.selectedCategory = ""
    state.selectedSubCategories = []
    state.selectedSection = ""
  },
  setForceRefresh(state, payload) {
    state.forceRefresh = payload
  },
  setDocumentTemplateConfig(state, data) {
    state.documentTemplateSettingsConfig = data
  }
}

export const actions = {
  async getDocuments({ commit, state, rootGetters }, { accountId, info }) {
    state.fetching = true
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_DOCUMENTS_URL}/docs/associations/${accountId}`,
        info
      )

      commit("clearDocuments")
      if (response.status !== 200) {
        commit("addErrorForDisplay", JSON.stringify(response.message))
      } else {
        state.list = []
        const data = response.data

        for (var i = 0; i < data.length; i++) {
          commit("addDocument", {
            id: data[i].id,
            metadata: data[i].metadata,
            properties: data[i].properties,
            clients: rootGetters["clientFile/selectedClients"],
            auth: data[i].auth
          })
        }
      }
    } catch (err) {
      console.log(err)
    }

    state.fetching = false
  },
  async getDraftDocument({ commit, state, rootGetters }, { docId }) {
    state.fetching = true
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_DOCUMENTS_URL}/docs/get/${rootGetters["mbuser/user"].account_id}/${docId}`
      )

      if (response.status !== 200) {
        commit("addErrorForDisplay", JSON.stringify(response.message))
      } else {
        commit("setDraftDocProperties", response.data)
      }
    } catch (err) {
      console.log(err)
    }

    state.fetching = false
  },
  async loadUPTemplateDocuments({ commit, state, getters }, payload) {
    state.fetching = true

    try {
      const templateRequests = await Promise.all([
        axios.get(
          `${process.env.VUE_APP_ADMIN3_SERVICE_URL}/api/v1/account/templates?filters[]=${payload.type}:${payload.value}`
        ),
        // if in network also get network exposed tempalates
        getters.isInNetwork
          ? axios.get(
              `${process.env.VUE_APP_ADMIN3_SERVICE_URL}/api/v1/account/network-templates?filters[]=${payload.type}:${payload.value}`
            )
          : null
      ])

      // handle bad request response
      if (
        templateRequests.some((response) => response && response.status !== 200)
      ) {
        const errorMessage = templateRequests.find(
          (response) => response.status !== 200
        ).message
        return commit("addErrorForDisplay", JSON.stringify(errorMessage))
      }

      // add templates
      state.unifiedPlatformTemplateList = []
      templateRequests.forEach((response) => {
        if (!response) {
          return
        }

        const data = response.data.data
        if (data) {
          data.forEach((template) => {
            if (Object.prototype.hasOwnProperty.call(template, "versions")) {
              template.versions.forEach((version) => {
                if (
                  version.status === "published" &&
                  version._id === template.active_version_id
                )
                  commit("addUnifiedPlatformTemplate", template)
              })
            }
          })
        }
      })
    } catch (err) {
      console.log(err)
    }

    state.fetching = false
  },
  async fetchStructuredTemplate({ commit, state }, payload) {
    state.fetching = true
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_ADMIN3_SERVICE_URL}/api/v1/account/templates/${payload.templateID}`
      )

      if (response.status !== 200) {
        commit("addErrorForDisplay", JSON.stringify(response.message))
      } else {
        const publishedVersion = response.data.data.versions.find(
          (version) => version._id === payload.versionID
        )
        commit("setCurrentTemplate", publishedVersion)
      }
    } catch (err) {
      console.log(err)
    }

    state.fetching = false
  },
  async fetchNonStructuredTemplate({ commit, state }, payload) {
    state.fetching = true
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_DOCUMENTS_URL}/docs/gettemplate/${payload.accountID}/${payload.docID}`
      )

      if (response.status !== 200) {
        commit("addErrorForDisplay", JSON.stringify(response.message))
      } else {
        commit("setCurrentTemplate", response.data)
      }
    } catch (err) {
      console.log(err)
    }

    state.fetching = false
  },
  async getTemplateDocuments({ commit, state }, { accountId, info }) {
    state.fetching = true
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_DOCUMENTS_URL}/docs/associations/${accountId}`,
        info
      )

      if (response.status !== 200) {
        commit("addErrorForDisplay", JSON.stringify(response.message))
      } else {
        state.templateList = []
        const data = response.data

        for (var i = 0; i < data.length; i++) {
          commit("addTemplateDocument", {
            id: data[i].id,
            metadata: data[i].metadata,
            properties: data[i].properties
          })
        }
      }
    } catch (err) {
      console.log(err)
    }

    state.fetching = false
  },
  async generateDocFromTemplate({ dispatch, commit, state }, payload) {
    state.fetching = true
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_DOCUMENTS_URL}/docs/createdocumentfromtemplate/${payload.accountId}/${payload.documentId}`,
        payload.docDetails
      )

      if (response.status !== 200) {
        commit("addErrorForDisplay", JSON.stringify(response.message))
      } else {
        await dispatch("getDocuments", {
          accountId: payload.accountId,
          info: { cases: [payload.docDetails.case_id] }
        })
      }
    } catch (err) {
      console.log(err)
    }

    state.fetching = false
  },
  async processStructuredTemplate({ commit, state }, payload) {
    state.fetching = true
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_DOCUMENTS_URL}/editor/processtemplate`,
        payload
      )

      if (response.status === 200) {
        return response.data
      } else {
        commit("addErrorForDisplay", JSON.stringify(response.message))
        return null
      }
    } catch (err) {
      console.log(err)
      return null
    } finally {
      state.fetching = false
    }
  },

  async loadDocumentConfigurations({ commit }) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_PCM_URL}/account-configs/type/document`
      )

      if (response.status === 200) {
        commit("setDocumentTemplateConfig", response.data.data.config)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async fetchTemplateDocument({ dispatch, state, rootGetters }, payload) {
    state.selectedDocument = {}
    await dispatch("getTemplateDocuments", {
      accountId: rootGetters["mbuser/user"].account_id,
      info: { template: ["general"] }
    })

    const template = state.templateList.find((c) => c.id === payload.id)

    if (template !== undefined) {
      state.isNew = false
      state.selectedDocument = template
    }
  },
  async tryGetLease({ commit, rootGetters }, docId) {
    return await axios
      .post(
        `${
          process.env.VUE_APP_DOCUMENTS_URL
        }/lease/tryacquirelease/${getAccountId(rootGetters)}/${docId}/${
          process.env.VUE_APP_DOCUMENTS_LEASE_DURATION
        }`
      )
      .then((res) => {
        commit("setLeaseKey", res.data)
        return true
      })
      .catch((err) => {
        if (err.response.status === 403) {
          return false
        } else {
          console.log(err)
        }
      })
  },
  async releaseLease({ commit, state, rootGetters }, docId) {
    await axios
      .post(
        `${process.env.VUE_APP_DOCUMENTS_URL}/lease/releaselease/${getAccountId(
          rootGetters
        )}/${docId}`,
        state.leaseKey
      )
      .then(() => commit("removeLeaseKey"))
      .catch((err) => console.log(err))
  },
  async deleteDocument({ commit, rootGetters }, docId) {
    return await axios
      .post(
        `${
          process.env.VUE_APP_DOCUMENTS_URL
        }/lease/tryacquirelease/${getAccountId(rootGetters)}/${docId}/${
          process.env.VUE_APP_DOCUMENTS_LEASE_DURATION
        }`
      )
      .then(async (res) => {
        await axios
          .post(
            `${process.env.VUE_APP_DOCUMENTS_URL}/docs/delete/${getAccountId(
              rootGetters
            )}/${docId}`,
            res.data
          )
          .then(() => {
            commit("removeDocument", docId)
          })
      })
      .catch(async (err) => {
        if (err.response.status === 403) {
          await window.alertAsync(
            "This document is currently being edited. It cannot be deleted at the moment."
          )
        } else {
          console.log(err)
        }
      })
  },
  async saveDocument({ rootGetters }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_DOCUMENTS_URL}/docs/postformdoc/${rootGetters["mbuser/user"].account_id}`,
        payload
      )

      if (response.status === 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
      return null
    }
    return null
  },
  async updateDocument({ rootGetters }, payload) {
    let docId = payload.id
    let auth = payload.auth
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_DOCUMENTS_URL}/docs/update/${rootGetters["mbuser/user"].account_id}/${docId}`,
        { auth: auth }
      )

      if (response.status === 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
      return null
    }
    return null
  },
  async updateDocumentMetadata({ rootGetters }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_DOCUMENTS_URL}/docs/setmetadata/${rootGetters["mbuser/user"].account_id}/${payload.id}`,
        payload.docToUpdate
      )

      if (response.status === 200) {
        return true
      }
    } catch (err) {
      console.log(err)
      return false
    }
    return false
  },
  setTemplateInfo({ commit }, docId, filename, description) {
    commit("setTemplateInfo", docId, filename, description)
  },
  setDocumentInfoForCreate({ commit }, payLoad) {
    commit("createDocumentInfo", payLoad)
  }
}

function getAccountId(rootGetters) {
  return rootGetters["mbuser/user"].account_id
}
