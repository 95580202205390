import * as MbAddressLookupStore from "@/plugins/components-plugin/store/modules/mbAddressLookup.js"
import * as MbValuationsStore from "@/plugins/components-plugin/store/modules/mbValuations.js"
import * as NotificationsStore from "@/plugins/components-plugin/store/modules/notifications.js"
import * as AdvisorStore from "@/plugins/components-plugin/store/modules/advisors.js"
import { v4 as uuidv4 } from "uuid"

export const registerStores = ({ store, mbAxios }) => {
  store.$mbAxios = mbAxios

  // Register vuex stores
  //be sure to set 'export const namespaced = true' within each store/module
  store.registerModule("mbAddressLookup", MbAddressLookupStore)
  store.registerModule("mbValuations", MbValuationsStore)
  store.registerModule("notifications", NotificationsStore)
  store.registerModule("advisors", AdvisorStore)
}

export const CommonUIPlugin = {
  install(Vue, options) {
    registerStores(options)

    //componentId (uuid) to identify a specific component instance (used for in-line alert boxes)
    Vue.mixin({
      data() {
        return {
          lazyLoadComponentId: null
        }
      },
      computed: {
        componentId() {
          return this.lazyLoadComponentId
        }
      },
      created() {
        this.lazyLoadComponentId = uuidv4()
      }
    })
  }
}
