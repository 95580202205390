import { ApolloClient } from "apollo-client"
import { setContext } from "apollo-link-context"
import { InMemoryCache } from "apollo-cache-inmemory"
import * as Realm from "realm-web"
import { createHttpLink } from "@apollo/client/core"
import { getInstance } from "@/plugins/auth0-plugin.js"
import store from "@/store"

let apolloInstance

export const getApolloInstance = () => apolloInstance

const app = new Realm.App(process.env.VUE_APP_DATA_DICTIONARY_APP_ID)
async function getBearerToken() {
  if (!app.currentUser) {
    const authService = getInstance()
    const token = await authService.getTokenSilently()
    await app.logIn(Realm.Credentials.jwt(token))
  } else {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    await app.currentUser.refreshCustomData()
  }
  return app.currentUser.accessToken
}

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_DATA_DICTIONARY_URL
})

const authLink = setContext(async (_, { headers }) => {
  // Use your async token function here:
  let token = ""
  if (process.env.VUE_APP_AUTH_ENABLED == "false") {
    const authToken = store.getters["auth/token"]
    try {
      await app.logIn(Realm.Credentials.jwt(authToken))
      token = app.currentUser.accessToken
    } catch (error) {
      console.log(error)
    }
  } else {
    token = await getBearerToken()
  }
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  }
})

export const createApolloClient = () => {
  if (apolloInstance) return apolloInstance

  apolloInstance = new ApolloClient({
    // Provide the URL to the API server.
    link: authLink.concat(httpLink),
    // Using a cache for blazingly
    // fast subsequent queries.
    cache: new InMemoryCache()
  })
}

export const ApolloPlugin = {
  install(Vue, options) {
    Vue.prototype.$apollo = createApolloClient(options)
  }
}
