import axios from "axios"
import { functionService } from "@/global/modules/helpers"
import { getAnnualisedEquivalent } from "@/global/modules/helpers/incomeHelpers"
import { createValidationRules } from "@/global/modules/helpers/sectionValidationHelpers"
import {
  ukAddressFields,
  internationalAddressFields
} from "@/global/modules/enum/addresses"

export const state = {
  id: null,
  benefits: [],
  employed: [],
  employmentFullData: [],
  soleTrader: [],
  ltdDirector: [],
  pensions: [],
  rental: [],
  otherIncome: [],
  allIncomeTypes: [],
  selectedClientId: "",
  selectedListId: "",
  selectedType: "",
  isNew: true,
  selectedPensionAndInvestment: {},
  selectedLtdDirector: {},
  selectedBenefit: {}
}

export const mutations = {
  clearIncomeRecords(state) {
    state.benefits = []
    state.employed = []
    state.soleTrader = []
    state.ltdDirector = []
    state.pensions = []
    state.rental = []
    state.otherIncome = []
    state.employmentFullData = []
    state.allIncomeTypes = []
    state.isNew = true
    state.selectedType = ""
    state.selectedPensionAndInvestment = {}
  },
  addPension(state, item) {
    state.pensions.push(item)
  },
  addRental(state, item) {
    state.rental.push(item)
  },
  addOtherIncome(state, item) {
    state.otherIncome.push(item)
  },
  addOrUpdateBenefit(state, item) {
    const existingIndex = state.benefits.findIndex(
      ({ id, array_id }) => id === item.id && array_id === item.array_id
    )
    if (existingIndex > -1) {
      state.benefits.splice(existingIndex, 1)
    }
    state.benefits.push(item)
  },
  addOrUpdateEmployed(state, item) {
    let index = state.employed.findIndex((income) => income.id === item.id)
    if (index !== -1) {
      state.employed.splice(index, 1)
    }
    state.employed.push(item)
  },
  addOrUpdateFullEmployment(state, item) {
    let index = state.employmentFullData.findIndex(
      (income) => income.id === item.id
    )
    if (index !== -1) {
      state.employmentFullData.splice(index, 1)
    }
    state.employmentFullData.push(item)
  },
  addOrUpdateSoleTrader(state, item) {
    let index = state.soleTrader.findIndex((income) => income.id === item.id)
    if (index !== -1) {
      state.soleTrader.splice(index, 1)
    }
    state.soleTrader.push(item)
  },
  addLtdDirector(state, item) {
    state.ltdDirector.push(item)
  },
  addSelectedClientIdAndType(state, data) {
    state.selectedClientId = data.clientId
    state.selectedType = data.type
  },
  addSelectedIdClientIdAndType(state, data) {
    state.selectedClientId = data.clientId
    state.selectedType = data.type
    state.id = data.id
    state.isNew = false
  },
  editSelectedClientIdAndType(state, data) {
    state.isNew = false
    state.selectedClientId = data.clientId
    state.id = data.id
    state.selectedType = data.type
  },
  isNewState(state, data) {
    state.isNew = data
  },
  allIncomeTypes(state, data) {
    let existingIndex = state.allIncomeTypes.findIndex(
      (income) => income.id === data.id
    )
    if (existingIndex !== -1) {
      state.allIncomeTypes.splice(existingIndex, 1)
    }
    state.allIncomeTypes.push(data)
  },
  setPensionAndInvestment(state, data) {
    state.selectedPensionAndInvestment = data
  },
  setLtdDirector(state, data) {
    state.selectedLtdDirector = data
  },
  setBenefit(state, data) {
    state.selectedBenefit = data
    state.isNew = false
  },
  resetClientAndType(state) {
    state.isNew = true
    state.selectedClientId = ""
    state.selectedType = ""
    state.id = null
    state.selectedPensionAndInvestment = {}
  }
}

export const getters = {
  benefits(state) {
    if (!state?.allIncomeTypes?.length) {
      return []
    }

    return state.benefits
  },
  employed(state) {
    return state.employed
  },
  employmentFullData(state) {
    return state.employmentFullData
  },
  soleTrader(state) {
    return state.soleTrader
  },
  ltdDirector(state) {
    return state.ltdDirector
  },
  pensions(state) {
    return state.pensions
  },
  rental(state) {
    return state.rental
  },
  otherIncome(state) {
    return state.otherIncome
  },
  isAddMode(state) {
    return state.isNew
  },
  selectedPensionAndInvestment(state) {
    return state.selectedPensionAndInvestment
  },
  selectedLtdDirector(state) {
    return state.selectedLtdDirector
  },
  selectedBenefit(state) {
    return state.selectedBenefit
  },
  selectedClientId(state) {
    return state.selectedClientId
  },
  selectedType(state) {
    return state.selectedType
  },
  allIncomeTypes(state) {
    return state.allIncomeTypes
  },
  selectedIncomeId(state) {
    return state.id
  },
  validation(_0, _1, _2, rootGetters) {
    return {
      employed: {
        ...mapRulesForEmployed(rootGetters)
      },
      soletrader: {
        ...mapRulesForSoleTrader(rootGetters)
      },
      ltdcompany: {
        ...mapRulesForLtdCompany(rootGetters)
      },
      pension: {
        ...mapRulesForPension(rootGetters)
      },
      rental: {
        ...mapRulesForRental(rootGetters)
      },
      investmentOrOther: {
        ...mapRulesForInvestmentOrOther(rootGetters)
      },
      benefit: {
        ...mapRulesForBenefit(rootGetters)
      }
    }
  }
}

function mapRulesForEmployed(rootGetters) {
  const fields = [
    "income_source_key",
    "income_source_chronological_status_key",
    "employer_name",
    "employment_start_date",
    "employment_end_date",
    "employment_contract_type_key",
    "is_employment_full_time",
    "has_employment_probationary_period",
    "job_probationary_end_date",
    "job_occupation_key",
    "job_title",
    "employed_income_basic_type_key",
    "income_frequency_key",
    "has_employment_additional_income",
    "employed_income_other_type_key",
    "income_can_be_evidenced",
    { name: "income_amount", alias: "basic_income_amount" },
    "income_amount",
    "income_payment_month_key",
    "income_payment_year_key",
    "has_employment_income_deductions",
    "employer_provides_sick_pay",
    "has_employment_death_in_service",
    "economic_region_key",
    "expenditure_amount",
    "is_expenditure_paid_before_tax",
    "expenditure_frequency_key",
    "employer_death_in_service_amount",
    "death_in_service_notes",
    "employer_sick_pay_percentage",
    "employer_sick_pay_period_count",
    "employer_sick_pay_frequency_key",
    ...ukAddressFields,
    ...internationalAddressFields
  ]

  const addressConditions = mapAddressConditions()

  const incomeHistoryCondition = {
    includes: [
      { var: "employed_income_other_type_key" },
      ["overtime", "bonus", "commission"]
    ]
  }

  const employmentStatusCondition = {
    "==": [{ var: "income_source_chronological_status_key" }, "previous"]
  }

  const probationPeriodCondition = {
    "==": [{ var: "has_employment_probationary_period" }, true]
  }

  const conditions = {
    ...addressConditions,
    income_amount: incomeHistoryCondition,
    income_payment_month_key: incomeHistoryCondition,
    income_payment_year_key: incomeHistoryCondition,
    employment_end_date: employmentStatusCondition,
    job_probationary_end_date: probationPeriodCondition
  }

  return mapRules(rootGetters, "income_employed", fields, conditions)
}

function mapRulesForSoleTrader(rootGetters) {
  const fields = [
    "income_source_key",
    "income_source_chronological_status_key",
    "business_trading_name",
    "job_occupation_key",
    "job_occupation_origo_key",
    "business_start_date",
    "business_end_date",
    "sole_trader_incomes",
    "net_profit_sole_trader_amount",
    "financial_year_ending_date",
    "is_financial_projected",
    "income_can_be_evidenced",
    "business_name",
    "first_name",
    "last_name",
    "accountant_qualification_body_key",
    "accountant_qualification_body_other",
    "phone_type_key",
    "phone_number",
    "phone_country_code_key",
    "email_address",
    ...ukAddressFields,
    ...internationalAddressFields
  ]

  const addressConditions = mapAddressConditions()

  return mapRules(rootGetters, "income_sole_trader", fields, addressConditions)
}

function mapRulesForLtdCompany(rootGetters) {
  const fields = [
    "income_source_key",
    "income_source_chronological_status_key",
    "business_address",
    "business_name",
    "business_start_date",
    "business_end_date",
    "business_shareholding_percentage",
    "financial_year_end_month_key",
    "business_financial_periods",
    "financial_year_ending_date",
    "is_financial_projected",
    "income_can_be_evidenced",
    "self_employed_income",
    "directors_gross_salary_amount",
    "ltd_company_net_profit_before_corporation_tax_amount",
    "ltd_company_net_profit_after_corporation_tax_amount",
    "directors_dividend_amount",
    "directors_share_of_net_profit_before_corporation_tax_amount",
    "directors_share_of_net_profit_after_corporation_tax_amount",
    "job_occupation_key",
    "job_occupation_origo_key",
    "first_name",
    "last_name",
    "accountant_qualification_body_key",
    "accountant_qualification_body_other",
    "phone_type_key",
    "phone_number",
    "phone_country_code_key",
    "email_address",
    ...ukAddressFields,
    ...internationalAddressFields
  ]

  const addressConditions = mapAddressConditions()

  const rules = mapRules(
    rootGetters,
    "income_ltd_director",
    fields,
    addressConditions
  )

  rules.international_line_1.required = true
  rules.postcode.required = true

  return rules
}

function mapRulesForPension(rootGetters) {
  const fields = [
    "income_source_key",
    "income_source_chronological_status_key",
    "pension_details",
    "income_pension_type_key",
    "income_description",
    "income_amount",
    "income_frequency_key"
  ]
  return mapRules(rootGetters, "income_pensions", fields)
}

function mapRulesForRental(rootGetters) {
  const fields = [
    "income_source_key",
    "income_source_chronological_status_key",
    "rental_details",
    "income_amount",
    "income_payment_year_key",
    "income_frequency_key",
    "income_rental_type_key"
  ]
  return mapRules(rootGetters, "income_rental", fields)
}

function mapRulesForInvestmentOrOther(rootGetters) {
  const fields = [
    "income_source_key",
    "income_source_chronological_status_key",
    "income_other_type_key",
    "income_description",
    "income_amount",
    "income_frequency_key"
  ]
  return mapRules(rootGetters, "income_investment_other", fields)
}

function mapRulesForBenefit(rootGetters) {
  const fields = [
    "income_source_key",
    "income_source_chronological_status_key",
    "income_benefit_type_key",
    "income_amount",
    "income_frequency_key"
  ]
  return mapRules(rootGetters, "income_benefits", fields)
}

function mapAddressConditions() {
  const conditions = {}

  const ukAddressCondition = {
    "==": [{ var: "is_non_uk_address" }, false]
  }

  ukAddressFields.forEach((field) => {
    conditions[field] = ukAddressCondition
  })

  const nonUkAddressCondition = {
    "==": [{ var: "is_non_uk_address" }, true]
  }

  internationalAddressFields.forEach((field) => {
    conditions[field] = nonUkAddressCondition
  })

  return conditions
}

function mapRules(rootGetters, subSection, fields, conditions) {
  const factFind = rootGetters["caseSectionValidation/factfind"]
  return createValidationRules(
    (field) => factFind.required("employment_and_income", subSection, field),
    fields,
    conditions
  )
}

export const actions = {
  loadEmploymentIncomes(context) {
    const clients = context.rootGetters["clientFile/clients"]
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    const clientIds = clients.reduce((ids, client) => {
      ids.push(client.id)
      return ids
    }, [])
    axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientemploymentandincome/getforclientids${snapshotIdQuery}`,
        clientIds
      )
      .then((res) => {
        context.commit("clearIncomeRecords")
        res.data.forEach((incomeData) => {
          mapEmploymentIncomeForList(incomeData, clients, context)
        })
      })
      .catch((err) => {
        context.commit("clearIncomeRecords")
        console.log(err)
      })
  },
  saveIncomeRecord(context, data) {
    return axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/clientemploymentandincome/createorupdate`,
        [data]
      )
      .then((res) => {
        context.commit("clearIncomeRecords")
        mapEmploymentIncomeForList(
          res.data[0],
          context.rootGetters["clientFile/clients"],
          context
        )
        return true
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  },
  async addUpdateEmploymentAndIncome({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/clientemploymentandincome/createorupdate`,
        [payload.data]
      )
      if (response.status === 200) {
        dispatch("loadEmploymentIncomes")
      }
    } catch (err) {
      console.log(err.response.data)
    }
  },
  saveSelectedClientIdAndType(context, payload) {
    context.commit("addSelectedClientIdAndType", payload)
  },
  detailsSelectedClientIdAndType(context, payload) {
    context.commit("editSelectedClientIdAndType", payload)
  },
  saveIncomeDetailsToView(context, payload) {
    context.commit("addSelectedIdClientIdAndType", payload)
  },
  saveBenefitIncomeToView(context, payload) {
    context.commit("setBenefit", payload)
  },
  fetchPensionAndInvestment({ commit, state }) {
    // init variables
    let selectedItem = null
    let itemToEdit = {}

    // search for the rental
    if (state.selectedType === "rental") {
      selectedItem = state.allIncomeTypes.find((c) => {
        return (
          c.income_source_key === state.selectedType &&
          c.linked_client_id == state.selectedClientId
        )
      })
    } else {
      // search for the pension and investment record
      selectedItem = state.allIncomeTypes.find((c) => {
        return (
          c.income_source_key === state.selectedType &&
          c.linked_client_id == state.selectedClientId &&
          c.id === state.id
        )
      })
    }

    if (state.selectedType === "pensions") {
      if (!selectedItem) {
        // initialise all the default values
        itemToEdit = {
          income_source_key: "pensions",
          linked_client_id: state.selectedClientId,
          pension_details: []
        }
      } else {
        itemToEdit = selectedItem
      }
    }

    if (state.selectedType === "rental") {
      if (!selectedItem) {
        // initialise all the default values
        itemToEdit = {
          income_source_key: "rental",
          linked_client_id: state.selectedClientId,
          rental_details: []
        }
      } else {
        itemToEdit = selectedItem
      }
    }

    if (state.selectedType === "investments_other") {
      if (!selectedItem) {
        // initialise all the default values
        itemToEdit = {
          income_source_key: "investments_other",
          linked_client_id: state.selectedClientId,
          other_details: []
        }
      } else {
        itemToEdit = selectedItem
      }
    }

    commit("setPensionAndInvestment", itemToEdit)
  },
  fetchEmploymentAndIncome({ commit, state }) {
    // init variables
    let mainItem = {}
    // search for the income record
    mainItem = state.allIncomeTypes.find((c) => {
      return c.id === state.id
    })

    switch (mainItem.income_source_key) {
      case "ltd_director":
        commit("setLtdDirector", mainItem)
        break
      default:
    }
  },
  updateBenefitIncome({ dispatch, state }, payload) {
    let mainBenefit = {}

    mainBenefit = state.allIncomeTypes.find((c) => {
      return c.id === payload[0].id
    })

    const benefitIndex = mainBenefit.benefit_details.findIndex(
      (benefit) => benefit.array_id === payload[0].array_id
    )

    mainBenefit.benefit_details.benefits[benefitIndex].income_amount =
      payload[0].income_amount

    dispatch("addUpdateEmploymentAndIncome", { data: mainBenefit })
  },
  deleteIncome({ dispatch }, payload) {
    const { income_source_key } = payload
    if (income_source_key === "rental") {
      dispatch("deleteRental", payload)
    } else if (income_source_key === "pensions") {
      dispatch("deletePension", payload)
    } else if (income_source_key === "benefits") {
      dispatch("deleteBenefitIncome", payload)
    } else {
      dispatch("deleteOtherIncome", payload)
    }
  },
  deleteBenefitIncome({ dispatch, state }, payload) {
    let mainItem = {}
    let benefitToDelete = -1

    mainItem = state.allIncomeTypes.find((c) => {
      return (
        c.income_source_key === payload.income_source_key &&
        c.id === payload.income_record_id
      )
    })

    benefitToDelete = mainItem.benefit_details.findIndex(
      (ben) => ben.array_id === payload.array_id
    )

    if (benefitToDelete > -1)
      mainItem.benefit_details.splice(benefitToDelete, 1)

    dispatch("addUpdateEmploymentAndIncome", { data: mainItem })
  },
  deletePension({ dispatch, state }, payload) {
    let mainItem = {}
    let pensionToDelete = -1
    // search for the pension record by id
    mainItem = state.allIncomeTypes.find((c) => {
      return (
        c.income_source_key === payload.income_source_key &&
        c.id === payload.income_record_id
      )
    })

    pensionToDelete = mainItem.pension_details.findIndex(
      (pension) => pension.array_id === payload.array_id
    )
    if (pensionToDelete > -1)
      mainItem.pension_details.splice(pensionToDelete, 1)

    dispatch("addUpdateEmploymentAndIncome", { data: mainItem })
  },
  deleteOtherIncome({ dispatch, state, rootGetters }, payload) {
    try {
      const targetObj = state.allIncomeTypes.find(
        (income) => income.id === payload.id
      )
      const incomeToDeleteObj = Object.assign({}, targetObj, {
        is_deleted: true,
        deleted_at: new Date(),
        deleted_by: rootGetters["auth/userSub"]
      })
      dispatch("addUpdateEmploymentAndIncome", { data: incomeToDeleteObj })
    } catch (e) {
      console.error(e)
    }
  },
  deleteRental({ dispatch, state }, payload) {
    let mainItem = {}
    // search for the rental record by id
    mainItem = state.allIncomeTypes.find((c) => {
      return (
        c.income_source_key === payload.income_source_key &&
        c.id === payload.income_record_id
      )
    })

    if (mainItem) {
      mainItem.rental_details = null
      mainItem.is_deleted = true
      //update the record
      dispatch("addUpdateEmploymentAndIncome", { data: mainItem })
    }
  }
}

function mapEmploymentIncomeForList(incomeData, clients, context) {
  incomeData.owner = clients
    .filter((client) => incomeData.linked_client_id == client.id)
    .reduce((names, client) => {
      names.push(client.fullname)
      return names
    }, [])
    .join(", ")
  const { owner, id } = incomeData
  context.commit("allIncomeTypes", incomeData)
  if (incomeData.income_source_key.toLowerCase() === "benefits") {
    incomeData.benefit_details.forEach((benefit) => {
      context.commit(
        "addOrUpdateBenefit",
        Object.assign({}, benefit, {
          income_record_id: id,
          owner,
          completed:
            incomeData.current_record_validation
              ?.is_record_section_validation_result_valid ?? false,
          income_source_key: "benefits",
          clientId: getClientIdByName(clients, owner),
          annual_income: getAnnualisedEquivalent(
            benefit.income_amount,
            benefit.income_frequency_key
          )
        })
      )
    })
  } else if (incomeData.income_source_key === "employed") {
    let income = {}
    income.owner = incomeData.owner
    income.id = incomeData.id
    income.clientId = getClientIdByName(clients, incomeData.owner)
    income.status = getDisplayText(
      context,
      "q-93fa0bc1-33db-4311-9533-4aa64fb632ff",
      incomeData.income_source_chronological_status_key
    )
    income.type = incomeData.income_source_key
    income.employer = incomeData.employed_details.employer_name
    income.time_in_employment = getTimeBetweenDates(
      incomeData.employed_details.employment_start_date,
      incomeData.employed_details.employment_end_date
    )
    if (incomeData.employed_details.other_income) {
      let totalIncome = 0
      incomeData.employed_details.other_income.forEach((element) => {
        if (!isNaN(element.income_amount)) {
          totalIncome += getAnnualisedEquivalent(
            element.income_amount,
            element.income_frequency_key
          )
        }
      })
      income.income =
        totalIncome +
        incomeData.employed_details.gross_basic_salary.income_amount
    } else {
      income.income =
        incomeData.employed_details.gross_basic_salary.income_amount
    }
    income.completed =
      incomeData.current_record_validation
        ?.is_record_section_validation_result_valid ?? false

    context.commit("addOrUpdateEmployed", income)
    context.commit("addOrUpdateFullEmployment", incomeData)
  } else if (incomeData.income_source_key === "sole_trader") {
    let income = {}
    income.owner = incomeData.owner
    income.id = incomeData.id
    income.clientId = getClientIdByName(clients, incomeData.owner)
    income.status = getDisplayText(
      context,
      "q-93fa0bc1-33db-4311-9533-4aa64fb632ff",
      incomeData.income_source_chronological_status_key
    )
    income.type = incomeData.income_source_key
    income.job_title = incomeData.sole_trader_details.job_title
    income.occupation = incomeData.sole_trader_details.job_occupation_key
    income.employer = incomeData.sole_trader_details.business_trading_name
    income.time_in_employment = getTimeBetweenDates(
      incomeData.sole_trader_details.business_start_date,
      incomeData.sole_trader_details.business_end_date
    )
    if (incomeData.sole_trader_details.sole_trader_incomes.length > 0) {
      // only get last year
      income.income =
        incomeData.sole_trader_details.sole_trader_incomes[0].net_profit_sole_trader_amount
    }
    income.completed =
      incomeData.current_record_validation
        ?.is_record_section_validation_result_valid ?? false

    context.commit("addOrUpdateSoleTrader", income)
    context.commit("addOrUpdateFullEmployment", incomeData)
  } else if (incomeData.income_source_key === "ltd_director") {
    let income = {}
    income.clientId = incomeData.linked_client_id
    income.owner = incomeData.owner
    income.id = incomeData.id
    income.status = getDisplayText(
      context,
      "q-93fa0bc1-33db-4311-9533-4aa64fb632ff",
      incomeData.income_source_chronological_status_key
    )
    income.type = incomeData.income_source_key
    income.occupation = incomeData.ltd_director_details.job_occupation_key
    income.employer = incomeData.ltd_director_details.business_name
    income.time_in_employment = getTimeBetweenDates(
      incomeData.ltd_director_details.business_start_date,
      incomeData.ltd_director_details.business_end_date
    )
    income.income =
      incomeData.ltd_director_details.business_financial_periods?.[0]
        .self_employed_income.directors_gross_salary_amount +
      incomeData.ltd_director_details.business_financial_periods?.[0]
        .self_employed_income.directors_dividend_amount
    income.completed =
      incomeData.current_record_validation
        ?.is_record_section_validation_result_valid ?? false
    context.commit("addOrUpdateFullEmployment", incomeData)
    context.commit("addLtdDirector", income)
  } else if (incomeData.income_source_key.toLowerCase() === "pensions") {
    incomeData.pension_details.forEach((pension) => {
      context.commit(
        "addPension",
        Object.assign({}, pension, {
          income_record_id: id,
          income_source_key: "pensions",
          owner,
          completed:
            incomeData.current_record_validation
              ?.is_record_section_validation_result_valid ?? false,
          clientId: getClientIdByName(clients, owner),
          annual_income: getAnnualisedEquivalent(
            pension.income_amount,
            pension.income_frequency_key
          )
        })
      )
    })
  } else if (incomeData.income_source_key.toLowerCase() === "rental") {
    incomeData.rental_details.forEach((details) => {
      context.commit(
        "addRental",
        Object.assign({}, details, {
          income_record_id: id,
          income_source_key: "rental",
          owner,
          completed:
            incomeData.current_record_validation
              ?.is_record_section_validation_result_valid ?? false,
          clientId: getClientIdByName(clients, owner),
          annual_income: getAnnualisedEquivalent(
            details.income_amount,
            details.income_frequency_key
          )
        })
      )
    })
  } else if (
    incomeData.income_source_key.toLowerCase() === "investments_other"
  ) {
    incomeData.other_details.forEach((details) => {
      context.commit(
        "addOtherIncome",
        Object.assign({}, details, {
          id,
          owner,
          completed:
            incomeData.current_record_validation
              ?.is_record_section_validation_result_valid ?? false,
          clientId: getClientIdByName(clients, owner),
          annual_income: getAnnualisedEquivalent(
            details.income_amount,
            details.income_frequency_key
          )
        })
      )
    })
  }
}

function getTimeBetweenDates(startDate, endDate) {
  if (endDate) {
    return functionService.timeBetweenDates(startDate, endDate)
  } else {
    return functionService.timeBetweenDates(startDate, new Date())
  }
}

function getClientIdByName(clients, fullname) {
  return clients.find((client) => client.fullname === fullname).id
}

function getDisplayText(context, formId, value) {
  const displayText =
    context.rootGetters["datadictionary/displayTextByFormIdAndValue"]
  return displayText(formId, value)
}
