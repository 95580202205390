import Vue from "vue"
import store from "../store"
import Meta from "vue-meta"
import routes from "./routes"
import Router from "vue-router"
import { sync } from "vuex-router-sync"
import Vidle from "v-idle"
import axios from "axios"
import routeProgressBus from "../routeProgressBus"

Vue.use(Meta)
Vue.use(Router)
Vue.use(Vidle)

// The middleware for every page of the application.
const globalMiddleware = []

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
  require.context("../global/modules/middleware", false, /.*\.js$/)
)

const router = createRouter()

sync(store, router)

export default router

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter() {
  const router = new Router({
    scrollBehavior,
    mode: "history",
    linkExactActiveClass: "active",
    routes
  })

  router.beforeEach(beforeEach)
  router.afterEach(afterEach)

  return router
}

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach(to, from, next) {
  routeProgressBus.$emit("started loading route")

  // Get the matched components and resolve them.
  const components = await resolveComponents(
    router.getMatchedComponents({ ...to })
  )

  let blockIfEnvKey =
    "blockIf" +
    process.env.VUE_APP_ENV[0].toUpperCase() +
    process.env.VUE_APP_ENV.slice(1)

  if (to.matched.some((route) => route.meta[blockIfEnvKey])) {
    next({ name: "notfound404" })
    return false
  }

  //Client Guard: check if user has access to the clientId
  if (to.matched.some((route) => route.meta.requiresAuthClient)) {
    /**
     * Verifies that the clientId coming in and the one held in the store
     * are not the same before a call is made to the API
     */
    if (to.params.id !== store.getters["clientFile/previousClientId"]) {
      await store.dispatch("clientFile/checkClientId", {
        id: to.params.id
      })

      // when client Id fails, re-direct to error page
      if (!store.getters["clientFile/clientIdValid"]) {
        next({
          name: "notfound404"
        })
      }
    }
  }
  // store current client Id if verified above
  if (to.params.id) {
    store.commit("clients/setCurrentClientId", to.params.id)
  }
  //Set Client Nav Active
  if (to.matched.some((route) => route.meta.clientFileNavActive)) {
    store.commit("clients/setClientFileActive", true)
  } else {
    store.commit("clients/setClientFileActive", false)
  }

  //Case Guard: check if user has access to the caseId
  if (to.matched.some((route) => route.meta.requiresAuthCase)) {
    if (to.params.caseId !== store.getters["cases/previousCaseId"]) {
      await store.dispatch("cases/checkCaseId", {
        id: to.params.caseId
      })
      // when case Id fails, re-direct to error page
      if (!store.getters["cases/caseIdValid"]) {
        next({
          name: "notfound404"
        })
      }
    }
  }

  //Set Client case Nav Active
  if (to.matched.some((route) => route.meta.caseNavActive)) {
    if (to.params.caseId) {
      store.commit("cases/setCurrentCaseId", to.params.caseId)
    }
    if (to.params.snapshotId) {
      store.commit("cases/setSnapshotId", to.params.snapshotId)
    }
    store.commit("clients/setClientCasesActive", true)
  } else {
    store.commit("clients/setClientCasesActive", false)
  }

  if (components.length === 0) {
    return next()
  }

  // Start the loading bar.
  if (components[components.length - 1].loading !== false) {
    // router.app.$nextTick(() => router.app.$loading.start())
  }

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components)

  // Call each middleware.
  callMiddleware(middleware, to, from, (...args) => {
    // Set the application layout only if "next()" was called with no args.
    if (args.length === 0) {
      // router.app.setLayout(components[0].layout || '')
    }
    next(...args)
  })
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach(to) {
  await router.app.$nextTick()

  routeProgressBus.$emit("finished loading route")

  // on unique case open send to useractivity endpoint
  if (
    to.params.caseId &&
    to.params.caseId !== store.getters["cases/previousCaseId"]
  ) {
    store.dispatch("cases/commitPreviousCaseId", to.params.caseId)
    axios.post(`${process.env.VUE_APP_CDS_URL}/useractivity/createorupdate`, {
      user_id: store.getters["mbuser/user"]._id,
      type: "case",
      reference_id: store.getters["cases/currentCase"].id
    })
  }

  // router.app.$loading.finish()
}

/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware(middleware, to, from, next) {
  const stack = middleware.reverse()

  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      if (args.length > 0) {
        // router.app.$loading.finish()
      }

      return next(...args)
    }

    const middleware = stack.pop()

    if (typeof middleware === "function") {
      middleware(to, from, _next)
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next)
    } else {
      throw Error(`Undefined middleware [${middleware}]`)
    }
  }

  _next()
}

/**
 * Resolve async components.
 *
 * @param  {Array} components
 * @return {Array}
 */
function resolveComponents(components) {
  return Promise.all(
    components.map((component) => {
      return typeof component === "function" ? component() : component
    })
  )
}

/**
 * Merge the the global middleware with the components middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware(components) {
  const middleware = [...globalMiddleware]

  components
    .filter((c) => c.middleware)
    .forEach((component) => {
      if (Array.isArray(component.middleware)) {
        middleware.push(...component.middleware)
      } else {
        middleware.push(component.middleware)
      }
    })

  return middleware
}

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    return { selector: to.hash }
  }

  const [component] = router.getMatchedComponents({ ...to }).slice(-1)

  if (component && component.scrollToTop === false) {
    return {}
  }

  return { x: 0, y: 0 }
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware(requireContext) {
  return requireContext
    .keys()
    .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
    .reduce(
      (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
      {}
    )
}
