export const state = {
  suppressedWarnings: {}
}

export const mutations = {
  suppress(state, suppressionKey) {
    state.suppressedWarnings[suppressionKey] = true
  }
}

export const getters = {
  isSuppressed(state) {
    return (suppressionKey) => !!state.suppressedWarnings[suppressionKey]
  }
}
export const actions = {
  suppressFutureWarnings({ commit }, suppressionKey) {
    commit("suppress", suppressionKey)
  }
}
