import store from "@/store"
import { requirementType } from "@/global/modules/enum/constants"
/**
 *
 * @param {String} category
 * @returns appropriate 'section' javascript property name for given category. Used by Case notes/tasks (no plans to use for case documents)
 * @example let category = 'case'; getCategorySectionKey(category) === 'case_section'; let section = taskOrNote['case_section']
 */
export function getCategorySectionKey(category) {
  switch (category) {
    case "case":
      return "case_section"
    case "requirement":
      return "requirement_section"
    case "application":
      return "application_section"
    case "fact_find":
    case "client":
      return "fact_find_section"
    default:
      throw `Not Implemented Exception 'getCategorySectionKey' for category '${category}'`
  }
}
/**
 *
 * @param {CaseNote | CaseTask} noteOrTask
 * @param {String} ddGroup 'note' or 'task'. 'document' not yet implemented.
 * @param {Document} doc
 * @param {String} sectionOverride
 * @param {String} clientId only required if calling from a non-clientFile child route, or if you need to change client id
 * @returns
 */
export function getCategorySectionLocation(
  noteOrTask,
  ddGroup,
  doc = null,
  sectionOverride = null,
  clientId = null
) {
  let section = null
  let category = null
  let requirement_id = null
  let application_id = null
  let case_id = null
  let requirement_type = null
  let requirement_trans_type = null
  if (doc) {
    section = doc.section ? doc.section[0] : null
    category = doc.category[0]
    requirement_id = doc.metadata.requirementid
      ? doc.metadata.requirementid[0]
      : null
    application_id = doc.application_id
    case_id = doc.metadata.cases ? doc.metadata.cases[0] : null
  } else {
    category = noteOrTask.category
    section = noteOrTask[getCategorySectionKey(category)]
    requirement_id = noteOrTask.requirement_id
    application_id = noteOrTask.application_id
    case_id = noteOrTask.case_id
  }
  if (requirement_id !== null) {
    let requirement = store.getters["requirements/allRequirements"].find(
      (req) => req.id === requirement_id
    )
    requirement_type = requirement.kyc_requirement_type_key
    if (requirement.mortgage_details) {
      requirement_trans_type =
        requirement.mortgage_details
          .kyc_mortgage_requirement_transaction_type_key
    }
  }
  if (sectionOverride) {
    section = sectionOverride
  }

  let name = null
  let params = {}
  if (case_id) {
    params.caseId = case_id
  }
  if (clientId) {
    params.id = clientId
  }

  switch (category) {
    case "case":
    case "fact_find":
    case "client":
      name = getName()
      break

    case "mortgage_advice":
    case "protection_advice":
    case "home_insurance_advice":
      name = getName()
      params.requirementId = requirement_id
      break

    case "requirement":
      name = getName()
      break

    case "application":
      name = getName()
      params.applicationId = application_id
      break
    default:
      console.error(
        `Not Implemented Exception 'getCategorySectionLocation' for category '${category}'`
      )
      name = ""
      break
  }
  if (params) {
    return { name: name, params: params }
  }
  return { name: name }

  //Returns name of route depending on value of 'section' and 'category'
  function getName() {
    //TODO in future, will need to account for separate views. e.g. Requirement type is mortgage or protection.
    //there are inconsistencies and spelling mistakes in the various DD. Need to update once PO update DD.
    if (category === "case" && section === null) return "case-documents-list"
    if (category === "requirement" && section === null)
      return "client-case-requirement-mortgage-documents"

    switch (section) {
      case "general":
        switch (category) {
          case "case":
            switch (ddGroup) {
              // There is no Case > General for tasks and Case Documents has no section (covered above)
              case "note":
                return "case-notes"
            }
            break
          case "client":
          case "fact_find":
            switch (ddGroup) {
              case "note":
                return "kyc-fact-find-notes"
              case "task":
                return "kyc-fact-find-tasks"
              case "doc":
                return "kyc-fact-find-docs"
            }
            break
          case "requirement":
            switch (ddGroup) {
              case "note":
                return "client-case-requirement-mortgage-notes"
              case "task":
                return "client-case-requirement-mortgage-tasks"
              case "doc":
                return "client-case-requirement-mortgage-documents"
            }
            break
        }
        break
      case "recommendations":
        switch (category) {
          case "case":
          case "client":
          case "fact_find":
            return "kyc-fact-find-overview"
          case "requirement":
            return "requirement-recommendation-list"
        }
        return "kyc-fact-find-overview"
      case "personal":
        return "kyc-personal-details"

      case "address":
        return "kyc-addresses"

      case "dependants":
        return "kyc-dependants-list"

      case "credit_history":
        return "kyc-credit-history-list"

      case "employment_income":
        return "kyc-employment-income-summary"

      case "loans_credit_commitments":
        return "kyc-commitments-list"

      case "budget_planner":
        return "kyc-budget-planner-list"

      case "vulnerable_customer":
        return "kyc-vulnerable-customer"

      case "properties":
        return "kyc-property-summary"

      case "mortgages":
        return "kyc-mortgages-list"

      case "policies":
        return "kyc-policies-list"

      case "savings_investments":
        return "kyc-savings-list"

      case "existing_mortgage":
        return "client-case-requirement-mortgage-existing-mortgage-list"

      case "about_the_property":
        return requirement_trans_type === "existing_mortgage_review"
          ? "client-case-requirement-existing-mortgage-property"
          : "client-case-requirement-purchase-property"

      case "loan_and_product_prefs":
        return "client-case-requirement-mortgage-loan-and-product"

      case "dip":
        return "client-case-requirement-mortgage-dip-list"

      case "property_ownership":
        if (requirement_trans_type === "purchase") {
          return "client-case-requirement-purchase-ownership-list"
        }
        return "client-case-requirement-existing-mortgage-review"

      case "research":
        if (
          requirement_type === requirementType.buyToLetMortgage ||
          requirement_type === requirementType.residentialMortgage
        ) {
          return "requirement-initial-research-scenario-list"
        }
        if (requirement_type === requirementType.protection) {
          return "requirement-protection-research-product-list"
        }
        if (requirement_type === requirementType.generalInsurance) {
          return "client-case-requirement-gi-research-product-list"
        }
        break
      case "needs_analysis":
        return "client-case-requirement-protection-needs-analysis"
      case "needs_preferences":
        if (requirement_type === requirementType.generalInsurance) {
          return "client-case-requirement-gi-needs-preferences"
        }
        return "client-case-requirement-protection-needs-preferences"
      case "recommend_apply":
        if (requirement_type === requirementType.generalInsurance) {
          return "client-case-requirement-gi-recommend-apply"
        }
        if (
          requirement_type === requirementType.residentialMortgage ||
          requirement_type === requirementType.buyToLetMortgage
        ) {
          return "client-case-requirement-mortgage-final-research"
        }
        return "client-case-requirement-protection-recommend-apply"
      case "property":
        return "client-case-requirement-gi-property"
    }
    console.error(
      `Cannot get router link for category: '${category}' section: '${section}'`
    )
    return ""
  }
}

/**
 *
 * @param {String} category
 * @param {String} ddGroup 'note' or 'task'. 'document' not yet implemented.
 * @param {String} requirementId requirement id if the category is requirement (needed to work out the requirement type)
 * @returns guid or empty string on error
 */
export function getSectionGuid(category, ddGroup, requirementId = null) {
  //TODO in future, will need to account for separate views. e.g. Requirement type is mortgage or protection.
  switch (category) {
    case "category":
      if (ddGroup === "note") return "q-428e8a0c-4a25-4c8a-a3fd-dff2c4c0b3ca"
      break
    case "case":
      if (ddGroup === "task") return "q-f113ff03-db0d-4256-bb41-411f36b172a7"
      if (ddGroup === "note") return "q-7bae83a0-ae55-411b-8525-7bb40bcd374a"
      break
    case "fact_find":
    case "client":
      if (ddGroup === "task") return "q-6a32be77-70fe-47a1-b023-fda27d1588a5"
      if (ddGroup === "note") {
        return "q-dd12e77a-ed65-4f09-822c-d5dd69534c54"
      }

      if (ddGroup === "doc") return "q-c36babbf-5a11-43a4-8eea-45a2f35accac"
      break
    case "requirement":
    case "mortgage_advice":
    case "protection_advice":
    case "home_insurance_advice":
      {
        let requirement = store.getters["requirements/allRequirements"].find(
          (req) => req.id === requirementId
        )
        if (!requirement) {
          console.error("Required field: 'requirement_id' is missing.")
          return ""
        }
        if (
          requirement.kyc_requirement_type_key === "buy_to_let_mortgage" ||
          requirement.kyc_requirement_type_key === "residential_mortgage"
        ) {
          return "q-3adbf005-df65-4567-a8c2-f4c34edca914"
        }
        if (requirement.kyc_requirement_type_key === "protection") {
          //same for all types
          return "q-1f86a431-0880-419d-b1ef-e9ecf500afb1"
        }
        if (
          requirement.kyc_requirement_type_key ===
          "buildings_contents_insurance"
        ) {
          return "q-77ffc56f-bef1-4233-b174-648552ec253a"
        }
      }
      break
    case "application":
      if (ddGroup === "task") return "" //placeholder for future work
      if (ddGroup === "note") return "" //placeholder for future work
      break
  }
  console.error(
    `Cannot find guid for category: '${category}' type: '${ddGroup}'`
  )
  return ""
}
export function cleanupUnusedFields(noteOrTask) {
  let toClear = {
    case_section: true,
    fact_find_section: true,
    requirement_id: true,
    requirement_section: true,
    application_id: true,
    application_section: true
  }
  switch (noteOrTask.category) {
    case "case":
      delete toClear.case_section
      break

    case "fact_find":
      delete toClear.fact_find_section
      break

    case "requirement":
      delete toClear.requirement_section
      delete toClear.requirement_id
      break

    case "application":
      delete toClear.application_id
      delete toClear.application_section
      break
  }
  Object.keys(toClear).forEach((key) => (noteOrTask[key] = null))
}
