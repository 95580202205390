import { extend } from "vee-validate"
import * as rules from "vee-validate/dist/rules"
import { messages } from "vee-validate/dist/locale/en.json"
import moment from "moment"
import { momentdbDateFormat } from "@/global/modules/enum/constants"

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  })
})

extend("accepted", (value) => {
  const values = ["yes", "on", 1, true]
  if (values.includes(value)) {
    return true
  }
  return "This field must be accepted"
})

extend("boolean", {
  getMessage(field) {
    return `This ${field} must be Yes or No`
  },
  validate(value) {
    const values = [true, false, 1, 0, "1", "0", "true", "false"]
    return values.includes(value)
  }
})

extend("after", {
  validate(value, args) {
    if (!args.dateFormat || args.dateFormat == "undefined") {
      args.dateFormat = momentdbDateFormat
    }
    let date = moment(value).format(args.dateFormat)
    return moment(date).isAfter(args.date) || args.message
  },
  params: ["date", "dateFormat", "message"]
})

extend("before", {
  validate(value, args) {
    if (!args.dateFormat || args.dateFormat == "undefined") {
      args.dateFormat = momentdbDateFormat
    }
    let date = moment(value).format(args.dateFormat)
    return moment(date).isBefore(args.date) || args.message
  },
  params: ["date", "dateFormat", "message"]
})

extend("after_or_equal", {
  validate(value, args) {
    if (!args.dateFormat || args.dateFormat == "undefined") {
      args.dateFormat = momentdbDateFormat
    }
    let date = moment(value).format(args.dateFormat)
    return moment(date).isSameOrAfter(args.date) || args.message
  },
  params: ["date", "dateFormat", "message"]
})

extend("before_or_equal", {
  validate(value, args) {
    if (!args.dateFormat || args.dateFormat == "undefined") {
      args.dateFormat = momentdbDateFormat
    }
    let date = moment(value).format(args.dateFormat)
    return moment(date).isSameOrBefore(args.date) || args.message
  },
  params: ["date", "dateFormat", "message"]
})

extend("date", () => {
  return true
})

extend("string", () => {
  return true
})

extend("decimal", {
  validate(value, { decimals = "*", separator = "." } = {}) {
    if (value == null || value == undefined || value == "") {
      return true
    }
    if (typeof value === "number") {
      value = value.toString()
    }

    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`
    const regex = new RegExp(`^\\d*(\\${separator}\\d${regexPart})?$`)

    return regex.test(value)
  }
})
