import axios from "axios"
export const state = {
  loading: false,
  error: null,
  queries: [],
  saving: false
}

export const getters = {
  isLoading: (state) => state.loading,
  isSaving: (state) => state.saving,
  error: (state) => state.error,
  queries: (state) => state.queries
}

export const mutations = {
  clearError(state) {
    state.error = null
  },
  setError(state, err) {
    state.error = err
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setSaving(state, saving) {
    state.saving = saving
  },
  setQueries(state, queries) {
    state.queries = queries
  }
}

export const actions = {
  remove({ commit, state }, id) {
    let queries = state.queries.filter((e) => e.id !== id)
    commit("setQueries", queries)
  },
  addOrUpdateLocal({ commit, state }, query) {
    let queries = state.queries
    let index = queries.indexOf(query)
    if (index >= 0) {
      queries[index] = query
    } else {
      queries.push(query)
    }
    commit("setQueries", queries)
  },
  async refreshAsync({ commit }) {
    commit("clearError")
    commit("setLoading", true)

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/query/getall`
      )

      if (response.status === 200) {
        commit("setQueries", response.data)
      } else {
        commit("setError", response.status)
      }
    } catch (err) {
      commit("setError", err)
    }

    commit("setLoading", false)
  },
  async deleteAsync({ commit, dispatch }, id) {
    commit("clearError")
    try {
      const response = await axios.delete(
        `${process.env.VUE_APP_CDS_URL}/query/${id}`
      )
      if (response.status >= 200 && response.status <= 299) {
        dispatch("remove", id)
      } else {
        commit("setError", response.status)
      }
    } catch (ex) {
      commit("setError", ex)
    }
  },
  async getAsync({ commit }, id) {
    commit("clearError")
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/query/${id}`,
        id
      )
      if (response.status >= 200 && response.status <= 299) {
        return response.data
      } else {
        commit("setError", response.status)
      }
    } catch (ex) {
      commit("setError", ex)
    }
    return null
  },
  async saveAsync({ commit, dispatch }, payload) {
    commit("clearError")
    commit("setSaving", true)
    let savedObject = null
    if (!payload) {
      return null
    }
    try {
      let endpoint = payload.id ? "update" : "create"

      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/query/${endpoint}`,
        payload
      )

      if (response.status >= 200 && response.status <= 299) {
        let id = null
        if (endpoint === "update") {
          id = payload.id
        } else {
          id = response.data.id
        }
        savedObject = await dispatch("getAsync", id)

        dispatch("addOrUpdateLocal", savedObject)
      } else {
        commit("setError", response.status)
      }
    } catch (ex) {
      commit("setError", ex)
    }
    commit("setSaving", false)
    return savedObject
  }
}
