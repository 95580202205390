import axios from "axios"

export const state = {
  dashboard: getEmptyDashboard(),
  errorForDisplay: null,
  loading: false
}

export const mutations = {
  clearDashboard(state) {
    state.dashboard = getEmptyDashboard()
  },
  setDashboard(state, data) {
    state.dashboard = data
  },
  setDashboardLoading(state, value) {
    state.loading = value
  },
  setErrorForDisplay(state, value) {
    state.errorForDisplay = value
  },
  clearErrorForDisplay(state) {
    state.errorForDisplay = null
  }
}
export const actions = {
  async loadDashboard(context) {
    context.commit("clearErrorForDisplay")

    try {
      context.commit("setDashboardLoading", true)
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/dashboard/get`
      )

      if (response.status === 200) {
        context.commit("setDashboard", response.data)
      }
    } catch (err) {
      console.error(err)
      context.commit("clearDashboard")
      context.commit("setErrorForDisplay", "Failed to load dashboard")
    } finally {
      context.commit("setDashboardLoading", false)
    }
  },
  getAllClients({ rootGetters }) {
    return rootGetters["clients/data"]
  }
}

export const getters = {
  dashboard(state) {
    return state.dashboard
  },
  loading(state) {
    return state.loading
  },
  errorForDisplay(state) {
    return state.errorForDisplay
  },
  cases(state) {
    return state.dashboard.cases
  },
  numberOfOpenCases(state) {
    return state.dashboard.summary.active_case_count
  },
  numberOfActiveApplications(state) {
    return state.dashboard.summary.active_application_count
  },
  recentCaseOpens(state) {
    return state.dashboard.cases
  },
  casesActivity(state) {
    return state.dashboard.recent_activities
  },
  openCases(state) {
    return state.dashboard.open_cases
  },
  clients(state) {
    return state.dashboard.clients
  },
  openTasks(state) {
    return state.dashboard.tasks
  }
}

function getEmptyDashboard() {
  return {
    summary: {
      client_count: 0,
      active_case_count: 0,
      active_requirement_count: 0,
      opportunities: 0
    },
    tasks: []
  }
}
