import { UserApi } from "../admin/users"

export const namespaced = true

export const state = {
  user: null,
  products: [],
  permissions: [],
  fetching: false,
  refreshing: false,
  error: null
}

export const getters = {
  isLoading: (state) => state.fetching,
  isRefreshing: (state) => state.refreshing,
  products: (state) => state.products,
  user: (state) => state.user,
  permissions: (state) => state.permissions
}

export const mutations = {
  setApiError(state, error) {
    state.error = error
    state.fetching = false
  },
  unsetApiError(state) {
    state.error = null
  },
  setFetchingUserData(state, fetching) {
    state.fetching = fetching
  },
  setRefreshingUserData(state, fetching) {
    state.refreshing = fetching
  },
  setUserData(state, user) {
    state.user = user
  },
  setUserPermissions(state, permissions) {
    state.permissions = permissions
    state.policy.setData(permissions)
  },
  setUserProducts(state, products) {
    state.products = products
  }
}

export const actions = {
  async refresh({ commit }, silently = false) {
    commit("unsetApiError")
    commit(silently ? "setRefreshingUserData" : "setFetchingUserData", true)
    try {
      const user = new UserApi()
      const userJson = await user.getSelf()
      const permissionsJson = await user.getPermissions()
      const productsJson = await user.getProducts()

      commit("setUserData", userJson.data)
      commit("setUserProducts", productsJson.data.products)
      commit("setUserPermissions", permissionsJson.data["frontend-permissions"])
      commit(silently ? "setRefreshingUserData" : "setFetchingUserData", false)
    } catch (e) {
      commit("setApiError", e)
    }
  }
}
