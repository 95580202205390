import axios from "axios"
import { displayProviderNameByIdAndType } from "../../global/modules/helpers/providerHelpers"
import { Mutex } from "async-mutex"

export const state = {
  count: 0,
  existingComplianceReviewsCount: 0,
  applications: [],
  selectedApplication: null,
  errorForDisplay: "",
  matchedApplications: [],
  existingComplianceReviews: [],
  applicationDetails: null,
  applicationCompliance: null,
  replacements: [],
  loadMutex: new Mutex(),
  loadAxiosController: null,
  showAssignComplianceOfficer: false,
  complianceReviewUpdated: false
}

function abortLoadAxiosController(state) {
  if (state.loadAxiosController) {
    try {
      state.loadAxiosController.abort("Operation cancelled by the user.")
    } catch (ex) {
      console.error(ex)
    }
  }
}
async function loadAppsWithMutexAsync(state, url, dto) {
  let request = state.loadMutex.runExclusive(async () => {
    abortLoadAxiosController(state)
    state.loadAxiosController = new AbortController()
    return axios.post(url, dto, {
      signal: state.loadAxiosController.signal
    }) //no await, return the raw promise.
  }) //end mutex
  return await request
}

export const mutations = {
  setCount(state, value) {
    state.count = value
  },
  clearApplications(state) {
    state.applications = []
  },
  addApplication(state, data) {
    state.applications.push(data)
  },
  getApplicationById(state, id) {
    state.selectedApplication = state.applications.find(
      (d) => d.case_application.id === id
    )
  },
  setSelectedApplication(state, data) {
    state.selectedApplication = data
  },
  setMatchedApplications(state, data) {
    state.matchedApplications = data
  },
  setExistingComplianceReviews(state, data) {
    state.existingComplianceReviews = data
  },
  setExistingComplianceReviewsCount(state, value) {
    state.existingComplianceReviewsCount = value
  },
  setRandomChecks(state, data) {
    state.randomApplicationChecks = data
  },
  setApplicationCompliance(state, data) {
    state.applicationCompliance = data
  },
  addErrorForDisplay(state, error) {
    state.errorForDisplay = error
  },
  setApplicationDetailsForCreation(state, data) {
    state.applicationDetails = data
  },
  clearApplicationDetails(state) {
    state.applicationDetails = null
  },
  setReplacements(state, data) {
    state.replacements = data
  },
  setShowAssignComplianceOfficer(state, data) {
    state.showAssignComplianceOfficer = data
  },
  setComplianceReviewUpdated(state, data) {
    state.complianceReviewUpdated = data
  }
}

export const getters = {
  accountId: (state, getters, rootState, rootGetters) =>
    rootGetters["mbuser/user"].account_id,
  applications: (state) => state.applications,
  selectedApplication: (state) => state.selectedApplication,
  errorForDisplay: (state) => state.errorForDisplay,
  applicationDetails: (state) => state.applicationDetails,
  applicationCompliance: (state) => state.applicationCompliance,
  randomApplicationChecks: (state) => state.randomApplicationChecks,
  matchedApplications: (state) => state.matchedApplications,
  existingComplianceReviews: (state) => state.existingComplianceReviews,
  replacements: (state) => state.replacements,
  mortgageApplicationSelectOptions: (state) => getMortgageOptions(state),
  generalInsuranceApplicationSelectOptions: (state) =>
    getGeneralInsuranceOptions(state),
  protectionApplicationSelectOptions: (state) => getProtectionOptions(state),
  applicationSelectOptions: (state) => getApplicationSelectOptions(state),
  count: (state) => state.count,
  existingComplianceReviewsCount: (state) =>
    state.existingComplianceReviewsCount,
  showAssignComplianceOfficer: (state) => state.showAssignComplianceOfficer,
  complianceReviewUpdated: (state) => state.complianceReviewUpdated
}

export const actions = {
  async fetchCheckedApplications({ commit, state }, appFetchInfo) {
    let default_sort = {
      sort_field: "application_created",
      sort_ascending: false
    }
    let sort_field = appFetchInfo.sort_field
      ? appFetchInfo.sort_field
      : default_sort
      ? default_sort.sort_field
      : ""
    let sort_ascending =
      appFetchInfo.sort_ascending !== null &&
      appFetchInfo.sort_ascending !== undefined
        ? appFetchInfo.sort_ascending
        : default_sort
        ? default_sort.sort_ascending
        : true
    let dto = {
      page_number: appFetchInfo.page_number,
      per_page: appFetchInfo.per_page,
      search_text: appFetchInfo.search_text,
      sort_field: sort_field,
      secondary_sort_field: appFetchInfo.secondary_sort_field,
      sort_ascending: sort_ascending
    }
    try {
      //api/v1/caseapplications/getforchecking/{account_id}
      let url = `${process.env.VUE_APP_CDS_URL}/caseapplications/getforchecking`
      let response = await loadAppsWithMutexAsync(state, url, dto)
      // Cancelled by subsequent request.
      if (response === undefined) return
      if (response.status === 200) {
        commit("setCaseData", response.data.Results)
        commit("setCount", response.data.Count)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async loadAppsMatchedForChecking({ getters, commit }, payload) {
    const accountId = getters.accountId
    const response = await axios.get(
      `${process.env.VUE_APP_CDS_URL}/caseapplications/getforchecking?account_id=${accountId}&PageNumber=${payload.page_number}&ItemsPerPage=${payload.per_page}`
    )

    if (response.status === 200) {
      commit("setMatchedApplications", response.data)
      commit("setCount", response.data.total_count)
      return true
    }
  },
  async loadExistingComplianceReviews({ commit, state }, payload) {
    let default_sort = {
      sort_field: "application_created",
      sort_ascending: false
    }
    let sort_field = payload.sort_field
      ? payload.sort_field
      : default_sort
      ? default_sort.sort_field
      : ""
    let sort_ascending =
      payload.sort_ascending !== null && payload.sort_ascending !== undefined
        ? payload.sort_ascending
        : default_sort
        ? default_sort.sort_ascending
        : true
    let dto = {
      report_name: "ComplianceReviewTasks",
      page_number: payload.page_number,
      per_page: payload.per_page,
      sort_field: sort_field,
      sort_ascending: sort_ascending
    }

    try {
      let url = `${process.env.VUE_APP_REPORTS_URL}/reports/runreport`
      let response = await loadAppsWithMutexAsync(state, url, dto)
      // Cancelled by subsequent request.
      if (response === undefined) return
      if (response.status === 200) {
        commit("setExistingComplianceReviews", response.data.Results)
        commit("setExistingComplianceReviewsCount", response.data.Count)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async loadApplications(context) {
    context.commit("clearApplications")
    const caseId = context.rootGetters["cases/currentCaseId"]
    const clients = context.rootGetters["clientFile/selectedClients"]
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    await axios
      .get(
        `${process.env.VUE_APP_CDS_URL}/caseapplicationoverview/getforcaseid/${caseId}${snapshotIdQuery}`
      )
      .then((response) => {
        if (response.data.length) {
          response.data.forEach((data) => {
            mapApplicationsForList(data, clients, context)
          })
        }
      })
  },
  async getApplicationsByCaseId(_, caseId) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/caseapplicationoverview/getforcaseid/${caseId}`
      )
      if (response.status === 200) {
        return response.data
      }
    } catch (err) {
      console.log(err)
    }
  },
  async getReplacements(context) {
    const caseId = context.rootGetters["cases/currentCaseId"]
    const applicationId = state.selectedApplication.case_application.id
    await axios
      .get(
        `${process.env.VUE_APP_CDS_URL}/caseapplications/${caseId}/application/${applicationId}/replacements`
      )
      .then((response) => {
        context.commit("setReplacements", response.data.value)
      })
      .catch((err) => console.error(err))
  },

  async convertToExisting({ dispatch, rootGetters }) {
    const caseId = rootGetters["cases/currentCaseId"]
    const applicationId = state.selectedApplication.case_application.id
    await axios
      .get(
        `${process.env.VUE_APP_CDS_URL}/caseapplications/${caseId}/application/${applicationId}/convert`
      )
      .then((response) => {
        let application = response.data.value
        response.data = [application]
        return dispatch("saveApplicationAndReload", response.data)
      })
  },

  async saveApplicationAndReload({ dispatch, commit }, application) {
    return await dispatch("saveApplication", application).then((e) => {
      commit("getApplicationById", e.data[0].id)
      return e.data[0]
    })
  },
  async saveApplication({ dispatch }, applications) {
    return await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/caseapplications/createorupdate`,
        applications
      )
      .then((saveResponse) => {
        return dispatch("loadApplications").then(() => {
          return saveResponse
        })
      })
  },
  async saveApplicationNoReload(_, applications) {
    return await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/caseapplications/createorupdate`,
        applications
      )
      .then((saveResponse) => {
        return saveResponse
      })
  },
  async getCaseCompliancesAsync({ commit }, payload) {
    return await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/caseapplications/GetCaseCompliancesAsync`,
        payload
      )
      .then((response) => {
        commit("setApplicationCompliance", response.data[0])
        return response.data[0]
      })
      .catch((err) => {
        let errMsg =
          "Error running GetCaseCompliancesAsync. Reason: " + err.message
        commit("addErrorForDisplay", errMsg)
      })
  },
  async selectRandomChecksAsync({ commit }, payload) {
    return await axios
      .post(
        `${process.env.VUE_APP_CDS_URL}/caseapplications/select-random-checks`,
        payload
      )
      .then((response) => {
        commit("setRandomChecks", response.data[0])
        return response.data
      })
      .catch((err) => {
        let errMsg = "Error running selectRandomChecks. Reason: " + err.message
        commit("addErrorForDisplay", errMsg)
      })
  },
  async createApplication({ commit }, payload) {
    return await axios
      .post(`${process.env.VUE_APP_CDS_URL}/caseapplications/createorupdate`, [
        payload
      ])
      .then((response) => {
        const newApplicationId = response.data[0].id
        return newApplicationId
      })
      .catch((err) => {
        let errMsg = "Error during application create. Reason: " + err.message
        commit("addErrorForDisplay", errMsg)
      })
  },
  async createProtectionApplication({ commit }, payload) {
    const requestProtection = axios.post(
      `${process.env.VUE_APP_CDS_URL}/caserequirementprotectionproduct/cases/${payload.application.caseId}/requirements/${payload.application.requirementId}/createorupdate`,
      [payload.protection]
    )

    const requestApplication = axios.post(
      `${process.env.VUE_APP_CDS_URL}/caseapplications/createorupdate`,
      [payload.application]
    )

    return await axios
      .all([requestProtection, requestApplication])
      .then(
        axios.spread((...responses) => {
          const newApplicationId = responses[1].data[0].id
          return newApplicationId
        })
      )
      .catch((err) => {
        let errMsg = "Error during application create. Reason: " + err.message
        commit("addErrorForDisplay", errMsg)
      })
  }
}

async function mapApplicationsForList(data, clients, context) {
  let linkedClients = clients
    .filter((client) =>
      data.case_application.linked_clients_ids.includes(client.id)
    )
    .reduce((names, client) => {
      names.push(client.fullname)
      return names
    }, [])
    .join(", ")

  if (data.product_type == "residential" || data.product_type == "buy_to_let") {
    context.commit(
      "addApplication",
      Object.assign(data, {
        clients: linkedClients,
        lender: displayProviderNameByIdAndType(data.lender_id, "mortgage"),
        lender_id: data.lender_id,
        product_type: getDisplayText(
          context,
          "q-69516e94-166f-4908-a9d3-e22428845e9b",
          data.product_type
        ),
        application_type: getDisplayText(
          context,
          "q-a7107142-9302-4b92-96f4-20ec946ed4d9",
          data.application_type
        ),
        linked_requirement: data.case_application.requirement_id,
        status: getDisplayText(
          context,
          "q-88696b1d-9e87-4655-b11a-77661e04ba64",
          data.case_application.closure_workflow_status_application_key
        )
      })
    )
  } else if (data.product_type == "protection") {
    context.commit(
      "addApplication",
      Object.assign(data, {
        clients: linkedClients,
        lender: displayProviderNameByIdAndType(data.lender_id, "protection"),
        lender_id: data.lender_id,
        product_type: getDisplayText(
          context,
          "q-5d62f2ec-18d4-45ab-954f-3bf937c3fbb8",
          data.product_type
        ),
        application_type: getApplicationType(context, data.application_type),
        linked_requirement: data.case_application.requirement_id,
        status: getDisplayText(
          context,
          "q-88696b1d-9e87-4655-b11a-77661e04ba64",
          data.case_application.closure_workflow_status_application_key
        )
      })
    )
  } else if (data.product_type == "general_insurance") {
    context.commit(
      "addApplication",
      Object.assign(data, {
        clients: linkedClients,
        lender: displayProviderNameByIdAndType(
          data.lender_id,
          "general_insurance"
        ),
        lender_id: data.lender_id,
        product_type: "Buildings / Contents",
        application_type: getAppTypeText(data.application_type),
        linked_requirement: data.case_application.requirement_id,
        status: getDisplayText(
          context,
          "q-88696b1d-9e87-4655-b11a-77661e04ba64",
          data.case_application.closure_workflow_status_application_key
        )
      })
    )
  }
}

function getAppTypeText(type) {
  var coverType =
    type === "buildings_contents"
      ? "Buildings & Contents"
      : type === "buildings_only"
      ? "Buildings Only"
      : "Contents Only"

  return coverType
}

function getDisplayText(context, formId, value) {
  const displayText =
    context.rootGetters["datadictionary/displayTextByFormIdAndValue"]
  return displayText(formId, value)
}

function getApplicationType(context, appType) {
  if (appType === "multi_plan") return "Multi Plan"

  const displayText =
    context.rootGetters["datadictionary/displayTextByFormIdAndValue"]

  const typeKeys = appType.split("|")

  if (typeKeys && typeKeys.length === 1)
    return displayText("q-b724ecde-6b02-4c3b-aa95-5d22b9a0b36a", typeKeys[0])

  let protectionType = displayText(
    "q-b724ecde-6b02-4c3b-aa95-5d22b9a0b36a",
    typeKeys[0]
  )

  let coverType = displayText(
    "q-a013b293-afbb-4bf2-b7ea-9c7ae3d4d59c",
    typeKeys[1]
  )

  return [protectionType, coverType].filter((e) => e).join(" ")
}

function getApplicationSelectOptions(state) {
  let options = state.applications.reduce((a, v) => {
    let text = [v.lender]
    if (v.address?.length > 0) {
      text.push(v.address)
    } else if (v.application_type?.length > 0) {
      text.push(v.application_type)
    }
    a.push({ text: text.join(", "), value: v.case_application.id })
    return a
  }, [])

  if (options.length === 0) {
    options.push({
      text: "No Applications Exist",
      value: null
    })
  }

  return options
}

function getMortgageOptions(state) {
  let options = state.applications.reduce((a, v) => {
    if (
      v.product_type.toLowerCase() === "residential" ||
      v.product_type.toLowerCase() === "buy to let"
    ) {
      let text = [v.lender]
      if (v.address?.length > 0) {
        text.push(v.address)
      }

      a.push({ text: text.join(", "), value: v.case_application.id })
    }
    return a
  }, [])

  if (options.length === 0) {
    options.push({
      text: "No Mortgage Applications Exist",
      value: null
    })
  }

  return options
}

function getGeneralInsuranceOptions(state) {
  let options = state.applications.reduce((a, v) => {
    if (v.product_type.toLowerCase() === "buildings / contents") {
      let text = [v.lender, v.application_type]
      a.push({ text: text.join(", "), value: v.case_application.id })
    }
    return a
  }, [])

  if (options.length === 0) {
    options.push({
      text: "No General Insurance Applications Exist",
      value: null
    })
  }

  return options
}

function getProtectionOptions(state) {
  let options = state.applications.reduce((a, v) => {
    if (v.product_type.toLowerCase() == "protection") {
      let text = [v.lender, v.application_type]
      a.push({ text: text.join(", "), value: v.case_application.id })
    }
    return a
  }, [])

  if (options.length === 0) {
    options.push({ text: "No Protection Applications Exist", value: null })
  }

  return options
}
