import {
  chainGuards,
  storeGuards,
  storeGetters
} from "@/global/modules/middleware/checkStore"
import { dashboardRoutes } from "./dashboard"
import { requirementRoutes } from "./requirement"
import { appicationRoutes } from "./application"
import { factFindRoutes } from "./factFind"
import { overviewRoutes } from "./overview"
import { commissionRoutes } from "./commissions"

const caseRoutes = [
  ...dashboardRoutes,
  ...requirementRoutes,
  ...factFindRoutes,
  ...appicationRoutes,
  {
    path: "administration",
    name: "administration",
    component: () =>
      import(
        /* webpackChunkName: "administration" */ "@/components/ux/clients/cases/CaseAdministration.vue"
      ),
    meta: {
      caseNavActive: true,
      title: "Administration",
      blockIfProduction: true
    }
  },
  {
    path: "documents",
    beforeEnter: chainGuards([
      storeGuards.loadAllTeamMembers,
      storeGuards.loadRequirementsGuard
    ]),
    name: "case-documents",
    component: () =>
      import(
        /* webpackChunkName: "case-documents-list" */ "@/components/ux/clients/cases/CaseAllDocuments.vue"
      ),
    meta: {
      caseNavActive: true
    },
    children: [
      {
        path: "",
        name: "case-documents-list",
        beforeEnter: storeGuards.loadCaseDocumentsGuard,
        component: () =>
          import(
            /* webpackChunkName: "case-documents-list" */ "@/components/ux/clients/cases/CaseDocumentList.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Documents"
        }
      },
      {
        path: "create-document",
        name: "create-document-from-template",
        beforeEnter: storeGuards.loadCaseDocumentsGuard,
        component: () =>
          import(
            /* webpackChunkName: "create-document-from-template" */ "@/components/ux/shared/documents/DocumentAddEdit.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Documents"
        }
      },
      {
        path: "edit-document",
        name: "edit-draft-document",
        beforeEnter: storeGuards.loadCaseDocumentsGuard,
        component: () =>
          import(
            /* webpackChunkName: "edit-draft-document" */ "@/components/ux/shared/documents/DocumentAddEdit.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Documents"
        }
      }
    ]
  },
  {
    path: "documentsold",
    beforeEnter: chainGuards([
      storeGuards.loadCaseDocumentsGuard,
      storeGuards.loadRequirementsGuard
    ]),
    name: "documentsold",
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "@/components/ux/clients/cases/CaseAllDocuments.vue"
      ),
    meta: {
      caseNavActive: true,
      title: "Documents"
    },
    children: [
      {
        path: "",
        name: "case-documents-list-old",
        component: () =>
          import(
            /* webpackChunkName: "case-documents-list" */ "@/components/ux/clients/cases/CaseDocumentList.vue"
          ),
        meta: {
          caseNavActive: true
        }
      },
      {
        path: "add-documents",
        name: "add-case-documents",
        component: () =>
          import(
            /* webpackChunkName: "add-case-documents" */ "@/components/ux/clients/cases/CaseAddDocuments.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Add Documents"
        }
      },
      {
        path: "doc-templates",
        name: "case-document-templates",
        component: () =>
          import(
            /* webpackChunkName: "case-document-templates" */ "@/components/ux/clients/cases/CaseDocumentTemplates.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "All Document Templates"
        }
      },
      {
        path: "createtemplate/:docId([0-9a-fA-F-]{36})",
        name: "case-document-create-template",
        component: () =>
          import(
            /* webpackChunkName: "case-document-create-template" */ "@/components/ux/shared/documents/DocumentEditor.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Create Document"
        }
      },
      {
        path: "editDocument/:docId([0-9a-fA-F-]{36})",
        name: "case-document-edit-document",
        component: () =>
          import(
            /* webpackChunkName: "case-document-edit-document" */ "@/components/ux/shared/documents/DocumentEditor.vue"
          ),
        meta: {
          caseNavActive: true,
          title: "Edit Document"
        }
      }
    ]
  },
  {
    path: "notes",
    beforeEnter: chainGuards([
      storeGuards.loadNotesGuard,
      storeGuards.loadRequirementsGuard
    ]),
    name: "case-notes",
    component: () =>
      import(
        /* webpackChunkName: "case-notes" */ "@/components/ux/clients/cases/CaseNotes.vue"
      ),
    meta: {
      caseNavActive: true,
      title: "Notes"
    }
  },
  ...commissionRoutes,
  {
    path: "management",
    name: "case-management",
    component: () =>
      import(
        /* webpackChunkName: "case-management" */ "@/components/ux/clients/cases/CaseManagement.vue"
      ),
    meta: {
      caseNavActive: true,
      title: "Case Management"
    }
  },
  {
    path: "compliance",
    name: "compliance",
    component: () =>
      import(
        /* webpackChunkName: "case-management" */ "@/components/ux/clients/cases/Compliance.vue"
      ),
    meta: {
      caseNavActive: true,
      title: "Compliance"
    }
  },
  {
    path: "messages/:preSelectedClientId([0-9a-fA-F]{24})?",
    beforeEnter: chainGuards([
      storeGuards.loadClientPortalGuard,
      storeGuards.loadMessagesGuard
    ]),

    name: "case-messages",
    component: () =>
      import(
        /* webpackChunkName: "case-messages" */ "@/components/ux/clients/cases/messaging/Chat.vue"
      ),
    meta: {
      caseNavActive: true,
      title: "Messages"
    }
  },
  ...overviewRoutes
]

function redirectCaseRoute(name, params) {
  const snapshotId = storeGetters.getSelectedSnapshotId()
  if (snapshotId) {
    return {
      name: `${name}-snapshot`,
      params: {
        ...params,
        snapshotId: snapshotId
      }
    }
  }
  return { name: `${name}-current` }
}

function mapChildRoutes(
  redirectRoute,
  currentRoute,
  snapshotRoute,
  childRoutes
) {
  for (let i = 0; i < childRoutes.length; i++) {
    const route = childRoutes[i]

    //Redirect route
    let childRedirectRoute = {
      path: route.path,
      name: route.name,
      redirect: (to) => redirectCaseRoute(route.name, to.params),
      children: []
    }

    redirectRoute.children.push(childRedirectRoute)

    //Current Case route
    let childCurrentRoute = {
      ...route,
      name: route.name ? `${route.name}-current` : route.name,
      children: []
    }

    currentRoute.children.push(childCurrentRoute)

    //Snapshot Case route
    let childSnapshotRoute = {
      ...route,
      name: route.name ? `${route.name}-snapshot` : route.name,
      children: [],
      meta: {
        ...route.meta,
        currentRoute: route.name
          ? `${route.name}-current`
          : "case-overview-current"
      }
    }

    if (!route.meta?.excludeFromSnapshot) {
      snapshotRoute.children.push(childSnapshotRoute)
    }

    if (route.children?.length > 0) {
      mapChildRoutes(
        childRedirectRoute,
        childCurrentRoute,
        childSnapshotRoute,
        route.children
      )
    }
  }
}

function mapCaseRoutes() {
  //All routes will route to here and then be redirected
  const redirectRoute = {
    path: "redirect",
    children: []
  }
  //This is where a route goes if viewing the current case, no snapshot id selected
  const currentRoute = {
    path: "",
    beforeEnter: chainGuards([
      storeGuards.clearSnapshotIdGuard,
      storeGuards.loadCaseGuard,
      storeGuards.loadCaseClientsGuard,
      storeGuards.clientAccessGuard,
      storeGuards.loadCompaniesGuard,
      storeGuards.loadAllTeamMembers
    ]),
    name: "client-case-current",
    component: () =>
      import(/* webpackChunkName: "client-case" */ "@/pages/clients/Case.vue"),
    meta: {
      caseNavActive: true,
      title: "Cases"
    },
    children: []
  }
  //This is where a route goes if a snapshot id has been selected
  const snapshotRoute = {
    path: "snapshot/:snapshotId([0-9a-fA-F]{24})",
    beforeEnter: chainGuards([
      storeGuards.loadCaseGuard,
      storeGuards.loadCaseClientsGuard,
      storeGuards.clientAccessGuard,
      storeGuards.loadCompaniesGuard,
      storeGuards.loadAllTeamMembers
    ]),
    name: "client-case-snapshot",
    component: () =>
      import(/* webpackChunkName: "client-case" */ "@/pages/clients/Case.vue"),
    meta: {
      caseNavActive: true,
      title: "Cases"
    },
    children: []
  }

  mapChildRoutes(redirectRoute, currentRoute, snapshotRoute, caseRoutes)

  const mappedCaseRoutes = [
    {
      path: "case/:caseId([0-9a-fA-F]{24})",
      beforeEnter: chainGuards([
        storeGuards.loadSnapshotsGuard,
        storeGuards.loadAdvisorsGuard,
        storeGuards.loadAdminsGuard,
        storeGuards.loadPrincipalsGuard,
        storeGuards.pollUnreadMessages
      ]),
      component: () =>
        import(
          /* webpackChunkName: "client-case-landing" */ "@/pages/clients/CaseLanding.vue"
        ),
      meta: {
        caseNavActive: true
      },
      children: [redirectRoute, currentRoute, snapshotRoute]
    }
  ]

  return mappedCaseRoutes
}

export { mapCaseRoutes }
