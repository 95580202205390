import axios from "axios"

export const state = {
  config: null
}

export const getters = {
  factfind: (state) => ({
    required: (section, subSection, field) =>
      state.config?.fact_find?.includes(`${section}.${subSection}|${field}`) ??
      false
  })
}

export const actions = {
  async loadConfigurations({ commit }) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_PCM_URL}/account-configs/type/crm-section-validation`
      )

      if (response.status === 200) {
        commit("setConfig", response.data.data.config)
      }
    } catch {
      //Handled by inteceptor
    }
  }
}

export const mutations = {
  setConfig(state, data) {
    state.config = data
  }
}
