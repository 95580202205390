import Vue from "vue"

export const state = {
  userId: null,
  name: null,
  token: null,
  sendToken: true
}

export const mutations = {
  setUser(state, payload) {
    state.token = payload.token
    state.userId = payload.userId
    state.name = payload.name
  },
  sendTokenState(state, payload) {
    state.sendToken = payload.sendToken
  }
}

export const getters = {
  userId(state) {
    return state.userId
  },
  name(state) {
    return state.name
  },
  token(state) {
    return state.token
  },
  sendToken(state) {
    return state.sendToken
  },
  userSub() {
    return Vue.prototype.$auth.user.sub
  }
}
