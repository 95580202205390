import {
  chainGuards,
  storeGuards
} from "@/global/modules/middleware/checkStore"

export const mortgageRequirementRoutes = [
  {
    path: "add/mortgagerequirement/:requirementId",
    name: "client-case-requirement-mortgage",
    beforeEnter: chainGuards([
      storeGuards.loadPropertiesGuard,
      storeGuards.selectedRequirementGuard,
      storeGuards.loadCaseTasksGuard,
      storeGuards.loadRequirementDocumentsGuard
    ]),
    component: () =>
      import(
        /* webpackChunkName: "client-case-requirements-mortgage" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/CaseRequirementForm.vue"
      ),
    children: [
      {
        path: "summary",
        beforeEnter: storeGuards.loadNotesGuard,
        name: "client-case-requirement-mortgage-summary",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-summary" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/Summary.vue"
          ),
        meta: {
          title: "Summary",
          blockIfProduction: true
        }
      },
      {
        path: "tasks",
        name: "client-case-requirement-mortgage-tasks",
        beforeEnter: storeGuards.loadNotesGuard,
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-tasks" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/Tasks.vue"
          ),
        meta: {
          title: "Tasks"
        }
      },
      {
        path: "notes",
        beforeEnter: storeGuards.loadNotesGuard,
        name: "client-case-requirement-mortgage-notes",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-notes" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/Notes.vue"
          ),
        meta: {
          title: "Notes"
        }
      },
      {
        path: "documents",
        beforeEnter: storeGuards.loadNotesGuard,
        name: "client-case-requirement-mortgage-documents",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-documents" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/Documents.vue"
          ),
        meta: {
          title: "Documents"
        }
      },
      {
        path: "initial-research",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard,
          storeGuards.loadScenariosGuard
        ]),
        name: "client-case-requirement-mortgage-initial-research",
        meta: {
          title: "Research"
        },
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-initial-research" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/InitialResearch.vue"
          ),
        children: [
          {
            path: "",
            name: "requirement-initial-research-scenario-list",
            component: () =>
              import(
                /* webpackChunkName: "requirement-initial-research-scenario-list" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/InitialResearchList.vue"
              )
          },
          {
            path: "add-scenario",
            name: "requirement-initial-research-add-scenario",
            component: () =>
              import(
                /* webpackChunkName: "requirement-initial-research-add-scenario" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/InitialResearchAddScenario.vue"
              ),
            meta: {
              title: "Scenario / Add",
              excludeFromSnapshot: true
            }
          },
          {
            path: "add-product",
            name: "requirement-initial-research-add-product",
            component: () =>
              import(
                /* webpackChunkName: "requirement-initial-research-add-product" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/InitialResearchAddProduct.vue"
              ),
            meta: {
              title: "Product / Add",
              excludeFromSnapshot: true
            }
          },
          {
            path: "edit-product/:scenarioRef/product/:productRef",
            beforeEnter: storeGuards.editProductInfoGuard,
            name: "requirement-initial-research-edit-product",
            component: () =>
              import(
                /* webpackChunkName: "requirement-initial-research-edit-product" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/InitialResearchAddProduct.vue"
              ),
            meta: {
              title: "Product"
            }
          }
        ]
      },
      {
        path: "dip",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadTasksGuard
        ]),
        name: "client-case-requirement-mortgage-dip",
        meta: {
          title: "Decision in Principle"
        },
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-dip" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/dips/DecisionInPrinciple.vue"
          ),
        children: [
          {
            path: "",
            name: "client-case-requirement-mortgage-dip-list",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-dip-list" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/dips/DipList.vue"
              )
          },
          {
            path: "add-dip",
            beforeEnter: storeGuards.addDipGuard,
            name: "client-case-requirement-mortgage-dip-add",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-dip-add" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/dips/DipAddEdit.vue"
              ),
            meta: {
              title: "DIP / Add",
              excludeFromSnapshot: true
            }
          },
          {
            path: "edit-dip/:dipId",
            beforeEnter: storeGuards.editDipGuard,
            name: "client-case-requirement-mortgage-dip-edit",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-dip-edit" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/dips/DipAddEdit.vue"
              ),
            meta: {
              title: "DIP / Edit"
            }
          }
        ]
      },
      {
        path: "existing-mortgage",
        beforeEnter: chainGuards([
          storeGuards.loadPropertiesGuard,
          storeGuards.loadMortgagesGuard,
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        name: "client-case-requirement-mortgage-existing-mortgage",
        meta: {
          title: "Existing Mortgage"
        },
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-existing-mortgage" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/ExistingMortgage.vue"
          ),
        children: [
          {
            path: "",
            name: "client-case-requirement-mortgage-existing-mortgage-list",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-existing-mortgage-list" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/existingmortgage/ExistingMortgageList.vue"
              )
          },
          {
            path: "link/residential-standard/:selectedMortgageId([0-9a-fA-F]{24})",
            beforeEnter: storeGuards.editMortgageInfoGuard,
            name: "client-case-requirement-mortgage-link-mortgage-details",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-link-mortgage-details" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/existingmortgage/AddLinkMortgageDetails.vue"
              )
          },
          {
            path: "add",
            name: "client-case-requirement-mortgage-add",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-add" */ "@/components/ux/shared/mortgage/MortgageAdd.vue"
              )
          },
          {
            path: "add/residential-standard",
            name: "client-case-requirement-mortgage-add-mortgage-details",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-add-mortgage-details" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/existingmortgage/AddLinkMortgageDetails.vue"
              )
          },

          {
            path: "linked-mortgage/:selectedMortgageId([0-9a-fA-F]{24})",
            beforeEnter: chainGuards([storeGuards.editMortgageInfoGuard]),
            name: "client-case-requirement-mortgage-linked-mortgage-details",
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-linked-mortgage-details" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/existingmortgage/LinkedMortgageDetails.vue"
              )
          }
        ]
      },
      {
        path: "property-existing-mortgage",
        name: "client-case-requirement-existing-mortgage-property",
        beforeEnter: chainGuards([
          storeGuards.selectedRequirementPropertyGuard,
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-existing-mortgage-property" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/existingmortgage/Property.vue"
          )
      },
      {
        path: "purchase",
        name: "client-case-requirement-purchase-ownership",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        meta: {
          title: "Purchase"
        },
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-purchase-ownership-menu" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/purchase/PurchaseMenu.vue"
          ),
        children: [
          {
            path: "",
            name: "client-case-requirement-purchase-ownership-list",
            beforeEnter: chainGuards([
              storeGuards.selectedRequirementGuard,
              storeGuards.loadPropertiesGuard,
              storeGuards.loadMortgagesGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-purchase-ownership-list" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/purchase/OwnershipList.vue"
              ),
            meta: {
              title: "Owned Properties"
            }
          },
          {
            path: "edit/property-mortgage/:selectedMortgageId([0-9a-fA-F]{24})?",
            name: "client-case-requirement-purchase-mortgage-linked",
            beforeEnter: storeGuards.editMortgageInfoGuard,
            props: {
              onBackRoute: {
                name: "client-case-requirement-purchase-ownership-list"
              },
              kycRoute: true
            },
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-purchase-mortgage-linked" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/shared/LinkedMortgage.vue"
              ),
            meta: {
              title: "Mortgage Property"
            }
          },
          {
            path: "edit/property-ownership/:selectedPropertyId([0-9a-fA-F]{24})?",
            name: "client-case-requirement-purchase-property-linked",
            props: {
              onBackRoute: {
                name: "client-case-requirement-purchase-ownership-list"
              }
            },
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-purchase-property-linked" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/shared/LinkedProperty.vue"
              ),
            meta: {
              title: "Property Details"
            }
          },
          {
            path: "property/create",
            name: "client-case-requirement-purchase-create-property",
            beforeEnter: storeGuards.createPropertyForAddGuard,
            props: {
              toRoute: "client-case-requirement-purchase-ownership-list",
              ownershipAddRoute: true
            },
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-purchase-create-property" */ "@/components/ux/shared/properties/PropertyAddEdit.vue"
              ),
            meta: {
              title: "Create Property",
              excludeFromSnapshot: true
            }
          }
        ]
      },
      {
        path: "mortgage-review",
        name: "client-case-requirement-existing-mortgage-review-ownership",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        meta: {
          title: "Existing Mortgage"
        },
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-existing-mortgage-menu" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/existingmortgage/MortgageMenu.vue"
          ),
        children: [
          {
            path: "",
            name: "client-case-requirement-existing-mortgage-review",
            beforeEnter: chainGuards([
              storeGuards.selectedRequirementGuard,
              storeGuards.loadPropertiesGuard,
              storeGuards.loadMortgagesGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-existing-mortgage-review" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/existingmortgage/ExistingMortgageReview.vue"
              ),
            meta: {
              title: "Existing Mortgage Review"
            }
          },
          {
            path: "edit/existing-mortgage/:selectedMortgageId([0-9a-fA-F]{24})?",
            name: "client-case-requirement-existing-mortgage-linked",
            beforeEnter: storeGuards.editMortgageInfoGuard,
            props: {
              onBackRoute: {
                name: "client-case-requirement-existing-mortgage-review"
              },
              kycRoute: true
            },
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-existing-mortgage-linked" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/shared/LinkedMortgage.vue"
              ),
            meta: {
              title: "Mortgage Review"
            }
          },
          {
            path: "edit/mortgage-property/:selectedPropertyId([0-9a-fA-F]{24})?",
            name: "client-case-requirement-existing-property-linked",
            props: {
              onBackRoute: {
                name: "client-case-requirement-existing-mortgage-review"
              }
            },
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-existing-property-linked" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/shared/LinkedProperty.vue"
              ),
            meta: {
              title: "Property Details"
            }
          },
          {
            path: "create/property",
            name: "client-case-requirement-existing-create-property",
            beforeEnter: storeGuards.createPropertyForAddGuard,
            props: {
              toRoute: "client-case-requirement-existing-mortgage-review",
              ownershipAddRoute: true
            },
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-existing-create-property" */ "@/components/ux/shared/properties/PropertyAddEdit.vue"
              ),
            meta: {
              title: "Create Property"
            }
          }
        ]
      },
      {
        path: "property-purchase",
        name: "client-case-requirement-purchase-property",
        beforeEnter: chainGuards([
          storeGuards.selectedRequirementPropertyGuard,
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-purchase-property" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/purchase/Property.vue"
          ),
        meta: {
          title: "Property"
        }
      },
      {
        path: "property-purchase/add",
        name: "client-case-requirement-purchase-property-create",
        beforeEnter: chainGuards([
          storeGuards.selectedRequirementPropertyGuard,
          storeGuards.addPurchaseRequirementPropertyGuard
        ]),
        props: {
          addRoute: true
        },
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-purchase-property-create" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/purchase/PropertyDetails.vue"
          ),
        meta: {
          title: "Property / Add",
          excludeFromSnapshot: true
        }
      },
      {
        path: "property-purchase/:selectedPropertyId([0-9a-fA-F]{24})/details",
        name: "client-case-requirement-purchase-property-details",
        beforeEnter: chainGuards([
          storeGuards.selectedRequirementPropertyGuard
        ]),
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-purchase-property-details" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/purchase/PropertyDetails.vue"
          ),
        meta: {
          title: "Property / Details"
        }
      },
      {
        path: "loan-and-product",
        name: "client-case-requirement-mortgage-loan-and-product",
        beforeEnter: chainGuards([
          storeGuards.selectedRequirementPropertyGuard,
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-loan-and-product" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/LoanAndProduct.vue"
          ),
        meta: {
          title: "Loan & Product Preferences"
        }
      },
      {
        path: "final-research",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        name: "client-case-requirement-mortgage-final-research",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-final-research" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/FinalResearch.vue"
          ),
        children: [
          {
            path: "",
            beforeEnter: storeGuards.loadWorkflowConfigurationsGuard,
            name: "requirement-recommendation-list",
            component: () =>
              import(
                /* webpackChunkName: "requirement-recommendation-list" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/RecommendationList.vue"
              ),
            meta: {
              title: "Recommend & Apply"
            }
          },
          {
            path: "mortgage-generate-document",
            name: "client-case-requirement-mortgage-generate-document",
            beforeEnter: storeGuards.selectedMortgageLoadTemplatesGuard,
            component: () =>
              import(
                /* webpackChunkName: "client-case-requirement-mortgage-generate-document" */ "@/components/ux/shared/documents/LinkedDocument.vue"
              ),
            meta: {
              title: "Create Sutability Letter",
              excludeFromSnapshot: true
            }
          }
        ]
      },
      {
        path: "dips",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        name: "client-case-requirement-mortgage-dips",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-dips" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/DIPs.vue"
          ),
        meta: {
          title: "Decisions in Principle"
        }
      },
      {
        path: "application",
        beforeEnter: chainGuards([
          storeGuards.loadNotesGuard,
          storeGuards.loadCaseTasksGuard
        ]),
        name: "client-case-requirement-mortgage-application",
        component: () =>
          import(
            /* webpackChunkName: "client-case-requirement-mortgage-application" */ "@/components/ux/clients/cases/requirements/mortgagerequirements/Application.vue"
          ),
        meta: {
          title: "Application(s)",
          blockIfProduction: true
        }
      }
    ],
    meta: {
      //requiresAuthCase: true,
      caseNavActive: true
    }
  }
]
