import MbUserDropdown from "./components/MbUserDropdown"
import Vue from "vue"
import * as UserStore from "./store/mbuser"
import { mapActions, mapGetters } from "vuex"
const { loadPolicy } = require("@open-policy-agent/opa-wasm")
import wasm from "./assets/policy.wasm"
import jwt_decode from "jwt-decode"

export * from "./admin/base"
export * from "./admin/accounts"
export * from "./admin/register"
export * from "./admin/users"

const debugLog = (user, ...args) => {
  if (user.settings?.debug) {
    console.log(...args)
  }
}

const checkOwnershipArray = (value, user) => {
  if (!Array.isArray(value)) {
    debugLog(user, `Expected array ownership, got: ${value}`)
    return [value]
  }
  return value
}

let mbuInstance

export const getMbuInstance = () => mbuInstance

export const useMbuInstance = ({ store, auth0, settings }) => {
  if (mbuInstance) return mbuInstance

  store.registerModule("mbuser", UserStore)

  mbuInstance = new Vue({
    store,
    data() {
      return {
        store: store,
        auth: auth0,
        settings: settings,
        opa: null
      }
    },
    computed: {
      ...mapGetters({
        isAccountAdmin: "mbuser/isAccountAdmin",
        isAuthorized: "mbuser/isAuthorized",
        isLoading: "mbuser/isLoading",
        isRefreshing: "mbuser/isRefreshing",
        products: "mbuser/products",
        user: "mbuser/user",
        permissions: "mbuser/permissions"
      }),

      env() {
        return this.settings.env || "production"
      }
    },
    methods: {
      async can({ product, ownership, area, action, policy }) {
        if (this.isLoading || this.isRefreshing) {
          return false
        }
        if (!this.user) {
          return false
        }
        const ownerships = checkOwnershipArray(ownership, this)
        const token = await this.getAuthClient().getTokenSilently()
        const claims = jwt_decode(token)

        const results = ownerships.map((ownership) => {
          const payload = {
            product,
            ownership,
            area,
            action,
            user: this.user,
            token: claims
          }
          debugLog(this, `Policy evaluating (${policy}/${ownership}):`, payload)
          const output = this.opa.evaluate(payload, policy)
          debugLog(
            this,
            `Policy evaluation (${policy}/${ownership}):`,
            output[0]?.result
          )
          return output
        })

        for (var i = 0; i < results.length; i++) {
          const result = results[i]

          if (result == null || result.length == 0) {
            continue
          }
          if (result[0]?.result) {
            return true
          }
        }
        return false
      },
      async cannot({ product, ownership, area, action }) {
        const can = await this.can({ product, ownership, area, action })
        return !can
      },
      ...mapActions({
        refresh: "mbuser/refresh"
      }),
      getStore() {
        return this.store
      },
      getAuthClient() {
        return this.auth()
      },
      getAppSettings() {
        return this.settings
      },
      getCurrentProduct() {
        return this.products.find((p) => p.id === this.settings.appId)
      },
      async refreshAndCheckPermissions() {
        await this.refresh()
        const opaData = this.permissions
        debugLog(this, "Loading permissions: ", opaData)
        this.opa.setData(opaData)

        // Only check T&C's and permissions on env's other than local
        if (this.env === "local") {
          return
        }

        const currentProduct = this.getCurrentProduct()
        const isUnifiedPlatform = currentProduct.id === "portal"
        const portal = this.products.find((p) => p.id == "portal")
        const { url } = portal
        const tAndCsUrl = `${url}/terms`
        if (
          !this.user?.terms_accepted_at &&
          !isUnifiedPlatform &&
          window.location.href !== tAndCsUrl
        ) {
          window.location.href = tAndCsUrl
        }

        // if not on trial or licenced access redirect to UP
        if (
          !isUnifiedPlatform &&
          !["trial", "licenced"].includes(currentProduct.access)
        ) {
          window.location.href = url
        }
      }
    },
    async created() {
      this.opa = await loadPolicy(wasm)
      if (this.getAuthClient().isAuthenticated) {
        await this.refreshAndCheckPermissions()
      }
      this.getAuthClient().$watch("isLoading", async (isLoading) => {
        if (isLoading) return
        await this.refreshAndCheckPermissions()
      })
    }
  })

  return mbuInstance
}

export const MbUserPlugin = {
  install(Vue, options) {
    Vue.prototype.$mbuser = useMbuInstance(options)

    Vue.prototype.$permission = (product, ownership, area, action) => {
      return { product, ownership, area, action, policy: "rbac/allow" }
    }

    Vue.prototype.$access = (product, ownership, area, action) => {
      return { product, ownership, area, action, policy: "rbac/access" }
    }

    Vue.component("MbUserDropdown", MbUserDropdown)

    // Adds `v-can="<permission>"` and `v-can="<permission>"` directves
    Vue.directive("can", {
      async bind(el, binding) {
        const mbuser = getMbuInstance()
        const store = mbuser.getStore()
        if (await mbuser.can(binding.value)) {
          debugLog(mbuser, "Display", el)
          el.style.display = null
        } else {
          debugLog(mbuser, "Hide", el)
          el.style.display = "none"
        }
        // watch for if the user data changes, due to a reauth or refetch and reapply the directive
        const unwatch = store.watch(
          (state) => state.mbuser.permissions,
          async () => {
            if (await mbuser.can(binding.value)) {
              el.style.display = null
            } else {
              el.style.display = "none"
            }
          }
        )
        el.__perm_unwatch = unwatch
      },
      unbind(el) {
        el.__perm_unwatch && el.__perm_unwatch()
      }
    })
    Vue.directive("cannot", {
      async bind(el, binding) {
        const mbuser = getMbuInstance()
        const store = mbuser.getStore()
        if (await mbuser.cannot(binding.value)) {
          el.style.display = null
        } else {
          el.style.display = "none"
        }
        // watch for if the user data changes, due to a reauth or refetch and reapply the directive
        const unwatch = store.watch(
          (state) => state.mbuser.permissions,
          async () => {
            if (await mbuser.cannot(binding.value)) {
              el.style.display = null
            } else {
              el.style.display = "none"
            }
          }
        )
        el.__perm_unwatch = unwatch
      },
      unbind(el) {
        el.__perm_unwatch && el.__perm_unwatch()
      }
    })
  }
}
