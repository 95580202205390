<template>
  <!-- emit event from focusout, as input child event continuously fires-->
  <input
    class="form-control"
    :class="{ 'is-invalid': highlightAsRequired }"
    type="date"
    :required="required"
    :value="value"
    :data-cy="field.form_id"
    :min="min"
    :max="max"
    :disabled="readonly"
    :readonly="readonly"
    @input="$emit('input', $event.target.value)"
    @focusout="(e) => emitDelayed('userupdated', e)"
  />
</template>

<script>
import FormInputHelpers from "@/plugins/components-plugin/global/modules/mixins/formInputHelpers.js"
import { format } from "date-fns"
import moment from "moment"
export default {
  name: "MbDateInput",
  mixins: [FormInputHelpers],
  props: {
    field: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    // Override input attributes
    attrs: {
      type: Object,
      default: () => {
        return {}
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    highlightAsRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attributes: {
        min: "",
        max: ""
      }
    }
  },
  computed: {
    // TODO: construct min, max, step attributes from setting_values
    min() {
      if (this.attributes.min && this.attributes.min.length) {
        return this.convertToDate(this.attributes.min)
      }
      return "0001-01-01"
    },
    max() {
      if (this.attributes.max && this.attributes.max.length) {
        return this.convertToDate(this.attributes.max)
      }
      return "9999-12-31"
    },
    required() {
      return this.field.validation.required ?? false
    },
    formattedDate() {
      if (this.value) {
        return format(new Date(this.value), "dd/MM/yyyy")
      }
      return ""
    }
  },
  beforeMount() {
    // If the dictionary item has an input config apply
    // to the input attributes
    if (this.field.input_config || false) {
      this.attributes = Object.assign(this.attributes, this.field.input_config)
    }
    // Apply any local overrides
    this.attributes = Object.assign(this.attributes, this.attrs)
  },
  methods: {
    convertToDate(offset) {
      let date = null
      // is it the correct format for min/max already?
      const isCorrectDateFormat = moment(offset, "YYYY-MM-DD", true).isValid()
      if (!isCorrectDateFormat) {
        if (offset.startsWith("-")) {
          date = moment().subtract(parseInt(offset.split("-")[1]), "years")
        } else {
          date = moment().add(parseInt(offset), "years")
        }
        return date.format("YYYY-MM-DD")
      }
      return offset
    }
  }
}
</script>
