export default {
  methods: {
    convertToWantedType(datatype, val) {
      switch (datatype) {
        case "boolean":
          if (val === "true") {
            return true
          } else if (val === "false") {
            return false
          } else {
            return null
          }
        case "integer":
          if (val === null || val === "") return null
          return parseInt(val)
        case "float":
          if (val === null || val === "") return null
          return parseFloat(val)
        default:
          return val
      }
    },
    convertFromWantedType(datatype, val) {
      switch (datatype) {
        case "boolean":
          if (typeof val == "boolean") {
            return val ? "true" : "false"
          }
          return null
        case "integer":
        case "float":
          return val?.toString()
        default:
          return val
      }
    }
  }
}
