import { render, staticRenderFns } from "./MbFormInputGroup.vue?vue&type=template&id=7c5b0d5a&"
import script from "./MbFormInputGroup.vue?vue&type=script&lang=js&"
export * from "./MbFormInputGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports