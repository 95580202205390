import Vue from "vue"
import * as DictionaryStore from "@/plugins/components-plugin/store/modules/dictionary"
import "@/validation"
import MbFormInput from "@/plugins/components-plugin/lib-components/mbFormInput"
import MbToolTip from "@/plugins/components-plugin/lib-components/MbToolTip"
import { ApolloPlugin } from "@/plugins/components-plugin/plugins/apollo-plugin"

let ddInstance

export const getDictionaryInstance = () => ddInstance

export const useDataDictionary = ({ store, auth0, settings }) => {
  if (ddInstance) return ddInstance

  // Register dd vuex store
  store.registerModule("datadictionary", DictionaryStore)

  ddInstance = new Vue({
    data() {
      return {
        store: store,
        auth0: auth0,
        settings: settings
      }
    },
    methods: {
      getStore() {
        return this.store
      },
      getAuthClient() {
        return this.auth0()
      },
      getAppSettings() {
        return this.settings()
      }
    }
  })
  return ddInstance
}

export const DataDictionaryPlugin = {
  install(Vue, options) {
    Vue.prototype.$dd = useDataDictionary(options)

    Vue.use(ApolloPlugin, {
      id: options.settings().id,
      url: options.settings().url
    })

    Vue.component("MbFormInput", MbFormInput)
    Vue.component("MbToolTip", MbToolTip)
  }
}
