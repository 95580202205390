import {
  chainGuards,
  storeGuards
} from "@/global/modules/middleware/checkStore"

export const factFindRoutes = [
  {
    path: "fact-find",
    beforeEnter: chainGuards([
      storeGuards.loadCaseTasksGuard,
      storeGuards.loadCaseDocumentsGuard
    ]),
    component: () =>
      import(
        /* webpackChunkName: "kyc" */ "@/components/ux/clients/cases/kycLanding.vue"
      ),
    meta: {
      title: "Fact Find"
    },
    children: [
      {
        path: "",
        name: "kyc-summary",
        component: () =>
          import(
            /* webpackChunkName: "kyc-summary" */ "@/components/ux/clients/cases/KnowYourCustomer.vue"
          ),
        meta: {
          //requiresAuthCase: true,
          caseNavActive: true,
          title: "Fact Find",
          blockIfProduction: true
        }
      },
      {
        path: "",
        name: "kyc-fact-find",
        component: () =>
          import(
            /* webpackChunkName: "kyc-fact-find" */ "@/components/ux/clients/cases/FullFactFind.vue"
          ),
        meta: {
          //requiresAuthCase: true,
          caseNavActive: true
        },
        children: [
          {
            path: "fact-find-overview",
            beforeEnter: storeGuards.loadNotesGuard,
            name: "kyc-fact-find-overview",
            component: () =>
              import(
                /* webpackChunkName: "kyc-fact-find-overview" */ "@/components/ux/clients/cases/KycFactFindOverview.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              blockIfProduction: true
            }
          },
          {
            path: "fact-find-tasks",
            name: "kyc-fact-find-tasks",

            beforeEnter: chainGuards([
              storeGuards.loadNotesGuard,
              storeGuards.loadRequirementsGuard
            ]),

            component: () =>
              import(
                /* webpackChunkName: "kyc-fact-find-tasks" */ "@/components/ux/clients/cases/KycFactFindTasks.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Tasks"
            }
          },
          {
            path: "fact-find-docs",
            beforeEnter: storeGuards.loadNotesGuard,

            name: "kyc-fact-find-docs",

            component: () =>
              import(
                /* webpackChunkName: "kyc-fact-find-docs" */ "@/components/ux/clients/cases/KycFactFindDocs.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Docs"
            }
          },
          {
            path: "personal-details",
            name: "kyc-personal-details",
            beforeEnter: chainGuards([
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "kyc-personal-details" */ "@/components/ux/clients/cases/KycPersonalDetails.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Personal Details"
            }
          },
          {
            path: "company-details",
            name: "kyc-company-details",
            beforeEnter: chainGuards([
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "kyc-company-details" */ "@/components/ux/clients/cases/KycCompanyDetails.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Company Details"
            }
          },
          {
            path: "addresses",
            //name: "kyc-addresses",
            beforeEnter: chainGuards([
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "kyc-addresses" */ "@/components/ux/clients/cases/KycAddresses.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Addresses"
            },
            children: [
              {
                path: "",
                name: "kyc-addresses",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-addresses-summary" */ "@/components/ux/clients/cases/KycAddressesSummary.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true
                }
              },
              {
                path: "add",
                name: "kyc-addresses-add",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-addresses-add" */ "@/components/ux/clients/cases/KycAddressesAdd.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Add",
                  excludeFromSnapshot: true
                }
              },
              {
                path: "edit",
                name: "kyc-addresses-edit",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-addresses-edit" */ "@/components/ux/clients/cases/KycAddressesEdit.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Details"
                }
              }
            ]
          },
          {
            path: "dependants",
            name: "kyc-dependants",
            beforeEnter: chainGuards([
              storeGuards.loadDependantsGuard,
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "kyc-dependants" */ "@/components/ux/clients/cases/KycDependants.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Dependants"
            },
            children: [
              {
                path: "",
                name: "kyc-dependants-list",
                component: () =>
                  import(
                    /* webpackChunkName: "dependants-list" */ "@/components/ux/shared/dependant/DependantList.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true
                }
              },
              {
                path: "add",
                name: "kyc-dependants-add",
                beforeEnter:
                  storeGuards.setExcludedClientsForCaseDependantsGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-dependants-add" */ "@/components/ux/shared/dependant/DependantDetailAddEditView.vue"
                  ),
                props: { routerEditMode: "Add" },
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: false,
                  title: "Add",
                  excludeFromSnapshot: true
                }
              },
              {
                path: ":dependantId([0-9a-fA-F]{24})",
                name: "kyc-dependants-detail",
                beforeEnter: chainGuards([
                  storeGuards.selectedDependantGuard,
                  storeGuards.setExcludedClientsForCaseDependantsGuard
                ]),
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-dependants-detail" */ "@/components/ux/shared/dependant/DependantDetailAddEditView.vue"
                  ),
                props: { routerEditMode: "View" },
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: false,
                  title: "Details"
                }
              }
            ]
          },
          {
            path: "credit-history",
            name: "kyc-credit-history",
            beforeEnter: chainGuards([
              storeGuards.loadCreditHistoryGuard,
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "kyc-credit-history" */ "@/components/ux/clients/cases/KycCreditHistory.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Credit History"
            },
            children: [
              {
                path: "",
                name: "kyc-credit-history-list",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-credit-history-list" */ "@/components/ux/shared/credit/CreditHistoryList.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true
                }
              },
              {
                path: "add",
                name: "kyc-credit-history-add",
                beforeEnter:
                  storeGuards.setExcludedClientsForCaseCreditHistoryGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-credit-history-add" */ "@/components/ux/shared/credit/CreditHistoryAdd.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "Add",
                  excludeFromSnapshot: true
                }
              },
              {
                path: "add/default",
                name: "kyc-credit-history-add-default",
                beforeEnter:
                  storeGuards.selectedClientAndTypeFactFindCreditHistoryGuard,

                component: () =>
                  import(
                    /* webpackChunkName: "kyc-credit-history-add-default" */ "@/components/ux/shared/credit/CreditHistoryDefault.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "Default"
                }
              },
              {
                path: "add/bankruptcy",
                name: "kyc-credit-history-add-bankruptcy",
                beforeEnter:
                  storeGuards.selectedClientAndTypeFactFindCreditHistoryGuard,

                component: () =>
                  import(
                    /* webpackChunkName: "kyc-credit-history-add-bankruptcy" */ "@/components/ux/shared/credit/CreditHistoryBankruptcy.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "Bankruptcy"
                }
              },
              {
                path: "add/dro",
                name: "kyc-credit-history-add-dro",
                beforeEnter:
                  storeGuards.selectedClientAndTypeFactFindCreditHistoryGuard,

                component: () =>
                  import(
                    /* webpackChunkName: "kyc-credit-history-add-dro" */ "@/components/ux/shared/credit/CreditHistoryDRO.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "DRO"
                }
              },
              {
                path: "add/iva",
                name: "kyc-credit-history-add-iva",
                beforeEnter:
                  storeGuards.selectedClientAndTypeFactFindCreditHistoryGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-credit-history-add-iva" */ "@/components/ux/shared/credit/CreditHistoryIVA.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "IVA"
                }
              },
              {
                path: "add/ccj",
                name: "kyc-credit-history-add-ccj",
                beforeEnter:
                  storeGuards.selectedClientAndTypeFactFindCreditHistoryGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-credit-history-add-ccj" */ "@/components/ux/shared/credit/CreditHistoryCCJ.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "CCJ"
                }
              },
              {
                path: "add/arrears",
                name: "kyc-credit-history-add-arrears",
                beforeEnter:
                  storeGuards.selectedClientAndTypeFactFindCreditHistoryGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-credit-history-add-arrears" */ "@/components/ux/shared/credit/CreditHistoryArrears.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "Arrears"
                }
              }
            ]
          },
          {
            path: "employment-and-income",
            name: "kyc-employment-income",
            beforeEnter: chainGuards([
              storeGuards.loadEmploymentIncomeGuard,
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "kyc-employment-income" */ "@/components/ux/clients/cases/KycEmploymentIncome.vue"
              ),
            meta: {
              title: "Employment & Income"
            },
            children: [
              {
                path: "",
                name: "kyc-employment-income-summary",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-employment-income-summary" */ "@/components/ux/clients/cases/kyc/employment/FFEmploymentIncomeSummary.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true
                }
              },
              {
                path: "add",
                name: "kyc-employment-income-add",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-employment-income-add" */ "@/components/ux/clients/files/employment/AddNewRecord.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "Add",
                  excludeFromSnapshot: true
                }
              },
              {
                path: "details",
                name: "kyc-employment-income-details-view",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-employment-income-details-view" */ "@/components/ux/clients/files/employment/AddNewRecord.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "Details"
                }
              },
              {
                path: "details/:incomeId([0-9a-fA-F]{24})/:incomeType",
                name: "kyc-employment-income-details",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-employment-income-details" */ "@/components/ux/clients/files/employment/Details.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  title: "Details"
                }
              }
            ]
          },
          {
            path: "commitments",
            name: "kyc-commitments",
            beforeEnter: chainGuards([
              storeGuards.loadCommitmentsGuard,
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "kyc-commitments" */ "@/components/ux/clients/cases/KycCommitments.vue"
              ),
            meta: {
              title: "Commitments"
            },
            children: [
              {
                path: "",
                name: "kyc-commitments-list",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-list" */ "@/components/ux/shared/commitments/LoansCreditCommitmentsList.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true
                }
              },
              {
                path: "add",
                name: "kyc-commitments-add",
                beforeEnter:
                  storeGuards.setExcludedClientsForCaseCommitmentsGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-add" */ "@/components/ux/clients/cases/kyc/commitments/addNewRecord.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Add",
                  excludeFromSnapshot: true
                }
              },
              {
                path: "add",
                name: "kyc-commitments-add-overdraft",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-add-overdraft" */ "@/components/ux/shared/commitments/OverdraftAddEdit.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Overdraft"
                }
              },
              {
                path: "add",
                name: "kyc-commitments-add-hire-purchase",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-add-hire-purchase" */ "@/components/ux/shared/commitments/AddHirePurchase.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Hire Purchase"
                }
              },
              {
                path: "add",
                name: "kyc-commitments-add-unsecured-loan",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-add-unsecured-loan" */ "@/components/ux/shared/commitments/UnsecuredLoanAddEdit.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Unsecured Loan"
                }
              },
              {
                path: "add",
                name: "kyc-commitments-add-pcp",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-add-pcp" */ "@/components/ux/shared/commitments/PcpAddEdit.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "PCP"
                }
              },
              {
                path: "add",
                name: "kyc-commitments-add-lease-agreements",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-add-lease-agreements" */ "@/components/ux/shared/commitments/LeaseAgreementAddEdit.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Lease Agreements"
                }
              },
              {
                path: "add",
                name: "kyc-commitments-add-credit-store-card",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-add-credit-store-card" */ "@/components/ux/shared/commitments/CreditStoreCardsAddEdit.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Credit Store Card"
                }
              },
              {
                path: "add",
                name: "kyc-commitments-add-other-commitments",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-commitments-add-other-commitments" */ "@/components/ux/shared/commitments/OtherCommitmentAddEdit.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Other Commitments"
                }
              }
            ]
          },
          {
            path: "properties",
            name: "kyc-property",
            beforeEnter: chainGuards([
              storeGuards.loadPropertiesGuard,
              storeGuards.loadMortgagesGuard,
              storeGuards.loadPoliciesGuard,
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "kyc-properties" */ "@/components/ux/clients/cases/KycProperties.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Properties"
            },
            children: [
              {
                path: "",
                name: "kyc-property-summary",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-property-summary" */ "@/components/ux/shared/properties/PropertiesList.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true
                }
              },
              {
                path: "property-address",
                name: "kyc-property-add",
                beforeEnter: chainGuards([
                  storeGuards.createPropertyForAddGuard,
                  storeGuards.setExcludedClientsForCasePropertiesGuard
                ]),
                props: {
                  ownershipAddRoute: true
                },
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-property-add-edit" */ "@/components/ux/shared/properties/PropertyAddEdit.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Property Address",
                  excludeFromSnapshot: true
                }
              },
              {
                path: "details/:selectedPropertyId([0-9a-fA-F]{24})?",
                beforeEnter: chainGuards([
                  storeGuards.selectedClientPropertyInfoGuard,
                  storeGuards.setExcludedClientsForCasePropertiesGuard
                ]),
                name: "kyc-property-details",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-property-add-edit" */ "@/components/ux/shared/properties/PropertyAddEdit.vue"
                  ),
                props: {
                  openMortgageRecordRoute: {
                    name: "kyc-mortgage-edit-residential-standard"
                  },
                  openPolicyRecordRoute: {
                    name: "kyc-policy-edit-buildingcontents"
                  },
                  onBackRoute: { name: "kyc-property-summary" }
                },
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Details"
                },
                children: [
                  {
                    path: "edit",
                    beforeEnter: storeGuards.propertyNotReadonlyGuard,
                    name: "kyc-property-details-edit",
                    component: () =>
                      import(
                        /* webpackChunkName: "kyc-property-add-edit" */ "@/components/ux/shared/properties/PropertyAddEdit.vue"
                      ),
                    props: {
                      openMortgageRecordRoute: {
                        name: "kyc-mortgage-edit-residential-standard"
                      },
                      openPolicyRecordRoute: {
                        name: "kyc-policy-edit-buildingcontents"
                      }
                    },
                    meta: {
                      //requiresAuthCase: true,
                      caseNavActive: true,
                      showPageNav: true,
                      title: "Details"
                    }
                  }
                ]
              }
            ]
          },
          {
            path: "mortgages",
            name: "kyc-mortgages",
            beforeEnter: chainGuards([
              storeGuards.loadPropertiesGuard,
              storeGuards.loadMortgagesGuard,
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            component: () =>
              import(
                /* webpackChunkName: "kyc-mortgages" */ "@/components/ux/clients/cases/KycMortgages.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Mortgages"
            },
            children: [
              {
                path: "",
                name: "kyc-mortgages-list",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-mortgages-list" */ "@/components/ux/shared/mortgage/MortgageList.vue"
                  ),
                meta: {
                  caseNavActive: true,
                  showPageNav: true
                }
              },
              {
                path: "add",
                beforeEnter:
                  storeGuards.setExcludedClientsForCaseMortgagesGuard,
                component: {
                  render: (c) => c("router-view")
                },
                children: [
                  {
                    path: "",
                    name: "kyc-mortgages-add",
                    component: () =>
                      import(
                        /* webpackChunkName: "kyc-mortgages-add" */ "@/components/ux/shared/mortgage/MortgageAdd.vue"
                      ),
                    meta: {
                      caseNavActive: true,
                      showPageNav: false,
                      excludeFromSnapshot: true
                    }
                  },
                  {
                    path: "residential-standard",
                    name: "kyc-mortgage-add-residential-standard",
                    props: {
                      onSaveRoute: { name: "kyc-mortgages-list" },
                      onCancelRoute: { name: "kyc-mortgages-list" },
                      kycRoute: true
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "kyc-mortgage-add-residential-standard" */ "@/components/ux/shared/mortgage/AddEditMortgageDetails.vue"
                      ),
                    meta: {
                      caseNavActive: true,
                      showPageNav: false,
                      excludeFromSnapshot: true
                    }
                  }
                ]
              },
              {
                path: "edit/residential-standard/:selectedMortgageId([0-9a-fA-F]{24})?",
                beforeEnter: storeGuards.editMortgageInfoGuard,
                name: "kyc-mortgage-edit-residential-standard",
                props: {
                  onSaveRoute: { name: "kyc-mortgages-list" },
                  onCancelRoute: { name: "kyc-mortgages-list" },
                  kycRoute: true
                },
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-mortgage-edit-residential-standard" */ "@/components/ux/shared/mortgage/AddEditMortgageDetails.vue"
                  ),
                meta: {
                  caseNavActive: true,
                  showPageNav: false
                }
              }
            ]
          },
          {
            path: "policies",

            beforeEnter: chainGuards([
              storeGuards.loadPoliciesGuard,
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            name: "kyc-policies",
            component: () =>
              import(
                /* webpackChunkName: "kyc-policies" */ "@/components/ux/clients/cases/KycPolicies.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Policies"
            },
            children: [
              {
                path: "",
                name: "kyc-policies-list",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-policies-list" */ "@/components/ux/shared/policy/PoliciesList.vue"
                  ),
                meta: {
                  caseNavActive: true,
                  displayName: "Fact Find > Policies"
                }
              },
              {
                path: "add",
                name: "kyc-policy-add",
                beforeEnter: storeGuards.setExcludedClientsForCasePoliciesGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-policy-add" */ "@/components/ux/shared/policy/PolicyAdd.vue"
                  ),
                meta: {
                  //requiresAuthClient: true,
                  title: "Add",
                  excludeFromSnapshot: true
                }
              },
              {
                path: "add/protection",
                name: "kyc-policy-add-protection",
                beforeEnter: storeGuards.addModePoliciesGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-policy-add-protection" */ "@/components/ux/shared/policy/AddEditProtection.vue"
                  ),
                meta: {
                  //requiresAuthClient: true,
                  title: "Protection"
                }
              },
              {
                path: "add/buildingcontents",
                beforeEnter: chainGuards([
                  storeGuards.addModePoliciesGuard,
                  storeGuards.loadPropertiesGuard
                ]),
                name: "kyc-policy-add-buildingcontents",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-policy-add-buildingcontents" */ "@/components/ux/shared/policy/AddEditBuildingContents.vue"
                  ),
                meta: {
                  //requiresAuthClient: true,
                  title: "Buildings & Contents"
                }
              },
              {
                path: "add/asu",
                name: "kyc-policy-add-asu",
                beforeEnter: storeGuards.addModePoliciesGuard,
                props: {
                  isASU: true,
                  onSaveRoute: { name: "kyc-policies-list" },
                  onCancelRoute: { name: "kyc-policies-list" }
                },
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-policy-add-asu" */ "@/components/ux/shared/policy/AddEditPolicy.vue"
                  ),
                meta: {
                  title: "ASU"
                }
              },
              {
                path: "details/:policyId([0-9a-fA-F]{24})?/protection",
                name: "kyc-policy-edit-protection",
                beforeEnter: storeGuards.editModePoliciesGuard,
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-policy-edit-protection" */ "@/components/ux/shared/policy/AddEditProtection.vue"
                  ),
                meta: {
                  //requiresAuthClient: true,
                  title: "Protection"
                }
              },
              {
                path: "details/:policyId([0-9a-fA-F]{24})?/buildingcontents",
                beforeEnter: chainGuards([
                  storeGuards.editModePoliciesGuard,
                  storeGuards.loadPropertiesGuard
                ]),
                name: "kyc-policy-edit-buildingcontents",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-policy-edit-buildingcontents" */ "@/components/ux/shared/policy/AddEditBuildingContents.vue"
                  ),
                meta: {
                  //requiresAuthClient: true,
                  title: "Buildings & Contents"
                }
              },
              {
                path: "details/:policyId([0-9a-fA-F]{24})?/asu",
                name: "kyc-policy-edit-asu",
                beforeEnter: storeGuards.editModePoliciesGuard,
                props: {
                  isASU: true,
                  onSaveRoute: { name: "kyc-policies-list" },
                  onCancelRoute: { name: "kyc-policies-list" }
                },
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-policy-edit-asu" */ "@/components/ux/shared/policy/AddEditPolicy.vue"
                  ),
                meta: {
                  title: "ASU"
                }
              }
            ]
          },
          {
            path: "savings-investments",
            name: "kyc-savings",
            beforeEnter: chainGuards([
              storeGuards.loadSavingsGuard,
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),

            component: () =>
              import(
                /* webpackChunkName: "kyc-savings" */ "@/components/ux/clients/cases/KycSavingsInvestments.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Savings & Investments"
            },
            children: [
              {
                path: "",
                name: "kyc-savings-list",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-savings-list" */ "@/components/ux/shared/savings/SavingsInvestmentsList.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true
                }
              },

              //{
              //  path: "add/unit-trust",

              {
                path: "add/savings-account",
                name: "kyc-savings-add-savings-account",
                beforeEnter: chainGuards([
                  storeGuards.selectedSavingsClientsGuard,
                  storeGuards.setExcludedClientsForCaseSavingsGuard
                ]),
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-savings-add-savings-account" */ "@/components/ux/shared/savings/AddSavingsAcc.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true,
                  showPageNav: true,
                  title: "Savings & Investments"
                }
              }
            ]
          },
          {
            path: "budget-planner",
            beforeEnter: chainGuards([
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            name: "kyc-budget-planner",
            component: () =>
              import(
                /* webpackChunkName: "kyc-budget-planner" */ "@/components/ux/clients/cases/KycBudgetPlanner.vue"
              ),
            meta: {
              caseNavActive: true,
              title: "Budget Planner"
            },
            children: [
              {
                path: "",
                beforeEnter: storeGuards.loadBudgetPlannerGuard,
                name: "kyc-budget-planner-list",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-budget-planner-list" */ "@/components/ux/shared/budget/BudgetPlannerList.vue"
                  ),
                meta: {
                  caseNavActive: true,
                  showPageNav: true
                }
              },
              {
                path: "add",
                name: "kyc-budget-planner-add",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-budget-planner-add" */ "@/components/ux/shared/budget/BudgetPlannerAdd.vue"
                  ),
                meta: {
                  caseNavActive: true,
                  showPageNav: false,
                  title: "Add",
                  excludeFromSnapshot: true
                }
              },
              {
                path: "add/budget",
                name: "kyc-budget-planner-add-budget",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-budget-planner-add-budget" */ "@/components/ux/shared/budget/BudgetPlannerDetails.vue"
                  ),
                meta: {
                  caseNavActive: true,
                  showPageNav: false,
                  title: "Details",
                  excludeFromSnapshot: true
                }
              },
              {
                path: "edit/budget/:selectedPlannerId([0-9a-fA-F]{24})?",
                beforeEnter: storeGuards.editBudgetPlannerGuard,
                name: "kyc-budget-planner-edit-budget",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-budget-planner-edit-budget" */ "@/components/ux/shared/budget/BudgetPlannerDetails.vue"
                  ),
                meta: {
                  caseNavActive: true,
                  showPageNav: false,
                  title: "Details"
                }
              }
            ]
          },
          {
            path: "vulnerable-customer",
            beforeEnter: chainGuards([
              storeGuards.loadNotesGuard,
              storeGuards.loadCaseTasksGuard
            ]),
            name: "kyc-vulnerable-customer",
            component: () =>
              import(
                /* webpackChunkName: "kyc-vulnerable-customer" */ "@/components/ux/clients/cases/KycVulnerableCustomer.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Vulnerable Customer"
            }
          },
          {
            path: "pvc",
            name: "kyc-pvc",
            component: () =>
              import(
                /* webpackChunkName: "kyc-pvc" */ "@/components/ux/clients/cases/KycPvc.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true
            }
          },
          {
            path: "fact-find-notes",
            beforeEnter: storeGuards.loadNotesGuard,
            name: "kyc-fact-find-notes",
            component: () =>
              import(
                /* webpackChunkName: "kyc-fact-find-notes" */ "@/components/ux/clients/cases/KycFactFindNotes.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true,
              title: "Notes"
            }
          },
          {
            path: "mortgage-req",
            component: () =>
              import(
                /* webpackChunkName: "kyc-mortgage-req" */ "@/components/ux/clients/cases/KycMortgageReq.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true
            },
            children: [
              {
                path: "",
                name: "kyc-mortgage-req-summary",
                component: () =>
                  import(
                    /* webpackChunkName: "kyc-mortgage-req-summary" */ "@/components/ux/clients/cases/kyc/mortgagereq/KycMortgageReqSummary.vue"
                  ),
                meta: {
                  //requiresAuthCase: true,
                  caseNavActive: true
                }
              }
            ]
          },
          {
            path: "protection-req",
            name: "kyc-protection-req",
            component: () =>
              import(
                /* webpackChunkName: "kyc-protection-req" */ "@/components/ux/clients/cases/KycProtectionReq.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true
            }
          },
          {
            path: "gi-req",
            name: "kyc-gi-req",
            component: () =>
              import(
                /* webpackChunkName: "kyc-gi-req" */ "@/components/ux/clients/cases/KycGiReq.vue"
              ),
            meta: {
              //requiresAuthCase: true,
              caseNavActive: true
            }
          }
        ]
      }
    ]
  }
]
