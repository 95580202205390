import moment from "moment"
require("twix")

const calcAge = function (dateString) {
  if (typeof dateString === "undefined" || dateString === "") return ""
  const today = new Date()
  const birthDate = new Date(dateString)

  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

const getCellVariant = function (rowData) {
  const cellVariants = {}
  let variant = ""

  // set status variant
  if ("status" in rowData) {
    switch (rowData.status) {
      case "Incomplete":
        variant = "danger"
        break
      case "Not Yet Due":
        variant = ""
        break
      default:
        variant = "success"
        break
    }
    cellVariants.status = variant
  }

  // set due variant
  if ("due" in rowData) {
    switch (rowData.due) {
      case "Today":
        variant = "danger"
        break
      default:
        variant = ""
        break
    }
    cellVariants.due = variant
  }

  return cellVariants
}

const timeBetweenDates = function (dateFrom, dateTo) {
  if (dateFrom) {
    if (dateTo) {
      var from = moment(dateFrom)
      var to = moment(dateTo)
      var years = to.diff(from, "year")
      from.add(years, "years")
      var months = to.diff(from, "months")
      from.add(months, "months")
      var days = to.diff(from, "days")
      var yearsString = years === 1 ? "Year" : "Years"
      var monthsString = months === 1 ? "Month" : "Months"
      var daysString = days === 1 ? "Day" : "Days"
      return `${years} ${yearsString} ${months} ${monthsString} ${days} ${daysString}`
    }
  }
  return "-"
}

const timeRemaining = function (dateFrom, dateTo) {
  if (dateFrom) {
    if (dateTo) {
      var from = moment(dateFrom)
      var to = moment(dateTo)
      var years = to.diff(from, "year")
      from.add(years, "years")
      var months = to.diff(from, "months")
      from.add(months, "months")
      var yearsString = years === 1 ? "Year" : "Years"
      var monthsString = months === 1 ? "Month" : "Months"
      return `${years} ${yearsString} ${months} ${monthsString}`
    }
  }
  return "-"
}

const addMonthsToDate = function (dateFrom, monthsToAdd) {
  if (dateFrom && monthsToAdd) {
    var from = moment(dateFrom)
    var future_date = moment(from).add(monthsToAdd, "months")
    return moment(future_date).format("YYYY-MM-DD")
  }
  return null
}

const formatNumberAsCurrency = function (
  value,
  alwaysShowPence = false,
  emptyValueString = ""
) {
  if (value === null || value === undefined) return emptyValueString
  let wholeNumberInput = value % 1 == 0
  // Set the minimum decimal places to 2 if there is a pence value to avoid showing eg. £100.1 for 100.10
  let minDecimals = alwaysShowPence || !wholeNumberInput ? 2 : 0
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: minDecimals
  }).format(value)
}

const debounce = function (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

const currencyTwoDecimalPlace = function (value) {
  if (!isNaN(value) && value !== null) {
    if (
      value.toString().indexOf(".") !== -1 &&
      value.toString().split(".")[1].length === 1
    ) {
      return `£${value.toString() + 0}`
    }
    return `£${Number(value)}`
  } else {
    return "N/A"
  }
}

const datesOverlap = function (startDateA, endDateA, startDateB, endDateB) {
  var d1 = moment(startDateA).twix(endDateA)
  var d2 = moment(startDateB).twix(endDateB)
  return d1.overlaps(d2)
}

const dateDiffDays = function (dateA, dateB) {
  var a = moment(dateA)
  var b = moment(dateB)
  return a.diff(b, "days")
}

const dateDiff = function (dateA, dateB) {
  var a = moment(dateA)
  var b = moment(dateB)
  return a.diff(b)
}

const removeDuplicateObjectUsingId = function (obj) {
  if (obj !== null) {
    let filteredObj = []
    filteredObj = obj.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
    return filteredObj
  }
}

export const MbFunctionService = {
  calcAge,
  getCellVariant,
  timeBetweenDates,
  addMonthsToDate,
  timeRemaining,
  formatNumberAsCurrency,
  debounce,
  currencyTwoDecimalPlace,
  datesOverlap,
  dateDiffDays,
  dateDiff,
  removeDuplicateObjectUsingId
}
