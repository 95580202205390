import axios from "axios"
import { getCategorySectionKey } from "@/global/modules/helpers/categoryHelpers.js"
export const state = {
  allTasks: [],
  caseTasks: [],
  factFindTasks: [],
  requirementTasks: [],
  applicationTasks: [],
  currentTask: {},
  taskActive: false,
  showReadonly: false,
  isNew: false
}

export const mutations = {
  clearCaseTasks(state) {
    state.allTasks = []
    state.caseTasks = []
    state.factFindTasks = []
    state.requirementTasks = []
    state.applicationTasks = []
  },
  addCaseTask(state, data) {
    state.caseTasks.push(data)
  },
  addFactFindTask(state, data) {
    state.factFindTasks.push(data)
  },
  addRequirementTask(state, data) {
    state.requirementTasks.push(data)
  },
  addApplicationTask(state, data) {
    state.applicationTasks.push(data)
  },
  setAllTasks(state, tasks) {
    state.allTasks = tasks
  },
  setTaskActive(state, status) {
    state.taskActive = status.show
    state.showReadonly = status.readonly
  },
  setTaskForAdd(state, data) {
    state.isNew = true
    state.currentTask = data
  },
  setTaskForEdit(state, task) {
    state.isNew = false
    state.currentTask = task
  }
}

export const getters = {
  allTasks: (state) => state.allTasks,
  caseTasks: (state) => state.caseTasks,
  factFindTasks: (state) => state.factFindTasks,
  requirementTasks: (state) => state.requirementTasks,
  applicationTasks: (state) => state.applicationTasks,
  showTask: (state) => state.taskActive,
  showReadonly: (state) => state.showReadonly,
  isAddMode: (state) => state.isNew,
  currentTask: (state) => state.currentTask
}

export const actions = {
  async loadTasks(context) {
    var caseId = context.rootGetters["cases/currentCaseId"]
    const clients = context.rootGetters["clientFile/selectedClients"]
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    await axios
      .get(
        `${process.env.VUE_APP_CDS_URL}/casetasks/getforcaseid/${caseId}${snapshotIdQuery}`
      )
      .then((response) => {
        context.commit("clearCaseTasks")
        context.commit("setAllTasks", response.data)
        response.data.forEach((caseTaskData) => {
          mapCaseTaskForList(caseTaskData, clients, context)
        })
      })
      .catch(() => context.commit("clearCaseTasks"))
  },
  async saveTask({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/casetasks/createorupdate`,
        payload
      )

      if (response.status === 200) {
        await dispatch("loadTasks")
      }
    } catch (err) {
      console.log(err.response.data)
    }
  },
  async deleteTask({ dispatch, state }, taskId) {
    var taskToDelete = state.allTasks.find((task) => task.id === taskId)
    if (taskToDelete) {
      taskToDelete.is_deleted = true
      return await axios
        .post(`${process.env.VUE_APP_CDS_URL}/casetasks/createorupdate`, [
          taskToDelete
        ])
        .then(() => {
          dispatch("loadTasks")
        })
    }
  },

  /**
   *
   * @param {*} param0 state objs
   * @param {*} task task override values to add
   * @param {*} defaultSection automatically populates correct section with value depending on payload.category
   */
  createTaskForAdd(context, { task, defaultSection }) {
    let case_id = context.rootGetters["cases/currentCaseId"]
    let defaultTask = {
      due_date: null,
      team_member: null,
      for_client: null,
      linked_clients: [],
      category: null,
      requirement_id: null,
      application_id: null,
      title: null,
      description: null,
      case_id: case_id,
      status: "open",
      comments: [],
      case_section: null,
      fact_find_section: null,
      requirement_section: null,
      application_section: null
    }
    let key = getCategorySectionKey(task.category)
    if (key && defaultSection) {
      task[key] = defaultSection
    }

    Object.assign(defaultTask, task) //override defaults with provided values
    context.commit("setTaskForAdd", defaultTask)
  },
  fetchTaskForEdit({ state, commit }, taskId) {
    var taskForEdit = state.allTasks.find((task) => task.id === taskId)
    commit("setTaskForEdit", JSON.parse(JSON.stringify(taskForEdit)))
  }
}

function mapCaseTaskForList(caseTaskData, clients, context) {
  caseTaskData.who = clients
    .filter((client) => caseTaskData.linked_clients.includes(client.id))
    .reduce((names, client) => {
      names.push(client.fullname)
      return names
    }, [])
    .join(", ")

  let task = { ...caseTaskData }
  let requirement = context.rootGetters["requirements/selectedRequirement"]
  switch (caseTaskData.category) {
    case "case":
      context.commit("addCaseTask", task)
      break
    case "fact_find":
      context.commit("addFactFindTask", task)
      break
    case "requirement":
      if (
        requirement.id &&
        task.requirement_id &&
        requirement.id === task.requirement_id
      ) {
        context.commit("addRequirementTask", task)
      }

      break
    case "application":
      context.commit("addApplicationTask", task)
      break
  }
}
