import Vue from "vue"
import "bootstrap"
import vSelect from "vue-select"
import Multiselect from "vue-multiselect"
import "@/global/styles/scss/app.scss"
import "@/global/styles/css/main.css"
import {
  TablePlugin,
  BPagination,
  TabsPlugin,
  ButtonPlugin,
  ProgressPlugin,
  BootstrapVueIcons,
  ModalPlugin,
  SpinnerPlugin
} from "bootstrap-vue"

import Page from "./components/ux/layout/Page.vue"
import MbDatatable from "@/components/core/form/MbDatatable.vue"
import VueSanitize from "vue-sanitize"

Vue.component("Page", Page)
Vue.component("VSelect", vSelect)
Vue.component("Multiselect", Multiselect)
Vue.component("MbDatatable", MbDatatable)
Vue.component("BPagination", BPagination)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(ButtonPlugin)
Vue.use(ProgressPlugin)
Vue.use(VueSanitize)
Vue.use(BootstrapVueIcons)
Vue.use(ModalPlugin)
Vue.use(SpinnerPlugin)
