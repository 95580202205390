<template>
  <div class="form-check form-check-primary">
    <input
      :id="uniqueId"
      v-model="checked"
      class="form-check-input"
      type="checkbox"
      :required="required"
      :data-cy="field.form_id"
      :disabled="readonly"
      :readonly="readonly"
      @change="(e) => emitDelayed('userupdated', e)"
    />
    <label
      v-if="labelText"
      :for="uniqueId"
      :readonly="readonly"
      class="form-check-label"
      v-html="labelText"
    ></label>
    <mb-tool-tip
      v-if="tooltip"
      class="ms-1"
      :custom-class="'client-header-info'"
      :data="tooltip"
    />
  </div>
</template>

<script>
import FormInputHelpers from "@/plugins/components-plugin/global/modules/mixins/formInputHelpers.js"
import MbToolTip from "./MbToolTip"
export default {
  name: "MbCheckboxInput",
  mixins: [FormInputHelpers, MbToolTip],
  props: {
    field: {
      type: Object,
      default: null
    },
    value: {
      type: [String, Boolean],
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false,
      default: null
    },
    labelText: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      checked: false
    }
  },
  computed: {
    required() {
      return this.field.validation.required ?? false
    },
    isStringValue() {
      return this.value instanceof "bool"
    },
    uniqueId() {
      // add :key="`unique-name-here`" on the mb-form-input to make unique (it will be appended to the guid),
      // otherwise the guid is used
      return this.$attrs["data-cy"]
    }
  },
  watch: {
    checked(checked) {
      // When string data_type we emit the default value for the field
      if (this.field.data_type === "string") {
        this.$emit("input", checked ? this.field.default_value : "")
      } else {
        this.$emit("input", checked)
      }
    },
    value(value) {
      this.updateChecked(value)
    }
  },
  beforeMount() {
    this.updateChecked(this.value)
  },
  methods: {
    updateChecked(value) {
      if (this.field.data_type === "string") {
        this.checked = value === this.field.default_value
      } else {
        this.checked = value
      }
    }
  }
}
</script>
<style lang="scss" scoped>
input[type="checkbox"][readonly],
label[readonly] {
  pointer-events: none;
}
</style>
