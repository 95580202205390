<template>
  <div class="d-flex flex-column gap-3">
    <!-- Row 1 -->
    <div class="d-flex flex-wrap gap-3">
      <mb-form-input
        v-model="property.type"
        guid="q-bc049848-ca98-4550-85dc-89731cdfa733"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['type']"
        @input="clearUnusedTypeData"
      ></mb-form-input>
      <mb-form-input
        v-model="property.style"
        guid="q-8f9df15b-ab72-4879-ba07-31ea7e3c594f"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['style']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.year_built"
        guid="q-73ab9266-5e56-4935-90e6-ebfd240caf50"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['year_built']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.bedrooms"
        guid="q-f38d74a1-54ce-42c1-985a-400d7b4af40b"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['bedrooms']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.bathrooms"
        guid="q-868e0db0-b69a-48ac-8977-0d7be44e7767"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['bathrooms']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.receptions"
        guid="q-27d07511-64e1-46a0-8262-69dddb5e1d45"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['receptions']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.heating_info"
        guid="q-60a66de5-7b40-4247-9ea9-bed925a45c3e"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['heating_info']"
      ></mb-form-input>
    </div>
    <!-- Row 2 -->
    <div class="d-flex flex-wrap gap-3">
      <mb-form-input
        v-model="property.tenure"
        guid="q-e81dba81-a907-4ff2-8323-59803b0d94e2"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['tenure']"
      ></mb-form-input>
      <mb-form-input
        v-show="isLeasehold"
        v-model="property.remaining_lease"
        guid="q-4531f4b4-b168-44c7-9a25-2b036a4bb322"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
      ></mb-form-input>
    </div>
    <!-- Row 3 -->
    <div class="d-flex flex-wrap gap-3">
      <mb-form-input
        v-model="property.listed_status"
        guid="q-336436e8-8145-4032-8bdb-fdeca28787d6"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['listed_status']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.ex_la"
        guid="q-b5b6f150-ce19-4caf-8b2f-d745bcceec3f"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['ex_la']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.nb_warranty"
        guid="q-74b28216-6bee-413e-9551-400d92663b39"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
      ></mb-form-input>
    </div>
    <!-- Row 4 -->
    <div class="d-flex flex-wrap gap-3">
      <mb-form-input
        v-model="property.construction"
        guid="q-7b15695b-b093-4ba9-a4b0-37633770586f"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['construction']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.roof_type"
        guid="q-37270d77-5602-4aea-93ae-1814fb5e68f5"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['roof_type']"
      ></mb-form-input>
      <mb-form-input
        v-model="property.flat_roof_percent"
        guid="q-83b6c84c-63c0-44ac-90f8-eb971d102046"
        label-class="col-form-label fw-light"
        label
        :readonly="readonly"
        :input-class="trackedDataClasses['flat_roof_percent']"
      ></mb-form-input>
    </div>
    <!-- Row 5 -->
    <div v-show="isFlat">
      <!-- wierd bug stops the above v-show working on the line below -->
      <div class="d-flex flex-wrap gap-3">
        <mb-form-input
          v-model="property.flat_floor_no"
          guid="q-f8738afe-ea62-4740-b092-40c87295242b"
          label-class="col-form-label fw-light"
          label
          :readonly="readonly"
          :input-class="trackedDataClasses['flat_floor_no']"
        ></mb-form-input>
        <mb-form-input
          v-model="property.number_of_floors"
          guid="q-68b3c445-4108-4f9f-9858-180d0d9060f0"
          label-class="col-form-label fw-light"
          label
          :readonly="readonly"
          :input-class="trackedDataClasses['number_of_floors']"
        ></mb-form-input>
        <mb-form-input
          v-model="property.lift"
          guid="q-c066650f-0b40-4838-840c-bed23df9b08f"
          label-class="col-form-label fw-light"
          label
          :readonly="readonly"
        ></mb-form-input>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    property: {
      type: Object,
      required: true
    },
    trackedDataClasses: {
      type: Object,
      required: false,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      required: true
    }
  }
}
</script>
