import axios from "axios"
import { requirementStatus } from "@/global/modules/enum/constants"

export const state = {
  selectedRequirement: {},
  requirementReadonlyState: false,
  mortgage: [],
  insurance: [],
  ginsurance: [],
  allRequirements: [],
  sourcingURL: "",
  lenderSVR: "",
  forceRefreshMortgageScenario: false,
  mortgageScenarios: [],
  manualScenarios: [],
  sourcingScenarios: [],
  products: [],
  dips: [],
  protectionProductsEditMode: false,
  giProductsEditMode: false,
  selectedProduct: {},
  scenarioIsNew: true,
  productIsNew: true,
  bcProductIsNew: true,
  requirementIsNew: false,
  selectedDip: {},
  dipIsNew: true,
  errorForDisplay: "",
  allRequiredSections: [],
  showWorkflowValidation: false,
  requirementProductActionType: "",
  workflowConfig: {},
  allProviders: []
}

export const mutations = {
  clearRequirements(state) {
    state.mortgage = []
    state.insurance = []
    state.ginsurance = []
    state.allRequirements = []
  },
  clearSelectedRequirement(state) {
    state.selectedRequirement = {}
  },
  clearAllScenarios(state) {
    state.mortgageScenarios = []
    state.manualScenarios = []
    state.sourcingScenarios = []
  },
  addMortgage(state, data) {
    state.mortgage.push(data)
  },
  addInsurance(state, data) {
    state.insurance.push(data)
  },
  addGInsurance(state, data) {
    state.ginsurance.push(data)
  },
  allRequirements(state, data) {
    state.allRequirements.push(data)
  },
  setRequirement(state, data) {
    state.selectedRequirement = data
  },
  addErrorForDisplay(state, error) {
    state.errorForDisplay = error
  },
  clearErrorForDisplay(state) {
    state.errorForDisplay = ""
  },
  addSourcingURL(state, data) {
    state.sourcingURL = data
  },
  addLenderSVR(state, data) {
    state.lenderSVR = data
  },
  addAllScenarios(state, data) {
    state.manualScenarios = data.manualScenarios
    state.sourcingScenarios = data.sourcingScenarios
    state.mortgageScenarios = data.mortgageScenarios
  },
  productIsNewState(state, data) {
    state.productIsNew = data
  },
  bcProductIsNewState(state, data) {
    state.bcProductIsNew = data
  },
  productIsNew(state) {
    state.productIsNew = false
  },
  requirementIsNewState(state, data) {
    state.requirementIsNew = data
  },
  setProducts(state, data) {
    state.products = data
  },
  setSelectedProduct(state, data) {
    state.selectedProduct = data
  },
  setProtectionProductsEditMode(state, data) {
    state.protectionProductsEditMode = data
  },
  setGiProductsEditMode(state, data) {
    state.giProductsEditMode = data
  },
  forceRefreshMortgageScenario(state) {
    state.forceRefreshMortgageScenario = true
  },
  setSelectedDip(state, data) {
    state.selectedDip = {}
    state.dipIsNew = false
    state.selectedDip = data
  },
  dipIsNewState(state, data) {
    state.dipIsNew = data
  },
  setAllRequiredSections(state, data) {
    state.allRequiredSections = data
  },
  setShowWorkflowValidation(state, value) {
    state.showWorkflowValidation = value
  },
  setRequirementProductActionType(state, value) {
    state.requirementProductActionType = value
  },
  setWorkflowConfig(state, data) {
    state.workflowConfig = data
  },
  setProviders(state, data) {
    data.forEach((provider) => {
      const providerDetails = {
        id: provider.id,
        providerDbName: provider.providerDbName,
        providerDisplayName: provider.providerDisplayName
      }
      state.allProviders.push(providerDetails)
    })
  }
}

export const actions = {
  async loadRequirements(context) {
    const caseId = context.rootGetters["cases/currentCaseId"]
    const clients = context.rootGetters["clientFile/selectedClients"]
    const companies = context.rootGetters["clientFile/companies"]
    const snapshotIdQuery = context.rootGetters["cases/snapshotIdQuery"]

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/caserequirements/getforcaseid/${caseId}${snapshotIdQuery}`
      )
      context.commit("clearRequirements")
      if (response.status === 200) {
        response.data.forEach((requirementData) => {
          mapRequirementsForList(
            requirementData,
            clients,
            companies,
            caseId,
            context
          )
        })
      }
    } catch (err) {
      console.log(err)
    }
  },
  async loadRequirementsForApplication(context, payload) {
    const clients = context.rootGetters["clientFile/selectedClients"]
    const companies = context.rootGetters["clientFile/companies"]

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/caserequirements/getforcaseid/${payload.caseId}`
      )
      context.commit("clearRequirements")
      if (response.status === 200) {
        response.data.forEach((requirementData) => {
          mapRequirementsForList(
            requirementData,
            clients,
            companies,
            payload.caseId,
            context
          )
        })
      }
    } catch (err) {
      console.log(err)
    }
  },
  /**
   *
   * @returns {Object} requirement, or null on failure
   */
  async addUpdateRequirement({ dispatch, commit, state }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/caserequirements/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        if (response.data?.length > 0) {
          await dispatch("loadRequirements")
          await dispatch("fetchRequirement", response.data[0]) //caller should update, but this is not always done correctly (e.g. section controls complete/incomplete)
          return state.selectedRequirement
        }
      }
    } catch (err) {
      let errMsg = "Error during requirement save. Reason: " + err.message
      commit("addErrorForDisplay", errMsg)

      return null
    }
    return null
  },
  async deleteRequirement({ dispatch }, payload) {
    // set delete properties
    payload.is_deleted = true
    payload.deleted_at = new Date()

    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/caserequirements/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        dispatch("loadRequirements")
      }
    } catch (err) {
      console.log(err)
    }
  },
  fetchRequirement({ commit, state, dispatch }, payload) {
    let mainItem = {}
    mainItem = state.allRequirements.find((c) => c.id === payload.id)
    commit("setRequirement", mainItem)
    dispatch("milestones/loadMilestones", null, { root: true })
  },
  async fetchSourcingURL(context, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_SOURCING_API_URL}/v1/session/startsession`,
        payload
      )

      if (response.status === 200) {
        context.commit("addSourcingURL", response.data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async fetchLenderSVR(context, payload) {
    context.state.lenderSVR = ""
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_INTS_URL}/integration/lendersvr/${payload}`
      )

      if (response.status === 200) {
        if (response.data.residential_rate > 0) {
          context.commit("addLenderSVR", response.data.residential_rate)
        }
      }
    } catch (err) {
      console.log(err)
    }
  },
  async addUpdateMortgageScenario(context, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/caserequirementmortgagescenarios/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        const selectedReqId = context.getters["selectedRequirement"].id
        await context.dispatch("loadRequirements") //Requirements version may have changed
        await context.dispatch("fetchRequirement", { id: selectedReqId })

        if (state.forceRefreshMortgageScenario) {
          await context.dispatch("loadMortgageScenarios", selectedReqId)
        } else {
          addUpdatedAllScenarios(context, selectedReqId, response.data)
        }

        return true
      }
    } catch (err) {
      let errMsg = "Error during requirement save. Reason: " + err.message
      context.commit("addErrorForDisplay", errMsg)

      return false
    }
    return false
  },
  async loadMortgageScenarios({ commit, state, rootGetters }, requirementId) {
    state.forceRefreshMortgageScenario = false
    const requirement = state.allRequirements.find(
      (c) => c.id === requirementId
    )
    if (!requirement) return false

    const snapshotIdQuery = rootGetters["cases/snapshotIdQuery"]

    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/caserequirementmortgagescenarios/case/${requirement.case_id}/requirement/${requirement.id}${snapshotIdQuery}`
      )

      if (response.status === 200) {
        commit("clearAllScenarios")

        const allScenarios = {
          manualScenarios: [],
          sourcingScenarios: [],
          mortgageScenarios: []
        }

        // manually recorded scenarios with manually recorded products
        response.data.manualScenarios.forEach((scenario) => {
          let manualScenario = mapManualScenario(scenario)

          allScenarios.manualScenarios.push(manualScenario)

          //Manual scenario holding product for sourcing scenario when true
          if (manualScenario.sourcing_scenario_id) return

          let mortgageScenario =
            mapMortgageScenarioFromManualScenario(manualScenario)

          allScenarios.mortgageScenarios.push(mortgageScenario)
        })

        // sourcing integration: scenario and products from sourcing
        response.data.sourcingBrainScenarios.forEach((scenario) => {
          let sourcingScenario = mapSourcingScenario(scenario)

          allScenarios.sourcingScenarios.push(sourcingScenario)

          let mortgageScenario = mapMortgageScenarioFromSourcingScenario(
            sourcingScenario,
            [...requirement.linked_clients],
            state.selectedRequirement.mortgage_details
              .kyc_mortgage_requirement_transaction_type_key
          )

          //add products that were manually recorded to the sourcing scenario
          let manualScenario = allScenarios.manualScenarios.find(
            (srcItem) =>
              srcItem.sourcing_scenario_id ===
              mortgageScenario.sourcing_scenario_id
          )

          if (manualScenario) {
            addUpdateProductsToMortgageScenarioFromManualScenario(
              mortgageScenario,
              manualScenario
            )
          }

          allScenarios.mortgageScenarios.push(mortgageScenario)
        })

        commit("addAllScenarios", allScenarios)

        return true
      }
    } catch (err) {
      let errMsg = "Error during requirement save. Reason: " + err.message
      commit("addErrorForDisplay", errMsg)

      return Promise.reject(errMsg)
    }
  },
  async loadProducts(context) {
    const caseId = context.rootGetters["cases/currentCaseId"]
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/caserequirementproduct/case/${caseId}/requirement/${state.selectedRequirement.id}`
      )
      if (response.status === 200) {
        context.commit("setProducts", response.data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async addUpdateProduct({ dispatch }, payload) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_CDS_URL}/caserequirementproduct/createorupdate`,
        [payload]
      )

      if (response.status === 200) {
        await dispatch("loadProducts")
        return true
      }
    } catch (err) {
      console.log(err)
      return false
    }
    return false
  },
  async loadWorkflowConfigurations({ commit }) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_PCM_URL}/account-configs/type/crm-case-workflow`
      )

      if (response.status === 200) {
        commit("setWorkflowConfig", response.data.data.config)
      }
    } catch {
      //Handled by inteceptor
    }
  },
  async loadProviders({ commit }, payload) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CDS_URL}/utilities/datadictionary/providers?type=${payload}`
      )

      if (response.status === 200) {
        commit("setProviders", response.data.providerLookup)
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export const getters = {
  selectedRequirement(state) {
    return state.selectedRequirement
  },
  requirementReadonlyState(state) {
    return (
      state.selectedRequirement?.closure_workflow_status_requirement_key ===
      requirementStatus.Closed
    )
  },
  selectedClientNames(state, getters, rootState, rootGetters) {
    return rootGetters["clientFile/selectedClients"]
      .filter(
        (client) =>
          state.selectedRequirement.linked_clients &&
          state.selectedRequirement.linked_clients.includes(client.id)
      )
      .reduce((names, client) => {
        names.push(client.fullname)
        return names
      }, [])
      .join(", ")
  },
  mortgage(state) {
    return state.mortgage
  },
  insurance(state) {
    return state.insurance
  },
  ginsurance(state) {
    return state.ginsurance
  },
  allRequirements(state) {
    return state.allRequirements
  },
  errorForDisplay(state) {
    return state.errorForDisplay
  },
  sourcingURL: (state) => state.sourcingURL,
  lenderSVR: (state) => state.lenderSVR,
  scenarioIsNew: (state) => state.scenarioIsNew,
  mortgageScenarios: (state) => state.mortgageScenarios,
  manualScenarios: (state) => state.manualScenarios,
  sourcingScenarios: (state) => state.sourcingScenarios,
  productIsNew: (state) => state.productIsNew,
  bcProductIsNew: (state) => state.bcProductIsNew,
  requirementIsNew: (state) => state.requirementIsNew,
  dipIsNew: (state) => state.dipIsNew,
  products: (state) => state.products,
  protectionProductsEditMode: (state) => state.protectionProductsEditMode,
  giProductsEditMode: (state) => state.giProductsEditMode,
  selectedProduct: (state) => state.selectedProduct,
  selectedDip: (state) => state.selectedDip,
  forceRefreshMortgageScenario: (state) => state.forceRefreshMortgageScenario,
  allRequiredSections: (state) => state.allRequiredSections,
  showWorkflowValidation: (state) => state.showWorkflowValidation,
  requirementProductActionType: (state) => state.requirementProductActionType,
  workflowConfig: (state) => state.workflowConfig,
  allProviders: (state) => state.allProviders
}

function addUpdatedAllScenarios(
  { state, getters, commit },
  requirementId,
  updatedScenarios
) {
  const requirement = state.allRequirements.find((c) => c.id === requirementId)
  if (!requirement) return false

  const allScenarios = {
    manualScenarios: getters["manualScenarios"],
    sourcingScenarios: getters["sourcingScenarios"],
    mortgageScenarios: getters["mortgageScenarios"]
  }

  const updatedSourcingScenarioIds = updatedScenarios.map(
    (s) => s.sourcing_scenario_id
  )

  const sourcingScenariosToUpdate = allScenarios.sourcingScenarios.filter((s) =>
    updatedSourcingScenarioIds.includes(s.sourcing_scenario_id)
  )

  const manualAndSourcedScenarios = {
    manualScenarios: updatedScenarios,
    sourcingBrainScenarios: sourcingScenariosToUpdate
  }

  commit("clearAllScenarios")

  // manually recorded scenarios with manually recorded products
  manualAndSourcedScenarios.manualScenarios.forEach((scenario) => {
    let manualScenario = mapManualScenario(scenario)

    const manualScenarioIndex = allScenarios.manualScenarios.findIndex(
      (s) => s.scenario_ref === manualScenario.scenario_ref
    )

    if (manualScenarioIndex !== -1) {
      allScenarios.manualScenarios.splice(
        manualScenarioIndex,
        1,
        manualScenario
      )
    } else {
      allScenarios.manualScenarios.push(manualScenario)
    }

    //Manual scenario holding product for sourcing scenario when true
    if (manualScenario.sourcing_scenario_id) return

    let mortgageScenario = mapMortgageScenarioFromManualScenario(manualScenario)

    const mortgageScenarioIndex = allScenarios.mortgageScenarios.findIndex(
      (s) => s.scenario_ref === manualScenario.scenario_ref
    )

    if (mortgageScenarioIndex !== -1) {
      allScenarios.mortgageScenarios.splice(
        mortgageScenarioIndex,
        1,
        mortgageScenario
      )
    } else {
      allScenarios.mortgageScenarios.push(mortgageScenario)
    }
  })

  // sourcing integration: scenario and products from sourcing
  manualAndSourcedScenarios.sourcingBrainScenarios.forEach((scenario) => {
    let mortgageScenario = mapMortgageScenarioFromSourcingScenario(
      scenario,
      [...requirement.linked_clients],
      state.selectedRequirement.mortgage_details
        .kyc_mortgage_requirement_transaction_type_key
    )

    //add products that were manually recorded to the sourcing scenario
    let manualScenario = allScenarios.manualScenarios.find(
      (srcItem) =>
        srcItem.sourcing_scenario_id === mortgageScenario.sourcing_scenario_id
    )

    if (manualScenario) {
      addUpdateProductsToMortgageScenarioFromManualScenario(
        mortgageScenario,
        manualScenario
      )
    }

    const mortgageScenarioIndex = allScenarios.manualScenarios.findIndex(
      (s) => s.scenario_ref === mortgageScenario.scenario_ref
    )

    if (mortgageScenarioIndex !== -1) {
      allScenarios.mortgageScenarios.splice(
        mortgageScenarioIndex,
        1,
        mortgageScenario
      )
    } else {
      allScenarios.mortgageScenarios.push(mortgageScenario)
    }
  })

  commit("addAllScenarios", allScenarios)
}

function mapManualScenario(scenario) {
  return {
    ...scenario,
    scenario_ref: scenario.sourcing_scenario_id || scenario.id,
    products:
      scenario.products?.map((product) => ({
        ...product,
        scenario_ref: scenario.sourcing_scenario_id || scenario.id,
        product_ref: product.sourcing_product_id || product.id
      })) ?? []
  }
}

function mapSourcingScenario(scenario) {
  return {
    ...scenario,
    scenario_ref: scenario.sourcing_scenario_id,
    products:
      scenario.products?.map((product) => ({
        ...product,
        scenario_ref: scenario.sourcing_scenario_id,
        product_ref: product.sourcing_product_id
      })) ?? []
  }
}

function mapMortgageScenarioFromManualScenario(scenario) {
  const mortgageScenario = {
    ...scenario,
    manual: true,
    manual_scenario_ref: scenario.id
  }

  mortgageScenario.products.forEach((p) => {
    p.manual = true
    p.displayInMain = true
  })

  return mortgageScenario
}

function mapMortgageScenarioFromSourcingScenario(
  scenario,
  linkedClients,
  transactionType
) {
  const mortgageScenario = {
    ...scenario,
    manual: false,
    manual_scenario_ref: 0
  }

  //update products linked_clients field
  mortgageScenario.products.forEach((prod) => {
    prod.linked_clients = linkedClients
    prod.mortgage_application_transaction_type_key =
      prod.mortgage_application_transaction_type_key == ""
        ? transactionType
        : prod.mortgage_application_transaction_type_key
    prod.manual = false
    prod.displayInMain = true
    prod.is_pinned = false
  })

  return mortgageScenario
}

function addUpdateProductsToMortgageScenarioFromManualScenario(
  mortgageScenario,
  manualScenario
) {
  manualScenario.products.forEach((p) => {
    const prodindex = mortgageScenario.products.findIndex(
      (sp) => sp.product_ref === p.product_ref
    )

    if (p.sourcing_product_id) {
      if (prodindex !== -1) {
        mortgageScenario.products[prodindex].is_pinned = true
      }
    } else {
      p.displayInMain = false
      p.manual = true

      if (prodindex === -1) {
        mortgageScenario.products.push(p)
      } else {
        mortgageScenario.products.splice(prodindex, 1, p)
      }
    }
  })
}

function mapRequirementsForList(
  requirementData,
  clients,
  companies,
  caseId,
  context
) {
  let filteredClients = clients.filter((c) =>
    requirementData.linked_clients?.includes(c.id)
  )

  let clientCompanies = companies
    .filter((c) => requirementData.linked_company_ids?.includes(c.id))
    .reduce((options, company) => {
      options.push(company.business_name)
      return options
    }, [])

  requirementData.who = filteredClients
    .reduce((names, client) => {
      names.push(client.fullname)
      return names
    }, [])
    .concat(clientCompanies)
    .join(", ")

  let requirement = {}

  if (
    requirementData.kyc_requirement_type_key === "protection" &&
    requirementData.protection_details.needs_details
  ) {
    if (process.env.VUE_APP_ENV !== "development") {
      for (const [question] of Object.entries(
        requirementData.protection_details.needs_details
      )) {
        requirementData.protection_details.needs_details[question].needs =
          requirementData.protection_details.needs_details[question].needs.map(
            (need) => {
              let applicant_value = need.is_joint ? "joint" : need.applicant
              return {
                ...need,
                applicant_for_dropdown: applicant_value
              }
            }
          )
      }
    }
  }

  var req = getNextReqIndex(context, requirementData.kyc_requirement_type_key)
  requirementData.req = req

  context.commit("allRequirements", requirementData)

  switch (requirementData.kyc_requirement_type_key) {
    case "residential_mortgage":
      requirement = {
        case_id: caseId,
        req: req,
        requirement_no: requirementData.requirement_reference,
        status: requirementData.closure_workflow_status_requirement_key,
        requirement_status:
          requirementData.closure_workflow_status_requirement_key,
        clients: requirementData.who,
        companies: requirementData?.linked_company_ids,
        type: context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
          "q-5d62f2ec-18d4-45ab-954f-3bf937c3fbb8",
          requirementData.kyc_requirement_type_key
        ),
        advisor_id: requirementData.advisor_id,
        administrator_id: requirementData.administrator_id,
        property_use: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-b92a1aba-90af-4160-8b52-a80e8745847b",
          requirementData.mortgage_details.property_usage_key
        ),
        kyc_mortgage_requirement_transaction_type_key: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-b2bd4129-243f-4a8d-b4fb-be46b3dea939",
          requirementData.mortgage_details
            .kyc_mortgage_requirement_transaction_type_key
        ),
        id: requirementData.id,
        version: requirementData.version
      }
      context.commit("addMortgage", requirement)
      break
    case "buy_to_let_mortgage":
      requirement = {
        case_id: caseId,
        req: req,
        requirement_no: requirementData.requirement_reference,
        status: requirementData.closure_workflow_status_requirement_key,
        requirement_status:
          requirementData.closure_workflow_status_requirement_key,
        clients: requirementData.who,
        companies: requirementData?.linked_company_ids,
        type: context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
          "q-5d62f2ec-18d4-45ab-954f-3bf937c3fbb8",
          requirementData.kyc_requirement_type_key
        ),
        advisor_id: requirementData.advisor_id,
        administrator_id: requirementData.administrator_id,
        property_use: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-b92a1aba-90af-4160-8b52-a80e8745847b",
          requirementData.mortgage_details.property_usage_key
        ),
        kyc_mortgage_requirement_transaction_type_key: context.rootGetters[
          "datadictionary/displayTextByFormIdAndValue"
        ](
          "q-b2bd4129-243f-4a8d-b4fb-be46b3dea939",
          requirementData.mortgage_details
            .kyc_mortgage_requirement_transaction_type_key
        ),
        id: requirementData.id,
        mortgagePreference: "01/01/2021",
        dip: "01/01/2021",
        research: "Incomplete",
        application: "Incomplete",
        version: requirementData.version
      }
      context.commit("addMortgage", requirement)
      break
    case "protection":
      requirement = {
        case_id: caseId,
        req: req,
        requirement_no: requirementData.requirement_reference,
        status: requirementData.closure_workflow_status_requirement_key,
        requirement_status:
          requirementData.closure_workflow_status_requirement_key,
        who: requirementData.who,
        type: context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
          "q-5d62f2ec-18d4-45ab-954f-3bf937c3fbb8",
          requirementData.kyc_requirement_type_key
        ),
        advisor_id: requirementData.advisor_id,
        administrator_id: requirementData.administrator_id,
        protection_details: requirementData.protection_details,
        general_insurance_details: requirementData.general_insurance_details,
        id: requirementData.id,
        version: requirementData.version
      }
      context.commit("addInsurance", requirement)
      break
    case "buildings_contents_insurance":
      requirement = {
        case_id: caseId,
        req: req,
        requirement_no: requirementData.requirement_reference,
        status: requirementData.closure_workflow_status_requirement_key,
        requirement_status:
          requirementData.closure_workflow_status_requirement_key,
        who: requirementData.who,
        type: context.rootGetters["datadictionary/displayTextByFormIdAndValue"](
          "q-5d62f2ec-18d4-45ab-954f-3bf937c3fbb8",
          requirementData.kyc_requirement_type_key
        ),
        advisor_id: requirementData.advisor_id,
        administrator_id: requirementData.administrator_id,
        protection_details: requirementData.protection_details,
        general_insurance_details: requirementData.general_insurance_details,
        id: requirementData.id,
        version: requirementData.version
      }
      context.commit("addGInsurance", requirement)
      break
  }
}

function getNextReqIndex(context, type) {
  switch (type) {
    case "residential_mortgage":
    case "buy_to_let_mortgage":
      return context.getters["mortgage"].length + 1
    case "protection":
      return context.getters["insurance"].length + 1
    case "buildings_contents_insurance":
      return context.getters["ginsurance"].length + 1
  }
}
