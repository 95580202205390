import rawAxios from "axios"

export const namespaced = true

export const state = {
  addresses: []
}

export const getters = {
  addresses: (state) => state.addresses
}

export const mutations = {
  setAddresses: (state, addresses) => (state.addresses = addresses)
}

export const actions = {
  async search({ commit }, address) {
    // patch solution for https://mortgageengine.atlassian.net/browse/CB-1744
    const axios =
      process.env.NODE_ENV === "production" ? this.$mbAxios : rawAxios

    return axios
      .get(
        `${process.env.VUE_APP_PROPERTY_URL}/address?query=${encodeURIComponent(
          address
        )}`
      )
      .then((response) => {
        commit(
          "setAddresses",
          response.data?.addresses.map((address) => ({
            ...address,
            fullAddress: `${address.international_line_1}, ${address.town}, ${address.postcode}`
            //international_line_1 is mapped from hometrack line_1 which is for both UK and international addresses
          })) ?? []
        )
      })
      .catch((error) => {
        commit("setAddresses", [])
        Promise.reject(error)
      })
  },
  clear({ commit }) {
    commit("setAddresses", [])
  }
}
