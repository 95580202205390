const dashboardRoutes = [
  {
    path: "dashboard",
    name: "client-case-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "client-case-dashboard" */ "@/components/ux/clients/cases/CaseDashboard.vue"
      ),
    meta: {
      //requiresAuthCase: true,
      caseNavActive: true,
      title: "Case Dashboard",
      blockIfProduction: true
    }
  }
]

export { dashboardRoutes }
