<template>
  <multiselect
    :id="componentId"
    v-model="value"
    style="min-width: 250px"
    :class="{
      'multiselect-required-border': highlightAsRequired
    }"
    :placeholder="placeholder"
    select-label=""
    :track-by="'text'"
    :label="'text'"
    :readonly="true"
    :multiple="true"
    :close-on-select="false"
    :clear-on-select="true"
    :options="getOptions()"
    :hide-selected="true"
    :searchable="false"
    :preselect-first="preselectOnSingleOption"
    :disabled="disabled"
  >
    <!--
      :limit="x" // will limit the amount of items shown selected
      :limit-text="function" // function which recieves a function that returns a string to show
      when :limit is reached.
      :options-limit // limits amount you are able to put in the list
      :hide-selected="true" // once an item is selected, it will be removed from the list.
    -->
  </multiselect>
</template>
<script>
import { Multiselect } from "vue-multiselect"
import { mapGetters } from "vuex"

export default {
  name: "MultiSelect",
  components: {
    Multiselect
  },
  props: {
    options: {
      type: Array,
      required: false,
      default: null
    },

    placeholder: {
      type: String,
      required: false,
      default: "Select all that apply..."
    },
    name: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    guid: {
      type: String,
      default: null,
      required: false
    },
    display: {
      type: Array,
      default: null,
      required: false
    },
    preSelect: {
      type: Boolean,
      default: true,
      required: false
    },
    highlightAsRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.default
    }
  },

  computed: {
    ...mapGetters({
      fieldByFormId: "datadictionary/fieldByFormID",
      selectedClients: "clientFile/selectedClients"
    }),
    preselectOnSingleOption() {
      return this.options.length == 1 ? (this.preSelect ? true : false) : false
    }
  },

  watch: {
    disabled: function () {
      this.disabledProperties()
    }
  },
  created() {
    if (this.display) {
      let clientNames = this.getClientNames()
      this.value = clientNames
    }
  },
  mounted() {
    this.disabledProperties()
  },
  methods: {
    selectedValues() {
      this.$emit("v-model", this.value)
      let multiSelect = document.querySelector(
        `[aria-owns="listbox-${this.componentId}"]`
      )
      if (multiSelect) {
        if (this.value?.length == this.options?.length) {
          let optionDialog = multiSelect.getElementsByClassName(
            "multiselect__content"
          )[0]
          optionDialog?.classList.add("d-none")
        } else {
          let optionDialog = multiSelect.getElementsByClassName(
            "multiselect__content"
          )[0]
          optionDialog?.classList.remove("d-none")
        }
      }
    },

    disabledProperties() {
      let multiSelect = document.querySelector(
        `[aria-owns="listbox-${this.componentId}"]`
      )
      if (multiSelect) {
        let selectIcon = multiSelect.getElementsByClassName(
          "multiselect__select"
        )[0]
        let removeIcon = multiSelect.getElementsByClassName(
          "multiselect__tag-icon"
        )
        if (this.disabled) {
          selectIcon?.classList.add("d-none")
          for (let icon of removeIcon) {
            icon?.classList.add("d-none")
          }
        } else {
          selectIcon?.classList.remove("d-none")
          for (let icon of removeIcon) {
            icon?.classList.remove("d-none")
          }
        }
      }
    },
    getClientNames() {
      let clientNames = []
      this.display.forEach((element) => {
        var client = this.selectedClients.find((e) => e.id == element)
        if (client) {
          clientNames.push({ text: client.fullname, value: client.id })
        }
      })

      return clientNames
    },
    limitText(amount) {
      return "and " + amount + " more.."
    },
    getOptions() {
      if (this.options) {
        this.selectedValues()
        return this.options
      }
    }
  }
}
</script>
<style>
.multiselect__select {
  background: white !important;
  border-radius: 10px;
  width: 25px;
  height: 35px;
}
.multiselect__tag {
  line-height: 0.9;
}
.multiselect__tags {
  min-height: 36px;
  line-height: 0.9 !important;
}
.multiselect--disabled {
  min-height: 36px;
  opacity: 0.7;
}
.multiselect__placeholder {
  margin-top: 5px;
  color: black !important;
  font-size: 13px;
}
.multiselect {
  min-width: 150px;
  width: fit-content;
}
</style>
