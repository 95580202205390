export const ukAddressFields = Object.freeze([
  "flat_number",
  "house_number",
  "building_name",
  "street",
  "district",
  "town",
  "county",
  "uk_country_key",
  "postcode"
])

export const internationalAddressFields = Object.freeze([
  "international_line_1",
  "international_line_2",
  "international_line_3",
  "international_line_4",
  "country_key"
])
